import Fraction from 'fraction.js';
import { toNearest } from '../../../util';

const adjustPrecision = toNearest(0.0625);

export const TYPES_WITH_GLASS = ['fw', 'sh', 'hr', 'fd', 'sgd', 'glass'];

export const TYPES_WITH_BALANCE = ['sh'];

export const reduceGlass = (estimate) => {
  const reduced = estimate.systems
    .filter(({ type, details }) => {
      const shape = details.find(({ type }) => type === 'shape');
      return (
        TYPES_WITH_GLASS.includes(type) &&
        (!shape || shape.desc === 'rectangle')
      );
    })
    .reduce((acc, cur) => {
      const glass = cur.verticals.map(({ glass }) => glass).flat();
      return glass.reduce((glassAcc, glassCur) => {
        const { glassComposition, width, lHeight, rHeight, qty } = glassCur;
        const key = `${
          glassComposition.composition
        }-${width}-${lHeight}-${rHeight}`;
        return {
          ...glassAcc,
          [key]: {
            ...glassCur,
            qty: (glassAcc[key] ? glassAcc[key].qty : 0) + qty * cur.qty,
          },
        };
      }, acc);
    }, {});

  return Object.values(reduced);
};

const reduceBalances = (estimate) => {
  const reduced = estimate.systems
    .filter(({ type }) => TYPES_WITH_BALANCE.includes(type))
    .reduce((acc, cur) => {
      const balances = cur.hardware.filter(({ type }) => type === 'balance');
      return balances.reduce(
        (balanceAcc, { desc, qty }) => ({
          ...balanceAcc,
          [desc]: {
            desc,
            qty: (balanceAcc[desc] ? balanceAcc[desc].qty : 0) + qty,
          },
        }),
        acc
      );
    }, {});

  return Object.values(reduced);
};

export const poItems = (type, estimate) => {
  if (type === 'glass') {
    const glass = reduceGlass(estimate);
    return glass.map(
      ({ qty, width, lHeight, rHeight, glassComposition: { composition } }) => {
        const w = new Fraction(adjustPrecision(width)).toFraction(true);
        const h = new Fraction(
          adjustPrecision(Math.max(lHeight, rHeight))
        ).toFraction(true);

        return {
          qty,
          itemNo: composition,
          description: `${w}" x ${h}"`,
        };
      }
    );
  }

  if (type === 'balance') {
    const balances = reduceBalances(estimate);
    return balances.map(({ desc, qty }) => ({
      qty,
      itemNo: desc,
      description: 'Balance',
    }));
  }

  return [];
};
