import React from 'react';
import { useMutation } from 'react-apollo';
import { Link, Redirect } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { signedIn } from './auth';
import { RESET_PASSWORD } from '../mutations';

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Password is Required';
  } else if (values.password.length < 8) {
    errors.password = 'Password has to be 8 characters or longer';
  }
  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = 'Confirmation does not match password';
  }
  return errors;
};

const ResetPassword = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const email = params.get('email');

  if (signedIn()) return <Redirect to="/" />;
  if (!(token && email)) return <Redirect to="/signin" />;

  const [
    forgotPassword,
    { error: mutationErrors, loading, data },
  ] = useMutation(RESET_PASSWORD);
  const complete = !loading && data;

  return (
    <Form
      onSubmit={(values) =>
        forgotPassword({ variables: { ...values, email, resetToken: token } })
      }
      validate={validate}
    >
      {({ handleSubmit, valid, errors, touched }) => (
        <div className="reset-password-container">
          <div className="reset-password-form p-4">
            <div className="content">
              <h4 className="font-weight-light text-center">
                Change your password
              </h4>

              {complete ? (
                <div className="mt-4">
                  <h6 className="font-weight-light text-center text-success">
                    Your password was successfully changed. Please sign in using
                    your new password.
                  </h6>
                  <Link
                    to="/signin"
                    className="btn btn-outline-primary btn-block mt-4"
                  >
                    <small>Sign in</small>
                  </Link>
                </div>
              ) : (
                <>
                  <h6 className="font-weight-light text-center">
                    Enter your new password and password confirmation
                  </h6>
                  {(errors.passwordConfirmation &&
                    touched.passwordConfirmation) ||
                    (mutationErrors && (
                      <div className="alert alert-danger mb-3">
                        {mutationErrors &&
                          mutationErrors.graphQLErrors
                            .map(({ message }) => message)
                            .join('.')}
                        {errors.passwordConfirmation &&
                          touched.passwordConfirmation &&
                          errors.passwordConfirmation}
                      </div>
                    ))}
                  <form onSubmit={handleSubmit} className="mt-5">
                    <Field
                      name="password"
                      render={({ input }) => (
                        <div className="input-container">
                          <input
                            {...input}
                            type="password"
                            placeholder="password"
                            className="form-control"
                          />
                          <FontAwesomeIcon
                            icon={['fal', 'unlock-alt']}
                            size="lg"
                          />
                        </div>
                      )}
                    />
                    <Field
                      name="passwordConfirmation"
                      render={({ input }) => (
                        <div className="input-container">
                          <input
                            {...input}
                            type="password"
                            placeholder="password confirmation"
                            className="form-control"
                          />
                          <FontAwesomeIcon
                            icon={['fal', 'unlock-alt']}
                            size="lg"
                          />
                        </div>
                      )}
                    />
                    <small className="text-muted">
                      *Password has to be 8 characters or more
                    </small>
                    <button
                      type="submit"
                      className="btn btn-outline-primary btn-block mt-4"
                      disabled={!valid || loading}
                    >
                      Change password
                    </button>
                  </form>
                </>
              )}

              {!complete && (
                <div className="mt-4 text-center">
                  <Link to="/signin">
                    <small>Sign in</small>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};

export default ResetPassword;
