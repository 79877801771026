import gql from 'graphql-tag';

export const SYSTEM = gql`
  query System($id: ID!) {
    system(id: $id) {
      id
      mark
      qty
      positiveLoad
      negativeLoad
      type
      config
      imagePath
      priceSqft
      sellPrice
      sellPriceTotal
      metaSellPrice
      metaSellPriceTotal
      autoPrice
      frameColor {
        id
        color
      }
      verticals {
        index
        width
        lHeight
        rHeight
        lites
        priceSqft
        extrusions {
          type
          lengths
          lengths
        }
        glass {
          width
          lHeight
          rHeight
          qty
          glassComposition {
            id
            composition
            heatTreatment
            type
            thickness
            interlayer
          }
        }
        screen {
          width
          height
          qty
        }
      }
      hardware {
        type
        code
        desc
        qty
      }
      details {
        type
        desc
      }
    }
  }
`;
