import React, { Component } from "react";
import PropTypes from "prop-types";
import currency from "currency.js";
import { Mutation, Query } from "react-apollo";
import { Form, Field } from "react-final-form";
import ValidatedField from "../general/ValidatedField";
import { PAYMENT_CREATE, PAYMENT_UPDATE } from "../../mutations";
import { ESTIMATE_SYSTEMS, PAYMENT } from "../../queries";

const PaymentMutation = props => {
  let formRef;
  const {
    formId,
    mutation,
    initialValues,
    updateCache,
    onCompleted,
    validate
  } = props;
  return (
    <Mutation
      mutation={mutation}
      update={updateCache}
      onCompleted={() => onCompleted(formRef)}
    >
      {mutation => (
        <Form
          onSubmit={variables =>
            mutation({
              variables: { ...variables, amount: variables.amount.toString() }
            })
          }
          validate={validate}
          initialValues={initialValues}
        >
          {({ handleSubmit, form }) => {
            formRef = form;
            return (
              <form id={formId} onSubmit={handleSubmit}>
                <Field name="id">
                  {input => <input {...input} type="hidden"></input>}
                </Field>
                <ValidatedField name="amount" label="Amount" type="number" />
              </form>
            );
          }}
        </Form>
      )}
    </Mutation>
  );
};

const PaymentQuery = ({
  paymentId,
  updateCache,
  onCompleted,
  validate,
  initialValues
}) => (
  <Query query={PAYMENT} variables={{ id: paymentId }}>
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error</p>;

      const amount = currency(data.payment.amount);

      return (
        <PaymentMutation
          formId="update-payment-form"
          mutation={PAYMENT_UPDATE}
          initialValues={{
            ...data.payment,
            ...initialValues,
            amount
          }}
          updateCache={updateCache}
          onCompleted={onCompleted}
          validate={validate}
        />
      );
    }}
  </Query>
);

export default class PaymentForm extends Component {
  static defaultProps = {
    onCompleted: () => {}
  };

  validate = values => {
    const errors = {};
    const required = ["amount"];
    required.forEach(r => !values[r] && (errors[r] = "Required"));
    return errors;
  };

  updateCacheOnCreate = (cache, { data: { paymentCreate } }) => {
    const { estimateId } = this.props;
    const data = cache.readQuery({
      query: ESTIMATE_SYSTEMS,
      variables: { id: estimateId }
    });
    const payments = [paymentCreate].concat(data.estimate.payments);
    cache.writeQuery({
      query: ESTIMATE_SYSTEMS,
      variables: { id: estimateId },
      data: { ...data, estimate: { ...data.estimate, payments } }
    });
  };

  updateCacheOnUpdate = (cache, { data: { paymentUpdate } }) => {
    const data = cache.readQuery({
      query: PAYMENT,
      variables: { id: paymentUpdate.id }
    });
    data.payment = paymentUpdate;
    cache.writeQuery({
      query: PAYMENT,
      variables: { id: paymentUpdate.id },
      data
    });
  };

  render() {
    const { paymentId, estimateId, onCompleted } = this.props;
    return paymentId ? (
      <PaymentQuery
        paymentId={paymentId}
        updateCache={this.updateCacheOnUpdate}
        onCompleted={onCompleted}
        validate={this.validate}
        initialValues={{ estimateId }}
      />
    ) : (
      <PaymentMutation
        formId="create-payment-form"
        mutation={PAYMENT_CREATE}
        updateCache={this.updateCacheOnCreate}
        onCompleted={onCompleted}
        validate={this.validate}
        initialValues={{ estimateId }}
      />
    );
  }
}

PaymentForm.propTypes = {
  onCompleted: PropTypes.func.isRequired
};
