import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAt,
  faBadgeDollar,
  faCheckSquare,
  faChevronCircleRight,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronLeft,
  faClipboardCheck,
  faCopy,
  faEdit,
  faEllipsisHAlt,
  faFileChartLine,
  faHourglassStart,
  faInboxIn,
  faInboxOut,
  faListUl,
  faPlus,
  faPrint,
  faSave,
  faSearch,
  faSignOut,
  faSquare,
  faTimes,
  faTrashAlt,
  faTruck,
  faUnlockAlt,
  faUserCircle,
} from '@fortawesome/pro-light-svg-icons';

export default () => {
  library.add(
    faPlus,
    faEdit,
    faEllipsisHAlt,
    faTrashAlt,
    faListUl,
    faPrint,
    faTimes,
    faUnlockAlt,
    faAt,
    faSignOut,
    faSave,
    faFileChartLine,
    faClipboardCheck,
    faChevronLeft,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faBadgeDollar,
    faInboxOut,
    faInboxIn,
    faTruck,
    faSearch,
    faSquare,
    faCheckSquare,
    faUserCircle,
    faChevronCircleRight,
    faHourglassStart,
    faCopy
  );
};
