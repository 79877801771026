import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Search from '../Search';
import { SEARCH } from '../../../queries';
import { useDebounce } from '../../../util';

const handleOnSelect = (value, history) => {
  const [type, id] = value.split(':');
  // eslint-disable-next-line default-case
  switch (type) {
    case 'Client':
      history.push(`/clients/${id}`);
      break;
    case 'Estimate':
      history.push(`/estimates/${id}`);
      break;
  }
};

const NavbarSearch = ({ history }) => {
  const [term, setTerm] = useState('');
  const debouncedTerm = useDebounce(term, 500);

  return (
    <Query
      query={SEARCH}
      variables={{ term: debouncedTerm }}
      fetchPolicy="network-only"
    >
      {({ data: { search: data } = {} }) => {
        return (
          <Search
            term={term}
            items={(data || []).map((item) => ({ ...item, label: item.name }))}
            onChange={(e) => setTerm(e.target.value)}
            onSelect={(value) => handleOnSelect(value, history)}
            renderItem={(item, highlighted) => {
              return (
                <div
                  key={`${item.__typename}-${item.id}`}
                  className="px-2 py-1"
                  style={{
                    backgroundColor: highlighted ? '#eeebf5' : 'transparent',
                  }}
                >
                  <span className="d-block text-dark">{item.title}</span>
                  <small className="text-muted">
                    {item.__typename === 'Estimate'
                      ? item.subTitle.padStart(6, 0)
                      : item.subTitle}
                  </small>
                </div>
              );
            }}
          />
        );
      }}
    </Query>
  );
};

export default withRouter(NavbarSearch);
