import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-apollo";
import AuthContext, { defaultState } from "./AuthContext";
import { CURRENT_USER } from "../queries";

const AuthProvider = ({ children }) => {
  const { data: { currentUser } = defaultState, loading } = useQuery(
    CURRENT_USER
  );

  if (loading) return null;

  return (
    <AuthContext.Provider value={{ ...defaultState, currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

export default AuthProvider;
