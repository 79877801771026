import React from 'react';
import companyLogo from '../../assets/images/company-logo.png';

export default ({ children, client }) => (
  <div className="company-header mb-4 clearfix">
    <img
      src={client ? `${client.logoPath}?${new Date().getTime()}` : companyLogo}
      alt="company logo"
      className="float-left mr-4"
    />
    <div className="company-info float-left">
      {client ? (
        <>
          <p>{client.address}</p>
          <p>{`${client.city}, ${client.state} ${client.zip}`}</p>
          {client.phone && <p>Phone: {client.phone}</p>}
          {client.email && <p>Email: {client.email}</p>}
        </>
      ) : (
        <>
          <p>1606 W 31th Pl</p>
          <p>Hialeah, Fl 33012</p>
          <p>Phone: (305) 216-4554</p>
          <p>Email: sales@luxgard.com</p>
        </>
      )}
    </div>
    <div className="right float-right">{children}</div>
  </div>
);
