import React from "react";
import Fraction from "fraction.js";
import { toNearest } from "../../../util";

const adjustPrecision = toNearest(0.0625);

// TODO: This will not work properly on systems with multiple verticals
export default ({ system: { verticals, qty } }) =>
  verticals.map((v, i) => {
    if (!v.screen) return null;
    return (
      <table
        className="table table-bordered table-sm screen-list"
        key={i}
        style={{ fontSize: 12 }}
      >
        <thead>
          <tr>
            <th>Screen Horizontals</th>
            <th>Screen Verticals</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {v.screen.qty * 2 * qty} @{" "}
              {new Fraction(adjustPrecision(v.screen.width)).toFraction(true)}"
            </td>
            <td>
              {v.screen.qty * 2 * qty} @{" "}
              {new Fraction(adjustPrecision(v.screen.height)).toFraction(true)}"
            </td>
          </tr>
        </tbody>
      </table>
    );
  });
