import { quotation } from '../quotation';
import Fraction from 'fraction.js';
import { toNearest, round } from '../../util';

const toFraction = (x) => new Fraction(toNearest(0.0625)(x)).toFraction(true);
const WIDTH = 1.93;

export const octagon = (two, side) => {
  if (!(two && side)) return;

  const radius = (side * Math.sqrt(4 + 2 * Math.sqrt(2))) / 2;
  const width = side * (1 + Math.sqrt(2));

  const group = two.makeGroup(
    two.makePolygon(radius, radius, radius, 8),
    two.makePolygon(radius, radius, radius - WIDTH, 8)
  );

  group.linewidth = 0.3;

  quotation({
    two,
    length: side,
    pos: 'down',
    x: radius - side / 2,
    y: radius + width / 2,
    separation: 6,
    label: `${toFraction(round(side, 4))}"`,
  });

  quotation({
    two,
    length: width,
    pos: 'down',
    y: radius + side / 2,
    x: radius - width / 2,
    separation: (width - side) / 2 + 12,
    label: `${toFraction(width)}"`,
  });

  quotation({
    two,
    length: width,
    pos: 'down',
    dir: 'vertical',
    x: radius + side / 2,
    y: radius - width / 2,
    separation: (width - side) / 2 + 10,
    label: `${toFraction(width)}"`,
  });

  return [-3, -3, radius * 2 + 6 + 10, radius * 2 + 6 + 12];
};
