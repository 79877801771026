import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import { AuthContext } from "../../../auth";
import Modal from "../../general/Modal";
import UserForm from "../../users/UserForm";

const submitForm = () => {
  document
    .getElementById("update-user-form")
    .dispatchEvent(new Event("submit", { cancelable: true }));
};

const onCompleted = form => {
  $("#user-profile").modal("hide");
  setTimeout(form.reset);
};

export default () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <>
      <button
        className="btn btn-link"
        onClick={() => $("#user-profile").modal()}
      >
        <FontAwesomeIcon
          icon={["fal", "user-circle"]}
          size="lg"
          className="mr-2"
        />
        {currentUser.email}
      </button>
      <Modal
        modalId="user-profile"
        title="Your Profile"
        onContinue={submitForm}
      >
        <UserForm userId={currentUser.id} onCompleted={onCompleted} />
      </Modal>
    </>
  );
};
