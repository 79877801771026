import { circle } from './circle';
import { halfCircle } from './halfCircle';
import { eyebrow } from './eyebrow';
import { fan } from './fan';
import { hexagon } from './hexagon';
import { octagon } from './octagon';
import { rectangle } from './rectangle';

export const fw = (two, width, lHeight, rHeight, shape) => {
  switch (shape) {
    case 'rectangle':
      return rectangle(two, width, lHeight, lHeight);

    case 'trapezoid':
      return rectangle(two, width, lHeight, rHeight);

    case 'circle':
      return circle(two, width / 2);

    case 'halfcircle':
      return halfCircle(two, width / 2);

    case 'eyebrow':
      return eyebrow(two, width, lHeight, rHeight);

    case 'fan':
      return fan(two, width, lHeight);

    case 'hexagon':
      return hexagon(two, width / 2);

    case 'octagon':
      return octagon(two, width / (1 + Math.sqrt(2)));

    default:
      break;
  }
};
