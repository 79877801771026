import React, { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

export default () => {
  const history = useHistory();
  const [month, setMonth] = useState(new Date());

  const dateParam = moment(month).format('Y-MM-DD');

  return (
    <div className="report">
      <div>
        <h5 className="title">Monthly Orders</h5>
        <DatePicker
          selected={month}
          onChange={(date) => setMonth(date)}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          className="form-control"
        />
      </div>
      <Button
        variant="link"
        onClick={() =>
          history.push(`/reports/monthly-orders?date=${dateParam}`)
        }
      >
        <FontAwesomeIcon icon={['fal', 'chevron-circle-right']} size="lg" />
      </Button>
    </div>
  );
};
