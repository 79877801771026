import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from 'react-apollo';
import { Form, Field } from 'react-final-form';
import ValidatedField from '../general/ValidatedField';
import ValidatedSelect from '../general/ValidatedSelect';
import Checkbox from '../general/Checkbox';
import {
  GLASS_COMPOSITION_CREATE,
  GLASS_COMPOSITION_UPDATE,
} from '../../mutations';
import { GLASS_COMPOSITIONS_LIST, GLASS_COMPOSITION } from '../../queries';
import {
  heatTreatments,
  types,
  thickness,
  interlayers,
  interlayerThickness,
} from './constants';

const GlassCompositionMutation = (props) => {
  let formRef;
  const {
    formId,
    mutation,
    initialValues,
    updateCache,
    onCompleted,
    validate,
  } = props;
  return (
    <Mutation
      mutation={mutation}
      update={updateCache}
      onCompleted={() => onCompleted(formRef)}
    >
      {(mutation) => (
        <Form
          onSubmit={(variables) =>
            mutation({
              variables: Object.fromEntries(
                Object.entries({
                  ...variables,
                  markup: parseFloat(variables.markup),
                }).map(([k, v]) => [k, v === 'None' ? null : v])
              ),
            })
          }
          validate={validate}
          initialValues={initialValues}
        >
          {({ handleSubmit, form }) => {
            formRef = form;
            return (
              <form id={formId} onSubmit={handleSubmit}>
                <Field name="id">
                  {(input) => <input {...input} type="hidden" />}
                </Field>
                <ValidatedField name="composition" label="Composition" />
                <ValidatedSelect name="heatTreatment" label="Heat Treatment">
                  <option>None</option>
                  {heatTreatments.map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </ValidatedSelect>
                <ValidatedSelect name="type" label="Type">
                  <option>None</option>
                  {types.map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </ValidatedSelect>
                <ValidatedSelect name="thickness" label="Thickness">
                  <option>None</option>
                  {thickness.map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </ValidatedSelect>
                <ValidatedSelect name="interlayer" label="Interlayer">
                  <option>None</option>
                  {interlayers.map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </ValidatedSelect>
                <ValidatedSelect
                  name="interlayerThickness"
                  label="Interlayer Thickness"
                >
                  <option>None</option>
                  {interlayerThickness.map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </ValidatedSelect>
                <ValidatedField name="markup" label="Markup" type="number" />
                <Checkbox name="glassOnly" label="Glass Only" />
              </form>
            );
          }}
        </Form>
      )}
    </Mutation>
  );
};

const GlassCompositionQuery = ({
  glassCompositionId,
  updateCache,
  onCompleted,
  validate,
}) => (
  <Query query={GLASS_COMPOSITION} variables={{ id: glassCompositionId }}>
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error</p>;

      return (
        <GlassCompositionMutation
          formId="update-glass-composition-form"
          mutation={GLASS_COMPOSITION_UPDATE}
          initialValues={data.glassComposition}
          updateCache={updateCache}
          onCompleted={onCompleted}
          validate={validate}
        />
      );
    }}
  </Query>
);

export default class GlassCompositionForm extends Component {
  static defaultProps = {
    onCompleted: () => {},
  };

  validate = (values) => {
    const errors = {};
    const required = ['composition', 'markup'];
    required.forEach(
      (r) => !values[r] && values[r] !== 0 && (errors[r] = 'Required')
    );
    return errors;
  };

  updateCacheOnCreate = (cache, { data: { glassCompositionCreate } }) => {
    const { glassCompositions } = cache.readQuery({
      query: GLASS_COMPOSITIONS_LIST,
    });
    cache.writeQuery({
      query: GLASS_COMPOSITIONS_LIST,
      data: {
        glassCompositions: [glassCompositionCreate].concat(glassCompositions),
      },
    });
  };

  updateCacheOnUpdate = (cache, { data: { glassCompositionUpdate } }) => {
    const { glassCompositions } = cache.readQuery({
      query: GLASS_COMPOSITIONS_LIST,
    });
    cache.writeQuery({
      query: GLASS_COMPOSITIONS_LIST,
      data: {
        glassCompositions: glassCompositions
          .filter((g) => g.id !== glassCompositionUpdate.id)
          .concat([glassCompositionUpdate]),
      },
    });
  };

  render() {
    const { glassCompositionId, onCompleted } = this.props;
    return glassCompositionId ? (
      <GlassCompositionQuery
        glassCompositionId={glassCompositionId}
        updateCache={this.updateCacheOnUpdate}
        onCompleted={onCompleted}
        validate={this.validate}
      />
    ) : (
      <GlassCompositionMutation
        formId="create-glass-composition-form"
        mutation={GLASS_COMPOSITION_CREATE}
        updateCache={this.updateCacheOnCreate}
        onCompleted={onCompleted}
        validate={this.validate}
      />
    );
  }
}

GlassCompositionForm.propTypes = {
  onCompleted: PropTypes.func.isRequired,
};
