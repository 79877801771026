import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import { ESTIMATE_SYSTEMS } from '../../queries';
import { PAYMENT_DELETE } from '../../mutations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Redirect } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment';
import Moment from 'react-moment';
import bootbox from 'bootbox';
import PaymentForm from './PaymentForm';
import Modal from '../general/Modal';
import { AuthContext } from '../../auth';

class Actions extends Component {
  updateCache = (cache, { data: { paymentDelete } }) => {
    const { estimateId } = this.props;
    const data = cache.readQuery({
      query: ESTIMATE_SYSTEMS,
      variables: { id: estimateId },
    });
    data.estimate.payments = data.estimate.payments.filter(
      (p) => p.id !== paymentDelete.id
    );
    cache.writeQuery({
      query: ESTIMATE_SYSTEMS,
      variables: { id: estimateId },
      data,
    });
  };

  render() {
    const { paymentId: id, editPayment } = this.props;

    return (
      <Mutation
        mutation={PAYMENT_DELETE}
        variables={{ id }}
        update={this.updateCache}
      >
        {(mutation) => (
          <div className="dropdown float-right mr-3">
            <button
              onClick={(e) => e.stopPropagation()}
              className="btn btn-link text-secondary p-0"
              type="button"
              id="estimate-options-menu"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <FontAwesomeIcon icon={['fal', 'ellipsis-h-alt']} />
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="estimate-options-menu"
            >
              <button className="dropdown-item" onClick={() => editPayment(id)}>
                <FontAwesomeIcon icon={['fal', 'edit']} className="mr-3" />
                Edit
              </button>
              <button
                className="dropdown-item"
                onClick={(e) => {
                  e.stopPropagation();
                  bootbox.confirm('Are you sure?', (r) => {
                    r && mutation();
                  });
                }}
              >
                <FontAwesomeIcon icon={['fal', 'trash-alt']} className="mr-3" />
                Delete
              </button>
            </div>
          </div>
        )}
      </Mutation>
    );
  }
}

export default class Payments extends Component {
  static contextType = AuthContext;

  state = {
    selectedPaymentId: null,
  };

  editPayment = (selectedPaymentId) => {
    this.setState({ selectedPaymentId }, () => {
      $('#edit-payment-modal').modal();
    });
  };

  submitPaymentForm = (id) => {
    document
      .getElementById(id)
      .dispatchEvent(new Event('submit', { cancelable: true }));
  };

  onPaymentFormCompleted = (form) => {
    $('#new-payment-modal').modal('hide');
    $('#edit-payment-modal').modal('hide');
    setTimeout(form.reset);
  };

  render() {
    const {
      history,
      match: {
        url,
        params: { estimateId },
      },
    } = this.props;
    const { currentUser } = this.context;

    if (!currentUser.internal) return <Redirect to="/" />;

    return (
      <Query query={ESTIMATE_SYSTEMS} variables={{ id: estimateId }}>
        {({ loading, error, data }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error</p>;

          const {
            estimate: { payments },
          } = data;

          return (
            <div className="payments-component">
              <div className="header clearfix">
                <h4 className="float-left mt-2">
                  <Link to={`/estimates/${estimateId}`}>
                    <FontAwesomeIcon
                      icon={['fal', 'chevron-left']}
                      className="mr-4"
                    />
                  </Link>
                  Payments
                </h4>
                <button
                  className="btn btn-primary float-right mb-3"
                  data-toggle="modal"
                  data-target="#new-payment-modal"
                >
                  New{' '}
                  <FontAwesomeIcon icon={['fal', 'plus']} className="ml-2" />
                </button>
              </div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Amount</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {payments
                    .sort((a, b) => moment(a.insertedAt).diff(b.insertedAt))
                    .map((p) => {
                      return (
                        <tr
                          key={p.id}
                          onClick={() => history.push(`${url}/${p.id}/print`)}
                        >
                          <td>
                            <Moment date={p.insertedAt} format="MM/DD/YYYY" />
                          </td>
                          <td>{p.amount}</td>
                          <td>
                            <Actions
                              paymentId={p.id}
                              estimateId={estimateId}
                              editPayment={this.editPayment}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              <Modal
                modalId="new-payment-modal"
                title="New Payment"
                onContinue={() => this.submitPaymentForm('create-payment-form')}
              >
                <PaymentForm
                  onCompleted={this.onPaymentFormCompleted}
                  estimateId={estimateId}
                />
              </Modal>
              <Modal
                modalId="edit-payment-modal"
                title="Edit Payment"
                onContinue={() => this.submitPaymentForm('update-payment-form')}
              >
                <PaymentForm
                  onCompleted={this.onPaymentFormCompleted}
                  paymentId={this.state.selectedPaymentId}
                  estimateId={estimateId}
                />
              </Modal>
            </div>
          );
        }}
      </Query>
    );
  }
}
