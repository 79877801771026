import findWindLoads from "./findWindLoads";
import fwTables from "./fwTables";
import shTables from "./shTables";
import hrTables from "./hrTables";

const calc = tables => (width, height, glass, config) =>
  findWindLoads(tables, width, height, glass, config);

export const fw = calc(fwTables);
export const sh = calc(shTables);
export const hr = calc(hrTables);
