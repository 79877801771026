import gql from 'graphql-tag'

export const PAYMENT_CREATE = gql`
  mutation PaymentCreate(
    $estimateId: ID!,
    $amount: String!
  ) {
    paymentCreate(
      estimateId: $estimateId,
      amount: $amount
    ) {
      id amount insertedAt
    }
  }
`

export const PAYMENT_UPDATE = gql`
  mutation PaymentUpdate(
    $id: ID!,
    $amount: String
  ) {
    paymentUpdate(
      id: $id,
      amount: $amount
    ) {
      id amount insertedAt
    }
  }
`

export const PAYMENT_DELETE = gql`
  mutation PaymentDelete($id: ID!) {
    paymentDelete(id: $id) {
      id
    }
  }
`