import gql from 'graphql-tag'

export const FRAME_COLOR_CREATE = gql`
  mutation FrameColorCreate(
    $color: String!,
  ) {
    frameColorCreate(color: $color) {
      id
      color
    }
  }
`

export const FRAME_COLOR_UPDATE = gql`
  mutation FrameColorUpdate(
    $id: ID!,
    $color: String,
  ) {
    frameColorUpdate(id: $id, color: $color) {
      id
      color
    }
  }
`

export const FRAME_COLOR_DELETE = gql`
  mutation FrameColorDelete($id: ID!) {
    frameColorDelete(id: $id) {
      id
    }
  }
`