import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import price from '../../price';
import { useSGDPrices } from './useSGDPrices';

export const usePriceUpdate = (formCtx, glassCompositions, markups) => {
  const prices = useSGDPrices();
  const [config, width, height, autoPrice, glassCompositionId] = useWatch({
    name: ['config', 'width', 'height', 'autoPrice', 'glassCompositionId'],
    control: formCtx.control,
  });

  useEffect(
    () => {
      const sgdPrice = price({
        type: 'sgd',
        compositions: glassCompositions,
        markups,
        prices,
        autoPrice,
        price: 'default',
        glassCompositionId,
        width,
        lHeight: height,
        rHeight: height,
      });

      if (sgdPrice !== 'default') formCtx.setValue('price', sgdPrice);
    },
    [config, width, height, autoPrice, prices, glassCompositionId]
  );
};
