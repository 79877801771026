import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PageControl = ({ dir, currentPage, nPages }) => (
  <li
    className={classNames("page-item", {
      disabled: currentPage === (dir === "left" ? 1 : nPages)
    })}
  >
    <Link
      className="page-link"
      to={{
        to: "/estimates",
        search: `?page=${currentPage + (dir === "left" ? -1 : 1)}`
      }}
    >
      <FontAwesomeIcon
        icon={[
          "fal",
          dir === "left" ? "chevron-double-left" : "chevron-double-right"
        ]}
      />
    </Link>
  </li>
);

const PageLink = ({ page, currentPage }) => (
  <li
    className={classNames("page-item", {
      active: currentPage === page
    })}
  >
    <Link
      className="page-link"
      to={{ to: "/estimates", search: `?page=${page}` }}
    >
      {page}
    </Link>
  </li>
);

const LeftEnd = ({ currentPage }) =>
  currentPage <= 3 ? (
    ""
  ) : (
    <Fragment>
      <li className="page-item">
        <Link
          className="page-link"
          to={{ to: "/estimates", search: `?page=${1}` }}
        >
          1
        </Link>
      </li>
      <li className="page-item disabled">
        <span className="page-link">...</span>
      </li>
    </Fragment>
  );

const RightEnd = ({ currentPage, nPages }) =>
  nPages - currentPage < 3 ? (
    ""
  ) : (
    <Fragment>
      <li className="page-item disabled">
        <span className="page-link">...</span>
      </li>
      <li className="page-item">
        <Link
          className="page-link"
          to={{ to: "/estimates", search: `?page=${nPages}` }}
        >
          {nPages}
        </Link>
      </li>
    </Fragment>
  );

const Pagination = ({ totalItems, pageSize, location }) => {
  const params = new URLSearchParams(location.search);
  const currentPage = parseInt(params.get("page") || 1, 10);
  const nPages = Math.ceil(totalItems / pageSize);
  const pages = [-2, -1, 0, 1, 2]
    .map(x => currentPage + x)
    .filter(page => page > 0 && page <= nPages);

  if (nPages <= 1) return "";

  return (
    <nav
      className="d-inline-block pagination-container"
      aria-label="estimates pagination"
    >
      <ul className="pagination">
        <PageControl dir="left" currentPage={currentPage} nPages={nPages} />
        <LeftEnd currentPage={currentPage} />
        {pages.map(page => (
          <PageLink key={page} page={page} currentPage={currentPage} />
        ))}
        <RightEnd currentPage={currentPage} nPages={nPages} />
        <PageControl dir="right" currentPage={currentPage} nPages={nPages} />
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired
};

export default withRouter(Pagination);
