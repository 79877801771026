import gql from "graphql-tag";

export const PRICES = gql`
  query Prices {
    prices {
      id
      type
      price
      limitSqft
    }
  }
`;

export const PRICES_BY_SYSTEM_TYPE = gql`
  query PricesBySystemType($systemType: SystemType!) {
    pricesBySystemType(systemType: $systemType) {
      id
      type
      price
      limitSqft
    }
  }
`;
