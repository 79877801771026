import Fraction from 'fraction.js';
import { toNearest } from '../util';
import { quotation } from './quotation';

const toFraction = (x) => new Fraction(toNearest(0.0625)(x)).toFraction(true);

const FRAME_WIDTH = 1.5;
const PANEL_EXT_WIDTH = 2.5;
const GS_WIDTH = 1;
const ASTRAGAL_WIDTH = 1.5;

const makeFrame = ({ two, width, height }) => [
  two.makeRectangle(FRAME_WIDTH / 2, height / 2, FRAME_WIDTH, height),
  two.makeRectangle(width - FRAME_WIDTH / 2, height / 2, FRAME_WIDTH, height),
  two.makeRectangle(
    width / 2,
    FRAME_WIDTH / 2,
    width - FRAME_WIDTH * 2,
    FRAME_WIDTH
  ),
  two.makeRectangle(
    width / 2,
    height - FRAME_WIDTH / 2,
    width - FRAME_WIDTH * 2,
    FRAME_WIDTH
  ),
  two.makeLine(
    FRAME_WIDTH,
    height - FRAME_WIDTH + 0.5,
    width - FRAME_WIDTH,
    height - FRAME_WIDTH + 0.5
  ),
  two.makeLine(FRAME_WIDTH, height - 0.5, width - FRAME_WIDTH, height - 0.5),
];

const makePanel = ({
  two,
  width,
  height,
  index,
  panelsN,
  astragal = false,
  offset = false,
}) => {
  const offsetWidth = ASTRAGAL_WIDTH + PANEL_EXT_WIDTH + GS_WIDTH;

  const panelWidth =
    (width -
      FRAME_WIDTH * 2 -
      PANEL_EXT_WIDTH -
      GS_WIDTH -
      (astragal || offset ? offsetWidth : 0)) /
      panelsN +
    PANEL_EXT_WIDTH +
    GS_WIDTH;

  const x =
    (offset ? offsetWidth : 0) +
    FRAME_WIDTH +
    panelWidth * index -
    (PANEL_EXT_WIDTH + GS_WIDTH) * index +
    panelWidth / 2;

  return two.makeGroup(
    two.makeRectangle(x, height / 2, panelWidth, height - FRAME_WIDTH * 2),
    // panel frame
    two.makeLine(
      x - panelWidth / 2 + PANEL_EXT_WIDTH,
      FRAME_WIDTH,
      x - panelWidth / 2 + PANEL_EXT_WIDTH,
      height - FRAME_WIDTH
    ),
    two.makeLine(
      x + panelWidth / 2 - PANEL_EXT_WIDTH,
      FRAME_WIDTH,
      x + panelWidth / 2 - PANEL_EXT_WIDTH,
      height - FRAME_WIDTH
    ),
    two.makeLine(
      x - panelWidth / 2 + PANEL_EXT_WIDTH,
      FRAME_WIDTH + PANEL_EXT_WIDTH,
      x + panelWidth / 2 - PANEL_EXT_WIDTH,
      FRAME_WIDTH + PANEL_EXT_WIDTH
    ),
    two.makeLine(
      x - panelWidth / 2 + PANEL_EXT_WIDTH,
      height - FRAME_WIDTH - PANEL_EXT_WIDTH,
      x + panelWidth / 2 - PANEL_EXT_WIDTH,
      height - FRAME_WIDTH - PANEL_EXT_WIDTH
    ),
    // glass stop
    two.makeLine(
      x - panelWidth / 2 + PANEL_EXT_WIDTH + GS_WIDTH,
      FRAME_WIDTH + PANEL_EXT_WIDTH,
      x - panelWidth / 2 + PANEL_EXT_WIDTH + GS_WIDTH,
      height - FRAME_WIDTH - PANEL_EXT_WIDTH
    ),
    two.makeLine(
      x + panelWidth / 2 - PANEL_EXT_WIDTH - GS_WIDTH,
      FRAME_WIDTH + PANEL_EXT_WIDTH,
      x + panelWidth / 2 - PANEL_EXT_WIDTH - GS_WIDTH,
      height - FRAME_WIDTH - PANEL_EXT_WIDTH
    ),
    two.makeLine(
      x - panelWidth / 2 + PANEL_EXT_WIDTH + GS_WIDTH,
      FRAME_WIDTH + PANEL_EXT_WIDTH + GS_WIDTH,
      x + panelWidth / 2 - PANEL_EXT_WIDTH - GS_WIDTH,
      FRAME_WIDTH + PANEL_EXT_WIDTH + GS_WIDTH
    ),
    two.makeLine(
      x - panelWidth / 2 + PANEL_EXT_WIDTH + GS_WIDTH,
      height - FRAME_WIDTH - PANEL_EXT_WIDTH - GS_WIDTH,
      x + panelWidth / 2 - PANEL_EXT_WIDTH - GS_WIDTH,
      height - FRAME_WIDTH - PANEL_EXT_WIDTH - GS_WIDTH
    )
  );
};

const makePanels = ({ two, width, height, config }) => {
  switch (config) {
    case '1': // XO
      return [
        makePanel({ two, width, height, index: 1, panelsN: 2 }),
        makePanel({ two, width, height, index: 0, panelsN: 2 }),
      ];
    case '2': // OX
      return [
        makePanel({ two, width, height, index: 0, panelsN: 2 }),
        makePanel({ two, width, height, index: 1, panelsN: 2 }),
      ];
    case '3': // XX
      return [
        makePanel({ two, width, height, index: 0, panelsN: 2 }),
        makePanel({ two, width, height, index: 1, panelsN: 2 }),
      ];
    case '4': // OXO
      return [
        makePanel({ two, width, height, index: 0, panelsN: 3, astragal: true }),
        makePanel({ two, width, height, index: 2, panelsN: 3, offset: true }),
        makePanel({ two, width, height, index: 1, panelsN: 3, offset: true }),
      ];
    case '5': // XOX
      return [
        makePanel({ two, width, height, index: 1, panelsN: 3 }),
        makePanel({ two, width, height, index: 0, panelsN: 3 }),
        makePanel({ two, width, height, index: 2, panelsN: 3 }),
      ];
    case '6': // XXO
      return [
        makePanel({ two, width, height, index: 0, panelsN: 3 }),
        makePanel({ two, width, height, index: 1, panelsN: 3 }),
        makePanel({ two, width, height, index: 2, panelsN: 3 }),
      ];
    case '7': // OXX
      return [
        makePanel({ two, width, height, index: 2, panelsN: 3 }),
        makePanel({ two, width, height, index: 1, panelsN: 3 }),
        makePanel({ two, width, height, index: 0, panelsN: 3 }),
      ];
    case '8': // XXX
      return [
        makePanel({ two, width, height, index: 0, panelsN: 3 }),
        makePanel({ two, width, height, index: 1, panelsN: 3 }),
        makePanel({ two, width, height, index: 2, panelsN: 3 }),
      ];
    case '9': // OXXO
      return [
        makePanel({ two, width, height, index: 0, panelsN: 4, astragal: true }),
        makePanel({ two, width, height, index: 3, panelsN: 4, offset: true }),
        makePanel({ two, width, height, index: 1, panelsN: 4, astragal: true }),
        makePanel({ two, width, height, index: 2, panelsN: 4, offset: true }),
      ];
    case '10': // OXXXXO
      return [
        makePanel({ two, width, height, index: 0, panelsN: 6, astragal: true }),
        makePanel({ two, width, height, index: 5, panelsN: 6, offset: true }),
        makePanel({ two, width, height, index: 1, panelsN: 6, astragal: true }),
        makePanel({ two, width, height, index: 4, panelsN: 6, offset: true }),
        makePanel({ two, width, height, index: 2, panelsN: 6, astragal: true }),
        makePanel({ two, width, height, index: 3, panelsN: 6, offset: true }),
      ];

    default:
      return [];
  }
};

export const sgd = ({ two, width, height, config }) => {
  two.clear();

  const frame = makeFrame({ two, width, height });
  const panels = makePanels({ two, width, height, config });

  const group = two.makeGroup(...frame, ...panels);
  group.linewidth = 0.3;

  quotation({
    two,
    length: width,
    pos: 'down',
    x: 0,
    y: height,
    label: `${toFraction(width)}"`,
  });

  quotation({
    two,
    length: height,
    dir: 'vertical',
    x: 0,
    label: `${toFraction(height)}"`,
  });

  two.update();

  return [-3 - 10, -3, width + 6 + 10, height + 6 + 10];
};
