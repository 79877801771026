const ANNEALED = [
  { height: 25, width: 18.125, pos: 70, neg: 70 },
  { height: 25, width: 25.5, pos: 70, neg: 70 },
  { height: 25, width: 36, pos: 70, neg: 70 },
  { height: 25, width: 52.125, pos: 70, neg: 70 },

  { height: 37.375, width: 18.125, pos: 70, neg: 70 },
  { height: 37.375, width: 25.5, pos: 70, neg: 70 },
  { height: 37.375, width: 36, pos: 70, neg: 70 },
  { height: 37.375, width: 52.125, pos: 70, neg: 70 },

  { height: 47, width: 23, pos: 70, neg: 70 },
  { height: 47, width: 29, pos: 70, neg: 70 },
  { height: 47, width: 31, pos: 70, neg: 70 },
  { height: 47, width: 35, pos: 70, neg: 70 },
  { height: 47, width: 41, pos: 70, neg: 70 },
  { height: 47, width: 47, pos: 70, neg: 70 },

  { height: 49.625, width: 18.125, pos: 70, neg: 70 },
  { height: 49.625, width: 25.5, pos: 70, neg: 70 },
  { height: 49.625, width: 36, pos: 70, neg: 70 },
  { height: 49.625, width: 52.125, pos: 70, neg: 70 },

  { height: 55, width: 18.125, pos: 70, neg: 70 },
  { height: 55, width: 25.5, pos: 70, neg: 70 },
  { height: 55, width: 36, pos: 70, neg: 70 },
  { height: 55, width: 52.125, pos: 70, neg: 70 },

  { height: 59, width: 23, pos: 70, neg: 70 },
  { height: 59, width: 29, pos: 70, neg: 70 },
  { height: 59, width: 31, pos: 70, neg: 70 },
  { height: 59, width: 35, pos: 70, neg: 70 },
  { height: 59, width: 41, pos: 70, neg: 70 },
  { height: 59, width: 47, pos: 70, neg: 70 },

  { height: 62, width: 18.125, pos: 70, neg: 70 },
  { height: 62, width: 25.5, pos: 70, neg: 70 },
  { height: 62, width: 36, pos: 70, neg: 70 },
  { height: 62, width: 52.125, pos: 70, neg: 70 },

  { height: 71, width: 18.125, pos: 70, neg: 70 },
  { height: 71, width: 23, pos: 70, neg: 70 },
  { height: 71, width: 25.5, pos: 70, neg: 70 },
  { height: 71, width: 29, pos: 70, neg: 70 },
  { height: 71, width: 31, pos: 70, neg: 70 },
  { height: 71, width: 35, pos: 70, neg: 70 },
  { height: 71, width: 36, pos: 70, neg: 70 },
  { height: 71, width: 41, pos: 70, neg: 70 },
  { height: 71, width: 47, pos: 70, neg: 70 },
  { height: 71, width: 52.125, pos: 70, neg: 70 },

  { height: 77, width: 18.125, pos: 70, neg: 70 },
  { height: 77, width: 25.5, pos: 70, neg: 70 },
  { height: 77, width: 36, pos: 70, neg: 70 },
  { height: 77, width: 52.125, pos: 70, neg: 70 },

  { height: 83, width: 23, pos: 70, neg: 70 },
  { height: 83, width: 29, pos: 70, neg: 70 },
  { height: 83, width: 31, pos: 70, neg: 70 },
  { height: 83, width: 35, pos: 70, neg: 70 },
  { height: 83, width: 41, pos: 70, neg: 70 },
  { height: 83, width: 47, pos: 70, neg: 70 },

  { height: 93, width: 23, pos: 70, neg: 70 },
  { height: 93, width: 29, pos: 70, neg: 70 },
  { height: 93, width: 31, pos: 70, neg: 70 },
  { height: 93, width: 35, pos: 70, neg: 70 },
  { height: 93, width: 41, pos: 70, neg: 70 },
];

const _5_16_ANNEALED = [
  { height: 25, width: 18.125, pos: 55, neg: 55 },
  { height: 25, width: 25.5, pos: 55, neg: 55 },
  { height: 25, width: 36, pos: 55, neg: 55 },
  { height: 25, width: 52.125, pos: 55, neg: 55 },

  { height: 37.375, width: 18.125, pos: 55, neg: 55 },
  { height: 37.375, width: 25.5, pos: 55, neg: 55 },
  { height: 37.375, width: 36, pos: 55, neg: 55 },
  { height: 37.375, width: 52.125, pos: 55, neg: 55 },

  { height: 47, width: 23, pos: 55, neg: 55 },
  { height: 47, width: 29, pos: 55, neg: 55 },
  { height: 47, width: 31, pos: 55, neg: 55 },
  { height: 47, width: 35, pos: 55, neg: 55 },
  { height: 47, width: 41, pos: 55, neg: 55 },
  { height: 47, width: 47, pos: 55, neg: 55 },

  { height: 49.625, width: 18.125, pos: 55, neg: 55 },
  { height: 49.625, width: 25.5, pos: 55, neg: 55 },
  { height: 49.625, width: 36, pos: 55, neg: 55 },
  { height: 49.625, width: 52.125, pos: 55, neg: 55 },

  { height: 55, width: 18.125, pos: 55, neg: 55 },
  { height: 55, width: 25.5, pos: 55, neg: 55 },
  { height: 55, width: 36, pos: 55, neg: 55 },
  { height: 55, width: 52.125, pos: 55, neg: 55 },

  { height: 59, width: 23, pos: 55, neg: 55 },
  { height: 59, width: 29, pos: 55, neg: 55 },
  { height: 59, width: 31, pos: 55, neg: 55 },
  { height: 59, width: 35, pos: 55, neg: 55 },
  { height: 59, width: 41, pos: 55, neg: 55 },
  { height: 59, width: 47, pos: 55, neg: 55 },

  { height: 62, width: 18.125, pos: 55, neg: 55 },
  { height: 62, width: 25.5, pos: 55, neg: 55 },
  { height: 62, width: 36, pos: 55, neg: 55 },
  { height: 62, width: 52.125, pos: 55, neg: 55 },

  { height: 71, width: 18.125, pos: 55, neg: 55 },
  { height: 71, width: 23, pos: 55, neg: 55 },
  { height: 71, width: 25.5, pos: 55, neg: 55 },
  { height: 71, width: 29, pos: 55, neg: 55 },
  { height: 71, width: 31, pos: 55, neg: 55 },
  { height: 71, width: 35, pos: 55, neg: 55 },
  { height: 71, width: 36, pos: 55, neg: 55 },
  { height: 71, width: 41, pos: 55, neg: 55 },
  { height: 71, width: 47, pos: 55, neg: 55 },
  { height: 71, width: 52.125, pos: 55, neg: 55 },

  { height: 77, width: 18.125, pos: 55, neg: 55 },
  { height: 77, width: 25.5, pos: 55, neg: 55 },
  { height: 77, width: 36, pos: 55, neg: 55 },
  { height: 77, width: 52.125, pos: 55, neg: 55 },

  { height: 83, width: 23, pos: 55, neg: 55 },
  { height: 83, width: 29, pos: 55, neg: 55 },
  { height: 83, width: 31, pos: 55, neg: 55 },
  { height: 83, width: 35, pos: 55, neg: 55 },
  { height: 83, width: 41, pos: 55, neg: 55 },
  { height: 83, width: 47, pos: 55, neg: 55 },

  { height: 93, width: 23, pos: 55, neg: 55 },
  { height: 93, width: 29, pos: 55, neg: 55 },
  { height: 93, width: 31, pos: 55, neg: 55 },
  { height: 93, width: 35, pos: 55, neg: 55 },
  { height: 93, width: 41, pos: 55, neg: 55 },
];

const HEAT_STRENGTHENED = [
  { height: 25, width: 18.125, pos: 80, neg: 80 },
  { height: 25, width: 25.5, pos: 80, neg: 80 },
  { height: 25, width: 36, pos: 80, neg: 80 },
  { height: 25, width: 52.125, pos: 80, neg: 80 },

  { height: 37.375, width: 18.125, pos: 80, neg: 80 },
  { height: 37.375, width: 25.5, pos: 80, neg: 80 },
  { height: 37.375, width: 36, pos: 80, neg: 80 },
  { height: 37.375, width: 52.125, pos: 80, neg: 80 },

  { height: 47, width: 23, pos: 80, neg: 80 },
  { height: 47, width: 29, pos: 80, neg: 80 },
  { height: 47, width: 31, pos: 80, neg: 80 },
  { height: 47, width: 35, pos: 80, neg: 80 },
  { height: 47, width: 41, pos: 80, neg: 80 },
  { height: 47, width: 47, pos: 80, neg: 80 },

  { height: 49.625, width: 18.125, pos: 80, neg: 80 },
  { height: 49.625, width: 25.5, pos: 80, neg: 80 },
  { height: 49.625, width: 36, pos: 80, neg: 80 },
  { height: 49.625, width: 52.125, pos: 80, neg: 80 },

  { height: 55, width: 18.125, pos: 80, neg: 80 },
  { height: 55, width: 25.5, pos: 80, neg: 80 },
  { height: 55, width: 36, pos: 80, neg: 80 },
  { height: 55, width: 52.125, pos: 80, neg: 80 },

  { height: 59, width: 23, pos: 80, neg: 80 },
  { height: 59, width: 29, pos: 80, neg: 80 },
  { height: 59, width: 31, pos: 80, neg: 80 },
  { height: 59, width: 35, pos: 80, neg: 80 },
  { height: 59, width: 41, pos: 80, neg: 80 },
  { height: 59, width: 47, pos: 80, neg: 80 },

  { height: 62, width: 18.125, pos: 80, neg: 80 },
  { height: 62, width: 25.5, pos: 80, neg: 80 },
  { height: 62, width: 36, pos: 80, neg: 80 },
  { height: 62, width: 52.125, pos: 80, neg: 80 },

  { height: 71, width: 18.125, pos: 80, neg: 80 },
  { height: 71, width: 23, pos: 80, neg: 80 },
  { height: 71, width: 25.5, pos: 80, neg: 80 },
  { height: 71, width: 29, pos: 80, neg: 80 },
  { height: 71, width: 31, pos: 80, neg: 80 },
  { height: 71, width: 35, pos: 80, neg: 80 },
  { height: 71, width: 36, pos: 80, neg: 80 },
  { height: 71, width: 41, pos: 80, neg: 80 },
  { height: 71, width: 47, pos: 80, neg: 80 },
  { height: 71, width: 52.125, pos: 80, neg: 80 },

  { height: 77, width: 18.125, pos: 80, neg: 80 },
  { height: 77, width: 25.5, pos: 80, neg: 80 },
  { height: 77, width: 36, pos: 80, neg: 80 },
  { height: 77, width: 52.125, pos: 80, neg: 80 },

  { height: 83, width: 23, pos: 80, neg: 80 },
  { height: 83, width: 29, pos: 80, neg: 80 },
  { height: 83, width: 31, pos: 80, neg: 80 },
  { height: 83, width: 35, pos: 80, neg: 80 },
  { height: 83, width: 41, pos: 80, neg: 80 },
  { height: 83, width: 47, pos: 80, neg: 80 },

  { height: 93, width: 23, pos: 80, neg: 80 },
  { height: 93, width: 29, pos: 80, neg: 80 },
  { height: 93, width: 31, pos: 80, neg: 80 },
  { height: 93, width: 35, pos: 80, neg: 80 },
  { height: 93, width: 41, pos: 80, neg: 80 },
];

const SMI = [
  { height: 25, width: 18.125, pos: 80, neg: 95 },
  { height: 25, width: 25.5, pos: 80, neg: 95 },
  { height: 25, width: 36, pos: 80, neg: 95 },
  { height: 25, width: 52.125, pos: 80, neg: 95 },

  { height: 37.375, width: 18.125, pos: 80, neg: 95 },
  { height: 37.375, width: 25.5, pos: 80, neg: 95 },
  { height: 37.375, width: 36, pos: 80, neg: 95 },
  { height: 37.375, width: 52.125, pos: 80, neg: 95 },

  { height: 47, width: 23, pos: 80, neg: 95 },
  { height: 47, width: 29, pos: 80, neg: 95 },
  { height: 47, width: 31, pos: 80, neg: 95 },
  { height: 47, width: 35, pos: 80, neg: 95 },
  { height: 47, width: 41, pos: 80, neg: 95 },
  { height: 47, width: 47, pos: 80, neg: 95 },

  { height: 49.625, width: 18.125, pos: 80, neg: 95 },
  { height: 49.625, width: 25.5, pos: 80, neg: 95 },
  { height: 49.625, width: 36, pos: 80, neg: 95 },
  { height: 49.625, width: 52.125, pos: 80, neg: 95 },

  { height: 55, width: 18.125, pos: 80, neg: 95 },
  { height: 55, width: 25.5, pos: 80, neg: 95 },
  { height: 55, width: 36, pos: 80, neg: 95 },
  { height: 55, width: 52.125, pos: 80, neg: 95 },

  { height: 59, width: 23, pos: 80, neg: 95 },
  { height: 59, width: 29, pos: 80, neg: 95 },
  { height: 59, width: 31, pos: 80, neg: 95 },
  { height: 59, width: 35, pos: 80, neg: 95 },
  { height: 59, width: 41, pos: 80, neg: 95 },
  { height: 59, width: 47, pos: 80, neg: 95 },

  { height: 62, width: 18.125, pos: 80, neg: 95 },
  { height: 62, width: 25.5, pos: 80, neg: 95 },
  { height: 62, width: 36, pos: 80, neg: 95 },
  { height: 62, width: 52.125, pos: 80, neg: 95 },

  { height: 71, width: 18.125, pos: 80, neg: 95 },
  { height: 71, width: 23, pos: 80, neg: 95 },
  { height: 71, width: 25.5, pos: 80, neg: 95 },
  { height: 71, width: 29, pos: 80, neg: 95 },
  { height: 71, width: 31, pos: 80, neg: 95 },
  { height: 71, width: 35, pos: 80, neg: 95 },
  { height: 71, width: 36, pos: 80, neg: 95 },
  { height: 71, width: 41, pos: 80, neg: 95 },
  { height: 71, width: 47, pos: 80, neg: 95 },
  { height: 71, width: 52.125, pos: 80, neg: 93.1 },

  { height: 77, width: 18.125, pos: 80, neg: 95 },
  { height: 77, width: 25.5, pos: 80, neg: 95 },
  { height: 77, width: 36, pos: 80, neg: 95 },
  { height: 77, width: 52.125, pos: 80, neg: 90.2 },

  { height: 83, width: 23, pos: 80, neg: 95 },
  { height: 83, width: 29, pos: 80, neg: 95 },
  { height: 83, width: 31, pos: 80, neg: 95 },
  { height: 83, width: 35, pos: 80, neg: 95 },
  { height: 83, width: 41, pos: 80, neg: 95 },
  { height: 83, width: 47, pos: 80, neg: 95 },

  { height: 93, width: 23, pos: 80, neg: 95 },
  { height: 93, width: 29, pos: 80, neg: 95 },
  { height: 93, width: 31, pos: 80, neg: 95 },
  { height: 93, width: 35, pos: 80, neg: 95 },
  { height: 93, width: 41, pos: 80, neg: 95 },
];

export default {
  LMI: {
    ANNEALED: {
      _5_16: _5_16_ANNEALED,
      _7_16: ANNEALED,
      _9_16: ANNEALED,
      _15_16: ANNEALED,
    },
    HEAT_STRENGTHENED: {
      _5_16: [],
      _7_16: HEAT_STRENGTHENED,
      _9_16: HEAT_STRENGTHENED,
      _15_16: HEAT_STRENGTHENED,
    },
  },
  SMI: {
    ANNEALED: { _5_16: [], _7_16: SMI, _9_16: SMI, _15_16: SMI },
    HEAT_STRENGTHENED: { _5_16: [], _7_16: SMI, _9_16: SMI, _15_16: SMI },
  },
};
