import React from "react";

export default ({ invites }) => (
  <table className="table table-hover">
    <thead>
      <tr>
        <th scope="col">Email</th>
        <th scope="col">Internal</th>
        <th scope="col">Admin</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      {invites.map(invite => (
        <tr key={invite.id}>
          <td>{invite.email}</td>
          <td>{invite.internal ? "Yes" : "No"}</td>
          <td>{invite.admin ? "Yes" : "No"}</td>
          <td></td>
        </tr>
      ))}
    </tbody>
  </table>
);
