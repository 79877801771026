import React from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import currency from 'currency.js';
import { useQuery } from 'react-apollo';
import { Table } from 'react-bootstrap';
import { ORDERS_LIST } from '../../../queries';

const groupByClient = (estimates) =>
  estimates.reduce(
    (acc, curr) => ({
      ...acc,
      [`client-${curr.client.id}`]: {
        client: curr.client,
        estimates: [
          ...(acc[`client-${curr.client.id}`]
            ? acc[`client-${curr.client.id}`].estimates
            : []),
          curr,
        ],
      },
    }),
    {}
  );

export default ({ location }) => {
  const params = new URLSearchParams(location.search);
  const date = params.get('date');
  const after = moment(date)
    .startOf('month')
    .format('Y-MM-DD');
  const before = moment(date)
    .add(1, 'months')
    .startOf('month')
    .format('Y-MM-DD');

  const { data, loading } = useQuery(ORDERS_LIST, {
    fetchPolicy: 'no-cache',
    variables: { before, after },
  });

  if (loading) return null;

  const monthlyTotal = data.estimates.reduce(
    (acc, cur) => acc.add(currency(cur.sellPriceTaxes)),
    currency(0)
  );
  const estimates = Object.values(groupByClient(data.estimates));

  return (
    <div className="monthly-orders-report">
      <h3 className="text-center">
        {moment(date).format('MMMM')}
        's Monthly Orders
      </h3>
      <h5 className="text-center mb-5">Total {monthlyTotal.format(true)}</h5>
      <div className="p-5">
        {estimates.map(({ client, estimates }) => {
          let clientTotal = currency(0);
          return (
            <Table key={client.id} bordered>
              <thead>
                <tr>
                  <th colSpan={3}>{client.name}</th>
                </tr>
              </thead>
              <tbody>
                {estimates.map((estimate) => {
                  clientTotal = clientTotal.add(
                    currency(estimate.sellPriceTaxes)
                  );
                  return (
                    <tr key={estimate.id}>
                      <td>#{estimate.id.padStart(6, 0)}</td>
                      <td>
                        <Moment
                          date={estimate.orderedAt}
                          format="MMM DD, YYYY"
                        />
                      </td>
                      <td>{estimate.sellPriceTaxes}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={2} align="right">
                    <b>Total</b>
                  </td>
                  <td>
                    <b>{clientTotal.format(true)}</b>
                  </td>
                </tr>
              </tbody>
            </Table>
          );
        })}
      </div>
    </div>
  );
};
