import React, { Component } from "react";
import { Query, Mutation } from "react-apollo";
import { GLASS_COMPOSITIONS_LIST } from "../../queries";
import { GLASS_COMPOSITION_DELETE } from "../../mutations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import bootbox from "bootbox";
import GlassCompositionForm from "./GlassCompositionForm";
import Modal from "../general/Modal";

class Actions extends Component {
  updateCache = (cache, { data: { glassCompositionDelete } }) => {
    const data = cache.readQuery({ query: GLASS_COMPOSITIONS_LIST });
    data.glassCompositions = data.glassCompositions.filter(
      g => g.id !== glassCompositionDelete.id
    );
    cache.writeQuery({
      query: GLASS_COMPOSITIONS_LIST,
      data
    });
  };

  render() {
    const { glassCompositionId: id } = this.props;

    return (
      <Mutation
        mutation={GLASS_COMPOSITION_DELETE}
        variables={{ id }}
        update={this.updateCache}
      >
        {mutation => (
          <div className="dropdown float-right mr-3">
            <button
              onClick={e => e.stopPropagation()}
              className="btn btn-link text-secondary p-0"
              type="button"
              id="estimate-options-menu"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <FontAwesomeIcon icon={["fal", "ellipsis-h-alt"]} />
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="estimate-options-menu"
            >
              <button
                className="dropdown-item"
                onClick={e => {
                  e.stopPropagation();
                  bootbox.confirm("Are you sure?", r => {
                    r &&
                      mutation().catch(e => {
                        const message = e.graphQLErrors
                          .map(e => e.message)
                          .join(".");
                        bootbox.alert(`Cannot delete composition. ${message}`);
                      });
                  });
                }}
              >
                <FontAwesomeIcon icon={["fal", "trash-alt"]} className="mr-3" />
                Delete
              </button>
            </div>
          </div>
        )}
      </Mutation>
    );
  }
}

export default class GlassCompositions extends Component {
  state = {
    selectedGlassCompositionId: null
  };

  editGlassComposition = selectedGlassCompositionId => {
    this.setState({ selectedGlassCompositionId }, () => {
      $("#edit-glass-composition-modal").modal();
    });
  };

  submitGlassCompositionForm = id => {
    document
      .getElementById(id)
      .dispatchEvent(new Event("submit", { cancelable: true }));
  };

  onGlassCompositionFormCompleted = form => {
    $("#new-glass-composition-modal").modal("hide");
    $("#edit-glass-composition-modal").modal("hide");
    setTimeout(form.reset);
  };

  render() {
    return (
      <Query query={GLASS_COMPOSITIONS_LIST}>
        {({ loading, error, data }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error</p>;

          const { glassCompositions } = data;

          return (
            <div className="glass-compositions-component">
              <div className="header clearfix">
                <h4 className="float-left mt-2">Glass Compositions</h4>
                <button
                  className="btn btn-primary float-right mb-3"
                  data-toggle="modal"
                  data-target="#new-glass-composition-modal"
                >
                  New{" "}
                  <FontAwesomeIcon icon={["fal", "plus"]} className="ml-2" />
                </button>
              </div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Composition</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {glassCompositions.map(g => (
                    <tr
                      key={g.id}
                      onClick={() => this.editGlassComposition(g.id)}
                    >
                      <td>{g.composition}</td>
                      <td>
                        <Actions glassCompositionId={g.id} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Modal
                modalId="new-glass-composition-modal"
                title="New Glass Composition"
                onContinue={() =>
                  this.submitGlassCompositionForm(
                    "create-glass-composition-form"
                  )
                }
              >
                <GlassCompositionForm
                  onCompleted={this.onGlassCompositionFormCompleted}
                />
              </Modal>
              <Modal
                modalId="edit-glass-composition-modal"
                title="Edit Glass Composition"
                onContinue={() =>
                  this.submitGlassCompositionForm(
                    "update-glass-composition-form"
                  )
                }
              >
                <GlassCompositionForm
                  onCompleted={this.onGlassCompositionFormCompleted}
                  glassCompositionId={this.state.selectedGlassCompositionId}
                />
              </Modal>
            </div>
          );
        }}
      </Query>
    );
  }
}
