import gql from 'graphql-tag'

export const PROJECTS_LIST = gql`{
  projects {
    id
    name
    insertedAt
    client {
      id
      name
    }
  }
  tableInfo(name: "projects") {
    count
  }
}`

export const PROJECT = gql`
  query Project($id: ID!) {
    project(id: $id) {
      id
      name
      insertedAt
      client {
        id
        name
      }
    }
  }
`
