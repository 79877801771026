export default ({ shape, width, lHeight, rHeight }) => {
  switch (shape) {
    case 'circle': {
      const side = parseFloat(width);
      return { width: side, lHeight: side, rHeight: side };
    }
    case 'halfcircle': {
      const radius = parseFloat(width) / 2;
      return { width: radius * 2, lHeight: radius, rHeight: radius };
    }
    case 'fan': {
      const height = parseFloat(lHeight);
      return { width: parseFloat(width), lHeight: height, rHeight: height };
    }
    case 'hexagon': {
      const side = parseFloat(width) / 2;
      const angle = 30 * (Math.PI / 180);
      const height = side + Math.cos(angle) * side;
      return { width: side * 2, lHeight: height, rHeight: height };
    }
    case 'octagon': {
      const x = parseFloat(width);
      return { width: x, lHeight: x, rHeight: x };
    }
    case 'rectangle': {
      return {
        width: parseFloat(width),
        lHeight: parseFloat(lHeight),
        rHeight: parseFloat(lHeight),
      };
    }

    // trapezoid and eyebrow
    default:
      return {
        width: parseFloat(width),
        lHeight: parseFloat(lHeight),
        rHeight: parseFloat(rHeight),
      };
  }
};
