import classNames from 'classnames';
import React from 'react';
import GlassLabel from './GlassLabel';
import Label from './Label';
import { numberOfLables } from './lib';

export default ({ estimate }) =>
  estimate.systems
    .filter(({ type }) => type !== 'misc')
    .sort((a, b) => a.mark.localeCompare(b.mark))
    .map((system, index) => {
      if (system.type === 'fd') {
        return system.verticals.map((vertical, verticalIndex) => {
          const classes = classNames('mx-auto border', {
            'mt-5': index === 0 && verticalIndex === 0,
          });

          return (
            <Label
              key={`${system.id}-${verticalIndex}`}
              estimate={estimate}
              system={system}
              index={verticalIndex}
              classes={classes}
              width={vertical.width}
            />
          );
        });
      }

      const labels = numberOfLables(system);
      const classes = classNames('mx-auto border', { 'mt-5': index === 0 });

      return Array.from(
        { length: labels },
        (_, labelIndex) =>
          system.type !== 'glass' ? (
            <Label
              key={`${system.id}-${labelIndex}`}
              estimate={estimate}
              system={system}
              index={labelIndex}
              classes={classes}
            />
          ) : (
            <GlassLabel
              key={`${system.id}-${labelIndex}`}
              estimate={estimate}
              system={system}
              index={labelIndex}
              classes={classes}
            />
          )
      );
    });
