import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Mutation, Query } from 'react-apollo'
import { Form, Field } from 'react-final-form'
import ValidatedField from '../general/ValidatedField'
import { FRAME_COLOR_CREATE, FRAME_COLOR_UPDATE } from '../../mutations'
import { FRAME_COLORS_LIST, FRAME_COLOR } from '../../queries'

const FrameColorMutation = props => {
  let formRef
  const { formId, mutation, initialValues, updateCache, onCompleted, validate } = props
  return (
    <Mutation
      mutation={mutation}
      update={updateCache}
      onCompleted={() => onCompleted(formRef)}
    >
      {mutation => (
        <Form
          onSubmit={variables => mutation({ variables })}
          validate={validate}
          initialValues={initialValues}
        >
          {({ handleSubmit, form }) => {
            formRef = form
            return (
              <form id={formId} onSubmit={handleSubmit}>
                <Field name="id">{input => <input {...input} type="hidden"></input>}</Field>
                <ValidatedField name="color" label="Color" />
              </form>
            )
          }}
        </Form>
      )}
    </Mutation>
  )
}

const FrameColorQuery = ({ frameColorId, updateCache, onCompleted, validate }) => (
  <Query query={FRAME_COLOR} variables={{ id: frameColorId }}>
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>
      if (error) return <p>Error</p>

      return (
        <FrameColorMutation
          formId="update-frame-color-form"
          mutation={FRAME_COLOR_UPDATE}
          initialValues={data.frameColor}
          updateCache={updateCache}
          onCompleted={onCompleted}
          validate={validate}
        />
      )
    }}
  </Query>
)

export default class FrameColorForm extends Component {
  static defaultProps = {
    onCompleted: () => {}
  }

  validate = values => {
    const errors = {}
    const required = ['color']
    required.forEach(r => !values[r] && (errors[r] = "Required"))
    return errors
  }

  updateCacheOnCreate = (cache, { data: { frameColorCreate } }) => {
    const { frameColors } = cache.readQuery({ query: FRAME_COLORS_LIST })
    cache.writeQuery({
      query: FRAME_COLORS_LIST,
      data: {
        frameColors: [frameColorCreate].concat(frameColors)
      }
    })
  }

  updateCacheOnUpdate = (cache, { data: { frameColorUpdate } }) => {
    const { frameColors } = cache.readQuery({ query: FRAME_COLORS_LIST })
    cache.writeQuery({
      query: FRAME_COLORS_LIST,
      data: {
        frameColors: frameColors.filter(f => f.id !== frameColorUpdate.id).concat([frameColorUpdate])
      }
    })
  }

  render() {
    const { frameColorId, onCompleted } = this.props
    return frameColorId
      ? <FrameColorQuery
          frameColorId={frameColorId}
          updateCache={this.updateCacheOnUpdate}
          onCompleted={onCompleted}
          validate={this.validate}
        />
      : <FrameColorMutation
          formId="create-frame-color-form"
          mutation={FRAME_COLOR_CREATE}
          updateCache={this.updateCacheOnCreate}
          onCompleted={onCompleted}
          validate={this.validate}
        />
  }
}

FrameColorForm.propTypes = {
  onCompleted: PropTypes.func.isRequired
}