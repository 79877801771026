export const CONFIGS = {
  X: '1',
  OX: '2',
  XO: '3',
  OXO: '4',
  XX: '5',
  OXX: '6',
  XXO: '7',
  OXXO: '8',
};

export const DOUBLE_DOOR = ['5', '6', '7', '8'];

export const LEFT_LITE = ['2', '4', '6', '8'];

export const RIGHT_LITE = ['3', '4', '7', '8'];
