import React from 'react';
import { useQuery } from 'react-apollo';
import { Redirect, useParams } from 'react-router-dom';
import {
  ESTIMATE_SYSTEMS,
  FRAME_COLORS_LIST,
  GLASS_COMPOSITIONS_LIST,
  MARKUPS,
  SYSTEM,
} from '../../queries';
import { useQueryParams } from '../../util';
import FdForm from './FdForm';
import FwForm from './FwForm';
import GlassForm from './GlassForm';
import HrForm from './HrForm';
import SgdForm from './SgdForm';
import ShForm from './ShForm';

const SystemForm = () => {
  const { estimateId, systemId } = useParams();
  const { type } = useQueryParams();
  const estimate = useQuery(ESTIMATE_SYSTEMS, {
    variables: { id: estimateId },
  });
  const compositions = useQuery(GLASS_COMPOSITIONS_LIST);
  const colors = useQuery(FRAME_COLORS_LIST);
  const markups = useQuery(MARKUPS);
  const system = useQuery(SYSTEM, {
    variables: { id: systemId },
    skip: !systemId,
  });

  if (
    estimate.loading ||
    system.loading ||
    compositions.loading ||
    colors.loading ||
    markups.loading
  )
    return <p>Loading...</p>;

  if (
    estimate.error ||
    system.error ||
    compositions.error ||
    colors.error ||
    markups.error
  )
    return <p>Error</p>;

  if (estimate.data.estimate.orderedAt)
    return <Redirect to={`/estimates/${estimateId}`} />;

  const marks = estimate.data.estimate.systems.map((s) => s.mark);

  const props = {
    estimateId: estimateId,
    systemId: systemId,
    marks: system.data
      ? marks.filter((m) => m !== system.data.system.mark)
      : marks,
    glassCompositions: compositions.data.glassCompositions,
    frameColors: colors.data.frameColors,
    markups: markups.data.markups,
  };

  switch (system.data ? system.data.system.type : type) {
    case 'fw':
      return <FwForm {...props} />;
    case 'sh':
      return <ShForm {...props} />;
    case 'hr':
      return <HrForm {...props} />;
    case 'fd':
      return <FdForm {...props} />;
    case 'sgd':
      return <SgdForm {...props} />;
    case 'glass':
      return <GlassForm {...props} />;
    default:
      return null;
  }
};

export default SystemForm;
