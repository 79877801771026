import classNames from 'classnames';
import React, { Fragment } from 'react';
import Moment from 'react-moment';
import { useLocation } from 'react-router-dom';
import { getFullSystemType } from '../../../util';
import CompanyHeader from '../../print/CompanyHeader';
import Page from '../../print/Page';
import { SHAPES } from '../../systems/FwForm';
import CutList from './CutList';
import GlassAndHardware from './GlassAndHardware';
import MiscList from './MiscList';
import Screens from './Screens';
import Summary from './Summary';

export default ({ estimate, type, order }) => {
  const query = new URLSearchParams(useLocation().search);
  const miscs = estimate.systems.filter((s) => s.type === 'misc');
  const clientMiscs = estimate.systems.filter((s) => s.type === 'client_misc');
  let systems = estimate.systems.filter(
    (s) => !['misc', 'client_misc'].includes(s.type)
  );
  const isClient = query.get('client') === 'true';

  const client = isClient ? estimate.metaClient : estimate.client;

  const shapes = Object.fromEntries(
    Object.entries(SHAPES).map(([k, v]) => [v, k])
  );

  systems =
    type === 'print'
      ? systems.sort((a, b) => a.mark.localeCompare(b.mark)).map((s) => [s])
      : systems
          .sort((a, b) => a.mark.localeCompare(b.mark))
          .reduce((acc, _cur, index, array) => {
            if (index % 2 === 0) acc.push(array.slice(index, index + 2));
            return acc;
          }, []);

  return (
    <Fragment>
      {systems.map((system, i) => {
        const classes = classNames('mx-auto', { 'mt-5': i === 0 });
        return (
          <Page className={classes} key={system[0].id}>
            <CompanyHeader client={isClient ? estimate.client : null}>
              <h5>
                {order ? 'Order' : 'Quote'} #{estimate.id.padStart(6, 0)}
              </h5>
              {order && (
                <small className="d-block text-right">
                  Ordered{' '}
                  <Moment date={estimate.orderedAt} format="MMM DD, YYYY" />
                </small>
              )}
              {!isClient && (
                <small className="d-block text-right">
                  ETC{' '}
                  {estimate.etc ? (
                    <Moment date={estimate.etc} format="MMM DD, YYYY" />
                  ) : (
                    'not set'
                  )}
                </small>
              )}
            </CompanyHeader>

            <table
              className="table table-sm table-bordered"
              style={{ fontSize: 12 }}
            >
              <tbody>
                <tr>
                  <th scope="col" className="py-0">
                    Client name
                  </th>
                  <td>{client.name}</td>
                  <th scope="col" className="py-0">
                    Client address
                  </th>
                  <td>
                    {client.address}, {client.city}, {client.state} {client.zip}
                  </td>
                </tr>
              </tbody>
            </table>

            {system.map((system, i) => {
              const { positiveLoad = 0, negativeLoad = 0 } = system;

              let shape;

              if (system.type === 'fw') {
                shape = 'Rectangle';
                const { lHeight, rHeight } = system.verticals[0];
                const shapeDetail = system.details.find(
                  ({ type }) => type === 'shape'
                );
                if (lHeight !== rHeight) shape = 'Trapezoid';
                if (shapeDetail) shape = shapes[shapeDetail.desc];
              }

              return (
                <Fragment key={i}>
                  <table
                    className="table table-bordered table-sm mb-0"
                    style={{ fontSize: 12 }}
                  >
                    <thead>
                      <tr>
                        <th>Mark</th>
                        {system.frameColor && <th>Frame Color</th>}
                        <th>Qty</th>
                        <th>Type</th>
                        {positiveLoad > 0 &&
                          negativeLoad > 0 && <th>Wind Loads</th>}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{system.mark}</td>
                        {system.frameColor && (
                          <td>{system.frameColor.color}</td>
                        )}
                        <td>{system.qty}</td>
                        <td>{getFullSystemType(system)}</td>
                        {positiveLoad > 0 &&
                          negativeLoad > 0 && (
                            <td>
                              +{positiveLoad} / -{negativeLoad}
                            </td>
                          )}
                      </tr>
                    </tbody>
                  </table>
                  <div
                    className={classNames('system-image-container', {
                      small: type !== 'print',
                    })}
                  >
                    {(positiveLoad === 0 || negativeLoad === 0) && (
                      <div className="top-left-message">
                        No compliance with HVHZ
                      </div>
                    )}
                    {system.type !== 'glass' && (
                      <div className="top-right-message">
                        Looking from the outside
                      </div>
                    )}
                    <img
                      src={system.imagePath}
                      alt={`Mark ${system.mark} drawing`}
                    />
                  </div>
                  <GlassAndHardware
                    reportType={type}
                    system={system}
                    className={classNames({
                      'mb-2': type !== 'print',
                      'mb-5': type === 'total',
                    })}
                  />
                  {type === 'print' && <Screens system={system} />}
                  {type === 'print' && <CutList system={system} />}
                  {['line', 'contract'].includes(type) && (
                    <table
                      className="table table-sm table-bordered mb-5 line-price"
                      style={{ fontSize: 12 }}
                    >
                      <tbody>
                        <tr>
                          <th scope="col">Sell Each</th>
                          <td>
                            {isClient ? system.metaSellPrice : system.sellPrice}
                          </td>
                          <th scope="col">Sell Total</th>
                          <td>
                            {isClient
                              ? system.metaSellPriceTotal
                              : system.sellPriceTotal}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </Fragment>
              );
            })}
          </Page>
        );
      })}
      {!isClient &&
        miscs.length > 0 && (
          <MiscList
            miscs={miscs}
            estimate={estimate}
            type={type}
            order={order}
          />
        )}
      {isClient &&
        (miscs.length > 0 || clientMiscs.length > 0) && (
          <MiscList
            miscs={[...miscs, ...clientMiscs]}
            estimate={estimate}
            type={type}
            order={order}
          />
        )}
      {type !== 'print' && (
        <Summary estimate={estimate} type={type} order={order} />
      )}
    </Fragment>
  );
};
