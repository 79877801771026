import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import currency from 'currency.js';
import classNames from 'classnames';
import Actions from './Actions';

export class EstimateBox extends Component {
  render() {
    const { match, estimate } = this.props;
    const {
      id,
      name,
      client,
      project,
      insertedAt,
      orderedAt,
      clientOrderedAt,
      sellPriceTaxes,
      payments,
      etc,
    } = estimate;

    const paidOff =
      orderedAt &&
      currency(sellPriceTaxes).subtract(
        payments.reduce(
          (acc, { amount }) => acc.add(currency(amount)),
          currency(0)
        )
      ).value <= 0;

    const bodyClassNames = classNames('card-body p-3', {
      'bg-light-green': paidOff,
    });

    return (
      <div className="card estimate-box">
        <div className={bodyClassNames}>
          <h5 className="card-title">
            {name}
            {paidOff && (
              <FontAwesomeIcon
                icon={['fal', 'badge-dollar']}
                className="ml-2 text-success"
              />
            )}
            <Actions estimate={estimate} />
          </h5>
          <small className="card-subtitle text-muted font-weight-light">
            <p className="mb-1">Quote #{id.padStart(6, 0)}</p>
            <p className="mb-1">For client {client.name}</p>
            <p className="mb-1">
              {project ? `For project ${project.name}` : 'No project'}
            </p>
            {orderedAt || clientOrderedAt ? (
              <p className="mb-1 text-success">
                {orderedAt ? 'Ordered' : 'Requested'} on{' '}
                <Moment date={orderedAt} format="MM/DD/YY" />
                {!!etc && (
                  <span className="ml-2">
                    (ETC: <Moment date={etc} format="MM/DD/YY" />)
                  </span>
                )}
                {!orderedAt && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Pending approval</Tooltip>}
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'hourglass-start']}
                      className="ml-2 text-danger"
                    />
                  </OverlayTrigger>
                )}
              </p>
            ) : (
              <p className="mb-1">
                Created on <Moment date={insertedAt} format="MMM DD, YYYY" />
              </p>
            )}
          </small>
        </div>
        <div className="card-footer text-center">
          {match.path.indexOf('orders') > -1 ? (
            <Link to={`/estimates/${id}`}>
              Go to quote
              <FontAwesomeIcon icon={['fal', 'list-ul']} className="ml-2" />
            </Link>
          ) : (
            <Link to={`${match.path}/${id}`}>
              View
              <FontAwesomeIcon icon={['fal', 'list-ul']} className="ml-2" />
            </Link>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(EstimateBox);
