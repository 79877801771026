import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import Field from '../../general/rhf/Field';
import {
  heatTreatments,
  interlayers,
  thickness,
  types,
} from '../../glassCompositions/constants';

const useFilteredGlassCompositions = (glassCompositions) => {
  const { control, setValue } = useFormContext();
  const [
    glassHeatTreatment,
    glassType,
    glassThickness,
    glassInterlayer,
    glassCompositionId,
  ] = useWatch({
    control,
    name: [
      'glassHeatTreatment',
      'glassType',
      'glassThickness',
      'glassInterlayer',
      'glassCompositionId',
    ],
  });

  const filtered = glassCompositions.filter(
    ({ heatTreatment, type, thickness, interlayer }) =>
      heatTreatment === (glassHeatTreatment || null) &&
      type === (glassType || null) &&
      thickness === (glassThickness || null) &&
      interlayer === (glassInterlayer || null)
  );

  const newCompositionId = !!filtered.find(
    ({ id }) => id === glassCompositionId
  )
    ? glassCompositionId
    : (filtered[0] || { id: null }).id;

  useEffect(
    () => {
      setValue('glassCompositionId', newCompositionId);
    },
    [newCompositionId]
  );

  return filtered;
};

const GlassSelection = ({ required, glassCompositions: allCompositions }) => {
  const glassCompositions = useFilteredGlassCompositions(allCompositions);

  return (
    <fieldset>
      <legend>Glass</legend>
      <Field type="select" name="glassHeatTreatment" label="Heat Treatment">
        <option value="">None</option>
        {heatTreatments.map(({ text, value }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </Field>
      <Field type="select" name="glassType" label="Type">
        <option value="">None</option>
        {types.map(({ text, value }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </Field>
      <Field type="select" name="glassThickness" label="Thickness">
        <option value="">None</option>
        {thickness.map(({ text, value }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </Field>
      <Field type="select" name="glassInterlayer" label="Interlayer">
        <option value="">None</option>
        {interlayers.map(({ text, value }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </Field>
      <Field
        type="select"
        name="glassCompositionId"
        label="Composition"
        register={{ required }}
      >
        <option value="">None</option>
        {glassCompositions.map(({ id, composition }) => (
          <option key={id} value={id}>
            {composition}
          </option>
        ))}
      </Field>
    </fieldset>
  );
};

GlassSelection.defaultProps = {
  required: true,
};

GlassSelection.propTypes = {
  required: PropTypes.bool,
  glassCompositions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      composition: PropTypes.string,
    })
  ).isRequired,
};

export default GlassSelection;
