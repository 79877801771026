import React, { useState, useCallback } from "react";
import { useQuery } from "react-apollo";
import { Route, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { USERS_AND_INVITES } from "../../../queries";
import { Button } from "react-bootstrap";
import UserList from "./UserList";
import InviteList from "./InviteList";
import UserForm from "./UserForm";

export default ({ match }) => {
  const [showForm, setShowForm] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const { data, loading } = useQuery(USERS_AND_INVITES);
  const closeForm = useCallback(() => setShowForm(false), []);

  return (
    <div className="users-component">
      <div className="header clearfix">
        <h4 className="header float-left mt-2">
          <NavLink exact to={match.url}>
            Users
          </NavLink>
          /<NavLink to={`${match.url}/invites`}>Invites</NavLink>
        </h4>
        <Button
          className="float-right mb-3"
          onClick={() => {
            setUserToEdit(null);
            setShowForm(true);
          }}
        >
          New <FontAwesomeIcon icon={["fal", "plus"]} className="ml-2" />
        </Button>
      </div>

      <Route
        exact
        path="/settings/users"
        render={props => (
          <UserList
            {...props}
            users={loading ? [] : data.users}
            onSelect={user => {
              setUserToEdit(user);
              setShowForm(true);
            }}
          />
        )}
      />
      <Route
        path="/settings/users/invites"
        render={props => (
          <InviteList {...props} invites={loading ? [] : data.openInvites} />
        )}
      />
      <UserForm show={showForm} handleClose={closeForm} user={userToEdit} />
    </div>
  );
};
