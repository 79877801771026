import React from 'react';
import { Mutation } from 'react-apollo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import $ from 'jquery';
import { PO_UPDATE } from '../../mutations';

const Actions = ({ po, selectPo }) => (
  <Mutation mutation={PO_UPDATE}>
    {(updatePO) => (
      <div className="dropdown float-right mr-3">
        <button
          onClick={(e) => e.stopPropagation()}
          className="btn btn-link text-secondary p-0"
          type="button"
          id="estimate-options-menu"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <FontAwesomeIcon icon={['fal', 'ellipsis-h-alt']} />
        </button>
        <div className="dropdown-menu" aria-labelledby="estimate-options-menu">
          <button
            className="dropdown-item"
            onClick={(e) => {
              e.stopPropagation();
              updatePO({
                variables: { id: po.id, sent: po.sent ? null : new Date() },
              });
            }}
          >
            <FontAwesomeIcon
              icon={['fal', po.sent ? 'inbox-in' : 'inbox-out']}
              className="mr-3"
            />
            {po.sent ? 'Unsend' : 'Send'}
          </button>
          <button
            className="dropdown-item"
            onClick={(e) => {
              e.stopPropagation();
              updatePO({
                variables: {
                  id: po.id,
                  received: po.received ? null : new Date(),
                },
              });
            }}
          >
            <FontAwesomeIcon icon={['fal', 'truck']} className="mr-3" />
            {po.received ? 'Unreceive' : 'Receive'}
          </button>
          <button
            className="dropdown-item"
            onClick={(e) => {
              e.stopPropagation();
              selectPo(po.id);
              $('#edit-po-modal').modal();
            }}
          >
            <FontAwesomeIcon icon={['fal', 'edit']} className="mr-3" />
            Edit
          </button>
        </div>
      </div>
    )}
  </Mutation>
);

const POsList = ({ selectPo, pos, location, history }) => {
  const params = new URLSearchParams(location.search);
  const page = params.get('page');

  return (
    <table className="table table-hover mb-5">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Vendor</th>
          <th scope="col">Created</th>
          <th scope="col">Sent</th>
          <th scope="col">Status</th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {pos.map((po) => {
          return (
            <tr
              key={po.id}
              onClick={() =>
                history.push(
                  `/pos/${po.id}/print${page ? `?page=${page}` : ''}`
                )
              }
            >
              <td>{po.id.padStart(6, 0)}</td>
              <td>{po.vendor.name}</td>
              <td>
                <Moment date={po.insertedAt} format="MM/DD/YYYY" />
              </td>
              <td>
                {po.sent ? (
                  <Moment date={po.sent} format="MM/DD/YYYY" />
                ) : (
                  'Not sent'
                )}
              </td>
              <td>
                {po.received ? (
                  <Moment date={po.received} format="MM/DD/YYYY" />
                ) : (
                  'Not received'
                )}
              </td>
              <td>
                <Actions po={po} selectPo={selectPo} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default withRouter(POsList);
