import { useMutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { ESTIMATE, ESTIMATE_SYSTEMS } from '../../../../queries';

export const useOnSubmit = ({ estimateId, getImage, mutation }) => {
  const history = useHistory();
  const [mutate] = useMutation(mutation, {
    onCompleted: () => history.push(`/estimates/${estimateId}`),
  });

  return (values) => {
    const fileName = `mark_${values.mark.toUpperCase()}.svg`;

    const variables = {
      ...(values.id && { id: values.id }),
      ...(!values.id && { estimateId: values.estimateId }),
      type: 'sgd',
      mark: values.mark,
      price: values.price.toString(),
      config: parseInt(values.config, 10),
      qty: parseInt(values.qty, 10),
      width: parseFloat(values.width),
      lHeight: parseFloat(values.height),
      rHeight: parseFloat(values.height),
      glassCompositionId: values.glassCompositionId,
      frameColorId: values.frameColorId,
      image: getImage(fileName),
      autoPrice: values.autoPrice,
    };

    mutate({
      variables,
      refetchQueries: [{ query: ESTIMATE, variables: { id: estimateId } }],
      ...(!values.id && {
        update: (cache, { data: { systemCreate } }) => {
          const data = cache.readQuery({
            query: ESTIMATE_SYSTEMS,
            variables: { id: estimateId },
          });
          data.estimate.systems = [systemCreate].concat(data.estimate.systems);
          cache.writeQuery({
            query: ESTIMATE_SYSTEMS,
            variables: { id: estimateId },
            data,
          });
        },
      }),
    });
  };
};
