import { quotation } from '../quotation';
import Fraction from 'fraction.js';
import { toNearest } from '../../util';

const toFraction = (x) => new Fraction(toNearest(0.0625)(x)).toFraction(true);

export const fan = (two, w, h) => {
  if (!(two && w && h)) return;

  const group = two.makeGroup(
    two.makeCurve(0, h, w / 2, 0, w, h, true),
    two.makePath(0, h, w, h)
  );

  group.linewidth = 0.3;

  quotation({
    two,
    length: h,
    dir: 'vertical',
    pos: 'down',
    x: w / 2,
    separation: w / 2 + 10,
    label: `${toFraction(h)}"`,
  });

  quotation({
    two,
    length: w,
    pos: 'down',
    y: h,
    label: `${toFraction(w)}"`,
  });

  return [-3, -3, w + 10 + 6, h + 10 + 6];
};
