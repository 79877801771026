import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import {
  NavLink,
  Redirect,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { ORDERS_LIST, PENDING_ORDERS_LIST } from '../../queries';
import EstimatesList from './EstimatesList';
import Pagination from '../general/Pagination';
import { pageParams, PAGE_SIZE } from '../../util';
import { AuthContext } from '../../auth';
import { Alert } from 'react-bootstrap';

const Orders = () => {
  const { currentUser } = useContext(AuthContext);
  const isPending = !!useRouteMatch({ path: '/orders/pending' });
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { data, loading, error } = useQuery(
    isPending ? PENDING_ORDERS_LIST : ORDERS_LIST,
    {
      fetchPolicy: 'network-only',
      variables: pageParams(params.get('page') || 1),
    }
  );

  if (!currentUser.internal && isPending) return <Redirect to="/" />;
  if (!data && loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  const { estimates, tableInfo } = data;

  return (
    <div className="row flex-grow-1">
      <div className="col-sm-3">
        <h3>{isPending ? 'Pending Orders' : 'Current Orders'}</h3>
        <h6 className="text-muted">{tableInfo.count} orders</h6>
        {!currentUser.internal && (
          <Alert variant="primary" className="mt-4">
            <small>
              <b className="mb-0">Please contact us when you place an order</b>
              <p className="mb-0">Phone: (305) 216-4554</p>
              <p className="mb-0">Email: sales@luxgard.com</p>
            </small>
          </Alert>
        )}

        {currentUser.internal && (
          <nav className="nav flex-column">
            <NavLink className="nav-link" exact to="/orders">
              Orders
            </NavLink>
            <NavLink className="nav-link" to="/orders/pending">
              Pending
            </NavLink>
          </nav>
        )}
      </div>
      <div className="col-sm-9 position-relative">
        <EstimatesList estimates={estimates} />
        <div className="estimates-pagination">
          <Pagination totalItems={tableInfo.count} pageSize={PAGE_SIZE} />
        </div>
      </div>
    </div>
  );
};

export default Orders;
