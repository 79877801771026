import gql from 'graphql-tag';

export const GLASS_COMPOSITIONS_LIST = gql`
  {
    glassCompositions {
      id
      composition
      heatTreatment
      type
      thickness
      interlayer
      interlayerThickness
      markup
      glassOnly
    }
  }
`;

export const GLASS_COMPOSITION = gql`
  query GlassComposition($id: ID!) {
    glassComposition(id: $id) {
      id
      composition
      heatTreatment
      type
      thickness
      interlayer
      interlayerThickness
      markup
      glassOnly
    }
  }
`;
