import gql from 'graphql-tag';

export const SIGNIN = gql`
  query Signin($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      token
    }
  }
`;

export const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      email
      internal
      admin
      client {
        id
        logoPath
      }
    }
  }
`;

export const USER_BY_ID = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      email
      internal
      admin
      client {
        id
      }
    }
  }
`;

export const USERS_AND_INVITES = gql`
  query UsersAndInvites {
    users {
      id
      email
      internal
      admin
      lockedAt
      client {
        id
      }
    }

    openInvites {
      id
      email
      internal
      admin
      client {
        id
      }
    }
  }
`;
