import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Page = ({ children, className, size }) => {
  const classes = classNames("print-page", size, className);
  return <div className={classes}>{children}</div>;
};

Page.defaultProps = {
  className: "",
  size: "letter"
};

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  size: PropTypes.oneOf(["letter", "label"])
};

export default Page;
