import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../general/Modal';
import $ from 'jquery';
import { Query } from 'react-apollo';
import { PROJECTS_LIST } from '../../queries';
import ProjectsList from './ProjectsList';
import ProjectForm from './ProjectForm';
import { AuthContext } from '../../auth';

export default class Projects extends Component {
  static contextType = AuthContext;

  state = {
    selectedProjectId: null,
  };

  setSelectedProjectId = (selectedProjectId) =>
    this.setState({ selectedProjectId });

  submitProjectForm = (id) => {
    document
      .getElementById(id)
      .dispatchEvent(new Event('submit', { cancelable: true }));
  };

  onProjectFormCompleted = (form) => {
    $('#new-project-modal').modal('hide');
    $('#edit-project-modal').modal('hide');
    setTimeout(form.reset);
  };

  render() {
    const { currentUser } = this.context;

    if (!currentUser.internal) return <Redirect to="/" />;

    return (
      <Query query={PROJECTS_LIST}>
        {({ loading, error, data }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error</p>;

          const { projects, tableInfo } = data;

          return (
            <div className="row">
              <div className="col-sm-3">
                <h3>Current Projects</h3>
                <h6 className="text-muted">{tableInfo.count} projects</h6>
                <button
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#new-project-modal"
                >
                  Add Project{' '}
                  <FontAwesomeIcon icon={['fal', 'plus']} className="ml-2" />
                </button>
              </div>
              <div className="col-sm-9">
                <ProjectsList
                  selectProject={this.setSelectedProjectId}
                  projects={projects}
                />
              </div>
              <Modal
                modalId="new-project-modal"
                title="New Project"
                onContinue={() => this.submitProjectForm('create-project-form')}
              >
                <ProjectForm onCompleted={this.onProjectFormCompleted} />
              </Modal>
              <Modal
                modalId="edit-project-modal"
                title="Edit Project"
                onContinue={() => this.submitProjectForm('update-project-form')}
              >
                <ProjectForm
                  onCompleted={this.onProjectFormCompleted}
                  projectId={this.state.selectedProjectId}
                />
              </Modal>
            </div>
          );
        }}
      </Query>
    );
  }
}
