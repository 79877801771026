import React from 'react';
import Moment from 'react-moment';
import Page from '../../../print/Page';
import CompanyHeader from '../../../print/CompanyHeader';
import Fraction from 'fraction.js';
import { toNearest } from '../../../../util';
import { estimateGlass } from './lib';

const adjustPrecision = toNearest(0.0625);

export default ({ estimate, order }) => {
  const { address, city, state: addressState, zip } = estimate.client;
  const glass = estimateGlass(estimate);

  return (
    <Page className="mx-auto mt-5">
      <CompanyHeader>
        <h5>
          {order ? 'Order' : 'Quote'} #{estimate.id.padStart(6, 0)}
        </h5>
        {order && (
          <small className="d-block text-right">
            Ordered <Moment date={estimate.orderedAt} format="MMM DD, YYYY" />
          </small>
        )}
        <small className="d-block text-right">
          ETC{' '}
          {estimate.etc ? (
            <Moment date={estimate.etc} format="MMM DD, YYYY" />
          ) : (
            'not set'
          )}
        </small>
      </CompanyHeader>

      <table className="table table-sm table-bordered" style={{ fontSize: 12 }}>
        <tbody>
          <tr>
            <th scope="col" className="py-0">
              Client name
            </th>
            <td>{estimate.client.name}</td>
            <th scope="col" className="py-0">
              Client address
            </th>
            <td>
              {address}, {city}, {addressState} {zip}
            </td>
          </tr>
        </tbody>
      </table>

      <h5 className="text-center">Glass List</h5>

      <table
        className="table table-bordered table-sm mb-0"
        style={{ fontSize: 12 }}
      >
        <tbody>
          <tr className="heading">
            <th>Mark</th>
            <th>System Type</th>
            <th>Glass Composition</th>
            <th>Glass Width</th>
            <th>Glass Height</th>
            <th>Qty</th>
          </tr>
          {glass.map((item) => (
            <tr key={item.id}>
              <td>{item.mark}</td>
              <td>{item.system}</td>
              <td>{item.composition}</td>
              <td>
                {new Fraction(adjustPrecision(item.width)).toFraction(true)}"
              </td>
              <td>
                {new Fraction(adjustPrecision(item.height)).toFraction(true)}"
              </td>
              <td>{item.qty}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Page>
  );
};
