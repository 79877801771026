import React from "react";
import EstimateBox from "./EstimateBox";

export default ({ selectEstimate, estimates }) => (
  <div className="row">
    {estimates.map(estimate => (
      <div className="col-sm-4 mb-3" key={estimate.id}>
        <EstimateBox estimate={estimate} selectEstimate={selectEstimate} />
      </div>
    ))}
  </div>
);
