import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { TYPES_WITH_BALANCE, TYPES_WITH_GLASS } from './lib';
import FormModal from './FormModal';

export default ({ estimate }) => {
  const [modal, setModal] = useState();
  const hideModal = () => setModal(null);

  const systemsTypes = estimate.systems.map(({ type }) => type);

  return (
    <>
      <DropdownButton
        id="dropdown-basic-button"
        title="See/Add PO"
        variant="link"
      >
        <Dropdown.Item as={Link} to={`/pos?estimateId=${estimate.id}`}>
          See POs
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => setModal('glass')}
          disabled={
            !systemsTypes.some((type) => TYPES_WITH_GLASS.includes(type))
          }
        >
          Glass PO
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => setModal('balance')}
          disabled={
            !systemsTypes.some((type) => TYPES_WITH_BALANCE.includes(type))
          }
        >
          Balance PO
        </Dropdown.Item>
      </DropdownButton>

      <FormModal
        show={modal === 'glass'}
        onClose={hideModal}
        type="glass"
        estimate={estimate}
      />
      <FormModal
        show={modal === 'balance'}
        onClose={hideModal}
        type="balance"
        estimate={estimate}
      />
    </>
  );
};
