import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

const Checkbox = ({ name, label }) => (
  <div className="form-group form-check">
    <label className="form-check-label">
      <Field
        name={name}
        component="input"
        type="checkbox"
        className="form-check-input"
      />
      {label}
    </label>
  </div>
)

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default Checkbox