import React, { Component } from "react";
import { Query } from "react-apollo";
import { VENDOR_LIST } from "../../queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import VendorForm from "./VendorForm";
import Modal from "../general/Modal";
import Pagination from "../general/Pagination";
import { pageParams } from "../../util";

const PAGE_SIZE = 10;

export default class FrameColors extends Component {
  state = {
    selectedVendorId: null
  };

  editVendor = selectedVendorId => {
    this.setState({ selectedVendorId }, () => {
      $("#edit-vendor-modal").modal();
    });
  };

  submitVendorForm = id => {
    document
      .getElementById(id)
      .dispatchEvent(new Event("submit", { cancelable: true }));
  };

  onVendorFormCompleted = form => {
    $("#new-vendor-modal").modal("hide");
    $("#edit-vendor-modal").modal("hide");
    setTimeout(form.reset);
  };

  render() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);

    return (
      <Query
        query={VENDOR_LIST}
        fetchPolicy="network-only"
        variables={pageParams(params.get("page") || 1, PAGE_SIZE)}
      >
        {({ loading, error, data }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error</p>;

          const { vendors, tableInfo } = data;

          return (
            <div className="vendors-component">
              <div className="header clearfix">
                <h4 className="float-left mt-2">Vendors</h4>
                <button
                  className="btn btn-primary float-right mb-3"
                  data-toggle="modal"
                  data-target="#new-vendor-modal"
                >
                  New{" "}
                  <FontAwesomeIcon icon={["fal", "plus"]} className="ml-2" />
                </button>
              </div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">name</th>
                  </tr>
                </thead>
                <tbody>
                  {vendors.map(v => (
                    <tr key={v.id} onClick={() => this.editVendor(v.id)}>
                      <td>{v.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="vendors-pagination">
                <Pagination totalItems={tableInfo.count} pageSize={PAGE_SIZE} />
              </div>

              <Modal
                modalId="new-vendor-modal"
                title="New Vendor"
                onContinue={() => this.submitVendorForm("create-vendor-form")}
              >
                <VendorForm onCompleted={this.onVendorFormCompleted} />
              </Modal>
              <Modal
                modalId="edit-vendor-modal"
                title="Edit Vendor"
                onContinue={() => this.submitVendorForm("update-vendor-form")}
              >
                <VendorForm
                  onCompleted={this.onVendorFormCompleted}
                  vendorId={this.state.selectedVendorId}
                />
              </Modal>
            </div>
          );
        }}
      </Query>
    );
  }
}
