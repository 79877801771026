import { quotation } from '../quotation';
import Fraction from 'fraction.js';
import { toNearest } from '../../util';

const toFraction = (x) => new Fraction(toNearest(0.0625)(x)).toFraction(true);
const WIDTH = 1.93;

export const hexagon = (two, side) => {
  if (!(two && side)) return;

  const group = two.makeGroup(
    two.makePolygon(side, side, side, 6),
    two.makePolygon(side, side, side - WIDTH, 6)
  );

  group.linewidth = 0.3;

  const angle = 30 * (Math.PI / 180);
  const quotationX = Math.sin(angle) * side;
  const quotationY = side + Math.cos(angle) * side;

  quotation({
    two,
    length: side,
    pos: 'down',
    x: quotationX,
    y: quotationY,
    separation: 6,
    label: `${toFraction(side)}"`,
  });

  quotation({
    two,
    length: side * 2,
    pos: 'down',
    y: quotationY / 2,
    separation: quotationY / 2 + 12,
    label: `${toFraction(side * 2)}"`,
  });

  quotation({
    two,
    length: Math.sqrt(3) * side,
    pos: 'down',
    dir: 'vertical',
    x: side * 1.5,
    y: (side * 2 - Math.sqrt(3) * side) / 2,
    separation: side / 2 + 10,
    label: `${toFraction(Math.sqrt(3) * side)}"`,
  });

  return [-3, -3, side * 2 + 6 + 10, side * 2 + 6 + 12];
};
