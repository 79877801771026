import { quotation } from './quotation';
import Fraction from 'fraction.js';
import { toNearest } from '../util';

const toFraction = (x) => new Fraction(toNearest(0.0625)(x)).toFraction(true);

const WIDTH_GS = 0.75;
const WIDTH_HEAD = 1.18;
const WIDTH_SILL = 0.75;
const WIDTH_JAMB = 2.375;
const WIDTH_DIV = 2.014;
const WIDTH_BOTTOM = 1.1365;

export const sh = (two, width, height) => {
  const JAMB_HEIGHT = height - WIDTH_HEAD - WIDTH_SILL;
  const VGS_HEIGHT = (JAMB_HEIGHT - WIDTH_BOTTOM * 2 - WIDTH_DIV) / 2;
  const ARROW_CENTER =
    height - (WIDTH_SILL + WIDTH_BOTTOM * 2) - VGS_HEIGHT / 2;
  const ARROW_HEIGHT =
    VGS_HEIGHT - WIDTH_GS * 2 - 4 > 20 ? 20 : VGS_HEIGHT - WIDTH_GS * 2 - 4;

  const left = [
    two.makeRectangle(
      WIDTH_JAMB / 2,
      WIDTH_HEAD + JAMB_HEIGHT / 2,
      WIDTH_JAMB,
      JAMB_HEIGHT
    ),
    two.makeRectangle(
      WIDTH_JAMB + WIDTH_GS / 2,
      WIDTH_HEAD + VGS_HEIGHT / 2,
      WIDTH_GS,
      VGS_HEIGHT
    ),
    two.makeRectangle(
      WIDTH_JAMB + WIDTH_GS / 2,
      height - WIDTH_SILL - WIDTH_BOTTOM * 2 - VGS_HEIGHT / 2,
      WIDTH_GS,
      VGS_HEIGHT
    ),
  ];
  const right = [
    two.makeRectangle(
      width - WIDTH_JAMB / 2,
      WIDTH_HEAD + JAMB_HEIGHT / 2,
      WIDTH_JAMB,
      JAMB_HEIGHT
    ),
    two.makeRectangle(
      width - WIDTH_JAMB - WIDTH_GS / 2,
      WIDTH_HEAD + VGS_HEIGHT / 2,
      WIDTH_GS,
      VGS_HEIGHT
    ),
    two.makeRectangle(
      width - WIDTH_JAMB - WIDTH_GS / 2,
      height - WIDTH_SILL - WIDTH_BOTTOM * 2 - VGS_HEIGHT / 2,
      WIDTH_GS,
      VGS_HEIGHT
    ),
  ];
  const top = [
    two.makeRectangle(width / 2, WIDTH_HEAD / 2, width, WIDTH_HEAD),
    two.makeRectangle(
      width / 2,
      WIDTH_HEAD + WIDTH_GS / 2,
      width - WIDTH_JAMB * 2 - WIDTH_GS * 2,
      WIDTH_GS
    ),
  ];
  const bot = [
    two.makeRectangle(width / 2, height - WIDTH_SILL / 2, width, WIDTH_SILL),
    two.makeRectangle(
      width / 2,
      height - WIDTH_SILL - WIDTH_BOTTOM / 2,
      width - WIDTH_JAMB * 2,
      WIDTH_BOTTOM
    ),
    two.makeRectangle(
      width / 2,
      height - WIDTH_SILL - WIDTH_BOTTOM - WIDTH_BOTTOM / 2,
      width - WIDTH_JAMB * 2,
      WIDTH_BOTTOM
    ),
    two.makeRectangle(
      width / 2,
      height - WIDTH_SILL - WIDTH_BOTTOM * 2 - WIDTH_GS / 2,
      width - WIDTH_JAMB * 2 - WIDTH_GS * 2,
      WIDTH_GS
    ),
  ];
  const div = [
    two.makeRectangle(
      width / 2,
      WIDTH_HEAD + VGS_HEIGHT + WIDTH_DIV / 2,
      width - WIDTH_JAMB * 2,
      WIDTH_DIV
    ),
    two.makeRectangle(
      width / 2,
      WIDTH_HEAD + VGS_HEIGHT - WIDTH_GS / 2,
      width - WIDTH_JAMB * 2 - WIDTH_GS * 2,
      WIDTH_GS
    ),
    two.makeRectangle(
      width / 2,
      WIDTH_HEAD + VGS_HEIGHT + WIDTH_DIV + WIDTH_GS / 2,
      width - WIDTH_JAMB * 2 - WIDTH_GS * 2,
      WIDTH_GS
    ),
  ];

  const arrow = [
    two.makeLine(
      width / 2,
      ARROW_HEIGHT > 4 ? ARROW_CENTER - ARROW_HEIGHT / 2 : ARROW_CENTER - 1,
      width / 2 - 2,
      (ARROW_HEIGHT > 4 ? ARROW_CENTER - ARROW_HEIGHT / 2 : ARROW_CENTER - 1) +
        2
    ),
    two.makeLine(
      width / 2,
      ARROW_HEIGHT > 4 ? ARROW_CENTER - ARROW_HEIGHT / 2 : ARROW_CENTER - 1,
      width / 2 + 2,
      (ARROW_HEIGHT > 4 ? ARROW_CENTER - ARROW_HEIGHT / 2 : ARROW_CENTER - 1) +
        2
    ),
  ];

  if (ARROW_HEIGHT > 4)
    arrow.push(
      two.makeLine(
        width / 2,
        ARROW_CENTER - ARROW_HEIGHT / 2,
        width / 2,
        ARROW_CENTER + ARROW_HEIGHT / 2
      )
    );

  const group = two.makeGroup(
    ...left,
    ...right,
    ...top,
    ...bot,
    ...div,
    ...arrow
  );
  group.linewidth = 0.3;

  quotation({ two, length: width, label: `${toFraction(width)}"` });
  quotation({
    two,
    length: height,
    dir: 'vertical',
    label: `${toFraction(height)}"`,
  });
  const notchLength = WIDTH_SILL + WIDTH_BOTTOM * 2 + VGS_HEIGHT;
  const glassHeight = height / 2 - 3.42; // from cut list service in backend
  quotation({
    two,
    length: notchLength,
    dir: 'vertical',
    pos: 'down',
    x: width,
    y: height - notchLength,
    label: `${toFraction(glassHeight + 2.637)}"`,
  });
  return [-3 - 10, -3 - 10, width + 10 + 10 + 6, height + 10 + 6];
};
