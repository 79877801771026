import currency from 'currency.js';
import createDecorator from 'final-form-calculate';
import PropTypes from 'prop-types';
import React, { Component, useContext } from 'react';
import { Mutation, Query } from 'react-apollo';
import { Field, Form, FormSpy } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import Two from 'two.js';
import { AuthContext } from '../../auth';
import { hr as draw } from '../../draw';
import { SYSTEM_CREATE, SYSTEM_UPDATE } from '../../mutations';
import {
  ESTIMATE,
  ESTIMATE_SYSTEMS,
  PRICES_BY_SYSTEM_TYPE,
  SYSTEM,
} from '../../queries';
import { hr as calcWindLoad } from '../../windLoads';
import Checkbox from '../general/Checkbox';
import ValidatedField from '../general/ValidatedField';
import ValidatedSelect from '../general/ValidatedSelect';
import GlassSelection, { glassFilter } from './GlassSelection';
import getPrice from './price';

const calculator = createDecorator({
  field: ['width', 'height', 'autoPrice', 'glassCompositionId'],
  updates: {
    price: (
      _value,
      {
        width,
        height,
        price,
        prices,
        autoPrice,
        glassCompositions,
        glassCompositionId,
        markups,
      }
    ) => {
      return getPrice({
        type: 'hr',
        compositions: glassCompositions,
        markups,
        prices,
        autoPrice,
        price,
        glassCompositionId,
        width,
        lHeight: height,
        rHeight: height,
      });
    },
  },
});

export const CONFIGS = [
  { label: '1/2 - 1/2 XO', value: '4' },
  { label: '1/2 - 1/2 OX', value: '1' },
  { label: '1/3 - 1/3 - 1/3', value: '2' },
  { label: '1/4 - 1/2 - 1/4', value: '3' },
];

const HANDLES = [
  { label: 'Sweep Latch', value: 'sweep_latch' },
  { label: 'Flip Lock', value: 'flip_lock' },
  { label: 'Spring Latch', value: 'spring_latch' },
];

class SystemMutation extends Component {
  static contextType = AuthContext;

  state = {
    two: null,
    twoElem: null,
    positiveLoad: 0,
    negativeLoad: 0,
  };

  draw = ({ values: { width, height, config, handle } }) => {
    const { two, twoElem } = this.state;
    const _width = parseFloat(width);
    const _height = parseFloat(height);
    if (!(two && _width > 10 && _height > 10)) return;
    two.clear();
    const viewBox = draw(two, _width, _height, parseInt(config, 10), handle);
    two.update();
    twoElem.children[0].setAttribute('viewBox', viewBox.join(' '));
  };

  windLoads = ({ values: { width, height, glassCompositionId, config } }) => {
    const { glassCompositions } = this.props;
    const glassComposition = glassCompositions.find(
      ({ id }) => id === glassCompositionId
    );
    const { pos, neg } = glassCompositionId
      ? calcWindLoad(width - 1 || 0, height - 1 || 0, glassComposition, config)
      : { pos: 0, neg: 0 };

    const { positiveLoad, negativeLoad } = this.state;
    if (positiveLoad !== pos || negativeLoad !== neg)
      this.setState({ positiveLoad: pos, negativeLoad: neg });
  };

  getSvgFile = (fileName) => {
    const svg = this.state.twoElem.children[0];
    const data = new XMLSerializer().serializeToString(svg);
    return new File([data], fileName, { type: 'image/svg+xml;charset=utf-8' });
  };

  handleSubmit = (mutation, variables) => {
    const { positiveLoad, negativeLoad } = this.state;
    const { handle } = variables;
    delete variables.handle;
    const handle_desc = HANDLES.find((h) => h.value === handle).label;

    const fileName = `mark_${variables.mark.toUpperCase()}.svg`;
    return mutation({
      variables: {
        ...variables,
        price: variables.price.toString(),
        qty: parseInt(variables.qty, 10),
        positiveLoad,
        negativeLoad,
        config: parseInt(variables.config, 10),
        width: parseFloat(variables.width),
        lHeight: parseFloat(variables.height),
        rHeight: parseFloat(variables.height),
        image: this.getSvgFile(fileName),
        hardware: [{ type: 'handle', code: handle, desc: handle_desc }],
      },
    });
  };

  componentDidMount() {
    const {
      initialValues: { positiveLoad = 0, negativeLoad = 0 },
    } = this.props;
    const opts = { width: '100%', height: '100%' };
    const twoElem = document.getElementById('drawing-container');
    const two = new Two(opts).appendTo(twoElem);
    this.setState({ two, twoElem, positiveLoad, negativeLoad }, () => {
      this.draw({ values: this.props.initialValues });
    });
  }

  render() {
    const { currentUser } = this.context;
    let formRef;
    const {
      formId,
      mutation,
      initialValues,
      updateCache,
      onCompleted,
      validate,
      goBack,
      glassCompositions,
      frameColors,
      markups,
      prices,
      autoPrice,
    } = this.props;
    const { positiveLoad, negativeLoad } = this.state;

    return (
      <Mutation
        mutation={mutation}
        update={updateCache}
        onCompleted={() => onCompleted(formRef)}
        refetchQueries={[
          { query: ESTIMATE, variables: { id: initialValues.estimateId } },
        ]}
      >
        {(mutation) => (
          <Form
            decorators={[calculator, glassFilter(glassCompositions)]}
            onSubmit={(variables) => this.handleSubmit(mutation, variables)}
            validate={validate}
            initialValues={{
              ...initialValues,
              prices,
              autoPrice,
              glassCompositions,
              markups,
            }}
          >
            {({ handleSubmit, form, initialValues, values }) => {
              formRef = form;
              return (
                <form id={formId} onSubmit={handleSubmit} className="h-100">
                  <FormSpy
                    onChange={(values) => {
                      this.draw(values);
                      this.windLoads(values);
                    }}
                    subscription={{ values: true }}
                  />
                  <div className="system-form row flex-grow-1">
                    <div className="col-3 d-flex flex-column">
                      <div>
                        <h3>
                          {initialValues.id
                            ? 'Edit Horizontal Roller Window'
                            : 'New Horizontal Roller Window'}
                        </h3>
                        <h6 className="mb-5">
                          Wind Loads:{' '}
                          <span className="badge badge-primary">
                            +{positiveLoad} / -{negativeLoad}
                          </span>
                        </h6>
                      </div>
                      <div className="form-container">
                        <div className="fixed">
                          <Field name="id">
                            {(input) => <input {...input} type="hidden" />}
                          </Field>
                          <ValidatedField
                            name="mark"
                            label="Mark"
                            type="text"
                          />
                          {currentUser.internal && (
                            <div className="d-flex align-items-end">
                              <ValidatedField
                                name="price"
                                label="Price per sqft"
                                type="number"
                                disabled={values.autoPrice}
                                className="flex-grow-1"
                              />
                              <div className="mb-4 ml-4" title="Auto Price">
                                <Field
                                  name="autoPrice"
                                  component="input"
                                  type="checkbox"
                                />
                              </div>
                            </div>
                          )}
                          <ValidatedField
                            name="qty"
                            label="Qty"
                            type="number"
                          />
                          <GlassSelection
                            glassCompositions={glassCompositions}
                          />
                          <ValidatedSelect
                            name="frameColorId"
                            label="Frame Color"
                          >
                            {frameColors.map(({ id, color }) => (
                              <option key={id} value={id}>
                                {color}
                              </option>
                            ))}
                          </ValidatedSelect>
                          <ValidatedSelect name="config" label="Config">
                            {CONFIGS.map((c) => (
                              <option key={c.value} value={c.value}>
                                {c.label}
                              </option>
                            ))}
                          </ValidatedSelect>
                          <ValidatedSelect name="handle" label="Handle">
                            {HANDLES.map((c) => (
                              <option key={c.value} value={c.value}>
                                {c.label}
                              </option>
                            ))}
                          </ValidatedSelect>
                          <ValidatedField
                            name="width"
                            label="Width"
                            type="number"
                          />
                          <ValidatedField
                            name="height"
                            label="Height"
                            type="number"
                          />
                          <Checkbox name="screen" label="Screen" />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block mt-4"
                      >
                        Save
                      </button>
                      <button
                        className="btn btn-outline-secondary btn-block"
                        onClick={goBack}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="col-9 d-flex">
                      <div id="drawing-container" className="flex-grow-1" />
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        )}
      </Mutation>
    );
  }
}

const SystemQuery = ({
  systemId,
  updateCache,
  onCompleted,
  validate,
  goBack,
  initialValues,
  glassCompositions,
  frameColors,
  markups,
  prices,
}) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Query query={SYSTEM} variables={{ id: systemId }}>
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error</p>;

        const handle = data.system.hardware.find((h) => h.type === 'handle')
          .code;
        const price = currency(data.system.priceSqft);

        const glassCompositionId =
          data.system.verticals[0].glass[0].glassComposition.id;

        const glassComposition = glassCompositions.find(
          ({ id }) => id === glassCompositionId
        );

        const filteredGlassCompositions = currentUser.internal
          ? glassCompositions
          : glassCompositions.filter(
              ({ id, glassOnly }) => !glassOnly || id === glassCompositionId
            );

        const initial = {
          id: data.system.id,
          type: 'hr',
          mark: data.system.mark,
          qty: data.system.qty,
          positiveLoad: data.system.positiveLoad,
          negativeLoad: data.system.negativeLoad,
          config: data.system.config.toString(),
          handle,
          width: data.system.verticals[0].width,
          height: data.system.verticals[0].lHeight,
          screen: !!data.system.verticals[0].screen,
          glassCompositionId,
          glassHeatTreatment: glassComposition.heatTreatment,
          glassType: glassComposition.type,
          glassThickness: glassComposition.thickness,
          glassInterlayer: glassComposition.interlayer,
          frameColorId: data.system.frameColor.id,
          price,
          ...initialValues,
        };

        return (
          <SystemMutation
            formId="update-system-form"
            mutation={SYSTEM_UPDATE}
            initialValues={initial}
            updateCache={updateCache}
            onCompleted={onCompleted}
            validate={validate}
            goBack={goBack}
            glassCompositions={filteredGlassCompositions}
            frameColors={frameColors}
            markups={markups}
            prices={prices}
            autoPrice={data.system.autoPrice}
          />
        );
      }}
    </Query>
  );
};

class HrForm extends Component {
  static contextType = AuthContext;

  static defaultProps = {
    onCompleted: () => {},
  };

  validate = (values) => {
    const errors = {};
    const required = [
      'mark',
      'width',
      'height',
      'qty',
      'glassCompositionId',
      'frameColorId',
      'price',
    ];
    required.forEach((r) => !values[r] && (errors[r] = 'Required'));
    if (values.mark && this.props.marks.includes(values.mark.toUpperCase())) {
      errors.mark = 'Mark is already in use';
    }
    return errors;
  };

  updateCacheOnCreate = (cache, { data: { systemCreate } }) => {
    const { estimateId } = this.props;
    const data = cache.readQuery({
      query: ESTIMATE_SYSTEMS,
      variables: { id: estimateId },
    });
    data.estimate.systems = [systemCreate].concat(data.estimate.systems);
    cache.writeQuery({
      query: ESTIMATE_SYSTEMS,
      variables: { id: estimateId },
      data,
    });
  };

  // TODO: Pretty sure there's a mechanism to do this simple update automatically
  updateCacheOnUpdate = (cache, { data: { systemUpdate } }) => {
    const data = cache.readQuery({
      query: SYSTEM,
      variables: { id: systemUpdate.id },
    });
    data.system = systemUpdate;
    cache.writeQuery({
      query: SYSTEM,
      variables: { id: systemUpdate.id },
      data,
    });
  };

  goBack = () => {
    const { history, estimateId } = this.props;
    history.push(`/estimates/${estimateId}`);
  };

  render() {
    const { currentUser } = this.context;
    const {
      systemId,
      estimateId,
      glassCompositions,
      frameColors,
      markups,
    } = this.props;
    const filteredGlassCompositions = currentUser.internal
      ? glassCompositions
      : glassCompositions.filter(({ glassOnly }) => !glassOnly);
    const glassCompositionId =
      filteredGlassCompositions.length && filteredGlassCompositions[0].id;
    const frameColorId = frameColors.length && frameColors[0].id;

    return (
      <Query query={PRICES_BY_SYSTEM_TYPE} variables={{ systemType: 'hr' }}>
        {({ loading, data, error }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error</p>;

          return systemId ? (
            <SystemQuery
              systemId={systemId}
              updateCache={this.updateCacheOnUpdate}
              onCompleted={this.goBack}
              validate={this.validate}
              goBack={this.goBack}
              initialValues={{ estimateId }}
              glassCompositions={glassCompositions}
              frameColors={frameColors}
              markups={markups}
              prices={data.pricesBySystemType}
            />
          ) : (
            <SystemMutation
              formId="create-system-form"
              mutation={SYSTEM_CREATE}
              initialValues={{
                type: 'hr',
                qty: 1,
                estimateId,
                config: 4,
                handle: 'sweep_latch',
                glassCompositionId,
                frameColorId,
                price: currency(0),
                screen: true,
              }}
              updateCache={this.updateCacheOnCreate}
              onCompleted={this.goBack}
              validate={this.validate}
              goBack={this.goBack}
              glassCompositions={filteredGlassCompositions}
              frameColors={frameColors}
              markups={markups}
              prices={data.pricesBySystemType}
              autoPrice={true}
            />
          );
        }}
      </Query>
    );
  }
}

HrForm.propTypes = {
  onCompleted: PropTypes.func.isRequired,
};

export default withRouter(HrForm);
