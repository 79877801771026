import React, { useContext } from 'react';
import { Route, NavLink, Redirect, useRouteMatch } from 'react-router-dom';
import Prices from './Prices';
import Users from './Users';
import GlassCompositions from '../glassCompositions';
import FrameColors from '../frameColors';
import Vendors from '../vendors';
import { AuthContext } from '../../auth';
import Markups from './Markups';

export default () => {
  const { currentUser } = useContext(AuthContext);
  const match = useRouteMatch('/settings');

  if (!currentUser.admin) return <Redirect to="/" />;

  return (
    <div className="row">
      <div className="col-sm-3">
        <h3>Application Settings</h3>
        <h6 className="text-muted">
          General settings that affect the entire application. This includes
          prices..
        </h6>

        <nav className="nav flex-column">
          <NavLink className="nav-link" exact to={`${match.url}`}>
            Prices
          </NavLink>
          <NavLink className="nav-link" exact to={`${match.url}/markups`}>
            Markups
          </NavLink>
          <NavLink className="nav-link" to={`${match.url}/users`}>
            Users
          </NavLink>
          <NavLink className="nav-link" to={`${match.url}/glass_compositions`}>
            Glass Compositions
          </NavLink>
          <NavLink className="nav-link" to={`${match.url}/frame_colors`}>
            Frame Colors
          </NavLink>
          <NavLink className="nav-link" to={`${match.url}/vendors`}>
            Vendors
          </NavLink>
        </nav>
      </div>
      <div className="col-sm-9">
        <Route exact path={match.path} component={Prices} />
        <Route path={`${match.path}/markups`} component={Markups} />
        <Route
          path={`${match.path}/glass_compositions`}
          component={GlassCompositions}
        />
        <Route path={`${match.path}/frame_colors`} component={FrameColors} />
        <Route path={`${match.path}/vendors`} component={Vendors} />
        <Route path={`${match.path}/users`} component={Users} />
      </div>
    </div>
  );
};
