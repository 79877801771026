import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import {
  PrivateRoute,
  Signin,
  Signup,
  ForgotPassword,
  ResetPassword,
} from '../auth';
import NavBar from './general/NavBar';
import Clients from './clients';
import Projects from './projects';
import Estimates from './estimates';
import Orders from './estimates/Orders';
import POs from './pos';
import Settings from './settings';
import Reports from './reports';
import Estimate from './estimates/Estimate';
import EstimatePrint from './estimates/print/EstimatePrint';
import PaymentPrint from './payments/print/PaymentPrint';
import POPrint from './pos/print/POPrint';
import Client from './clients/Client';

const Main = () => (
  <div className="App container-fluid px-5 pb-5 pt-2 h-100 d-flex flex-column">
    <NavBar />
    <main className="content container-fluid bg-white p-3 flex-grow-1 d-flex flex-column">
      <Switch>
        <Redirect exact from="/" to="/estimates" />
        <Route path="/estimates/:estimateId" component={Estimate} />
        <Route path="/estimates" component={Estimates} />
        <Route path="/orders" component={Orders} />
        <Route path="/pos" component={POs} />
        <Route path="/clients/:id" component={Client} />
        <Route path="/clients" component={Clients} />
        <Route path="/projects" component={Projects} />
        <Route path="/reports" component={Reports} />
        <Route path="/settings" component={Settings} />
      </Switch>
    </main>
  </div>
);

export default () => (
  <Switch>
    <Route path="/signin" component={Signin} />
    <Route path="/signup" component={Signup} />
    <Route path="/forgotPassword" component={ForgotPassword} />
    <Route path="/resetPassword" component={ResetPassword} />
    <PrivateRoute path="/estimates/:id/print" component={EstimatePrint} />
    <PrivateRoute
      path="/estimates/:estimateId/payments/:id/print"
      component={PaymentPrint}
    />
    <PrivateRoute path="/pos/:id/print" component={POPrint} />
    <PrivateRoute path="/" component={Main} />
  </Switch>
);
