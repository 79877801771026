import gql from 'graphql-tag';

export const MARKUPS = gql`
  query Markups {
    markups {
      id
      type
      desc
      value
    }
  }
`;
