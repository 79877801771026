import { quotation } from '../quotation';
import Fraction from 'fraction.js';
import { toNearest } from '../../util';

const toFraction = (x) => new Fraction(toNearest(0.0625)(x)).toFraction(true);
const WIDTH = 1.93 / 2;

export const rectangle = (two, width, lHeight, rHeight) => {
  if (!(two && width > 10 && lHeight > 10 && rHeight > 10)) return;
  const viewportWidth =
    lHeight !== rHeight ? width + 10 + 10 + 6 : width + 10 + 6;
  const sign = lHeight > rHeight ? 1 : -1;
  const height = Math.max(lHeight, rHeight);
  const shortHeight = Math.min(lHeight, rHeight);
  const topLength = Math.sqrt(
    Math.pow(height - shortHeight, 2) + Math.pow(width, 2)
  );
  const topAngle = Math.acos((height - shortHeight) / topLength);
  const offset = WIDTH / Math.sin(topAngle);
  const secondaryOffset = WIDTH * Math.cos(topAngle);

  const top = [
    two.makePath(
      0,
      height - lHeight,
      0,
      height - lHeight + offset,
      width,
      height - rHeight + offset,
      width,
      height - rHeight,
      false
    ),
    two.makePath(
      0,
      height - lHeight + offset,
      0,
      height - lHeight + offset * 2,
      width,
      height - rHeight + offset * 2,
      width,
      height - rHeight + offset,
      false
    ),
  ];

  const left = [
    two.makePath(
      0,
      height - lHeight,
      0,
      height,
      WIDTH,
      height,
      WIDTH,
      height - lHeight + secondaryOffset * sign,
      false
    ),
    two.makePath(
      WIDTH,
      height - lHeight + secondaryOffset * sign + offset * 2,
      WIDTH,
      height - WIDTH * 2,
      WIDTH * 2,
      height - WIDTH * 2,
      WIDTH * 2,
      height - lHeight + (secondaryOffset * sign + offset) * 2,
      false
    ),
  ];

  const right = [
    two.makePath(
      width,
      height - rHeight,
      width,
      height,
      width - WIDTH,
      height,
      width - WIDTH,
      height - rHeight - secondaryOffset * sign,
      false
    ),
    two.makePath(
      width - WIDTH,
      height - rHeight - secondaryOffset * sign + offset * 2,
      width - WIDTH,
      height - WIDTH * 2,
      width - WIDTH * 2,
      height - WIDTH * 2,
      width - WIDTH * 2,
      height - rHeight + (-secondaryOffset * sign + offset) * 2,
      false
    ),
  ];

  const bot = [
    two.makeRectangle(width / 2, height - WIDTH / 2, width - WIDTH * 2, WIDTH),
    two.makeRectangle(
      width / 2,
      height - WIDTH - WIDTH / 2,
      width - WIDTH * 2,
      WIDTH
    ),
  ];

  const group = two.makeGroup(...top, ...left, ...right, ...bot);
  group.linewidth = 0.3;

  quotation({
    two,
    length: width,
    pos: 'down',
    y: height,
    label: `${toFraction(width)}"`,
  });
  quotation({
    two,
    length: lHeight,
    dir: 'vertical',
    y: height - lHeight,
    label: `${toFraction(lHeight)}"`,
  });
  if (lHeight !== rHeight)
    quotation({
      two,
      length: rHeight,
      dir: 'vertical',
      pos: 'down',
      y: height - rHeight,
      x: width,
      label: `${toFraction(rHeight)}"`,
    });

  return [-3 - 10, -3, viewportWidth, height + 6 + 10];
};
