import gql from 'graphql-tag';

export const ESTIMATES_LIST = gql`
  query Estimates($limit: Int, $offset: Int) {
    estimates(limit: $limit, offset: $offset) {
      id
      name
      markup
      tax
      orderedAt
      clientOrderedAt
      sellPrice
      taxes
      sellPriceTaxes
      insertedAt
      etc
      client {
        id
        name
        email
        phone
        address
        city
        state
        zip
        logoPath
      }
      metaClient {
        id
        name
        email
        phone
        address
        city
        state
        zip
        logoPath
      }
      project {
        id
        name
      }
      payments {
        id
        amount
        insertedAt
      }
    }
    tableInfo(name: "estimates") {
      count
    }
  }
`;

export const ORDERS_LIST = gql`
  query Orders($limit: Int, $offset: Int, $before: Date, $after: Date) {
    estimates: orders(
      limit: $limit
      offset: $offset
      before: $before
      after: $after
    ) {
      id
      name
      markup
      tax
      orderedAt
      clientOrderedAt
      sellPrice
      taxes
      sellPriceTaxes
      insertedAt
      etc
      client {
        id
        name
        email
        phone
        address
        city
        state
        zip
        logoPath
      }
      metaClient {
        id
        name
        email
        phone
        address
        city
        state
        zip
        logoPath
      }
      project {
        id
        name
      }
      payments {
        id
        amount
        insertedAt
      }
    }
    tableInfo(name: "orders") {
      count
    }
  }
`;

export const PENDING_ORDERS_LIST = gql`
  query Pending($limit: Int, $offset: Int, $before: Date, $after: Date) {
    estimates: pending(
      limit: $limit
      offset: $offset
      before: $before
      after: $after
    ) {
      id
      name
      markup
      tax
      orderedAt
      clientOrderedAt
      sellPrice
      taxes
      sellPriceTaxes
      insertedAt
      etc
      client {
        id
        name
        email
        phone
        address
        city
        state
        zip
        logoPath
      }
      metaClient {
        id
        name
        email
        phone
        address
        city
        state
        zip
        logoPath
      }
      project {
        id
        name
      }
      payments {
        id
        amount
        insertedAt
      }
    }
    tableInfo(name: "pending") {
      count
    }
  }
`;

export const ESTIMATE = gql`
  query Estimate($id: ID!) {
    estimate(id: $id) {
      id
      name
      markup
      tax
      orderedAt
      clientOrderedAt
      sellPrice
      taxes
      sellPriceTaxes
      insertedAt
      metaMarkup
      metaTax
      etc
      client {
        id
        name
        email
        phone
        address
        city
        state
        zip
        logoPath
      }
      metaClient {
        id
        name
        email
        phone
        address
        city
        state
        zip
        logoPath
      }
      project {
        id
        name
      }
    }
  }
`;

export const ESTIMATE_SYSTEMS = gql`
  query EstimateSystems($id: ID!) {
    estimate(id: $id) {
      id
      name
      markup
      tax
      metaTax
      orderedAt
      clientOrderedAt
      sellPrice
      taxes
      sellPriceTaxes
      metaSellPrice
      metaTaxes
      metaSellPriceTaxes
      insertedAt
      etc
      client {
        id
        name
        email
        phone
        address
        city
        state
        zip
        logoPath
      }
      metaClient {
        id
        name
        email
        phone
        address
        city
        state
        zip
        logoPath
      }
      project {
        id
        name
      }
      payments {
        id
        amount
        insertedAt
      }
      systems {
        id
        mark
        type
        config
        imagePath
        qty
        positiveLoad
        negativeLoad
        autoPrice
        priceSqft
        sellPrice
        sellPriceTotal
        metaSellPrice
        metaSellPriceTotal
        frameColor {
          id
          color
        }
        verticals {
          index
          width
          lHeight
          rHeight
          priceSqft
          extrusions {
            desc
            lengths
            type
          }
          glass {
            width
            lHeight
            rHeight
            qty
            glassComposition {
              id
              composition
              heatTreatment
              type
              thickness
              interlayer
              interlayerThickness
            }
          }
          screen {
            width
            height
            qty
          }
        }
        hardware {
          id
          type
          code
          desc
          qty
        }
        details {
          type
          desc
        }
      }
    }
  }
`;
