import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { AuthContext } from '../../../auth';
import { useInitialValues, useTwoJs } from './lib';
import Header from './Header';
import Form from './Form';
import Drawing from './Drawing';
import { useOnSubmit } from './lib';
import {
  SYSTEM_CREATE_STOREFRONT,
  SYSTEM_UPDATE_STOREFRONT,
} from '../../../mutations';

const FdForm = ({
  systemId,
  marks,
  glassCompositions,
  frameColors,
  markups,
}) => {
  const isNew = !systemId;
  const { two, twoElem, getImage } = useTwoJs();
  const { currentUser } = useContext(AuthContext);
  const params = useParams();
  const formContext = useForm({
    mode: 'onBlur',
    defaultValues: {
      estimateId: params.estimateId,
      qty: 1,
      autoPrice: true,
    },
  });
  const glassCompositionId = useWatch({
    control: formContext.control,
    name: 'glassCompositionId',
  });

  const onSubmit = useOnSubmit({
    getImage,
    mutation: isNew ? SYSTEM_CREATE_STOREFRONT : SYSTEM_UPDATE_STOREFRONT,
    estimateId: params.estimateId,
  });
  useInitialValues({ systemId, formContext });

  const filteredGlassCompositions = currentUser.internal
    ? glassCompositions
    : glassCompositions.filter(
        ({ id, glassOnly }) => !glassOnly || id === glassCompositionId
      );

  return (
    <Row className="system-form">
      <Col xs={3} className="d-flex flex-column">
        <Header isNew={isNew} />
        <div className="form-container">
          <div className="fixed">
            <Form
              context={formContext}
              marks={marks}
              glassCompositions={filteredGlassCompositions}
              frameColors={frameColors}
              markups={markups}
            />
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary btn-block mt-4"
          onClick={formContext.handleSubmit(onSubmit)}
        >
          Save
        </button>
        <Link
          to={`/estimates/${params.estimateId}`}
          className="btn btn-outline-secondary btn-block"
        >
          Cancel
        </Link>
      </Col>
      <Col xs={9} className="d-flex">
        <Drawing formContext={formContext} two={two} twoElem={twoElem} />
      </Col>
    </Row>
  );
};

FdForm.defaultProps = {
  systemId: '',
};

FdForm.propTypes = {
  systemId: PropTypes.string,
  marks: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FdForm;
