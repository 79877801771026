import { useQuery } from 'react-apollo';
import { PRICES_BY_SYSTEM_TYPE } from '../../../../queries';

export const useFDPrices = () => {
  const fd = useQuery(PRICES_BY_SYSTEM_TYPE, {
    variables: { systemType: 'fd' },
  });
  const fdLite = useQuery(PRICES_BY_SYSTEM_TYPE, {
    variables: { systemType: 'fd_lite' },
  });
  if (fd.loading || fdLite.loading) return { fd: [], fdLite: [] };
  return {
    fd: fd.data.pricesBySystemType,
    fdLite: fdLite.data.pricesBySystemType,
  };
};
