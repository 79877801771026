import { SHAPES } from '../components/systems/FwForm';

const shapes = Object.fromEntries(
  Object.entries(SHAPES).map(([k, v]) => [v, k])
);

export const getFullSystemType = (system) => {
  switch (system.type) {
    case 'hr':
      return 'Horizontal Roller';
    case 'sh':
      return 'Single Hung';
    case 'fw':
      if (system.verticals) {
        let shape = 'Rectangle';
        const { lHeight, rHeight } = system.verticals[0];
        const shapeDetail = system.details.find(({ type }) => type === 'shape');
        if (lHeight !== rHeight) shape = 'Trapezoid';
        if (shapeDetail) shape = shapes[shapeDetail.desc];
        return `Fixed Window: ${shape}`;
      }
      return 'Fixed Window';
    case 'fd':
      return 'French Door';
    case 'fd_lite':
      return 'French Door Sidelite';
    case 'sgd':
      return 'Sliding Glass Door';
    case 'glass':
      return 'Glass';
    case 'misc':
    case 'client_misc':
      return 'Miscellaneous';
    default:
      return 'Unknown';
  }
};
