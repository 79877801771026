import React, { Component } from "react";
import { Query, Mutation } from "react-apollo";
import { FRAME_COLORS_LIST } from "../../queries";
import { FRAME_COLOR_DELETE } from "../../mutations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import bootbox from "bootbox";
import FrameColorForm from "./FrameColorForm";
import Modal from "../general/Modal";

class Actions extends Component {
  updateCache = (cache, { data: { frameColorDelete } }) => {
    const data = cache.readQuery({ query: FRAME_COLORS_LIST });
    data.frameColors = data.frameColors.filter(
      f => f.id !== frameColorDelete.id
    );
    cache.writeQuery({
      query: FRAME_COLORS_LIST,
      data
    });
  };

  render() {
    const { frameColorId: id } = this.props;

    return (
      <Mutation
        mutation={FRAME_COLOR_DELETE}
        variables={{ id }}
        update={this.updateCache}
      >
        {mutation => (
          <div className="dropdown float-right mr-3">
            <button
              onClick={e => e.stopPropagation()}
              className="btn btn-link text-secondary p-0"
              type="button"
              id="estimate-options-menu"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <FontAwesomeIcon icon={["fal", "ellipsis-h-alt"]} />
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="estimate-options-menu"
            >
              <button
                className="dropdown-item"
                onClick={e => {
                  e.stopPropagation();
                  bootbox.confirm("Are you sure?", r => {
                    r &&
                      mutation().catch(e => {
                        const message = e.graphQLErrors
                          .map(e => e.message)
                          .join(".");
                        bootbox.alert(`Cannot delete frame color. ${message}`);
                      });
                  });
                }}
              >
                <FontAwesomeIcon icon={["fal", "trash-alt"]} className="mr-3" />
                Delete
              </button>
            </div>
          </div>
        )}
      </Mutation>
    );
  }
}

export default class FrameColors extends Component {
  state = {
    selectedFrameColorId: null
  };

  editFrameColor = selectedFrameColorId => {
    this.setState({ selectedFrameColorId }, () => {
      $("#edit-frame-color-modal").modal();
    });
  };

  submitFrameColorForm = id => {
    document
      .getElementById(id)
      .dispatchEvent(new Event("submit", { cancelable: true }));
  };

  onFrameColorFormCompleted = form => {
    $("#new-frame-color-modal").modal("hide");
    $("#edit-frame-color-modal").modal("hide");
    setTimeout(form.reset);
  };

  render() {
    return (
      <Query query={FRAME_COLORS_LIST}>
        {({ loading, error, data }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error</p>;

          const { frameColors } = data;

          return (
            <div className="frame-colors-component">
              <div className="header clearfix">
                <h4 className="float-left mt-2">Frame Colors</h4>
                <button
                  className="btn btn-primary float-right mb-3"
                  data-toggle="modal"
                  data-target="#new-frame-color-modal"
                >
                  New{" "}
                  <FontAwesomeIcon icon={["fal", "plus"]} className="ml-2" />
                </button>
              </div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">color</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {frameColors.map(f => (
                    <tr key={f.id} onClick={() => this.editFrameColor(f.id)}>
                      <td>{f.color}</td>
                      <td>
                        <Actions frameColorId={f.id} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Modal
                modalId="new-frame-color-modal"
                title="New Frame Color"
                onContinue={() =>
                  this.submitFrameColorForm("create-frame-color-form")
                }
              >
                <FrameColorForm onCompleted={this.onFrameColorFormCompleted} />
              </Modal>
              <Modal
                modalId="edit-frame-color-modal"
                title="Edit Frame Color"
                onContinue={() =>
                  this.submitFrameColorForm("update-frame-color-form")
                }
              >
                <FrameColorForm
                  onCompleted={this.onFrameColorFormCompleted}
                  frameColorId={this.state.selectedFrameColorId}
                />
              </Modal>
            </div>
          );
        }}
      </Query>
    );
  }
}
