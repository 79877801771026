import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import price from '../../price';
import { useFDPrices } from './useFDPrices';

export const usePriceUpdate = (formCtx, glassCompositions, markups) => {
  const prices = useFDPrices();
  const [
    config,
    doorWidth,
    leftWidth,
    rightWidth,
    height,
    autoPrice,
    glassCompositionId,
  ] = useWatch({
    name: [
      'config',
      'doorWidth',
      'leftWidth',
      'rightWidth',
      'height',
      'autoPrice',
      'glassCompositionId',
    ],
    control: formCtx.control,
  });

  useEffect(
    () => {
      const doorPrice = price({
        type: 'fd',
        compositions: glassCompositions,
        markups,
        prices: prices.fd,
        autoPrice,
        price: 'default',
        glassCompositionId,
        width: doorWidth,
        lHeight: height,
        rHeight: height,
      });

      const litePrice = price({
        type: 'fd_lite',
        compositions: glassCompositions,
        markups,
        prices: prices.fdLite,
        autoPrice,
        price: 'default',
        glassCompositionId,
        width: leftWidth || 0 + rightWidth || 0,
        lHeight: height,
        rHeight: height,
      });

      if (doorPrice !== 'default') formCtx.setValue('doorPrice', doorPrice);
      if (litePrice !== 'default') formCtx.setValue('litePrice', litePrice);
    },
    [
      config,
      doorWidth,
      leftWidth,
      rightWidth,
      height,
      autoPrice,
      prices.fd,
      prices.fdLite,
      glassCompositionId,
    ]
  );
};
