import React from "react";
import { Route, Redirect } from "react-router-dom";
import { signedIn } from "./auth";
import AuthProvider from "./AuthProvider";

export default ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      signedIn() ? (
        <AuthProvider>
          <Component {...props} />
        </AuthProvider>
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
