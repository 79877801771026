import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';
import { AuthContext } from '../../../auth';
import Field from '../../general/rhf/Field';
import { CONFIGS } from './constants';
import GlassSelection from './GlassSelection';
import { usePriceUpdate } from './lib';

const Form = ({ context, marks, glassCompositions, frameColors, markups }) => {
  const { currentUser } = useContext(AuthContext);
  usePriceUpdate(context, glassCompositions, markups);
  const autoPrice = useWatch({
    name: 'autoPrice',
    control: context.control,
  });

  return (
    <FormProvider {...context}>
      <form>
        <Field name="estimateId" type="hidden" />
        <Field name="id" type="hidden" />
        <Field
          name="mark"
          label="Mark"
          register={{
            required: 'Required',
            validate: {
              taken: (value) =>
                marks.includes(value.toUpperCase())
                  ? 'Mark is already in use'
                  : true,
            },
          }}
        />
        <Field
          name="autoPrice"
          type={currentUser.internal ? 'checkbox' : 'hidden'}
          label="Auto Price"
        />
        <Field
          name="price"
          label="Price per sqft"
          type={currentUser.internal ? 'number' : 'hidden'}
          readOnly={!!autoPrice}
          register={{ required: 'Required' }}
        />
        <Field
          type="number"
          name="qty"
          label="Qty"
          register={{ required: true }}
        />
        <GlassSelection glassCompositions={glassCompositions} />
        <Field type="select" name="frameColorId" label="Frame Color">
          {frameColors.map(({ id, color }) => (
            <option key={id} value={id}>
              {color}
            </option>
          ))}
        </Field>
        <Field type="select" name="config" label="Config">
          {Object.entries(CONFIGS).map(([config, value]) => (
            <option key={config} value={value}>
              {config}
            </option>
          ))}
        </Field>
        <Field
          type="number"
          name="width"
          label="Width"
          register={{ required: 'Required' }}
        />
        <Field
          type="number"
          name="height"
          label="Height"
          register={{ required: 'Required' }}
        />
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  context: PropTypes.object.isRequired,
  marks: PropTypes.arrayOf(PropTypes.string).isRequired,
  glassCompositions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      composition: PropTypes.string.isRequired,
    })
  ).isRequired,
  frameColors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Form;
