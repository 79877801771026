import currency from 'currency.js';
import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AuthContext } from '../../../auth';

const Payments = ({ estimate }) => {
  const { currentUser } = useContext(AuthContext);
  const { estimateId } = useParams();

  const openBalance = currency(estimate.sellPriceTaxes).subtract(
    estimate.payments.reduce(
      (acc, { amount }) => acc.add(currency(amount)),
      currency(0)
    )
  );

  return (
    <div>
      <span className="float-right">
        <table className="table table-sm table-borderless">
          <tbody>
            <tr>
              <th itemScope="row">Price:</th>
              <td align="right">{estimate && estimate.sellPrice}</td>
            </tr>
            <tr>
              <th itemScope="row">Taxes:</th>
              <td align="right">{estimate && estimate.taxes}</td>
            </tr>
            <tr>
              <th itemScope="row">Total:</th>
              <td align="right">{estimate && estimate.sellPriceTaxes}</td>
            </tr>
            <tr className="border-top">
              <th itemScope="row">Open Balance:</th>
              <td align="right">{openBalance.format(true)}</td>
            </tr>
            {currentUser.internal && (
              <tr>
                <td colSpan="2" align="center">
                  <Link to={`/estimates/${estimateId}/payments`}>Payments</Link>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </span>
    </div>
  );
};

export default Payments;
