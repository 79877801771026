import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import currency from 'currency.js';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React from 'react';
import { Mutation, Query } from 'react-apollo';
import { Col, Row } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { withRouter } from 'react-router-dom';
import { PO_CREATE, PO_UPDATE } from '../../mutations';
import { PO, PO_LIST, VENDORS_NAMES } from '../../queries';
import { pageParams } from '../../util';
import ValidatedField from '../general/ValidatedField';
import ValidatedSelect from '../general/ValidatedSelect';

const PAGE_SIZE = 10;

const items = [
  '101',
  '102',
  '103',
  '104',
  '106',
  '107',
  '108',
  '110',
  '111',
  '113',
  '115',
  '116',
  '117',
  '118',
  '119',
  '201',
  '202',
  '203',
  '204',
  '205',
  '206',
  '207',
  '208',
  '209',
  '210',
  '210A',
  '211',
  '213',
  '216',
  '301',
  '302',
  '303',
  '304',
  '305',
  '306',
  '307',
  '308',
  '309',
  '315',
  '319',
];

const validate = (values) => {
  const errors = {};
  if (!values.vendorId) errors.vendorId = 'Required';
  return errors;
};

const itemsValidate = (values) => {
  return values.map((item) => {
    const itemErrors = {};
    if (!item.qty && !item.weight) {
      itemErrors.qty = 'Required';
      itemErrors.weight = 'Required';
    }
    if (item.qty && item.weight) {
      itemErrors.qty = 'Provide qty or weight';
      itemErrors.weight = 'Provide qty or weight';
    }
    if (item.qty && item.qty < 1) itemErrors.qty = 'Qty has to be at least 1';
    if (!item.itemNo) itemErrors.itemNo = 'Required';
    if (item.itemNo === 'custom' && !item.customItemNo)
      itemErrors.customItemNo = 'Required';
    return itemErrors;
  });
};

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

const POMutation = (props) => {
  let formRef;
  const {
    formId,
    mutation,
    initialValues,
    onCompleted,
    validate,
    params,
  } = props;

  const _initialValues = {
    ...initialValues,
    poItems: initialValues.poItems.map((values) => ({
      ...values,
      ...(items.includes(values.itemNo)
        ? { itemNo: values.itemNo }
        : { itemNo: 'custom', customItemNo: values.itemNo }),
    })),
  };

  return (
    <Query query={VENDORS_NAMES}>
      {({ loading, error, data: { vendors } = {} }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error</p>;

        const sortedVendors = vendors.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        return (
          <Mutation
            mutation={mutation}
            validate={validate}
            onCompleted={() => onCompleted(formRef)}
            refetchQueries={[
              {
                query: PO_LIST,
                variables: pageParams(params.get('page') || 1, PAGE_SIZE),
              },
            ]}
          >
            {(mutation) => (
              <Form
                onSubmit={(variables) =>
                  mutation({
                    variables: {
                      ...variables,
                      poItems: variables.poItems.map(
                        ({ customItemNo, ...item }) => ({
                          ...item,
                          ...(item.qty && { qty: parseInt(item.qty, 10) }),
                          ...(item.weight && {
                            weight: parseFloat(item.weight),
                          }),
                          price: item.price ? item.price.toString() : null,
                          itemNo:
                            item.itemNo === 'custom'
                              ? customItemNo
                              : item.itemNo,
                        })
                      ),
                    },
                  })
                }
                mutators={{ ...arrayMutators }}
                validate={validate}
                initialValues={_initialValues}
              >
                {({ handleSubmit, form }) => {
                  formRef = form;
                  return (
                    <form id={formId} onSubmit={handleSubmit}>
                      <ValidatedSelect name="vendorId" label="Vendor">
                        <option value="" disabled>
                          Select a vendor...
                        </option>
                        {sortedVendors.map((v) => (
                          <option key={v.id} value={v.id}>
                            {v.name}
                          </option>
                        ))}
                      </ValidatedSelect>
                      <FieldArray name="poItems" validate={itemsValidate}>
                        {({ fields }) => (
                          <>
                            {fields.map((name) => (
                              <div className="bg-light p-2 mb-2" key={name}>
                                <Row>
                                  <Col>
                                    <ValidatedField
                                      name={`${name}.qty`}
                                      label="Qty"
                                      type="number"
                                      cols={[3, 9]}
                                    />
                                  </Col>
                                  <Col>
                                    <ValidatedField
                                      name={`${name}.weight`}
                                      label="Weight"
                                      type="number"
                                      cols={[3, 9]}
                                    />
                                  </Col>
                                </Row>
                                <ValidatedSelect
                                  name={`${name}.itemNo`}
                                  label="Item #"
                                  cols={[3, 9]}
                                >
                                  <option value="custom">Custom</option>
                                  {items.map((item) => (
                                    <option>{item}</option>
                                  ))}
                                </ValidatedSelect>
                                <Condition when={`${name}.itemNo`} is="custom">
                                  <ValidatedField
                                    name={`${name}.customItemNo`}
                                    label="Custom #"
                                    cols={[3, 9]}
                                  />
                                </Condition>
                                <ValidatedField
                                  name={`${name}.description`}
                                  label="Description"
                                  cols={[3, 9]}
                                />
                                <ValidatedField
                                  name={`${name}.price`}
                                  label="Price"
                                  type="number"
                                  cols={[3, 9]}
                                  className="mb-0"
                                />
                              </div>
                            ))}
                            <button
                              type="button"
                              className="btn btn-outline-primary d-block mx-auto"
                              onClick={() => fields.push({ itemNo: 'custom' })}
                            >
                              <FontAwesomeIcon
                                icon={['fal', 'plus']}
                                size="lg"
                              />
                            </button>
                          </>
                        )}
                      </FieldArray>
                    </form>
                  );
                }}
              </Form>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

const POQuery = ({ id, onCompleted, params }) => (
  <Query query={PO} variables={{ id }}>
    {({ loading, error, data: { po } = {} }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error</p>;

      const poItems = po.poItems.map(({ __typename, ...attrs }) => ({
        ...attrs,
        price: attrs.price ? currency(attrs.price) : null,
      }));

      return (
        <POMutation
          formId="update-po-form"
          initialValues={{ ...po, vendorId: po.vendor.id, poItems }}
          mutation={PO_UPDATE}
          onCompleted={onCompleted}
          validate={validate}
          params={params}
        />
      );
    }}
  </Query>
);

const POForm = ({ onCompleted, poId, location }) => {
  const params = new URLSearchParams(location.search);

  return poId ? (
    <POQuery id={poId} onCompleted={onCompleted} params={params} />
  ) : (
    <POMutation
      formId="create-po-form"
      mutation={PO_CREATE}
      onCompleted={onCompleted}
      validate={validate}
      params={params}
      initialValues={{
        poItems: [{ itemNo: '', description: '', price: '' }],
      }}
    />
  );
};

POForm.propTypes = {
  onCompleted: PropTypes.func.isRequired,
};

export default withRouter(POForm);
