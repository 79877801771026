const ANNEALED = [
  { height: 25, width: 18.125, pos: 70, neg: 70 },
  { height: 25, width: 25.5, pos: 70, neg: 70 },
  { height: 25, width: 36, pos: 70, neg: 70 },
  { height: 25, width: 52.125, pos: 70, neg: 70 },

  { height: 37.375, width: 18.125, pos: 70, neg: 70 },
  { height: 37.375, width: 25.5, pos: 70, neg: 70 },
  { height: 37.375, width: 36, pos: 68.5, neg: 68.5 },
  { height: 37.375, width: 52.125, pos: 66, neg: 66 },

  { height: 47, width: 23, pos: 70, neg: 70 },
  { height: 47, width: 29, pos: 70, neg: 70 },
  { height: 47, width: 35.25, pos: 70, neg: 70 },
  { height: 47, width: 41, pos: 60.2, neg: 60.2 },

  { height: 49.625, width: 18.125, pos: 70, neg: 70 },
  { height: 49.625, width: 25.5, pos: 70, neg: 70 },
  { height: 49.625, width: 36, pos: 68.5, neg: 68.5 },

  { height: 55, width: 18.125, pos: 70, neg: 70 },
  { height: 55, width: 25.5, pos: 70, neg: 70 },
  { height: 55, width: 36, pos: 68.5, neg: 68.5 },

  { height: 59, width: 23, pos: 70, neg: 70 },
  { height: 59, width: 29, pos: 70, neg: 70 },

  { height: 62, width: 18.125, pos: 70, neg: 70 },
  { height: 62, width: 23, pos: 70, neg: 70 },
  { height: 62, width: 25.5, pos: 70, neg: 70 },
  { height: 62, width: 29, pos: 70, neg: 70 },

  { height: 71, width: 18.125, pos: 70, neg: 70 },
  { height: 71, width: 23, pos: 70, neg: 70 },
  { height: 71, width: 25.5, pos: 70, neg: 70 },

  { height: 75, width: 18.125, pos: 70, neg: 70 },
  { height: 75, width: 25.5, pos: 70, neg: 70 },

  { height: 83, width: 23, pos: 70, neg: 70 },
];

const _5_16_ANNEALED = [
  { height: 25, width: 18.125, pos: 55, neg: 55 },
  { height: 25, width: 25.5, pos: 55, neg: 55 },
  { height: 25, width: 36, pos: 55, neg: 55 },
  { height: 25, width: 52.125, pos: 55, neg: 55 },

  { height: 37.375, width: 18.125, pos: 55, neg: 55 },
  { height: 37.375, width: 25.5, pos: 55, neg: 55 },
  { height: 37.375, width: 36, pos: 68.5, neg: 68.5 },
  { height: 37.375, width: 52.125, pos: 66, neg: 66 },

  { height: 47, width: 23, pos: 55, neg: 55 },
  { height: 47, width: 29, pos: 55, neg: 55 },
  { height: 47, width: 35.25, pos: 55, neg: 55 },
  { height: 47, width: 41, pos: 60.2, neg: 60.2 },

  { height: 49.625, width: 18.125, pos: 55, neg: 55 },
  { height: 49.625, width: 25.5, pos: 55, neg: 55 },
  { height: 49.625, width: 36, pos: 68.5, neg: 68.5 },

  { height: 55, width: 18.125, pos: 55, neg: 55 },
  { height: 55, width: 25.5, pos: 55, neg: 55 },
  { height: 55, width: 36, pos: 68.5, neg: 68.5 },

  { height: 59, width: 23, pos: 55, neg: 55 },
  { height: 59, width: 29, pos: 55, neg: 55 },

  { height: 62, width: 18.125, pos: 55, neg: 55 },
  { height: 62, width: 23, pos: 55, neg: 55 },
  { height: 62, width: 25.5, pos: 55, neg: 55 },
  { height: 62, width: 29, pos: 55, neg: 55 },

  { height: 71, width: 18.125, pos: 55, neg: 55 },
  { height: 71, width: 23, pos: 55, neg: 55 },
  { height: 71, width: 25.5, pos: 55, neg: 55 },

  { height: 75, width: 18.125, pos: 55, neg: 55 },
  { height: 75, width: 25.5, pos: 55, neg: 55 },

  { height: 83, width: 23, pos: 55, neg: 55 },
];

const HEAT_STRENGTHENED = [
  { height: 25, width: 18.125, pos: 80, neg: 95 },
  { height: 25, width: 25.5, pos: 80, neg: 95 },
  { height: 25, width: 36, pos: 80, neg: 95 },
  { height: 25, width: 52.125, pos: 80, neg: 95 },

  { height: 37.375, width: 18.125, pos: 80, neg: 95 },
  { height: 37.375, width: 25.5, pos: 80, neg: 95 },
  { height: 37.375, width: 36, pos: 80, neg: 95 },
  { height: 37.375, width: 52.125, pos: 80, neg: 95 },

  { height: 47, width: 23, pos: 80, neg: 95 },
  { height: 47, width: 29, pos: 80, neg: 95 },
  { height: 47, width: 31, pos: 80, neg: 95 },
  { height: 47, width: 35, pos: 80, neg: 95 },
  { height: 47, width: 41, pos: 80, neg: 95 },
  { height: 47, width: 47, pos: 80, neg: 95 },
  { height: 47, width: 53, pos: 80, neg: 95 },
  { height: 47, width: 59, pos: 80, neg: 95 },
  { height: 47, width: 65, pos: 80, neg: 95 },
  { height: 47, width: 71, pos: 80, neg: 95 },
  { height: 47, width: 77, pos: 80, neg: 95 },

  { height: 49.625, width: 18.125, pos: 80, neg: 95 },
  { height: 49.625, width: 25.5, pos: 80, neg: 95 },
  { height: 49.625, width: 36, pos: 80, neg: 95 },
  { height: 49.625, width: 52.125, pos: 75.8, neg: 90 },

  { height: 55, width: 18.125, pos: 80, neg: 95 },
  { height: 55, width: 25.5, pos: 80, neg: 95 },
  { height: 55, width: 36, pos: 80, neg: 95 },
  { height: 55, width: 52.125, pos: 72.1, neg: 85.7 },

  { height: 59, width: 23, pos: 80, neg: 95 },
  { height: 59, width: 29, pos: 80, neg: 95 },
  { height: 59, width: 31, pos: 80, neg: 95 },
  { height: 59, width: 35, pos: 80, neg: 95 },
  { height: 59, width: 41, pos: 80, neg: 95 },
  { height: 59, width: 47, pos: 80, neg: 95 },
  { height: 59, width: 53, pos: 70.9, neg: 84.2 },
  { height: 59, width: 59, pos: 63.7, neg: 75.7 },
  { height: 59, width: 65, pos: 63.7, neg: 75.7 },
  { height: 59, width: 71, pos: 63.7, neg: 75.7 },
  { height: 59, width: 77, pos: 63.7, neg: 75.7 },

  { height: 62, width: 18.125, pos: 80, neg: 95 },
  { height: 62, width: 23, pos: 80, neg: 95 },
  { height: 62, width: 25.5, pos: 80, neg: 95 },
  { height: 62, width: 29, pos: 80, neg: 95 },
  { height: 62, width: 31, pos: 80, neg: 95 },
  { height: 62, width: 35, pos: 80, neg: 95 },
  { height: 62, width: 36, pos: 80, neg: 95 },
  { height: 62, width: 41, pos: 80, neg: 95 },
  { height: 62, width: 47, pos: 80, neg: 95 },
  { height: 62, width: 52.125, pos: 72.1, neg: 85.7 },
  { height: 62, width: 53, pos: 70.9, neg: 84.2 },
  { height: 62, width: 59, pos: 63.7, neg: 75.7 },
  { height: 62, width: 65, pos: 60.6, neg: 72 },
  { height: 62, width: 71, pos: 60.6, neg: 72 },

  { height: 71, width: 18.125, pos: 80, neg: 95 },
  { height: 71, width: 23, pos: 80, neg: 95 },
  { height: 71, width: 25.5, pos: 80, neg: 95 },
  { height: 71, width: 29, pos: 80, neg: 95 },
  { height: 71, width: 31, pos: 80, neg: 95 },
  { height: 71, width: 35, pos: 80, neg: 95 },
  { height: 71, width: 36, pos: 80, neg: 95 },
  { height: 71, width: 41, pos: 80, neg: 95 },
  { height: 71, width: 47, pos: 80, neg: 95 },
  { height: 71, width: 52.125, pos: 72.1, neg: 85.7 },
  { height: 71, width: 53, pos: 70.9, neg: 84.2 },
  { height: 71, width: 59, pos: 63.7, neg: 75.7 },
  { height: 71, width: 65, pos: 57.8, neg: 68.7 },

  { height: 75, width: 18.125, pos: 80, neg: 95 },
  { height: 75, width: 25.5, pos: 80, neg: 95 },
  { height: 75, width: 36, pos: 80, neg: 94.6 },
  { height: 75, width: 52.125, pos: 72.1, neg: 85.7 },

  { height: 83, width: 23, pos: 80, neg: 95 },
  { height: 83, width: 29, pos: 80, neg: 95 },
  { height: 83, width: 31, pos: 80, neg: 95 },
  { height: 83, width: 35, pos: 80, neg: 95 },
  { height: 83, width: 41, pos: 80, neg: 95 },
  { height: 83, width: 47, pos: 80, neg: 95 },
  { height: 83, width: 53, pos: 70.9, neg: 84.2 },

  { height: 99, width: 23, pos: 80, neg: 95 },
  { height: 99, width: 29, pos: 80, neg: 95 },
  { height: 99, width: 31, pos: 80, neg: 95 },
  { height: 99, width: 35, pos: 80, neg: 95 },
  { height: 99, width: 41, pos: 80, neg: 95 },
  { height: 99, width: 47, pos: 80, neg: 95 },

  { height: 107, width: 23, pos: 80, neg: 95 },
  { height: 107, width: 29, pos: 80, neg: 95 },
  { height: 107, width: 31, pos: 80, neg: 95 },
  { height: 107, width: 35, pos: 80, neg: 95 },
  { height: 107, width: 41, pos: 80, neg: 95 },

  { height: 119, width: 23, pos: 80, neg: 95 },
  { height: 119, width: 29, pos: 80, neg: 95 },
  { height: 119, width: 31, pos: 80, neg: 95 },
  { height: 119, width: 35.25, pos: 80, neg: 95 },
];

const SMI = [
  { height: 25, width: 18.125, pos: 80, neg: 95 },
  { height: 25, width: 25.5, pos: 80, neg: 95 },
  { height: 25, width: 36, pos: 80, neg: 95 },
  { height: 25, width: 52.125, pos: 80, neg: 95 },

  { height: 37.375, width: 18.125, pos: 80, neg: 95 },
  { height: 37.375, width: 25.5, pos: 80, neg: 95 },
  { height: 37.375, width: 36, pos: 80, neg: 95 },
  { height: 37.375, width: 52.125, pos: 80, neg: 95 },

  { height: 47, width: 23, pos: 80, neg: 95 },
  { height: 47, width: 29, pos: 80, neg: 95 },
  { height: 47, width: 31, pos: 80, neg: 95 },
  { height: 47, width: 35, pos: 80, neg: 95 },
  { height: 47, width: 41, pos: 80, neg: 95 },
  { height: 47, width: 47, pos: 80, neg: 95 },
  { height: 47, width: 53, pos: 80, neg: 95 },
  { height: 47, width: 59, pos: 80, neg: 95 },
  { height: 47, width: 62, pos: 77.9, neg: 90.7 },

  { height: 49.625, width: 18.125, pos: 80, neg: 95 },
  { height: 49.625, width: 25.5, pos: 80, neg: 95 },
  { height: 49.625, width: 36, pos: 80, neg: 95 },
  { height: 49.625, width: 52.125, pos: 80, neg: 95 },

  { height: 55, width: 18.125, pos: 80, neg: 95 },
  { height: 55, width: 25.5, pos: 80, neg: 95 },
  { height: 55, width: 36, pos: 80, neg: 95 },
  { height: 55, width: 52.125, pos: 78.9, neg: 93.7 },

  { height: 59, width: 23, pos: 80, neg: 95 },
  { height: 59, width: 29, pos: 80, neg: 95 },
  { height: 59, width: 31, pos: 80, neg: 95 },
  { height: 59, width: 35, pos: 80, neg: 95 },
  { height: 59, width: 41, pos: 80, neg: 95 },
  { height: 59, width: 47, pos: 80, neg: 95 },

  { height: 62, width: 18.125, pos: 80, neg: 95 },
  { height: 62, width: 23, pos: 80, neg: 95 },
  { height: 62, width: 25.5, pos: 80, neg: 95 },
  { height: 62, width: 29, pos: 80, neg: 95 },
  { height: 62, width: 31, pos: 80, neg: 95 },
  { height: 62, width: 35, pos: 80, neg: 95 },
  { height: 62, width: 36, pos: 80, neg: 95 },
  { height: 62, width: 41, pos: 80, neg: 95 },
  { height: 62, width: 47, pos: 77.9, neg: 90.7 },

  { height: 71, width: 18.125, pos: 80, neg: 95 },
  { height: 71, width: 23, pos: 80, neg: 95 },
  { height: 71, width: 25.5, pos: 80, neg: 95 },
  { height: 71, width: 29, pos: 80, neg: 95 },
  { height: 71, width: 31, pos: 80, neg: 95 },
  { height: 71, width: 35, pos: 80, neg: 95 },
  { height: 71, width: 36, pos: 80, neg: 95 },
  { height: 71, width: 41, pos: 78, neg: 86.8 },

  { height: 75, width: 18.125, pos: 80, neg: 95 },
  { height: 75, width: 25.5, pos: 80, neg: 95 },
  { height: 75, width: 36, pos: 80, neg: 94.6 },

  { height: 83, width: 23, pos: 80, neg: 95 },
  { height: 83, width: 29, pos: 80, neg: 95 },
  { height: 83, width: 31, pos: 80, neg: 95 },
  { height: 83, width: 35.25, pos: 80, neg: 90 },

  { height: 99, width: 23, pos: 80, neg: 95 },
  { height: 99, width: 29, pos: 80, neg: 95 },

  { height: 107, width: 23, pos: 80, neg: 95 },

  { height: 119, width: 23, pos: 80, neg: 95 },
];

export default {
  LMI: {
    ANNEALED: {
      _5_16: _5_16_ANNEALED,
      _7_16: ANNEALED,
      _9_16: ANNEALED,
      _15_16: ANNEALED,
    },
    HEAT_STRENGTHENED: {
      _5_16: [],
      _7_16: HEAT_STRENGTHENED,
      _9_16: HEAT_STRENGTHENED,
      _15_16: HEAT_STRENGTHENED,
    },
  },
  SMI: {
    ANNEALED: { _5_16: [], _7_16: SMI, _9_16: SMI, _15_16: SMI },
    HEAT_STRENGTHENED: { _5_16: [], _7_16: SMI, _9_16: SMI, _15_16: SMI },
  },
};
