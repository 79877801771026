import gql from 'graphql-tag';

export const SYSTEM_CREATE = gql`
  mutation SystemCreate(
    $estimateId: ID!
    $mark: String!
    $type: SystemType!
    $width: Float!
    $lHeight: Float!
    $rHeight: Float!
    $image: Upload
    $config: Int
    $qty: Int
    $positiveLoad: Float
    $negativeLoad: Float
    $hardware: [HardwareInput]
    $details: [DetailsInput]
    $glassCompositionId: ID
    $frameColorId: ID
    $screen: Boolean
    $price: String
    $autoPrice: Boolean
  ) {
    systemCreate(
      estimateId: $estimateId
      mark: $mark
      type: $type
      width: $width
      lHeight: $lHeight
      rHeight: $rHeight
      image: $image
      config: $config
      qty: $qty
      positiveLoad: $positiveLoad
      negativeLoad: $negativeLoad
      hardware: $hardware
      details: $details
      glassCompositionId: $glassCompositionId
      frameColorId: $frameColorId
      screen: $screen
      price: $price
      autoPrice: $autoPrice
    ) {
      id
      mark
      type
      config
      imagePath
      qty
      positiveLoad
      negativeLoad
      priceSqft
      sellPrice
      sellPriceTotal
      metaSellPrice
      metaSellPriceTotal
      autoPrice
      frameColor {
        id
        color
      }
      verticals {
        index
        width
        lHeight
        rHeight
        lites
        priceSqft
        extrusions {
          type
          lengths
          desc
        }
        glass {
          width
          lHeight
          rHeight
          qty
          glassComposition {
            id
            composition
          }
        }
        screen {
          width
          height
          qty
        }
      }
      hardware {
        type
        code
        desc
        qty
      }
      details {
        type
        desc
      }
    }
  }
`;

export const SYSTEM_UPDATE = gql`
  mutation SystemUpdate(
    $id: ID!
    $mark: String!
    $positiveLoad: Float
    $negativeLoad: Float
    $type: SystemType!
    $width: Float!
    $lHeight: Float!
    $rHeight: Float!
    $image: Upload
    $config: Int
    $qty: Int
    $hardware: [HardwareInput]
    $details: [DetailsInput]
    $glassCompositionId: ID
    $frameColorId: ID
    $screen: Boolean
    $price: String
    $autoPrice: Boolean
  ) {
    systemUpdate(
      id: $id
      mark: $mark
      positiveLoad: $positiveLoad
      negativeLoad: $negativeLoad
      type: $type
      width: $width
      lHeight: $lHeight
      rHeight: $rHeight
      image: $image
      config: $config
      qty: $qty
      hardware: $hardware
      details: $details
      glassCompositionId: $glassCompositionId
      frameColorId: $frameColorId
      screen: $screen
      price: $price
      autoPrice: $autoPrice
    ) {
      id
      mark
      type
      config
      imagePath
      qty
      positiveLoad
      negativeLoad
      priceSqft
      sellPrice
      sellPriceTotal
      metaSellPrice
      metaSellPriceTotal
      autoPrice
      frameColor {
        id
        color
      }
      verticals {
        index
        width
        lHeight
        rHeight
        lites
        priceSqft
        extrusions {
          type
          lengths
          lengths
          desc
        }
        glass {
          width
          lHeight
          rHeight
          qty
          glassComposition {
            id
            composition
          }
        }
        screen {
          width
          height
          qty
        }
      }
      hardware {
        type
        code
        desc
        qty
      }
      details {
        type
        desc
      }
    }
  }
`;

export const SYSTEM_CREATE_STOREFRONT = gql`
  mutation SystemCreateStorefront(
    $estimateId: ID!
    $mark: String!
    $type: SystemType!
    $image: Upload
    $config: Int
    $qty: Int
    $details: [DetailsInput]
    $verticals: [VerticalInput]
    $glassCompositionId: ID
    $frameColorId: ID
    $autoPrice: Boolean
  ) {
    systemCreateStorefront(
      estimateId: $estimateId
      mark: $mark
      type: $type
      image: $image
      config: $config
      qty: $qty
      details: $details
      verticals: $verticals
      glassCompositionId: $glassCompositionId
      frameColorId: $frameColorId
      autoPrice: $autoPrice
    ) {
      id
      mark
      type
      config
      imagePath
      qty
      positiveLoad
      negativeLoad
      priceSqft
      sellPrice
      sellPriceTotal
      metaSellPrice
      metaSellPriceTotal
      autoPrice
      frameColor {
        id
        color
      }
      verticals {
        index
        width
        lHeight
        rHeight
        lites
        priceSqft
        extrusions {
          type
          lengths
          desc
        }
        glass {
          width
          lHeight
          rHeight
          qty
          glassComposition {
            id
            composition
          }
        }
        screen {
          width
          height
          qty
        }
      }
      hardware {
        type
        code
        desc
        qty
      }
      details {
        type
        desc
      }
    }
  }
`;

export const SYSTEM_UPDATE_STOREFRONT = gql`
  mutation SystemUpdateStorefront(
    $id: ID!
    $mark: String!
    $type: SystemType!
    $image: Upload
    $config: Int
    $qty: Int
    $details: [DetailsInput]
    $verticals: [VerticalInput]
    $glassCompositionId: ID
    $frameColorId: ID
    $autoPrice: Boolean
  ) {
    systemUpdateStorefront(
      id: $id
      mark: $mark
      type: $type
      image: $image
      config: $config
      qty: $qty
      details: $details
      verticals: $verticals
      glassCompositionId: $glassCompositionId
      frameColorId: $frameColorId
      autoPrice: $autoPrice
    ) {
      id
      mark
      type
      config
      imagePath
      qty
      positiveLoad
      negativeLoad
      priceSqft
      sellPrice
      sellPriceTotal
      autoPrice
      frameColor {
        id
        color
      }
      verticals {
        index
        width
        lHeight
        rHeight
        lites
        priceSqft
        extrusions {
          type
          lengths
          lengths
          desc
        }
        glass {
          width
          lHeight
          rHeight
          qty
          glassComposition {
            id
            composition
          }
        }
        screen {
          width
          height
          qty
        }
      }
      hardware {
        type
        code
        desc
        qty
      }
      details {
        type
        desc
      }
    }
  }
`;

export const SYSTEM_UPDATE_MANY = gql`
  mutation SystemUpdateMany($data: [SystemUpdateManyInput]!) {
    systemUpdateMany(data: $data) {
      id
    }
  }
`;

export const SYSTEM_DELETE = gql`
  mutation SystemDelete($id: ID!) {
    systemDelete(id: $id) {
      id
    }
  }
`;
