import React from 'react';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';

const Estimates = ({ estimates }) => {
  const history = useHistory();

  return (
    <div>
      <h3>Estimates</h3>
      <table className="table table-hover table-striped">
        <thead>
          <tr>
            <th itemScope="col">Name</th>
            <th itemScope="col">Number</th>
            <th itemScope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {estimates.map((estimate) => {
            let status = 'Quote';
            let date = estimate.insertedAt;
            if (estimate.clientOrderedAt) {
              status = 'Pending';
              date = estimate.clientOrderedAt;
            }
            if (estimate.orderedAt) {
              status = 'Order';
              date = estimate.orderedAt;
            }

            return (
              <tr
                key={estimate.id}
                onClick={() => history.push(`/estimates/${estimate.id}`)}
              >
                <td>{estimate.name}</td>
                <td>{estimate.id.padStart(6, 0)}</td>
                <td>
                  {status} on <Moment date={date} format="MMM DD, YYYY" />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Estimates;
