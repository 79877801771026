import React, { Component, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from 'react-apollo';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import ValidatedField from '../general/ValidatedField';
import ValidatedSelect from '../general/ValidatedSelect';
import { ESTIMATE_CREATE, ESTIMATE_UPDATE } from '../../mutations';
import { pageParams } from '../../util';
import {
  ESTIMATES_LIST,
  ESTIMATE,
  CLIENTS_LIST,
  PROJECTS_LIST,
  ESTIMATE_SYSTEMS,
} from '../../queries';
import { AuthContext } from '../../auth';

const DatePickerAdapter = ({ input: { onChange, value }, ...rest }) => (
  <DatePicker selected={value} onChange={(date) => onChange(date)} {...rest} />
);

const EstimateMutation = (props) => {
  const { currentUser } = useContext(AuthContext);
  let formRef;
  const {
    formId,
    mutation,
    initialValues,
    updateCache,
    onCompleted,
    validate,
    location,
  } = props;

  const isOrderRequested = !!(
    initialValues &&
    (initialValues.clientOrderedAt || initialValues.orderedAt)
  );

  let _initialValues = initialValues
    ? {
        ...initialValues,
        ...(initialValues.etc && { etc: new Date(initialValues.etc) }),
      }
    : {
        clientId: currentUser.internal ? '' : currentUser.client.id,
        projectId: '',
        ...(!currentUser.internal && { metaClientId: '' }),
      };

  const params = new URLSearchParams(location.search);

  const calculator = useMemo(
    () => (clients) =>
      createDecorator({
        field: currentUser.internal ? 'clientId' : 'metaClientId',
        updates: {
          [currentUser.internal ? 'markup' : 'metaMarkup']: (clientId) => {
            if (
              clientId !==
              _initialValues[currentUser.internal ? 'clientId' : 'metaClientId']
            ) {
              const client = clients.find(({ id }) => clientId === id);
              return client ? client.markup : 0;
            }
            return _initialValues[
              currentUser.internal ? 'markup' : 'metaMarkup'
            ];
          },
          [currentUser.internal ? 'tax' : 'metaTax']: (clientId) => {
            if (
              clientId !==
              _initialValues[currentUser.internal ? 'clientId' : 'metaClientId']
            ) {
              const client = clients.find(({ id }) => clientId === id);
              return client ? client.tax : 0;
            }
            return _initialValues[currentUser.internal ? 'tax' : 'metaTax'];
          },
        },
      }),
    []
  );

  return (
    <Query query={CLIENTS_LIST}>
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error</p>;

        const sortedClients = data.clients.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        const refetchQueries = _initialValues.id
          ? [{ query: ESTIMATE_SYSTEMS, variables: { id: _initialValues.id } }]
          : [
              {
                query: ESTIMATES_LIST,
                variables: pageParams(params.get('page') || 1),
              },
            ];

        if (!currentUser.internal) {
          const client = sortedClients.find(
            ({ id }) => currentUser.client.id === id
          );
          _initialValues = {
            ..._initialValues,
            tax: client ? client.tax : 0,
            markup: client ? client.markup : 0,
          };
        }

        return (
          <Query query={PROJECTS_LIST}>
            {({ loading, error, data }) => {
              if (loading) return <p>Loading...</p>;
              if (error) return <p>Error</p>;

              const sortedProjects = data.projects.sort((a, b) =>
                a.name.localeCompare(b.name)
              );

              return (
                <Mutation
                  mutation={mutation}
                  update={updateCache}
                  onCompleted={() => onCompleted(formRef)}
                  refetchQueries={refetchQueries}
                >
                  {(mutation) => (
                    <Form
                      onSubmit={(variables) =>
                        mutation({
                          variables: {
                            ...variables,
                            markup: parseFloat(variables.markup),
                            tax: parseFloat(variables.tax),
                            metaMarkup: parseFloat(variables.metaMarkup),
                            metaTax: parseFloat(variables.metaTax),
                          },
                        })
                      }
                      validate={(values) => validate(values, currentUser)}
                      initialValues={_initialValues}
                      decorators={[calculator(sortedClients)]}
                    >
                      {({ handleSubmit, form, values }) => {
                        formRef = form;
                        if (isOrderRequested)
                          return (
                            <form id={formId} onSubmit={handleSubmit}>
                              <Field name="id">
                                {(input) => <input {...input} type="hidden" />}
                              </Field>
                              <div className="full-width-datepicker">
                                <label htmlFor="etc" className="d-block">
                                  ETC
                                </label>
                                <Field
                                  name="etc"
                                  dateFormat="yyyy/MM/dd"
                                  component={DatePickerAdapter}
                                  className="form-control"
                                />
                              </div>
                            </form>
                          );
                        return (
                          <form id={formId} onSubmit={handleSubmit}>
                            <Field name="id">
                              {(input) => <input {...input} type="hidden" />}
                            </Field>
                            {!currentUser.internal && (
                              <>
                                <Field name="clientId">
                                  {(input) => (
                                    <input {...input} type="hidden" />
                                  )}
                                </Field>
                                <Field name="markup">
                                  {(input) => (
                                    <input {...input} type="hidden" />
                                  )}
                                </Field>
                                <Field name="tax">
                                  {(input) => (
                                    <input {...input} type="hidden" />
                                  )}
                                </Field>
                              </>
                            )}
                            <ValidatedSelect
                              name={
                                currentUser.internal
                                  ? 'clientId'
                                  : 'metaClientId'
                              }
                              label="Client"
                            >
                              <option value="" disabled>
                                Select a client...
                              </option>
                              {(currentUser.internal
                                ? sortedClients
                                : sortedClients.filter(
                                    (c) => c.id !== currentUser.client.id
                                  )
                              ).map((c) => (
                                <option key={c.id} value={c.id}>
                                  {c.name}
                                </option>
                              ))}
                            </ValidatedSelect>
                            {currentUser.internal && (
                              <div className="form-group">
                                <label htmlFor="projectId">Project</label>
                                <Field
                                  id="projectId"
                                  name="projectId"
                                  component="select"
                                  className="form-control"
                                >
                                  <option value="">No project</option>
                                  {sortedProjects
                                    .filter(
                                      (p) => p.client.id === values.clientId
                                    )
                                    .map((p) => (
                                      <option key={p.id} value={p.id}>
                                        {p.name}
                                      </option>
                                    ))}
                                </Field>
                              </div>
                            )}
                            <ValidatedField name="name" label="Name" />
                            <ValidatedField
                              name={
                                currentUser.internal ? 'markup' : 'metaMarkup'
                              }
                              label="Markup"
                              type="number"
                            />
                            <ValidatedField
                              name={currentUser.internal ? 'tax' : 'metaTax'}
                              label="Tax"
                              type="number"
                            />
                            {currentUser.internal && (
                              <div className="full-width-datepicker">
                                <label htmlFor="etc" className="d-block">
                                  ETC
                                </label>
                                <Field
                                  name="etc"
                                  dateFormat="yyyy/MM/dd"
                                  component={DatePickerAdapter}
                                  className="form-control"
                                />
                              </div>
                            )}
                          </form>
                        );
                      }}
                    </Form>
                  )}
                </Mutation>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

const EstimateQuery = ({
  estimateId,
  updateCache,
  onCompleted,
  validate,
  location,
}) => (
  <Query query={ESTIMATE} variables={{ id: estimateId }}>
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error</p>;

      return (
        <EstimateMutation
          formId={`update-estimate-${estimateId}-form`}
          mutation={ESTIMATE_UPDATE}
          initialValues={{
            ...data.estimate,
            clientId: data.estimate.client.id,
            ...(data.estimate.metaClient && {
              metaClientId: data.estimate.metaClient.id,
            }),
          }}
          updateCache={updateCache}
          onCompleted={onCompleted}
          validate={validate}
          location={location}
        />
      );
    }}
  </Query>
);

class EstimateForm extends Component {
  static defaultProps = {
    onCompleted: () => {},
  };

  validate = (values, user) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Required';
    }
    if (!values.markup && values.markup !== 0) {
      errors.markup = 'Required';
    }
    if (!values.clientId || values.clientId === '') {
      errors.clientId = 'Required';
    }
    if (!values.tax && values.tax !== 0) {
      errors.tax = 'Required';
    }
    if (!user.internal) {
      if (values.metaClientId === '') {
        errors.metaClientId = 'Required';
      }
      if (!values.metaMarkup && values.metaMarkup !== 0) {
        errors.metaMarkup = 'Required';
      }
      if (!values.metaTax && values.metaTax !== 0) {
        errors.metaTax = 'Required';
      }
    }
    return errors;
  };

  render() {
    const { estimateId, onCompleted, location } = this.props;
    return estimateId ? (
      <EstimateQuery
        estimateId={estimateId}
        updateCache={() => {}}
        onCompleted={onCompleted}
        validate={this.validate}
        location={location}
      />
    ) : (
      <EstimateMutation
        formId="create-estimate-form"
        mutation={ESTIMATE_CREATE}
        updateCache={() => {}}
        onCompleted={onCompleted}
        validate={this.validate}
        location={location}
      />
    );
  }
}

EstimateForm.propTypes = {
  onCompleted: PropTypes.func.isRequired,
};

export default withRouter(EstimateForm);
