import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import currency from 'currency.js';
import Page from '../../print/Page';
import CompanyHeader from '../../print/CompanyHeader';

export default ({ estimate, paymentId }) => {
  const { address, city, state: addressState, zip } = estimate.client;

  const payment = estimate.payments.find((p) => p.id === paymentId);

  const openBalance = currency(estimate.sellPriceTaxes).subtract(
    estimate.payments
      .filter((p) => moment(p.insertedAt).isSameOrBefore(payment.insertedAt))
      .reduce((acc, { amount }) => acc.add(currency(amount)), currency(0))
  );

  return (
    <Page className="mx-auto mt-5">
      <CompanyHeader>
        <table className="table table-borderless">
          <tbody>
            <tr>
              <td>
                <h5>Payment</h5>
              </td>
              <td>
                <h5>#{payment.id.padStart(6, 0)}</h5>
              </td>
            </tr>
            <tr>
              <td>
                <h5>Order</h5>
              </td>
              <td>
                <h5>#{estimate.id.padStart(6, 0)}</h5>
              </td>
            </tr>
          </tbody>
        </table>
      </CompanyHeader>

      <h5 className="text-center mt-5 mb-3">Payment Receipt</h5>

      <table className="table table-bordered">
        <tbody>
          <tr>
            <th scope="col" className="py-0">
              Client name
            </th>
            <td>{estimate.client.name}</td>
            <th scope="col" className="py-0">
              Client address
            </th>
            <td>
              {address}, {city}, {addressState} {zip}
            </td>
          </tr>
          <tr>
            <th scope="col" className="py-0">
              Amount
            </th>
            <td>{payment.amount}</td>
            <th scope="col" className="py-0">
              Date
            </th>
            <td>
              <Moment date={payment.insertedAt} format="MMM DD, YYYY" />
            </td>
          </tr>
          <tr>
            <th scope="col" className="py-0">
              Remaining Balance
            </th>
            <td>{openBalance.format(true)}</td>
          </tr>
        </tbody>
      </table>
    </Page>
  );
};
