import { ApolloClient } from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";
import { createLink } from "apollo-absinthe-upload-link";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import env from "./environment";
import introspectionQueryResultData from "./fragmentTypes.json";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("auth_token");
  const newHeaders = { ...headers };
  if (token) newHeaders.authorization = `Bearer ${token}`;
  return { headers: newHeaders };
});

const onErrorLink = history =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        if (message === "unauthorized") {
          const {
            location: { pathname }
          } = history;
          localStorage.removeItem("auth_token");
          pathname !== "/signin" && history.push("/signin");
        }
      });
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

export default history =>
  new ApolloClient({
    link: ApolloLink.from([
      authLink,
      onErrorLink(history),
      createLink({
        uri: `${env.GRAPHQL_HOST}/graphql`
      })
    ]),
    cache: new InMemoryCache({ fragmentMatcher })
  });
