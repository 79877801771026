import React, { useState } from "react";
import { useMutation } from "react-apollo";
import { Form, Field } from "react-final-form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { SIGNUP } from "../../mutations";

const validate = values => {
  const errors = {};
  if (!values.password) {
    errors.password = "Password is Required";
  } else if (values.password.length < 8) {
    errors.password = "Password has to be 8 characters or longer";
  }
  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = "Confirmation does not match password";
  }
  return errors;
};

const SignupForm = ({ token, email, signup, loading, mutationErrors }) => (
  <Form
    onSubmit={variables => signup({ variables })}
    validate={validate}
    initialValues={{ token, email }}
  >
    {({ handleSubmit, valid, errors, touched }) => (
      <form onSubmit={handleSubmit}>
        <h2 className="font-weight-light text-center">Welcome to Luxgard!</h2>
        <h4 className="font-weight-light text-center mb-5">
          Create your password
        </h4>
        {(errors.passwordConfirmation && touched.passwordConfirmation) ||
          (mutationErrors && (
            <div className="alert alert-danger mb-3">
              {mutationErrors &&
                mutationErrors.graphQLErrors
                  .map(({ message }) => message)
                  .join(".")}
              {errors.passwordConfirmation &&
                touched.passwordConfirmation &&
                errors.passwordConfirmation}
            </div>
          ))}
        <Field name="token">
          {input => <input {...input} type="hidden" />}
        </Field>
        <Field name="email">
          {input => <input {...input} type="hidden" />}
        </Field>
        <div className="fields">
          <Field
            name="password"
            render={({ input }) => (
              <div className="input-container">
                <input
                  {...input}
                  type="password"
                  placeholder="password"
                  className="form-control"
                />
                <FontAwesomeIcon icon={["fal", "unlock-alt"]} size="lg" />
              </div>
            )}
          />
          <Field
            name="passwordConfirmation"
            render={({ input }) => (
              <div className="input-container">
                <input
                  {...input}
                  type="password"
                  placeholder="password confirmation"
                  className="form-control"
                />
                <FontAwesomeIcon icon={["fal", "unlock-alt"]} size="lg" />
              </div>
            )}
          />
        </div>
        <small className="text-muted">
          *Password has to be 8 characters or more
        </small>
        <Button
          type="submit"
          variant="outline-primary"
          block
          className="mt-4"
          disabled={!valid || loading}
        >
          Create Account
        </Button>
      </form>
    )}
  </Form>
);

const Succesful = () => (
  <>
    <h2 className="font-weight-light text-center">Account created!</h2>
    <h6
      className="font-weight-light text-center mb-5"
      style={{ maxWidth: 250 }}
    >
      Your account was succesfuly created. Please sign in with your new
      credentials.
    </h6>
    <Link to="/signin" className="btn btn-outline-primary d-block">
      Sign in
    </Link>
  </>
);

export default ({ token, email }) => {
  const [displayForm, setDisplayForm] = useState(true);
  const [signup, { loading, error }] = useMutation(SIGNUP, {
    onCompleted: () => setDisplayForm(false)
  });

  return displayForm ? (
    <SignupForm
      token={token}
      email={email}
      signup={signup}
      loading={loading}
      mutationErrors={error}
    />
  ) : (
    <Succesful />
  );
};
