import React, { Component } from "react";
import PropTypes from "prop-types";
import { Mutation, Query } from "react-apollo";
import { Form, Field } from "react-final-form";
import ValidatedField from "../general/ValidatedField";
import { VENDOR_CREATE, VENDOR_UPDATE } from "../../mutations";
import { VENDOR, VENDOR_LIST } from "../../queries";
import { withRouter } from "react-router-dom";
import { pageParams } from "../../util";

const PAGE_SIZE = 10;

const VendorMutation = props => {
  let formRef;
  const {
    formId,
    mutation,
    initialValues,
    onCompleted,
    validate,
    params
  } = props;
  return (
    <Mutation
      mutation={mutation}
      onCompleted={() => onCompleted(formRef)}
      refetchQueries={[
        {
          query: VENDOR_LIST,
          variables: pageParams(params.get("page") || 1, PAGE_SIZE)
        }
      ]}
    >
      {mutation => (
        <Form
          onSubmit={variables => mutation({ variables })}
          validate={validate}
          initialValues={initialValues}
        >
          {({ handleSubmit, form }) => {
            formRef = form;
            return (
              <form id={formId} onSubmit={handleSubmit}>
                <Field name="id">
                  {input => <input {...input} type="hidden"></input>}
                </Field>
                <ValidatedField name="name" label="Name" />
                <ValidatedField name="email" label="Email" />
                <ValidatedField name="street" label="Street" />
                <ValidatedField name="city" label="City" />
                <ValidatedField name="state" label="State" />
                <ValidatedField name="zip" label="ZIP" />
                <ValidatedField name="phone" label="Phone" />
                <ValidatedField name="fax" label="Fax" />
              </form>
            );
          }}
        </Form>
      )}
    </Mutation>
  );
};

const VendorQuery = ({ vendorId, onCompleted, validate, params }) => (
  <Query query={VENDOR} variables={{ id: vendorId }}>
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error</p>;

      return (
        <VendorMutation
          formId="update-vendor-form"
          mutation={VENDOR_UPDATE}
          initialValues={data.vendor}
          onCompleted={onCompleted}
          validate={validate}
          params={params}
        />
      );
    }}
  </Query>
);

class VendorForm extends Component {
  static defaultProps = {
    onCompleted: () => {}
  };

  validate = values => {
    const errors = {};
    const required = ["name", "street", "city", "state", "zip", "phone"];
    required.forEach(r => !values[r] && (errors[r] = "Required"));
    return errors;
  };

  render() {
    const { vendorId, onCompleted, location } = this.props;
    const params = new URLSearchParams(location.search);

    return vendorId ? (
      <VendorQuery
        vendorId={vendorId}
        onCompleted={onCompleted}
        validate={this.validate}
        params={params}
      />
    ) : (
      <VendorMutation
        formId="create-vendor-form"
        mutation={VENDOR_CREATE}
        onCompleted={onCompleted}
        validate={this.validate}
        params={params}
      />
    );
  }
}

VendorForm.propTypes = {
  onCompleted: PropTypes.func.isRequired
};

export default withRouter(VendorForm);
