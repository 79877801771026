import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MarkupForm from './MarkupForm';

const Descriptions = ({ markups, type, descriptions, editing, edit }) =>
  descriptions.map((desc) => {
    const isEditing = editing.type === type && editing.desc === desc.value;
    const markup = markups.find(
      (m) => m.type === type && m.desc === desc.value
    );
    const markupValue = markup ? markup.value : 0.0;

    return (
      <tr key={desc.value}>
        <td>{desc.title}</td>
        <td width="30%">
          <div className="markup">
            {isEditing ? (
              <MarkupForm
                type={type}
                desc={desc.value}
                markup={markupValue}
                onComplete={() => edit({})}
              />
            ) : (
              <>
                {markupValue}
                <Button
                  variant="link"
                  onClick={() => edit({ type, desc: desc.value })}
                >
                  <FontAwesomeIcon icon={['fal', 'edit']} />
                </Button>
              </>
            )}
          </div>
        </td>
      </tr>
    );
  });

Descriptions.defaultProps = {
  editing: {},
};

Descriptions.propTypes = {
  markups: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      desc: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  type: PropTypes.string.isRequired,
  descriptions: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, value: PropTypes.string })
  ).isRequired,
  editing: PropTypes.shape({ type: PropTypes.string, desc: PropTypes.string }),
  edit: PropTypes.func.isRequired,
};

export default Descriptions;
