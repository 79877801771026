import { useQuery } from 'react-apollo';
import { PRICES_BY_SYSTEM_TYPE } from '../../../../queries';

export const useSGDPrices = () => {
  const { loading, data } = useQuery(PRICES_BY_SYSTEM_TYPE, {
    variables: { systemType: 'sgd' },
  });

  if (loading) return [];

  return data.pricesBySystemType;
};
