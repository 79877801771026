import classNames from 'classnames';
import React from 'react';
import logo from '../../../../assets/images/company-logo.png';
import { noa, round, series } from '../../../../util';
import Page from '../../../print/Page';
import { CONFIGS as hrConfigs } from '../../../systems/HrForm';

const Label = ({ estimate, system, inedx, classes, width }) => {
  const { verticals } = system;
  const totalWidth = verticals.reduce((acc, { width: cur }) => acc + cur, 0);
  const lHeight = verticals[0].lHeight;
  const rHeight = verticals[verticals.length - 1].rHeight;
  const glass = verticals[0].glass[0];

  return (
    <Page key={`${system.id}-${inedx}`} className={classes} size="label">
      <div className="px-4">
        <img src={logo} className="w-100" alt="Luxgard logo" />
      </div>
      <div className="text-center mt-4">
        <h6>Luxgard Windows & Doors, LLC</h6>
        <h6>Mark {system.mark}</h6>
        <h6>
          <b>{series(system)}</b>
        </h6>
        <h6 className="mb-0">
          <b>
            Size: {width ? round(width) : round(totalWidth)}" X{' '}
            {round(Math.max(lHeight, rHeight))}"
          </b>
        </h6>
        {system.type === 'hr' && (
          <h6 className="mb-0">
            Configuration:{' '}
            {
              hrConfigs.find(
                ({ value }) => parseInt(value, 10) === system.config
              ).label
            }
          </h6>
        )}
        <h6 className="mb-0">Frame Color: {system.frameColor.color}</h6>
        <h6>{glass.glassComposition.composition}</h6>
        <h6 className="mb-0">Client Name: {estimate.client.name}</h6>
        <h6 className={classNames({ 'mb-0': estimate.client.phone })}>
          Client email: {estimate.client.email}
        </h6>
        {estimate.client.phone && (
          <h6>Client phone: {estimate.client.phone}</h6>
        )}
        <h4>
          <b>Order# {estimate.id.padStart(6, 0)}</b>
        </h4>
        <h6 className="mb-0">
          <b>FLORIDA PRODUCT APPROVAL</b>
        </h6>
        {system.type !== 'fd' &&
          system.type !== 'sgd' && (
            <h5>
              <b>{noa(system)}</b>
            </h5>
          )}
        <div className="border">
          <h6 className="mb-0">
            <small>
              <b>DESSIGN PRESSURE</b>
            </small>
          </h6>
          <h1 className="mb-0">
            {system.positiveLoad && system.negativeLoad
              ? `+${system.positiveLoad} / -${system.negativeLoad}`
              : 'N/A'}
          </h1>
        </div>
        <h6>
          <small>
            <b>DO NOT REMOVE LABEL PRIOR TO INSPECTION</b>
          </small>
        </h6>
      </div>
    </Page>
  );
};

export default Label;
