import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import GlobalChanges from './GlobalChanges';
import { useSelectionContext } from './SelectionProvider';

const Header = ({ estimate }) => {
  const [show, setShow] = useState(false);
  const { selected } = useSelectionContext();

  return (
    <div className="d-flex justify-content-between align-items-center">
      <h3>Systems</h3>
      <Button
        variant="link"
        onClick={() => setShow(true)}
        disabled={!selected.length}
      >
        Global Changes
      </Button>
      <GlobalChanges
        estimate={estimate}
        show={show}
        handleClose={() => setShow(false)}
      />
    </div>
  );
};

export default Header;
