import { quotation } from './quotation';
import Fraction from 'fraction.js';
import { toNearest } from '../util';

const toFraction = (x) => new Fraction(toNearest(0.0625)(x)).toFraction(true);

const WIDTH_GS = 0.75;
const WIDTH_SILL_HEAD = 3.429;
const WIDTH_JAMB = 1;
const WIDTH_SIDES = 1.0835;
const WIDTH_DIV = 2.014;

export const hr = (two, width, height, config, handle) => {
  const left = [
    two.makeRectangle(WIDTH_JAMB / 2, height / 2, WIDTH_JAMB, height),
  ];

  const right = [
    two.makeRectangle(width - WIDTH_JAMB / 2, height / 2, WIDTH_JAMB, height),
  ];

  if (config !== 4) {
    right.push(
      two.makeRectangle(
        width - WIDTH_JAMB - WIDTH_SIDES / 2,
        height / 2,
        WIDTH_SIDES,
        height - WIDTH_SILL_HEAD * 2
      ),
      two.makeRectangle(
        width - WIDTH_JAMB - WIDTH_SIDES * 1.5,
        height / 2,
        WIDTH_SIDES,
        height - WIDTH_SILL_HEAD * 2
      ),
      two.makeRectangle(
        width - WIDTH_JAMB - WIDTH_SIDES * 2 - WIDTH_GS / 2,
        height / 2,
        WIDTH_GS,
        height - WIDTH_SILL_HEAD * 2
      )
    );
  }

  const top_bot = [
    two.makeRectangle(
      width / 2,
      WIDTH_SILL_HEAD / 2,
      width - WIDTH_JAMB * 2,
      WIDTH_SILL_HEAD
    ),
    two.makeRectangle(
      width / 2,
      height - WIDTH_SILL_HEAD / 2,
      width - WIDTH_JAMB * 2,
      WIDTH_SILL_HEAD
    ),
  ];

  const divs = [];

  if (config === 1 || config === 4) {
    const HGS_WIDTH =
      (width - WIDTH_JAMB * 2 - WIDTH_GS * 4 - WIDTH_SIDES * 2 - WIDTH_DIV) / 2;
    const CENTER =
      WIDTH_JAMB +
      WIDTH_GS * 2 +
      HGS_WIDTH +
      WIDTH_DIV / 2 +
      (config === 4 ? WIDTH_SIDES * 2 : 0);

    if (config === 1) {
      // notch quotation
      quotation({
        two,
        length: HGS_WIDTH + (WIDTH_GS + WIDTH_SIDES) * 2 + WIDTH_JAMB,
        pos: 'down',
        x: CENTER + WIDTH_DIV / 2,
        y: height,
        label: `${toFraction(width / 2 - 1.017)}"`,
      });
      // left glass stop
      left.push(
        two.makeRectangle(
          WIDTH_JAMB + WIDTH_GS / 2,
          height / 2,
          WIDTH_GS,
          height - WIDTH_SILL_HEAD * 2
        )
      );
      // left panel glass stops
      top_bot.push(
        two.makeRectangle(
          WIDTH_JAMB + WIDTH_GS + HGS_WIDTH / 2,
          WIDTH_SILL_HEAD + WIDTH_GS / 2,
          HGS_WIDTH,
          WIDTH_GS
        ),
        two.makeRectangle(
          WIDTH_JAMB + WIDTH_GS + HGS_WIDTH / 2,
          height - WIDTH_SILL_HEAD - WIDTH_GS / 2,
          HGS_WIDTH,
          WIDTH_GS
        )
      );
      // arrow
      right.push(
        two.makeLine(
          CENTER + WIDTH_DIV / 2 + HGS_WIDTH - 3,
          height / 2,
          CENTER + WIDTH_DIV / 2 + WIDTH_GS + 3,
          height / 2
        ),
        two.makeLine(
          CENTER + WIDTH_DIV / 2 + WIDTH_GS + 3,
          height / 2,
          CENTER + WIDTH_DIV / 2 + WIDTH_GS + 6,
          height / 2 - 3
        ),
        two.makeLine(
          CENTER + WIDTH_DIV / 2 + WIDTH_GS + 3,
          height / 2,
          CENTER + WIDTH_DIV / 2 + WIDTH_GS + 6,
          height / 2 + 3
        )
      );
    } else {
      // config 4 (xo)
      // notch quotation
      quotation({
        two,
        length: HGS_WIDTH + (WIDTH_GS + WIDTH_SIDES) * 2 + WIDTH_JAMB,
        pos: 'down',
        x: 0,
        y: height,
        label: `${toFraction(width / 2 - 1.017)}"`,
      });
      // quotation({
      //   two,
      //   length: HGS_WIDTH + WIDTH_GS * 2 + WIDTH_JAMB,
      //   pos: "down",
      //   x: CENTER + WIDTH_DIV / 2,
      //   y: height,
      //   label: `${round(width / 2 - 2.515, 4)}"`
      // });
      // right gs
      right.push(
        two.makeRectangle(
          width - WIDTH_JAMB - WIDTH_GS / 2,
          height / 2,
          WIDTH_GS,
          height - WIDTH_SILL_HEAD * 2
        )
      );
      // left glass stop
      left.push(
        two.makeRectangle(
          WIDTH_JAMB + WIDTH_SIDES * 2 + WIDTH_GS / 2,
          height / 2,
          WIDTH_GS,
          height - WIDTH_SILL_HEAD * 2
        )
      );
      // left panel glass stops
      top_bot.push(
        two.makeRectangle(
          WIDTH_JAMB + WIDTH_GS + WIDTH_SIDES * 2 + HGS_WIDTH / 2,
          WIDTH_SILL_HEAD + WIDTH_GS / 2,
          HGS_WIDTH,
          WIDTH_GS
        ),
        two.makeRectangle(
          WIDTH_JAMB + WIDTH_GS + WIDTH_SIDES * 2 + HGS_WIDTH / 2,
          height - WIDTH_SILL_HEAD - WIDTH_GS / 2,
          HGS_WIDTH,
          WIDTH_GS
        )
      );
      // left side jamb extrusions
      left.push(
        two.makeRectangle(
          WIDTH_JAMB + WIDTH_SIDES / 2,
          height / 2,
          WIDTH_SIDES,
          height - WIDTH_SILL_HEAD * 2
        ),
        two.makeRectangle(
          WIDTH_JAMB + WIDTH_SIDES * 1.5,
          height / 2,
          WIDTH_SIDES,
          height - WIDTH_SILL_HEAD * 2
        )
      );
      //arrow
      left.push(
        two.makeLine(
          WIDTH_JAMB + WIDTH_SIDES * 2 + WIDTH_GS + 3,
          height / 2,
          CENTER - WIDTH_DIV / 2 - WIDTH_GS - 3,
          height / 2
        ),
        two.makeLine(
          CENTER - WIDTH_DIV / 2 - WIDTH_GS - 3,
          height / 2,
          CENTER - WIDTH_DIV / 2 - WIDTH_GS - 6,
          height / 2 - 3
        ),
        two.makeLine(
          CENTER - WIDTH_DIV / 2 - WIDTH_GS - 3,
          height / 2,
          CENTER - WIDTH_DIV / 2 - WIDTH_GS - 6,
          height / 2 + 3
        )
      );
    }

    // center
    divs.push(
      two.makeRectangle(
        CENTER,
        height / 2,
        WIDTH_DIV,
        height - WIDTH_SILL_HEAD * 2
      ),
      two.makeRectangle(
        CENTER - (WIDTH_DIV + WIDTH_GS) / 2,
        height / 2,
        WIDTH_GS,
        height - WIDTH_SILL_HEAD * 2
      ),
      two.makeRectangle(
        CENTER + (WIDTH_DIV + WIDTH_GS) / 2,
        height / 2,
        WIDTH_GS,
        height - WIDTH_SILL_HEAD * 2
      )
    );
    top_bot.push(
      two.makeRectangle(
        CENTER + (WIDTH_DIV + HGS_WIDTH) / 2 + WIDTH_GS,
        WIDTH_SILL_HEAD + WIDTH_GS / 2,
        HGS_WIDTH,
        WIDTH_GS
      ),
      two.makeRectangle(
        CENTER + (WIDTH_DIV + HGS_WIDTH) / 2 + WIDTH_GS,
        height - WIDTH_SILL_HEAD - WIDTH_GS / 2,
        HGS_WIDTH,
        WIDTH_GS
      )
    );
  } else {
    const X = WIDTH_JAMB + WIDTH_SIDES * 2 + WIDTH_GS;
    const HGS_WIDTH =
      (width -
        WIDTH_JAMB * 2 -
        WIDTH_SIDES * 4 -
        WIDTH_GS * 6 -
        WIDTH_DIV * 2) /
      (config === 2 ? 3 : 4);
    left.push(
      two.makeRectangle(
        WIDTH_JAMB + WIDTH_SIDES / 2,
        height / 2,
        WIDTH_SIDES,
        height - WIDTH_SILL_HEAD * 2
      ),
      two.makeRectangle(
        WIDTH_JAMB + WIDTH_SIDES * 1.5,
        height / 2,
        WIDTH_SIDES,
        height - WIDTH_SILL_HEAD * 2
      ),
      two.makeRectangle(
        WIDTH_JAMB + WIDTH_SIDES * 2 + WIDTH_GS / 2,
        height / 2,
        WIDTH_GS,
        height - WIDTH_SILL_HEAD * 2
      )
    );
    divs.push(
      two.makeRectangle(
        X + HGS_WIDTH + WIDTH_GS / 2,
        height / 2,
        WIDTH_GS,
        height - WIDTH_SILL_HEAD * 2
      ),
      two.makeRectangle(
        X + HGS_WIDTH + WIDTH_GS + WIDTH_DIV / 2,
        height / 2,
        WIDTH_DIV,
        height - WIDTH_SILL_HEAD * 2
      ),
      two.makeRectangle(
        X + HGS_WIDTH + WIDTH_GS * 1.5 + WIDTH_DIV,
        height / 2,
        WIDTH_GS,
        height - WIDTH_SILL_HEAD * 2
      ),
      two.makeRectangle(
        width - X - HGS_WIDTH - WIDTH_GS / 2,
        height / 2,
        WIDTH_GS,
        height - WIDTH_SILL_HEAD * 2
      ),
      two.makeRectangle(
        width - X - HGS_WIDTH - WIDTH_GS - WIDTH_DIV / 2,
        height / 2,
        WIDTH_DIV,
        height - WIDTH_SILL_HEAD * 2
      ),
      two.makeRectangle(
        width - X - HGS_WIDTH - WIDTH_GS * 1.5 - WIDTH_DIV,
        height / 2,
        WIDTH_GS,
        height - WIDTH_SILL_HEAD * 2
      )
    );
    top_bot.push(
      two.makeRectangle(
        X + HGS_WIDTH / 2,
        WIDTH_SILL_HEAD + WIDTH_GS / 2,
        HGS_WIDTH,
        WIDTH_GS
      ),
      two.makeRectangle(
        X + HGS_WIDTH / 2,
        height - WIDTH_SILL_HEAD - WIDTH_GS / 2,
        HGS_WIDTH,
        WIDTH_GS
      ),
      two.makeRectangle(
        width - X - HGS_WIDTH / 2,
        WIDTH_SILL_HEAD + WIDTH_GS / 2,
        HGS_WIDTH,
        WIDTH_GS
      ),
      two.makeRectangle(
        width - X - HGS_WIDTH / 2,
        height - WIDTH_SILL_HEAD - WIDTH_GS / 2,
        HGS_WIDTH,
        WIDTH_GS
      )
    );
    //arrows
    left.push(
      two.makeLine(
        WIDTH_JAMB + WIDTH_SIDES * 2 + WIDTH_GS + 3,
        height / 2,
        WIDTH_JAMB + WIDTH_SIDES * 2 + WIDTH_GS + HGS_WIDTH - 3,
        height / 2
      ),
      two.makeLine(
        WIDTH_JAMB + WIDTH_SIDES * 2 + WIDTH_GS + HGS_WIDTH - 3,
        height / 2,
        WIDTH_JAMB + WIDTH_SIDES * 2 + WIDTH_GS + HGS_WIDTH - 6,
        height / 2 - 3
      ),
      two.makeLine(
        WIDTH_JAMB + WIDTH_SIDES * 2 + WIDTH_GS + HGS_WIDTH - 3,
        height / 2,
        WIDTH_JAMB + WIDTH_SIDES * 2 + WIDTH_GS + HGS_WIDTH - 6,
        height / 2 + 3
      )
    );
    right.push(
      two.makeLine(
        width - WIDTH_JAMB - WIDTH_SIDES * 2 - WIDTH_GS - 3,
        height / 2,
        width - WIDTH_JAMB - WIDTH_SIDES * 2 - WIDTH_GS - HGS_WIDTH + 3,
        height / 2
      ),
      two.makeLine(
        width - WIDTH_JAMB - WIDTH_SIDES * 2 - WIDTH_GS - HGS_WIDTH + 3,
        height / 2,
        width - WIDTH_JAMB - WIDTH_SIDES * 2 - WIDTH_GS - HGS_WIDTH + 6,
        height / 2 - 3
      ),
      two.makeLine(
        width - WIDTH_JAMB - WIDTH_SIDES * 2 - WIDTH_GS - HGS_WIDTH + 3,
        height / 2,
        width - WIDTH_JAMB - WIDTH_SIDES * 2 - WIDTH_GS - HGS_WIDTH + 6,
        height / 2 + 3
      )
    );

    if (config === 2) {
      // notch quotation
      if (['sweep_latch', 'flip_lock'].includes(handle)) {
        quotation({
          two,
          length: X + HGS_WIDTH + WIDTH_GS,
          pos: 'down',
          y: height,
          label: `${toFraction(width / 3 - 1.211)}"`,
        });
        quotation({
          two,
          length: X + HGS_WIDTH + WIDTH_GS,
          pos: 'down',
          x: width - (X + HGS_WIDTH + WIDTH_GS),
          y: height,
          label: `${toFraction(width / 3 - 1.211)}"`,
        });
      } else if (handle === 'spring_latch') {
        quotation({
          two,
          length: X + HGS_WIDTH + WIDTH_GS,
          pos: 'down',
          y: height,
          label: `${toFraction(width / 3 - 1.052)}"`,
        });
        quotation({
          two,
          length: X + HGS_WIDTH + WIDTH_GS,
          pos: 'down',
          x: width - (X + HGS_WIDTH + WIDTH_GS),
          y: height,
          label: `${toFraction(width / 3 - 1.052)}"`,
        });
      }
      top_bot.push(
        two.makeRectangle(
          X + HGS_WIDTH * 1.5 + WIDTH_GS * 2 + WIDTH_DIV,
          WIDTH_SILL_HEAD + WIDTH_GS / 2,
          HGS_WIDTH,
          WIDTH_GS
        ),
        two.makeRectangle(
          X + HGS_WIDTH * 1.5 + WIDTH_GS * 2 + WIDTH_DIV,
          height - WIDTH_SILL_HEAD - WIDTH_GS / 2,
          HGS_WIDTH,
          WIDTH_GS
        )
      );
    } else if (config === 3) {
      // notch quotation
      if (['sweep_latch', 'flip_lock'].includes(handle)) {
        quotation({
          two,
          length: X + HGS_WIDTH + WIDTH_GS,
          pos: 'down',
          y: height,
          label: `${toFraction(width / 4 - 0.55)}"`,
        });
        quotation({
          two,
          length: X + HGS_WIDTH + WIDTH_GS,
          pos: 'down',
          x: width - (X + HGS_WIDTH + WIDTH_GS),
          y: height,
          label: `${toFraction(width / 4 - 0.55)}"`,
        });
      } else if (handle === 'spring_latch') {
        quotation({
          two,
          length: X + HGS_WIDTH + WIDTH_GS,
          pos: 'down',
          y: height,
          label: `${toFraction(width / 4 - 0.197)}"`,
        });
        quotation({
          two,
          length: X + HGS_WIDTH + WIDTH_GS,
          pos: 'down',
          x: width - (X + HGS_WIDTH + WIDTH_GS),
          y: height,
          label: `${toFraction(width / 4 - 0.197)}"`,
        });
      }
      top_bot.push(
        two.makeRectangle(
          X + HGS_WIDTH * 2 + WIDTH_GS * 2 + WIDTH_DIV,
          WIDTH_SILL_HEAD + WIDTH_GS / 2,
          HGS_WIDTH * 2,
          WIDTH_GS
        ),
        two.makeRectangle(
          X + HGS_WIDTH * 2 + WIDTH_GS * 2 + WIDTH_DIV,
          height - WIDTH_SILL_HEAD - WIDTH_GS / 2,
          HGS_WIDTH * 2,
          WIDTH_GS
        )
      );
    }
  }
  const group = two.makeGroup(...left, ...right, ...top_bot, ...divs);
  group.linewidth = 0.3;

  quotation({ two, length: width, label: `${toFraction(width)}"` });
  quotation({
    two,
    length: height,
    dir: 'vertical',
    label: `${toFraction(height)}"`,
  });
  return [-3 - 10, -3 - 10, width + 6 + 10, height + 6 + 10 + 10];
};
