import inferMissileImpact from "./inferMissileImpact";

const NOAS = {
  fw: { LMI: "FL# 30533.1", SMI: "FL# 30533.2" },
  sh: { LMI: "FL# 30346.1", SMI: "FL# 30346.2" },
  hr: { LMI: "FL# 30312.1", SMI: "FL# 30312.2" }
};

export default system => {
  const missileImpact = inferMissileImpact(
    system.verticals[0].glass[0].glassComposition
  );
  return NOAS[system.type][missileImpact];
};
