import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bootbox from 'bootbox';
import $ from 'jquery';
import moment from 'moment';
import React, { useContext } from 'react';
import { useMutation } from 'react-apollo';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../../auth';
import {
  ESTIMATE_COPY,
  ESTIMATE_DELETE,
  ESTIMATE_UPDATE,
} from '../../mutations';
import { ESTIMATES_LIST } from '../../queries';
import { pageParams } from '../../util';
import Modal from '../general/Modal';
import EstimateForm from './EstimateForm';

const submitEstimateForm = (id) => {
  document
    .getElementById(id)
    .dispatchEvent(new Event('submit', { cancelable: true }));
};

const onEstimateFormCompleted = (form, modalId) => {
  $(`#${modalId}`).modal('hide');
  setTimeout(form.reset);
};

const orderEstimate = ({ mutation, id, orderedDate, currentUser }) => {
  const orderedAt = orderedDate
    ? null
    : moment()
        .utc()
        .format();
  mutation({
    variables: {
      id,
      [currentUser.internal ? 'orderedAt' : 'clientOrderedAt']: orderedAt,
    },
  });
};

export default ({ estimate: { id, orderedAt, clientOrderedAt } }) => {
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [estimateUpdate] = useMutation(ESTIMATE_UPDATE);

  const [estimateDelete] = useMutation(ESTIMATE_DELETE, {
    variables: { id },
    onCompleted: () => history.push('/estimates'),
    refetchQueries: [
      {
        query: ESTIMATES_LIST,
        variables: pageParams(params.get('page') || 1),
      },
    ],
  });

  const [estimateCopy] = useMutation(ESTIMATE_COPY, {
    variables: { id },
    onCompleted: () => history.push('/estimates'),
    refetchQueries: [
      {
        query: ESTIMATES_LIST,
        variables: pageParams(params.get('page') || 1),
      },
    ],
  });

  return (
    <div className="dropdown float-right">
      <button
        className="btn btn-link text-secondary p-0"
        type="button"
        id="estimate-options-menu"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <FontAwesomeIcon icon={['fal', 'ellipsis-h-alt']} />
      </button>
      <div className="dropdown-menu" aria-labelledby="estimate-options-menu">
        {orderedAt && (
          <button
            className="dropdown-item"
            onClick={() => history.push(`/estimates/${id}/print?order=true`)}
          >
            <FontAwesomeIcon icon={['fal', 'print']} className="mr-3" />
            Print
          </button>
        )}
        {(currentUser.internal || !orderedAt) && (
          <button
            className="dropdown-item"
            onClick={() => {
              bootbox.confirm(
                (currentUser.internal && orderedAt) ||
                (!currentUser.internal && clientOrderedAt)
                  ? 'Do you want to cancel this order?'
                  : 'Do you want to order this estimate?',
                (r) => {
                  r &&
                    orderEstimate({
                      mutation: estimateUpdate,
                      id,
                      orderedDate: currentUser.internal
                        ? orderedAt
                        : clientOrderedAt,
                      currentUser,
                    });
                }
              );
            }}
          >
            <FontAwesomeIcon
              icon={['fal', 'clipboard-check']}
              className="mr-3"
            />
            {(currentUser.internal && orderedAt) ||
            (!currentUser.internal && clientOrderedAt)
              ? 'Cancel order'
              : 'Order'}
          </button>
        )}
        {currentUser.internal &&
          (orderedAt || clientOrderedAt) && (
            <button
              className="dropdown-item"
              onClick={() => $(`#edit-estimate-${id}-modal`).modal()}
            >
              <FontAwesomeIcon icon={['fal', 'edit']} className="mr-3" />
              Edit ETC
            </button>
          )}
        {!orderedAt &&
          !clientOrderedAt && (
            <button
              className="dropdown-item"
              onClick={() => $(`#edit-estimate-${id}-modal`).modal()}
            >
              <FontAwesomeIcon icon={['fal', 'edit']} className="mr-3" />
              Edit
            </button>
          )}
        <button
          className="dropdown-item"
          onClick={() => {
            bootbox.confirm('Are you sure?', (r) => {
              r && estimateCopy();
            });
          }}
        >
          <FontAwesomeIcon icon={['fal', 'copy']} className="mr-3" />
          Copy
        </button>
        {!orderedAt &&
          !clientOrderedAt && (
            <>
              <div className="dropdown-divider" />
              <button
                className="dropdown-item"
                onClick={() => {
                  bootbox.confirm('Are you sure?', (r) => {
                    r && estimateDelete();
                  });
                }}
              >
                <FontAwesomeIcon icon={['fal', 'trash-alt']} className="mr-3" />
                Delete
              </button>
            </>
          )}
      </div>
      <Modal
        modalId={`edit-estimate-${id}-modal`}
        title="Edit Estimate"
        onContinue={() => submitEstimateForm(`update-estimate-${id}-form`)}
      >
        <EstimateForm
          onCompleted={(form) =>
            onEstimateFormCompleted(form, `edit-estimate-${id}-modal`)
          }
          estimateId={id}
        />
      </Modal>
    </div>
  );
};
