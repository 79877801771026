import React, { Component, useContext } from 'react';
import { Route, Link } from 'react-router-dom';
import Moment from 'react-moment';
import $ from 'jquery';
import classNames from 'classnames';
import { Query } from 'react-apollo';
import { ESTIMATE_SYSTEMS } from '../../queries';
import SystemForm from '../systems/SystemForm';
import SystemsList from '../systems/SystemsList';
import MiscModals from '../systems/misc/MiscModals';
import Payments from '../payments';
import SystemsDropdown from './SystemsDropdown';
import PosDropdown from './PosDropdown';
import EstimatePOs from './EstimatePOs';
import Actions from './Actions';
import { AuthContext } from '../../auth';

const SideBar = ({ match, estimate }) => {
  const { currentUser } = useContext(AuthContext);
  const reportClassNames = classNames('nav-link btn-link', {
    disabled: estimate.systems.length === 0,
  });
  const clientReportClassNames = classNames('nav-link btn-link', {
    disabled: estimate.systems.length === 0 || !estimate.metaClient,
  });

  return (
    <ul className="nav flex-column">
      <li className="nav-item dropdown">
        <SystemsDropdown estimate={estimate} />
      </li>
      {currentUser.internal && (
        <li className="nav-item dropdown">
          <PosDropdown estimate={estimate} />
        </li>
      )}
      {/* <li className="nav-item">
        <Link to={`${match.url}/pos`} className="nav-link">
          POs
        </Link>
      </li> */}
      <li className="nav-item">
        <Link
          to={`${match.url}/${currentUser.internal ? 'print' : 'print/total'}`}
          className={reportClassNames}
        >
          Report
        </Link>
      </li>
      {!currentUser.internal && (
        <li className="nav-item">
          <Link
            to={`${match.url}/print/total?client=true`}
            className={clientReportClassNames}
          >
            Client Report
          </Link>
        </li>
      )}
    </ul>
  );
};

export default class Estimate extends Component {
  state = { selectedMisc: null };

  editMisc = (selectedMisc) => {
    this.setState({ selectedMisc }, () => $('#edit-misc-modal').modal());
  };

  render() {
    const { selectedMisc } = this.state;
    const { match } = this.props;

    return (
      <Query
        query={ESTIMATE_SYSTEMS}
        variables={{ id: match.params.estimateId }}
      >
        {({ loading, error, data: { estimate } = {} }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error</p>;

          return (
            <div className="row flex-grow-1">
              <div className="col-sm-3">
                <h3 className="font-weight-bold">
                  Estimate {estimate.name}
                  <Actions estimate={estimate} />
                </h3>
                <div className="p-2">
                  <h6 className="mb-1 font-weight-bold">
                    <span className="text-muted">Quote #: </span>
                    {estimate.id.padStart(6, 0)}
                  </h6>
                  <h6 className="mb-1 font-weight-bold">
                    <span className="text-muted">Client: </span>
                    {estimate.client.name}
                  </h6>
                  <h6 className="mb-1 font-weight-bold">
                    <span className="text-muted">Project: </span>
                    {estimate.project ? estimate.project.name : 'None'}
                  </h6>
                  <h6 className="mb-1 font-weight-bold">
                    <span className="text-muted">Created: </span>
                    <Moment date={estimate.insertedAt} format="MMM DD, YYYY" />
                  </h6>
                  {estimate.orderedAt && (
                    <h6 className="mb-1 font-weight-bold">
                      <span className="text-muted">Ordered: </span>
                      <Moment date={estimate.orderedAt} format="MMM DD, YYYY" />
                    </h6>
                  )}
                  <h6 className="mb-1 font-weight-bold">
                    <span className="text-muted">ETC: </span>
                    {estimate.etc ? (
                      <Moment date={estimate.etc} format="MMM DD, YYYY" />
                    ) : (
                      'Not set'
                    )}
                  </h6>
                </div>

                <hr />

                <SideBar match={match} estimate={estimate} />
              </div>
              <div className="col-sm-9">
                <Route
                  exact={true}
                  path={`${match.path}`}
                  render={(props) => (
                    <SystemsList
                      {...props}
                      estimate={estimate}
                      editMisc={this.editMisc}
                    />
                  )}
                />
                <Route
                  path={`${match.path}/systems/new`}
                  component={SystemForm}
                />
                <Route
                  path={`${match.path}/systems/:systemId/edit`}
                  component={SystemForm}
                />
                <Route path={`${match.path}/payments`} component={Payments} />
                <Route path={`${match.path}/pos`} component={EstimatePOs} />
              </div>
              <MiscModals
                estimateId={estimate.id}
                misc={selectedMisc}
                onClose={() => this.setState({ selectedMisc: null })}
              />
            </div>
          );
        }}
      </Query>
    );
  }
}
