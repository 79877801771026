import gql from "graphql-tag";

export const PRICE_CREATE = gql`
  mutation PriceCreate($type: SystemType!, $price: String!, $limitSqft: Float) {
    priceCreate(type: $type, price: $price, limitSqft: $limitSqft) {
      id
      type
      price
      limitSqft
    }
  }
`;

export const PRICE_UPSERT = gql`
  mutation PriceUpsert(
    $fw: String!
    $hr: String!
    $sh: String!
    $glass: String!
  ) {
    priceUpsert(fw: $fw, hr: $hr, sh: $sh, glass: $glass) {
      fw
      hr
      sh
      glass
    }
  }
`;

export const PRICE_DELETE = gql`
  mutation PriceDelete($id: ID!) {
    priceDelete(id: $id) {
      id
    }
  }
`;
