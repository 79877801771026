import currency from 'currency.js';

const sortPrices = (prices) => {
  const sorted = prices
    .filter((price) => price.limitSqft)
    .sort((a, b) => a.limitSqft - b.limitSqft);
  const defaultPrice = prices.find((el) => !el.limitSqft);
  return { prices: sorted, defaultPrice };
};

export const getPrice = (allPrices, width, height) => {
  if (!(width && height)) return currency(0);

  const { prices, defaultPrice } = sortPrices(allPrices);
  const sqft = (width * height) / 144;
  const selected = prices.find((el) => sqft <= el.limitSqft);

  if (selected) return currency(selected.price);

  return defaultPrice ? currency(defaultPrice.price) : currency(0);
};
