import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useWatch } from 'react-hook-form';
import { AuthContext } from '../../../auth';
import Field from '../../general/rhf/Field';
import GlassSelection from './GlassSelection';
import { CONFIGS, DOUBLE_DOOR, LEFT_LITE, RIGHT_LITE } from './constants';
import { usePriceUpdate } from './lib';

const Form = ({ context, marks, glassCompositions, frameColors, markups }) => {
  const { currentUser } = useContext(AuthContext);
  usePriceUpdate(context, glassCompositions, markups);
  const [config, autoPrice] = useWatch({
    name: ['config', 'autoPrice'],
    control: context.control,
  });

  return (
    <FormProvider {...context}>
      <form>
        <Field name="estimateId" type="hidden" />
        <Field name="id" type="hidden" />
        <Field
          name="mark"
          label="Mark"
          register={{
            required: 'Required',
            validate: {
              taken: (value) =>
                marks.includes(value.toUpperCase())
                  ? 'Mark is already in use'
                  : true,
            },
          }}
        />
        <Field
          name="autoPrice"
          type={currentUser.internal ? 'checkbox' : 'hidden'}
          label="Auto Price"
        />
        <Field
          name="doorPrice"
          label="Door price per sqft"
          type={currentUser.internal ? 'number' : 'hidden'}
          readOnly={!!autoPrice}
          register={{ required: 'Required' }}
        />
        <Field
          name="litePrice"
          label="Lite price per sqft"
          type={currentUser.internal ? 'number' : 'hidden'}
          readOnly={!!autoPrice}
          register={{ required: 'Required' }}
        />
        <Field
          type="number"
          name="qty"
          label="Qty"
          register={{ required: true }}
        />
        <GlassSelection glassCompositions={glassCompositions} />
        <Field type="select" name="frameColorId" label="Frame Color">
          {frameColors.map(({ id, color }) => (
            <option key={id} value={id}>
              {color}
            </option>
          ))}
        </Field>
        <Field type="select" name="config" label="Config">
          {Object.entries(CONFIGS).map(([config, value]) => (
            <option key={config} value={value}>
              {config}
            </option>
          ))}
        </Field>
        {!DOUBLE_DOOR.includes(config) && (
          <Field type="select" name="hinges" label="Hinges">
            {['Left', 'Right'].map((config) => (
              <option key={config}>{config}</option>
            ))}
          </Field>
        )}
        {DOUBLE_DOOR.includes(config) && (
          <Field type="select" name="active" label="Active">
            {['Left', 'Right'].map((config) => (
              <option key={config}>{config}</option>
            ))}
          </Field>
        )}
        <Field type="select" name="swing" label="Swing">
          {['Out', 'In'].map((config) => (
            <option key={config}>{config}</option>
          ))}
        </Field>
        <Field
          type="number"
          name="doorWidth"
          label="Door Width"
          register={{ required: 'Required' }}
        />
        {LEFT_LITE.includes(config) && (
          <Field
            type="number"
            name="leftWidth"
            label="Left Sidelite Width"
            register={{ required: 'Required' }}
          />
        )}
        {RIGHT_LITE.includes(config) && (
          <Field
            type="number"
            name="rightWidth"
            label="Right Sidelite Width"
            register={{ required: 'Required' }}
          />
        )}
        <Field
          type="number"
          name="height"
          label="Height"
          register={{ required: 'Required' }}
        />
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  context: PropTypes.object.isRequired,
  marks: PropTypes.arrayOf(PropTypes.string).isRequired,
  glassCompositions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      composition: PropTypes.string.isRequired,
    })
  ).isRequired,
  frameColors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Form;
