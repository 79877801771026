import { quotation } from '../quotation';
import Fraction from 'fraction.js';
import { toNearest } from '../../util';

const toFraction = (x) => new Fraction(toNearest(0.0625)(x)).toFraction(true);
const WIDTH = 1.93;

export const circle = (two, radius) => {
  if (!(two && radius)) return;

  const group = two.makeGroup(
    two.makeCircle(radius, radius, radius),
    two.makeCircle(radius, radius, radius - WIDTH)
  );

  group.linewidth = 0.3;

  quotation({
    two,
    length: radius * 2,
    pos: 'down',
    y: radius,
    separation: radius + 5,
    label: `${toFraction(radius * 2)}"`,
  });

  return [-3, -3, radius * 2 + 6, radius * 2 + 12];
};
