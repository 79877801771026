import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import { MARKUPS as MARKUPS_INFO } from './lib';
import { Table } from 'react-bootstrap';
import Descriptions from './Descriptions';
import { MARKUPS } from '../../../queries';

const Markups = () => {
  const [editing, setEditing] = useState({});
  const { loading, data } = useQuery(MARKUPS);

  if (loading) return null;

  const markups = data.markups.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.type]: acc[cur.type] ? [...acc[cur.type], cur] : [cur],
    }),
    {}
  );

  return (
    <div className="markups">
      {MARKUPS_INFO.map(({ type, title, descriptions }) => (
        <div key={type}>
          <h4>{title}</h4>
          <Table>
            <thead>
              <tr>
                <th>Description</th>
                <th width="30%">Markup</th>
              </tr>
            </thead>
            <tbody>
              <Descriptions
                markups={markups[type] || []}
                type={type}
                descriptions={descriptions}
                editing={editing}
                edit={setEditing}
              />
            </tbody>
          </Table>
        </div>
      ))}
    </div>
  );
};

export default Markups;
