import React from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from 'react-apollo';
import { Form, Field } from 'react-final-form';
import ValidatedField from '../general/ValidatedField';
import { USER_CREATE, USER_UPDATE } from '../../mutations';
import { USER_BY_ID } from '../../queries';

const UserMutation = (props) => {
  let formRef;
  const { formId, mutation, initialValues, onCompleted, validate } = props;
  return (
    <Mutation mutation={mutation} onCompleted={() => onCompleted(formRef)}>
      {(mutation) => (
        <Form
          onSubmit={(variables) =>
            mutation({
              variables: Object.fromEntries(
                Object.entries(variables).map(([k, v]) => [
                  k,
                  v === 'None' ? null : v,
                ])
              ),
            })
          }
          validate={validate}
          initialValues={initialValues}
        >
          {({ handleSubmit, form }) => {
            formRef = form;
            return (
              <form id={formId} onSubmit={handleSubmit}>
                <Field name="id">
                  {(input) => <input {...input} type="hidden" />}
                </Field>
                <ValidatedField name="email" label="Email" />
                <ValidatedField
                  type="password"
                  name="password"
                  label="Password"
                />
                <ValidatedField
                  type="password"
                  name="passwordConfirmation"
                  label="Password Confirmation"
                />
              </form>
            );
          }}
        </Form>
      )}
    </Mutation>
  );
};

const UserQuery = ({ userId, onCompleted, validate }) => (
  <Query query={USER_BY_ID} variables={{ id: userId }}>
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error</p>;

      return (
        <UserMutation
          formId="update-user-form"
          mutation={USER_UPDATE}
          initialValues={data.user}
          onCompleted={onCompleted}
          validate={validate}
        />
      );
    }}
  </Query>
);

const validate = (values) => {
  const { password, passwordConfirmation } = values;
  const errors = {};

  const required = ['email', ...(password ? ['passwordConfirmation'] : [])];
  required.forEach((r) => !values[r] && (errors[r] = 'Required'));

  if (password && password.length < 8)
    errors.password = 'Should be at least 8 characters';

  if (password && passwordConfirmation && password !== passwordConfirmation)
    errors.passwordConfirmation = 'Does not match password';

  return errors;
};

const UserForm = ({ userId, onCompleted }) =>
  userId ? (
    <UserQuery userId={userId} onCompleted={onCompleted} validate={validate} />
  ) : (
    <UserMutation
      formId="create-user-form"
      mutation={USER_CREATE}
      onCompleted={onCompleted}
      validate={validate}
    />
  );

UserForm.defaultProps = {
  onCompleted: () => {},
};

UserForm.propTypes = {
  onCompleted: PropTypes.func.isRequired,
};

export default UserForm;
