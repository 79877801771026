import React from 'react';
import { useFormState } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import ValidatedSelect from '../general/ValidatedSelect';
import {
  heatTreatments,
  types,
  thickness,
  interlayers,
} from '../glassCompositions/constants';

const filterCompositions = (glassCompositions, values) =>
  glassCompositions.filter(
    ({ heatTreatment, type, thickness, interlayer }) =>
      heatTreatment === (values.glassHeatTreatment || null) &&
      type === (values.glassType || null) &&
      thickness === (values.glassThickness || null) &&
      interlayer === (values.glassInterlayer || null)
  );

export const glassFilter = (glassCompositions) =>
  createDecorator({
    field: [
      'glassHeatTreatment',
      'glassType',
      'glassThickness',
      'glassInterlayer',
    ],
    updates: {
      glassCompositionId: (_value, values) => {
        const filtered = filterCompositions(glassCompositions, values);

        if (!filtered.length) return null;

        return !!filtered.find(({ id }) => id === values.glassCompositionId)
          ? values.glassCompositionId
          : filtered[0].id;
      },
    },
  });

const GlassSelection = ({ glassCompositions: allCompositions }) => {
  const { values } = useFormState({ subscription: { values: true } });

  const glassCompositions = filterCompositions(allCompositions, values);

  return (
    <fieldset>
      <legend>Glass</legend>
      <ValidatedSelect name="glassHeatTreatment" label="Heat Treatment">
        <option value="">None</option>
        {heatTreatments.map(({ text, value }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </ValidatedSelect>
      <ValidatedSelect name="glassType" label="Type">
        <option value="">None</option>
        {types.map(({ text, value }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </ValidatedSelect>
      <ValidatedSelect name="glassThickness" label="Thickness">
        <option value="">None</option>
        {thickness.map(({ text, value }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </ValidatedSelect>
      <ValidatedSelect name="glassInterlayer" label="Interlayer">
        <option value="">None</option>
        {interlayers.map(({ text, value }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </ValidatedSelect>
      <ValidatedSelect name="glassCompositionId" label="Composition">
        <option value="">None</option>
        {glassCompositions.map(({ id, composition }) => (
          <option key={id} value={id}>
            {composition}
          </option>
        ))}
      </ValidatedSelect>
    </fieldset>
  );
};

export default GlassSelection;
