import React from 'react';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { ESTIMATE_SYSTEMS } from '../../../queries';
import Header from './Header';
import Payments from './Payments';
import SelectionProvider from './SelectionProvider';
import SystemsTable from './SystemsTable';

const SystemsList = ({ estimate, editMisc }) => {
  const { estimateId } = useParams();
  const { loading, data } = useQuery(ESTIMATE_SYSTEMS, {
    variables: { id: estimateId },
  });

  if (loading) return null;

  const { estimate: estimateWithSystems } = data;

  return (
    <SelectionProvider estimate={estimateWithSystems}>
      {estimate.orderedAt && <Payments estimate={estimate} />}
      <Header estimate={estimateWithSystems} />
      <SystemsTable estimate={estimateWithSystems} editMisc={editMisc} />
    </SelectionProvider>
  );
};

export default SystemsList;
