export const MARKUPS = [
  {
    type: 'SYSTEM_MARKUP',
    title: 'System Markups',
    descriptions: [
      { title: 'Fixed Window', value: 'fw' },
      { title: 'Single Hung', value: 'sh' },
      { title: 'Horizontal Roller', value: 'hr' },
      { title: 'French Door', value: 'fd' },
      { title: 'French Door Sidelite', value: 'fd_lite' },
      { title: 'Sliding Glass Door', value: 'sgd' },
      { title: 'Glass', value: 'glass' },
    ],
  },
  {
    type: 'FW_SHAPE_MARKUP',
    title: 'Fixed Window Shape Markup',
    descriptions: [
      { title: 'Rectangle', value: 'rectangle' },
      { title: 'Trapezoid', value: 'trapezoid' },
      { title: 'Circle', value: 'circle' },
      { title: 'Half Circle', value: 'halfcircle' },
      { title: 'Eyebrow', value: 'eyebrow' },
      { title: 'Fan', value: 'fan' },
      { title: 'Hexagon', value: 'hexagon' },
      { title: 'Octagon', value: 'octagon' },
    ],
  },
];
