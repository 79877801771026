import React, { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AuthContext } from '../../auth';

const SystemsDropdown = ({ match, estimate }) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <>
      <button
        id="systemMenuButton"
        type="button"
        className="btn btn-link dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        disabled={estimate.orderedAt || estimate.clientOrderedAt}
      >
        Add System
      </button>
      <div className="dropdown-menu">
        <Link
          to={{
            pathname: `${match.url}/systems/new`,
            search: '?type=fw',
          }}
          className="dropdown-item"
        >
          Fixed Window
        </Link>
        <Link
          to={{
            pathname: `${match.url}/systems/new`,
            search: '?type=hr',
          }}
          className="dropdown-item"
        >
          Horizontal Roller
        </Link>
        <Link
          className="dropdown-item"
          to={{
            pathname: `${match.url}/systems/new`,
            search: '?type=sh',
          }}
        >
          Single Hung
        </Link>
        <Link
          className="dropdown-item"
          to={{
            pathname: `${match.url}/systems/new`,
            search: '?type=fd',
          }}
        >
          French Door
        </Link>
        {currentUser.internal && (
          <Link
            className="dropdown-item"
            to={{
              pathname: `${match.url}/systems/new`,
              search: '?type=sgd',
            }}
          >
            Sliding Glass Door
          </Link>
        )}
        <Link
          className="dropdown-item"
          to={{
            pathname: `${match.url}/systems/new`,
            search: '?type=glass',
          }}
        >
          Glass
        </Link>
        <button
          className="dropdown-item"
          data-toggle="modal"
          data-target="#new-misc-modal"
        >
          Miscellaneous
        </button>
      </div>
    </>
  );
};

export default withRouter(SystemsDropdown);
