import React from 'react';
import Moment from 'react-moment';
import currency from 'currency.js';
import { useLocation } from 'react-router-dom';
import Page from '../../print/Page';
import CompanyHeader from '../../print/CompanyHeader';

export default ({ estimate, type, order }) => {
  const query = new URLSearchParams(useLocation().search);
  const openBalance = currency(estimate.sellPriceTaxes).subtract(
    estimate.payments.reduce(
      (acc, { amount }) => acc.add(currency(amount)),
      currency(0)
    )
  );

  const isMetaClient = query.get('client') === 'true';
  const client = isMetaClient ? estimate.metaClient : estimate.client;

  return (
    <Page className="mx-auto">
      <CompanyHeader client={isMetaClient ? estimate.client : null}>
        <h5>
          {order ? 'Order' : 'Quote'} #{estimate.id.padStart(6, 0)}
        </h5>
        {order && (
          <small className="d-block text-right">
            Ordered <Moment date={estimate.orderedAt} format="MMM DD, YYYY" />
          </small>
        )}
      </CompanyHeader>
      <table className="table table-borderless table-sm mt-5">
        <tbody>
          <tr>
            <th scope="col" width="90">
              Date:
            </th>
            <td>
              <Moment date={estimate.insertedAt} format="MM/DD/YYYY" />
            </td>
          </tr>
          <tr>
            <th scope="col">Estimate:</th>
            <td>{estimate.name}</td>
          </tr>
          <tr>
            <th scope="col">Client:</th>
            <td>{client.name}</td>
          </tr>
        </tbody>
      </table>
      <table
        className="table table-bordered table-sm mb-0"
        style={{ fontSize: 12 }}
      >
        <tbody>
          <tr>
            <th scope="col">Subtotal</th>
            <td>
              {isMetaClient ? estimate.metaSellPrice : estimate.sellPrice}
            </td>
          </tr>
          <tr>
            <th scope="col">Tax Rate</th>
            <td>{isMetaClient ? estimate.metaTax : estimate.tax}%</td>
          </tr>
          <tr>
            <th scope="col">Tax Total</th>
            <td>{isMetaClient ? estimate.metaTaxes : estimate.taxes}</td>
          </tr>
          <tr>
            <th scope="col">Total</th>
            <td>
              {isMetaClient
                ? estimate.metaSellPriceTaxes
                : estimate.sellPriceTaxes}
            </td>
          </tr>
          {!isMetaClient && (
            <tr>
              <th scope="col">Open Balance</th>
              <td>{openBalance.format(true)}</td>
            </tr>
          )}
        </tbody>
      </table>

      {type === 'contract' && (
        <>
          <div className="row mt-4">
            <div className="col-9">
              <small>
                <p className="mb-0">
                  Material will be invoiced upon shipment from this company's
                  warehouse of frame and/or glass.
                </p>
                <p className="mb-0">
                  Delivery and/or installation of screening may be withheld for
                  conveninece of customer, but this shall in no way affect date
                  of invoice or amount due.
                </p>
                <p className="mb-0">
                  If the amount herein is not paid when due, it shall bear
                  interest at the highest legal rate. All costs of collection
                  shall be paid by customer including reasonable attorney's
                  fees.
                </p>
                <p className="mb-0">
                  Title to materials delivered and/or installed purusuant to
                  this order shall remain in the name of <b>LUXGARD</b>, until
                  same is paid in full, and the purcahser waiver all right to
                  homestead and other exemption under state law applicable.
                </p>
                <p className="mb-0">
                  <b>LUXGARD</b>, shall not be responsible for delay in delivery
                  resulting from strikes, labor difficulties, unavailability of
                  materials, fire or other casualty, or any cause whatsoever
                  beyond its control.
                </p>
                <p className="mb-0">Prices subject to change without notice.</p>
                <p className="mb-0">
                  Acceptance of contract subject to approval by a credit
                  department. Contract price does not include any window and GSD
                  installation permit fees unless stated herein.
                </p>
              </small>
            </div>
            <div className="col-3">
              <table className="table tabe-sm table-bordered">
                <thead>
                  <tr>
                    <th>Payment Terms</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td align="center">50% Deposit</td>
                  </tr>
                  <tr>
                    <td align="center">50% Upon Order Release</td>
                  </tr>
                  <tr>
                    <td align="center">Installation fees</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
      {!isMetaClient && (
        <small>
          <p className="mt-4">
            All rates apply, only line items mentioned are included. Customer
            agrees to pay all cost/expenses incurred collecting any amount due,
            including attorney's fees and associated expenses. A $25 charge is
            added to checks returned and 1.5% monthly interest fee for past due
            balance. Quote valid for 30 days.
          </p>
          <p>THIS QUOTE IS SUBJECT TO FIELD VERIFICATION.</p>
        </small>
      )}
      <div className="row mt-5">
        <div className="col">
          <p className="mb-0">Salesman</p>
          <p className="border-bottom">X</p>
        </div>
        <div className="col">
          <p className="mb-0">Client</p>
          <p className="border-bottom">X</p>
        </div>
        <div className="col">
          <p className="mb-0">Date</p>
          <p className="border-bottom">
            <Moment date={new Date()} format="MM/DD/YYYY" />
          </p>
        </div>
      </div>
    </Page>
  );
};
