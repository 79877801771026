import React from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from 'react-apollo';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Field from '../../general/rhf/Field';
import { MARKUP_UPSERT } from '../../../mutations/markups';
import { MARKUPS } from '../../../queries';

const MarkupForm = ({ type, desc, markup, onComplete }) => {
  const form = useForm({ defaultValues: { markup } });
  const [upsert, { loading }] = useMutation(MARKUP_UPSERT, {
    refetchQueries: [{ query: MARKUPS }],
  });

  const onSubmit = async ({ markup }) => {
    await upsert({ variables: { type, desc, value: parseFloat(markup) } });
    onComplete();
  };

  return (
    <FormProvider {...form}>
      <Field type="number" name="markup" register={{ required: true }} />
      <Button
        variant="link"
        disabled={loading}
        onClick={form.handleSubmit(onSubmit)}
      >
        <FontAwesomeIcon icon={['fal', 'save']} />
      </Button>
    </FormProvider>
  );
};

MarkupForm.propTypes = {
  type: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  markup: PropTypes.number.isRequired,
};

export default MarkupForm;
