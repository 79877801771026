import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import { LEFT_LITE, RIGHT_LITE, DOUBLE_DOOR } from '../constants';
import { ESTIMATE, ESTIMATE_SYSTEMS } from '../../../../queries';

export const useOnSubmit = ({ estimateId, getImage, mutation }) => {
  const history = useHistory();
  const [mutate] = useMutation(mutation, {
    onCompleted: () => history.push(`/estimates/${estimateId}`),
  });

  return (values) => {
    const fileName = `mark_${values.mark.toUpperCase()}.svg`;
    const verticals = [];

    // left lite
    if (LEFT_LITE.includes(values.config)) {
      verticals.push({
        index: 0,
        width: parseFloat(values.leftWidth),
        lHeight: parseFloat(values.height),
        rHeight: parseFloat(values.height),
        lites: [parseFloat(values.height)],
        price: values.litePrice.toString(),
      });
    }

    // door
    verticals.push({
      index: LEFT_LITE.includes(values.config) ? 1 : 0,
      width: parseFloat(values.doorWidth),
      lHeight: parseFloat(values.height),
      rHeight: parseFloat(values.height),
      lites: [parseFloat(values.height)],
      price: values.doorPrice.toString(),
    });

    // right lite
    if (RIGHT_LITE.includes(values.config)) {
      verticals.push({
        index: LEFT_LITE.includes(values.config) ? 2 : 1,
        width: parseFloat(values.rightWidth),
        lHeight: parseFloat(values.height),
        rHeight: parseFloat(values.height),
        lites: [parseFloat(values.height)],
        price: values.litePrice.toString(),
      });
    }

    // details
    const details = [
      ...(DOUBLE_DOOR.includes(values.config)
        ? [{ type: 'active', desc: values.active }]
        : []),
      ...(!DOUBLE_DOOR.includes(values.config)
        ? [{ type: 'hinges', desc: values.hinges }]
        : []),
      { type: 'swing', desc: values.swing },
    ];

    const variables = {
      ...(values.id && { id: values.id }),
      ...(!values.id && { estimateId: values.estimateId }),
      type: 'fd',
      mark: values.mark,
      config: parseInt(values.config, 10),
      qty: parseInt(values.qty, 10),
      glassCompositionId: values.glassCompositionId,
      frameColorId: values.frameColorId,
      verticals,
      details,
      image: getImage(fileName),
      autoPrice: values.autoPrice,
    };

    mutate({
      variables,
      refetchQueries: [{ query: ESTIMATE, variables: { id: estimateId } }],
      ...(!values.id && {
        update: (cache, { data: { systemCreateStorefront } }) => {
          const data = cache.readQuery({
            query: ESTIMATE_SYSTEMS,
            variables: { id: estimateId },
          });
          data.estimate.systems = [systemCreateStorefront].concat(
            data.estimate.systems
          );
          cache.writeQuery({
            query: ESTIMATE_SYSTEMS,
            variables: { id: estimateId },
            data,
          });
        },
      }),
    });
  };
};
