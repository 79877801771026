import React from "react";
import PropTypes from "prop-types";

const Modal = ({
  modalId,
  title,
  children,
  closeBtnText,
  continueBtnText,
  onClose,
  onContinue
}) => (
  <div
    className="modal fade"
    id={modalId}
    data-backdrop="static"
    tabIndex="-1"
    role="dialog"
    aria-labelledby={`${modalId}-label`}
    aria-hidden="true"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id={`${modalId}-label`}>
            {title}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">{children}</div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={onClose}
          >
            {closeBtnText}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={onContinue}
          >
            {continueBtnText}
          </button>
        </div>
      </div>
    </div>
  </div>
);

Modal.defaultProps = {
  closeBtnText: "Close",
  continueBtnText: "Save",
  onClose: () => {},
  onContinue: () => {}
};

Modal.propTypes = {
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string,
  closeBtnText: PropTypes.string.isRequired,
  continueBtnText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

export default Modal;
