import classNames from 'classnames';
import React from 'react';
import Moment from 'react-moment';
import { useLocation } from 'react-router-dom';
import CompanyHeader from '../../print/CompanyHeader';
import Page from '../../print/Page';

export default ({ miscs, estimate, type, order }) => {
  const query = new URLSearchParams(useLocation().search);
  const { address, city, state: addressState, zip } = estimate.client;
  const isClient = query.get('client') === 'true';

  const systems = miscs
    .reduce((acc, _cur, index, array) => {
      if (index % 15 === 0) acc.push(array.slice(index, index + 15));
      return acc;
    }, [])
    .sort((a, b) => a.mark.localeCompare(b.mark));

  return systems.map((system, i) => {
    const classes = classNames('mx-auto', { 'mt-5': i === 0 });
    return (
      <Page className={classes} key={system[0].id}>
        <CompanyHeader>
          <h5>
            {order ? 'Order' : 'Quote'} #{estimate.id.padStart(6, 0)}
          </h5>
          {order && (
            <small className="d-block text-right">
              Ordered <Moment date={estimate.orderedAt} format="MMM DD, YYYY" />
            </small>
          )}
          {!isClient && (
            <small className="d-block text-right">
              ETC{' '}
              {estimate.etc ? (
                <Moment date={estimate.etc} format="MMM DD, YYYY" />
              ) : (
                'not set'
              )}
            </small>
          )}
        </CompanyHeader>

        <table
          className="table table-sm table-bordered"
          style={{ fontSize: 12 }}
        >
          <tbody>
            <tr>
              <th scope="col" className="py-0">
                Client name
              </th>
              <td>{estimate.client.name}</td>
              <th scope="col" className="py-0">
                Client address
              </th>
              <td>
                {address}, {city}, {addressState} {zip}
              </td>
            </tr>
          </tbody>
        </table>

        <h5 className="text-center mt-5">Miscellaneous</h5>

        <table
          className="table table-bordered table-sm mb-0"
          style={{ fontSize: 12 }}
        >
          <thead>
            <tr>
              <th>Mark</th>
              <th>Description</th>
              <th>Qty</th>
              {['line', 'contract'].includes(type) && <th>Sell Each</th>}
              {['line', 'contract'].includes(type) && <th>Sell Total</th>}
            </tr>
          </thead>
          <tbody>
            {system.map((system, i) => {
              const desc = system.details.find((d) => d.type === 'desc').desc;
              return (
                <tr key={i}>
                  <td>{system.mark}</td>
                  <td>{desc}</td>
                  <td>{system.qty}</td>
                  {['line', 'contract'].includes(type) && (
                    <td>
                      {isClient ? system.metaSellPrice : system.sellPrice}
                    </td>
                  )}
                  {['line', 'contract'].includes(type) && (
                    <td>
                      {isClient
                        ? system.metaSellPriceTotal
                        : system.sellPriceTotal}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Page>
    );
  });
};
