import React, { useState, useContext } from 'react';
import { Query, useQuery } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../general/Modal';
import POForm from './POForm';
import { PO_LIST, ESTIMATE } from '../../queries';
import POsList from './POsList';
import Pagination from '../general/Pagination';
import { pageParams } from '../../util';
import { AuthContext } from '../../auth';

const PAGE_SIZE = 10;

const onPOFormCompleted = (form) => {
  $('#new-po-modal').modal('hide');
  $('#edit-po-modal').modal('hide');
  setTimeout(form.reset);
};

const submitPoForm = (id) => {
  document
    .getElementById(id)
    .dispatchEvent(new Event('submit', { cancelable: true }));
};

export default ({ location }) => {
  const params = new URLSearchParams(location.search);
  const estimateId = params.get('estimateId');

  const { currentUser } = useContext(AuthContext);
  const [selectedPOId, setSelectedPOId] = useState();
  const { data: estimateData } = useQuery(ESTIMATE, {
    variables: { id: estimateId },
    skip: !estimateId,
  });

  if (!currentUser.internal) return <Redirect to="/" />;

  return (
    <Query
      query={PO_LIST}
      fetchPolicy="network-only"
      variables={{
        ...pageParams(params.get('page') || 1, PAGE_SIZE),
        ...(estimateId && { estimateId }),
      }}
    >
      {({ loading, error, data: { pos, tableInfo } = {} }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error</p>;

        return (
          <div className="row">
            <div className="col-sm-3">
              <h3>Current POs</h3>
              {estimateData && (
                <h6>
                  {estimateData.estimate.orderedAt ? 'Order' : 'Estimate'} #
                  {estimateData.estimate.id.padStart(6, 0)}
                </h6>
              )}
              <h6 className="text-muted">{tableInfo.count} POs</h6>
              <button
                className="btn btn-primary"
                onClick={() => {
                  setSelectedPOId(null);
                  $('#new-po-modal').modal();
                }}
              >
                Add PO{' '}
                <FontAwesomeIcon icon={['fal', 'plus']} className="ml-2" />
              </button>
            </div>
            <div className="col-sm-9 position-relative">
              <POsList pos={pos} selectPo={setSelectedPOId} />
              <div className="pos-pagination">
                <Pagination totalItems={tableInfo.count} pageSize={PAGE_SIZE} />
              </div>
            </div>
            <Modal
              modalId="new-po-modal"
              title="New PO"
              onContinue={() => submitPoForm('create-po-form')}
            >
              {!selectedPOId && <POForm onCompleted={onPOFormCompleted} />}
              <br />
            </Modal>
            <Modal
              modalId="edit-po-modal"
              title="Edit PO"
              onContinue={() => submitPoForm('update-po-form')}
            >
              {selectedPOId && (
                <POForm onCompleted={onPOFormCompleted} poId={selectedPOId} />
              )}
              <br />
            </Modal>
          </div>
        );
      }}
    </Query>
  );
};
