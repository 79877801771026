import React from 'react';
import { Route, NavLink, useRouteMatch } from 'react-router-dom';
import CompanyLogo from './CompanyLogo';

export default () => {
  const match = useRouteMatch('/settings');

  return (
    <div className="row">
      <div className="col-sm-3">
        <h3>Application Settings</h3>
        <h6 className="text-muted">
          General settings that affect the entire application.
        </h6>

        <nav className="nav flex-column">
          <NavLink className="nav-link" exact to={`${match.url}`}>
            Company Logo
          </NavLink>
        </nav>
      </div>
      <div className="col-sm-9">
        <Route exact path={match.path} component={CompanyLogo} />
      </div>
    </div>
  );
};
