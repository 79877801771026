import { useApolloClient } from "react-apollo";

import { useHistory } from "react-router-dom";

export const signedIn = () => localStorage.getItem("auth_token") !== null;

export const useSignOut = () => {
  const history = useHistory();
  const client = useApolloClient();

  const signOut = () => {
    localStorage.removeItem("auth_token");
    history.push("/signin");
    client.resetStore();
  };

  return signOut;
};
