import Two from 'two.js';
import { round } from '../util';

export const quotation = (opts) => {
  const {
    two,
    dir = 'horizontal',
    pos = 'up',
    x = 0,
    y = 0,
    separation = 10,
    length,
    label,
  } = opts;

  if (!two) throw new Error('Two instance is required');
  if (!length) throw new Error('Length is required');

  let shapes = [];

  if (dir === 'horizontal') {
    if (pos === 'up') {
      shapes.push(two.makeLine(x, y - 2, x, y - separation));
      shapes.push(two.makeLine(x + length, y - 2, x + length, y - separation));
      shapes.push(
        two.makeLine(
          x - 2,
          y - separation + 2,
          x + length + 2,
          y - separation + 2
        )
      );
      const text = new Two.Text(
        label || `${round(length, 4)}"`,
        x + length / 2,
        y - separation,
        { size: 5, fill: '#616A72' }
      );
      shapes.push(two.add(text));
    } else if (pos === 'down') {
      shapes.push(two.makeLine(x, y + 2, x, y + separation));
      shapes.push(two.makeLine(x + length, y + 2, x + length, y + separation));
      shapes.push(
        two.makeLine(
          x - 2,
          y + separation - 2,
          x + length + 2,
          y + separation - 2
        )
      );
      const text = new Two.Text(
        label || `${round(length, 4)}"`,
        x + length / 2,
        y + separation + 1,
        { size: 5, fill: '#616A72' }
      );
      shapes.push(two.add(text));
    } else {
      throw new Error("Invalid position. Valid options are 'up' and 'down'");
    }
  } else if (dir === 'vertical') {
    if (pos === 'up') {
      shapes.push(two.makeLine(x - separation, y, x - 2, y));
      shapes.push(two.makeLine(x - separation, y + length, x - 2, y + length));
      shapes.push(
        two.makeLine(
          x - separation + 2,
          y - 2,
          x - separation + 2,
          y + length + 2
        )
      );
      const text = new Two.Text(
        label || `${round(length, 4)}"`,
        x - separation,
        y + length / 2,
        { size: 5, fill: '#616A72' }
      );
      text.rotation = -Math.PI / 2;
      shapes.push(two.add(text));
    } else if (pos === 'down') {
      shapes.push(two.makeLine(x + separation, y, x + 2, y));
      shapes.push(two.makeLine(x + separation, y + length, x + 2, y + length));
      shapes.push(
        two.makeLine(
          x + separation - 2,
          y - 2,
          x + separation - 2,
          y + length + 2
        )
      );
      const text = new Two.Text(
        label || `${round(length, 4)}"`,
        x + separation + 1,
        y + length / 2,
        { size: 5, fill: '#616A72' }
      );
      text.rotation = -Math.PI / 2;
      shapes.push(two.add(text));
    } else {
      throw new Error("Invalid position. Valid options are 'up' and 'down'");
    }
  } else {
    throw new Error(
      "Invalid direction. Valid options are 'horizontal' and 'vertical'"
    );
  }

  const group = two.makeGroup(...shapes);
  group.linewidth = 0.3;
  group.stroke = '#616A72';
  return group;
};
