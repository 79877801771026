import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Form } from 'react-final-form';
import { PRICE_CREATE } from '../../../mutations';
import { PRICES } from '../../../queries';
import { getFullSystemType } from '../../../util';
import Actions from './Actions';
import ValidatedField from './ValidatedField';

const TYPES = ['fw', 'sh', 'hr', 'fd', 'fd_lite', 'sgd', 'glass'];

const update = (cache, { data: { priceCreate: data } }) => {
  const { prices } = cache.readQuery({ query: PRICES });
  cache.writeQuery({ query: PRICES, data: { prices: prices.concat(data) } });
};

const validate = (values, existing) => {
  const errors = {};
  if (!values.price) {
    errors.price = 'Required';
  }
  if (!values.limitSqft && existing.some(({ limitSqft }) => !limitSqft)) {
    errors.limitSqft = 'Required: Default already exists';
  }
  if (
    values.limitSqft &&
    existing.some(({ limitSqft }) => limitSqft === parseFloat(values.limitSqft))
  ) {
    errors.limitSqft = 'Invalid: Value already exists';
  }
  return errors;
};

const groupPrices = (prices) =>
  prices.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.type]: acc[curr.type] ? [...acc[curr.type], curr] : [curr],
    }),
    {}
  );

export default () => {
  const [newPriceType, setNewPriceType] = useState();
  const [createPrice] = useMutation(PRICE_CREATE, {
    update,
    onCompleted: () => setNewPriceType(null),
  });
  const { data, loading, error } = useQuery(PRICES);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  const prices = groupPrices(data.prices);

  return TYPES.map((type) => {
    const groupedPrices =
      (prices[type] || []).sort((a, b) => a.limitSqft - b.limitSqft) || [];

    return (
      <div key={type}>
        <h4>
          {getFullSystemType({ type })}
          <button
            className="btn btn-link"
            onClick={() => setNewPriceType(type)}
          >
            <FontAwesomeIcon icon={['fal', 'plus']} />
          </button>
        </h4>

        <Form
          onSubmit={({ limitSqft, price }) =>
            createPrice({
              variables: {
                limitSqft: parseFloat(limitSqft),
                type: newPriceType,
                price,
              },
            })
          }
          validate={(values) => validate(values, groupedPrices)}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Limit (sqft)</th>
                    <th scope="col">Price (sqft)</th>
                    <th scope="col">Default</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {groupedPrices.map((price) => (
                    <tr key={price.id}>
                      <td>{price.limitSqft && `<= ${price.limitSqft}`}</td>
                      <td>{price.price}</td>
                      <td>
                        <FontAwesomeIcon
                          icon={[
                            'fal',
                            price.limitSqft ? 'square' : 'check-square',
                          ]}
                        />
                      </td>
                      <td>
                        <Actions priceId={price.id} />
                      </td>
                    </tr>
                  ))}
                  {newPriceType === type && (
                    <tr>
                      <td>
                        <ValidatedField name="limitSqft" placeholder="Limit" />
                      </td>
                      <td>
                        <ValidatedField name="price" placeholder="Price" />
                      </td>
                      <td />
                      <td>
                        <button
                          disabled={submitting}
                          type="submit"
                          className="btn btn-link float-right"
                        >
                          <FontAwesomeIcon icon={['fal', 'save']} />
                        </button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </form>
          )}
        </Form>
      </div>
    );
  });
};
