import gql from 'graphql-tag'

export const FRAME_COLORS_LIST = gql`{
  frameColors {
    id
    color
  }
}`

export const FRAME_COLOR = gql`
  query FrameColor($id: ID!) {
    frameColor(id: $id) {
      id
      color
    }
  }
`