import React, { useState, useContext } from 'react';
import { useMutation } from 'react-apollo';
import { CLIENT_UPDATE } from '../../mutations';
import { AuthContext } from '../../auth';

const getLogoPath = (path) => `${path}?${new Date().getTime()}`;

export default () => {
  const { currentUser } = useContext(AuthContext);
  const [logoPath, setLogoPath] = useState(
    getLogoPath(currentUser.client.logoPath)
  );
  const [updateClient] = useMutation(CLIENT_UPDATE, {
    onCompleted: () => setLogoPath(getLogoPath(currentUser.client.logoPath)),
  });

  return (
    <div className="company-logo-component">
      <div className="header clearfix">
        <h4 className="float-left mt-2">Company Logo</h4>
      </div>
      <h6 className="text-muted">
        This logo will be used for the reports you give to your clients
      </h6>

      <div
        className="logo-container"
        style={{ backgroundImage: `url(${logoPath})` }}
      >
        <input
          type="file"
          name="logo"
          id="logo"
          onChange={(e) =>
            updateClient({
              variables: { id: currentUser.client.id, logo: e.target.files[0] },
            })
          }
        />
        <label for="logo">Choose a file</label>
      </div>
    </div>
  );
};
