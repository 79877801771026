import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Query } from 'react-apollo';
import { ESTIMATE_SYSTEMS } from '../../../queries';
import { AuthContext } from '../../../auth';
import Pages from './Pages';

export default class PaymentPrint extends Component {
  static contextType = AuthContext;

  goBack = () => {
    const { history, match } = this.props;
    history.push(`/estimates/${match.params.estimateId}/payments`);
  };

  render() {
    const { match } = this.props;
    const { id, estimateId } = match.params;
    const { currentUser } = this.context;

    if (!currentUser.internal) return <Redirect to="/" />;

    return (
      <Query query={ESTIMATE_SYSTEMS} variables={{ id: estimateId }}>
        {({ loading, error, data: { estimate } = {} }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error</p>;

          return (
            <div className="pages-container">
              <div className="actions">
                <button
                  onClick={window.print}
                  className="print-button btn btn-outline-success"
                >
                  <FontAwesomeIcon icon={['fal', 'print']} />
                </button>
                <button
                  onClick={this.goBack}
                  className="close-button btn btn-outline-danger"
                >
                  <FontAwesomeIcon icon={['fal', 'times']} />
                </button>
              </div>
              <Pages estimate={estimate} paymentId={id} />
            </div>
          );
        }}
      </Query>
    );
  }
}
