import React from 'react'
import moment from 'moment'
import ClientBox from './ClientBox'


export default ({ selectClient, clients }) => (
  <div className="row">
    {clients.sort((a, b) => moment(a.insertedAt).diff(b.insertedAt)).map(client => (
      <div className="col-sm-4 mb-3" key={client.id}>
        <ClientBox client={client} selectClient={selectClient} />
      </div>
    ))}
  </div>
)