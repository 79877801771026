import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';

const Input = ({
  type,
  name,
  disabled,
  readOnly,
  className,
  register: registerOptions = {},
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[name];

  return (
    <input
      type={type}
      disabled={disabled}
      readOnly={readOnly}
      tabIndex={readOnly ? -1 : 0}
      {...register(name, registerOptions)}
      className={classNames(className, {
        'form-control': type !== 'checkbox',
        'is-invalid': !!error,
      })}
    />
  );
};

const Select = ({
  name,
  children,
  disabled,
  readOnly,
  className,
  register: registerOptions = {},
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[name];

  return (
    <select
      disabled={disabled}
      readOnly={readOnly}
      tabIndex={readOnly ? -1 : 0}
      {...register(name, registerOptions)}
      className={classNames(className, 'form-control', {
        'is-invalid': !!error,
      })}
    >
      {children}
    </select>
  );
};

const Field = (props) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { type, name, label, readOnly } = props;
  const error = errors[name];

  const input =
    type === 'select' ? <Select {...props} /> : <Input {...props} />;

  if (!label || type === 'hidden') return input;

  return (
    <label
      className={classNames('w-100 mb-3', {
        'read-only': readOnly,
        'd-flex align-items-center': type === 'checkbox',
      })}
    >
      {type !== 'checkbox' && <span className="d-block mb-2">{label}</span>}
      {input}
      {type === 'checkbox' && <span className="d-block ml-2">{label}</span>}
      <div className="invalid-feedback font-weight-normal">
        {error && error.message}
      </div>
    </label>
  );
};

Field.defaultProps = {
  type: 'text',
  label: '',
  className: '',
  disabled: false,
  readOnly: false,
};

Field.propTypes = {
  type: PropTypes.oneOf(['select', 'text', 'number', 'checkbox', 'hidden']),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Field;
