import gql from 'graphql-tag';

export const GLASS_COMPOSITION_CREATE = gql`
  mutation GlassCompositionCreate(
    $composition: String!
    $heatTreatment: GlassHeatTreatment
    $type: GlassType
    $thickness: GlassThickness
    $interlayer: GlassInterlayer
    $interlayerThickness: GlassInterlayerThickness
    $markup: Float!
    $glassOnly: Boolean
  ) {
    glassCompositionCreate(
      composition: $composition
      heatTreatment: $heatTreatment
      type: $type
      thickness: $thickness
      interlayer: $interlayer
      interlayerThickness: $interlayerThickness
      markup: $markup
      glassOnly: $glassOnly
    ) {
      id
      composition
      heatTreatment
      type
      thickness
      interlayer
      interlayerThickness
      markup
      glassOnly
    }
  }
`;

export const GLASS_COMPOSITION_UPDATE = gql`
  mutation GlassCompositionUpdate(
    $id: ID!
    $composition: String
    $heatTreatment: GlassHeatTreatment
    $type: GlassType
    $thickness: GlassThickness
    $interlayer: GlassInterlayer
    $interlayerThickness: GlassInterlayerThickness
    $markup: Float
    $glassOnly: Boolean
  ) {
    glassCompositionUpdate(
      id: $id
      composition: $composition
      heatTreatment: $heatTreatment
      type: $type
      thickness: $thickness
      interlayer: $interlayer
      interlayerThickness: $interlayerThickness
      markup: $markup
      glassOnly: $glassOnly
    ) {
      id
      composition
      heatTreatment
      type
      thickness
      interlayer
      interlayerThickness
      markup
      glassOnly
    }
  }
`;

export const GLASS_COMPOSITION_DELETE = gql`
  mutation GlassCompositionDelete($id: ID!) {
    glassCompositionDelete(id: $id) {
      id
    }
  }
`;
