import React, { useContext } from 'react';
import { AuthContext } from '../../auth';
import ClientSettings from './ClientSettings';
import UserSettings from './UserSettings';

export default () => {
  const { currentUser } = useContext(AuthContext);

  return currentUser.internal ? <UserSettings /> : <ClientSettings />;
};
