import React from 'react';
import Moment from 'react-moment';
import currency from 'currency.js';
import Page from '../../print/Page';
import CompanyHeader from '../../print/CompanyHeader';

const Info = ({ po, vendor }) => (
  <table className="table table-borderless" style={{ fontSize: 12 }}>
    <tbody>
      <tr>
        <th className="p-0" style={{ width: 50 }}>
          Vendor
        </th>
        <td>{vendor.name}</td>
      </tr>
      <tr>
        <td />
        <td>{vendor.street}</td>
      </tr>
      <tr>
        <td />
        <td>{`${vendor.city} ${vendor.state}, ${vendor.zip}`}</td>
      </tr>
      <tr>
        <td />
        <td>{vendor.phone}</td>
      </tr>
      {vendor.fax && (
        <tr>
          <td />
          <td>{vendor.fax}</td>
        </tr>
      )}
      {vendor.email && (
        <tr>
          <td />
          <td>{vendor.email}</td>
        </tr>
      )}
      <tr>
        <td colSpan="2">
          <hr />
        </td>
      </tr>
      <tr>
        <th className="p-0" style={{ width: 50 }}>
          Sent
        </th>
        <td>
          {po.sent ? (
            <Moment date={po.sent} format="MMM DD, YYYY" />
          ) : (
            <span className="text-danger">Not sent</span>
          )}
        </td>
      </tr>
      <tr>
        <th className="p-0" style={{ width: 50 }}>
          Received
        </th>
        <td>
          {po.received ? (
            <Moment date={po.received} format="MMM DD, YYYY" />
          ) : (
            <span className="text-danger">Not received</span>
          )}
        </td>
      </tr>
      <tr>
        <th className="p-0" style={{ width: 50 }}>
          PO #
        </th>
        <td>{po.id.padStart(6, 0)}</td>
      </tr>
      {po.estimate && (
        <tr>
          <th className="p-0" style={{ width: 50 }}>
            Order #
          </th>
          <td>{po.estimate.id.padStart(6, 0)}</td>
        </tr>
      )}
    </tbody>
  </table>
);

export default ({ po: { vendor, ...po } }) => {
  const total = po.poItems.reduce(
    (acc, { price }) => currency(price).add(acc),
    currency()
  );
  return (
    <Page className="mx-auto mt-5">
      <CompanyHeader>
        <Info po={po} vendor={vendor} />
      </CompanyHeader>

      <h5 className="text-center mt-5 mb-3">Purchase Order</h5>

      <table className="table table-bordered" style={{ border: 'none' }}>
        <thead>
          <tr>
            <th className="py-0">Item #</th>
            <th className="py-0">Qty/Weight</th>
            <th className="py-0">Description</th>
            <th className="py-0">Price</th>
          </tr>
        </thead>
        <tbody>
          {po.poItems.map((item) => (
            <tr key={item.id}>
              <td>{item.itemNo}</td>
              <td>{item.qty ? `${item.qty} units` : `${item.weight} lbs`}</td>
              <td>{item.description}</td>
              <td>{item.price}</td>
            </tr>
          ))}
          <tr>
            <td colSpan="3" align="right" style={{ border: 'none' }}>
              Total
            </td>
            <td>{total.value > 0 ? total.format(true) : ''}</td>
          </tr>
        </tbody>
      </table>
    </Page>
  );
};
