// common environment for all stages
const common = {}

const env = {
  dev: { GRAPHQL_HOST: "http://localhost:4000"},
  production: { GRAPHQL_HOST: "" },
  staging: {}
}

export default {
  ...common,
  ...env[process.env.REACT_APP_STAGE]
}