import currency from 'currency.js';
import PropTypes from 'prop-types';
import React, { Component, useContext } from 'react';
import { Mutation, Query } from 'react-apollo';
import { Field, Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { AuthContext } from '../../../auth';
import { SYSTEM_CREATE, SYSTEM_UPDATE } from '../../../mutations';
import { ESTIMATE, ESTIMATE_SYSTEMS, SYSTEM } from '../../../queries';
import ValidatedField from '../../general/ValidatedField';

class SystemMutation extends Component {
  handleSubmit = (mutation, variables) => {
    const { desc } = variables;
    delete variables.desc;

    return mutation({
      variables: {
        ...variables,
        qty: parseInt(variables.qty, 10),
        price: variables.price.toString(),
        width: 0,
        lHeight: 0,
        rHeight: 0,
        details: [{ type: 'desc', desc }],
      },
    });
  };

  render() {
    let formRef;
    const {
      formId,
      mutation,
      initialValues,
      updateCache,
      onCompleted,
      validate,
    } = this.props;
    return (
      <Mutation
        mutation={mutation}
        update={updateCache}
        onCompleted={() => onCompleted(formRef)}
        refetchQueries={[
          { query: ESTIMATE, variables: { id: initialValues.estimateId } },
        ]}
      >
        {(mutation) => (
          <Form
            onSubmit={(variables) => this.handleSubmit(mutation, variables)}
            validate={validate}
            initialValues={initialValues}
          >
            {({ handleSubmit, form }) => {
              formRef = form;
              return (
                <form id={formId} onSubmit={handleSubmit}>
                  <Field name="id">
                    {(input) => <input {...input} type="hidden" />}
                  </Field>
                  <ValidatedField name="mark" label="Mark" type="text" />
                  <ValidatedField name="qty" label="Qty" type="number" />
                  <ValidatedField name="desc" label="Description" type="text" />
                  <ValidatedField name="price" label="Price" type="number" />
                </form>
              );
            }}
          </Form>
        )}
      </Mutation>
    );
  }
}

const SystemQuery = ({
  systemId,
  updateCache,
  onCompleted,
  validate,
  initialValues,
}) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Query query={SYSTEM} variables={{ id: systemId }}>
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error</p>;

        const desc = data.system.details.find((d) => d.type === 'desc').desc;
        const price = currency(data.system.priceSqft);
        const initial = {
          id: data.system.id,
          type: currentUser.internal ? 'misc' : 'client_misc',
          mark: data.system.mark,
          qty: data.system.qty,
          desc,
          price,
          ...initialValues,
        };

        return (
          <SystemMutation
            formId="edit-misc-form"
            mutation={SYSTEM_UPDATE}
            initialValues={initial}
            updateCache={updateCache}
            onCompleted={onCompleted}
            validate={validate}
          />
        );
      }}
    </Query>
  );
};

class MiscForm extends Component {
  static contextType = AuthContext;

  static defaultProps = {
    onCompleted: () => {},
  };

  validate = (values) => {
    const errors = {};
    const required = ['mark', 'qty', 'desc', 'price'];
    required.forEach((r) => !values[r] && (errors[r] = 'Required'));
    if (values.mark && this.props.marks.includes(values.mark.toUpperCase())) {
      errors.mark = 'Mark is already in use';
    }
    return errors;
  };

  updateCacheOnCreate = (cache, { data: { systemCreate } }) => {
    const { estimateId } = this.props;
    const data = cache.readQuery({
      query: ESTIMATE_SYSTEMS,
      variables: { id: estimateId },
    });
    data.estimate.systems = [systemCreate].concat(data.estimate.systems);
    cache.writeQuery({
      query: ESTIMATE_SYSTEMS,
      variables: { id: estimateId },
      data,
    });
  };

  render() {
    const { misc, estimateId, onCompleted } = this.props;
    const { currentUser } = this.context;

    return misc ? (
      <SystemQuery
        formId="edit-misc-form"
        systemId={misc.id}
        updateCache={() => {}}
        onCompleted={onCompleted}
        validate={this.validate}
        initialValues={{ estimateId }}
      />
    ) : (
      <SystemMutation
        formId="new-misc-form"
        mutation={SYSTEM_CREATE}
        initialValues={{
          type: currentUser.internal ? 'misc' : 'client_misc',
          qty: 1,
          estimateId,
        }}
        updateCache={this.updateCacheOnCreate}
        onCompleted={onCompleted}
        validate={this.validate}
      />
    );
  }
}

MiscForm.propTypes = {
  onCompleted: PropTypes.func.isRequired,
};

export default withRouter(MiscForm);
