import React from 'react';
import Moment from 'react-moment';

export default ({ users, onSelect }) => (
  <table className="table table-hover">
    <thead>
      <tr>
        <th scope="col">Email</th>
        <th scope="col">Internal</th>
        <th scope="col">Admin</th>
        <th scope="col">Locked At</th>
        <th scope="col" />
      </tr>
    </thead>
    <tbody>
      {users.map((user) => (
        <tr key={user.id} onClick={() => onSelect(user)}>
          <td>{user.email}</td>
          <td>{user.internal ? 'Yes' : 'No'}</td>
          <td>{user.admin ? 'Yes' : 'No'}</td>
          <td>
            {user.lockedAt ? (
              <Moment date={user.lockedAt} format="MMM DD, YYYY" />
            ) : (
              'Not locked'
            )}
          </td>
          <td />
        </tr>
      ))}
    </tbody>
  </table>
);
