import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Query } from "react-apollo";
import { PO } from "../../../queries";
import Pages from "./Pages";

export default class POPrint extends Component {
  goBack = () => {
    const { history, location } = this.props;
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    history.push(`/pos${page ? `?page=${page}` : ""}`);
  };

  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    return (
      <Query query={PO} variables={{ id }}>
        {({ loading, error, data: { po } = {} }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error</p>;

          return (
            <div className="pages-container">
              <div className="actions">
                <button
                  onClick={window.print}
                  className="print-button btn btn-outline-success"
                >
                  <FontAwesomeIcon icon={["fal", "print"]} />
                </button>
                <button
                  onClick={this.goBack}
                  className="close-button btn btn-outline-danger"
                >
                  <FontAwesomeIcon icon={["fal", "times"]} />
                </button>
              </div>
              <Pages po={po} />
            </div>
          );
        }}
      </Query>
    );
  }
}
