import gql from 'graphql-tag';

const CLIENT_FIELDS = [
  'email',
  'name',
  'phone',
  'fax',
  'address',
  'city',
  'state',
  'zip',
  'markup',
  'tax',
];

export const CLIENT_CREATE = gql`
  mutation ClientCreate(
    $email: String!
    $name: String!
    $phone: String
    $fax: String
    $address: String
    $city: String
    $state: String
    $zip: String
    $markup: Float!
    $tax: Float!
    $ownerId: ID
  ) {
    clientCreate(
      email: $email
      name: $name
      phone: $phone
      fax: $fax
      address: $address
      city: $city
      state: $state
      zip: $zip
      markup: $markup
      tax: $tax
      ownerId: $ownerId
    ) {
      id
      email
      name
      phone
      fax
      address
      city
      state
      zip
      markup
      tax
      insertedAt
      logoPath
    }
  }
`;

export const CLIENT_UPDATE = gql`
  mutation ClientUpdate(
    $id: ID!
    $email: String
    $name: String
    $phone: String
    $fax: String
    $address: String
    $city: String
    $state: String
    $zip: String
    $markup: Float
    $tax: Float
    $logo: Upload
  ) {
    clientUpdate(
      id: $id
      email: $email
      name: $name
      phone: $phone
      fax: $fax
      address: $address
      city: $city
      state: $state
      zip: $zip
      markup: $markup
      tax: $tax
      logo: $logo
    ) {
      id
      tax
      email
      name
      phone
      fax
      address
      city
      state
      zip
      markup
      insertedAt
      logoPath
    }
  }
`;

export const CLIENT_DELETE = gql`
  mutation ClientDelete($id: ID!) {
    clientDelete(id: $id) {
      id
    }
  }
`;
