import gql from 'graphql-tag';

export const PO_CREATE = gql`
  mutation PoCreate(
    $vendorId: ID!
    $estimateId: ID
    $poItems: [PoItemInput!]!
  ) {
    poCreate(vendorId: $vendorId, estimateId: $estimateId, poItems: $poItems) {
      id
      received
      sent
      insertedAt
      vendor {
        id
        name
        street
        city
        state
        zip
        phone
        fax
        email
      }
      poItems {
        id
        qty
        weight
        itemNo
        description
        price
      }
    }
  }
`;

export const PO_UPDATE = gql`
  mutation PoCreate(
    $id: ID!
    $vendorId: ID
    $sent: String
    $received: String
    $poItems: [PoItemInput!]
  ) {
    poUpdate(
      id: $id
      vendorId: $vendorId
      sent: $sent
      received: $received
      poItems: $poItems
    ) {
      id
      received
      sent
      insertedAt
      vendor {
        id
        name
        street
        city
        state
        zip
        phone
        fax
        email
      }
      poItems {
        id
        qty
        weight
        itemNo
        description
        price
      }
    }
  }
`;
