export const CONFIGS = {
  XO: '1',
  OX: '2',
  XX: '3',
  OXO: '4',
  XOX: '5',
  XXO: '6',
  OXX: '7',
  XXX: '8',
  OXXO: '9',
  OXXXXO: '10',
};
