import React from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import classNames from "classnames";

const Input = ({ input, meta, type, name, disabled }) => (
  <>
    <input
      {...input}
      type={type}
      disabled={disabled}
      id={name}
      className={`form-control ${meta.error && meta.touched && "is-invalid"}`}
    />
    {meta.error && meta.touched && (
      <div className="invalid-feedback">{meta.error}</div>
    )}
  </>
);

const ValidatedField = ({ name, label, type, cols, className, disabled }) => (
  <Field name={name}>
    {({ input, meta }) => (
      <div
        className={classNames("form-group", className, {
          row: cols.length >= 2
        })}
      >
        <label
          htmlFor={name}
          className={classNames({
            [`col-sm-${cols[0]} col-form-label`]: cols.length >= 2
          })}
        >
          {label}
        </label>
        {cols.length >= 2 ? (
          <div className={`col-sm-${cols[1]}`}>
            <Input
              input={input}
              meta={meta}
              type={type}
              name={name}
              disabled={disabled}
            />
          </div>
        ) : (
          <Input
            input={input}
            meta={meta}
            type={type}
            name={name}
            disabled={disabled}
          />
        )}
      </div>
    )}
  </Field>
);

ValidatedField.defaultProps = {
  type: "text",
  cols: []
};

ValidatedField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  cols: PropTypes.arrayOf(PropTypes.number)
};

export default ValidatedField;
