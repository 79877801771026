import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Search from './Search';
import logo from '../../../assets/images/logo.png';
import { useSignOut, AuthContext } from '../../../auth';
import UserProfile from './UserProfile';

const NavBar = () => {
  const { currentUser } = useContext(AuthContext);
  const signOut = useSignOut();

  return (
    <nav className="nav-bar-component navbar navbar-expand-lg navbar-light px-0 mb-2">
      <Link className="navbar-brand" to="/">
        <img src={logo} alt="Krystal logo" width="40" height="40" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav">
          <NavLink className="nav-item nav-link" to="/estimates">
            Estimates
          </NavLink>
          <NavLink className="nav-item nav-link" to="/orders">
            Orders
          </NavLink>
          {currentUser.internal && (
            <NavLink className="nav-item nav-link" to="/pos">
              POs
            </NavLink>
          )}
          <NavLink className="nav-item nav-link" to="/clients">
            Clients
          </NavLink>
          {currentUser.internal && (
            <NavLink className="nav-item nav-link" to="/projects">
              Projects
            </NavLink>
          )}
          {currentUser.internal && (
            <NavLink className="nav-item nav-link" to="/reports">
              Reports
            </NavLink>
          )}
          {(currentUser.admin || !currentUser.internal) && (
            <NavLink className="nav-item nav-link" to="/settings">
              Settings
            </NavLink>
          )}
          {currentUser.internal && <Search />}
        </div>
        <div className="ml-auto">
          <UserProfile />
          <button className="btn btn-primary" onClick={signOut}>
            Sign out
            <FontAwesomeIcon icon={['fal', 'sign-out']} className="ml-3" />
          </button>
        </div>
      </div>
    </nav>
  );
};

NavBar.defaultProps = { signOut: () => {} };
NavBar.propTypes = { signOut: PropTypes.func.isRequired };

export default NavBar;
