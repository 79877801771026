import React from 'react';
import classNames from 'classnames';
import { series, round } from '../../../../util';
import { CONFIGS as hrConfigs } from '../../../systems/HrForm';
import Page from '../../../print/Page';
import logo from '../../../../assets/images/company-logo.png';

const GlassLabel = ({ estimate, system, inedx, classes }) => {
  const {
    verticals: [
      {
        width,
        lHeight,
        rHeight,
        glass: [glass],
      },
    ],
  } = system;

  return (
    <Page
      key={`${system.id}-${inedx}`}
      className={classNames(classes, 'glass')}
      size="label"
    >
      <div className="px-4">
        <img src={logo} className="w-100" alt="Luxgard logo" />
      </div>
      <div className="text-center mt-4">
        <h6>Luxgard Windows & Doors, LLC</h6>
        <h6>Mark {system.mark}</h6>
        <h6>
          <b>{series(system)}</b>
        </h6>
        <h6 className="mb-0">
          <b>
            Size: {round(width)}" X {round(Math.max(lHeight, rHeight))}"
          </b>
        </h6>
        {system.type === 'hr' && (
          <h6 className="mb-0">
            Configuration:{' '}
            {
              hrConfigs.find(
                ({ value }) => parseInt(value, 10) === system.config
              ).label
            }
          </h6>
        )}
        <h6>{glass.glassComposition.composition}</h6>
        <h6 className="mb-0">Client Name: {estimate.client.name}</h6>
        <h6 className={classNames({ 'mb-0': estimate.client.phone })}>
          Client email: {estimate.client.email}
        </h6>
        {estimate.client.phone && (
          <h6>Client phone: {estimate.client.phone}</h6>
        )}
        <h4>
          <b>Order# {estimate.id.padStart(6, 0)}</b>
        </h4>
      </div>
    </Page>
  );
};

export default GlassLabel;
