import React from 'react';
import { useQuery } from 'react-apollo';
import { Button, Modal } from 'react-bootstrap';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import {
  FRAME_COLORS_LIST,
  GLASS_COMPOSITIONS_LIST,
  MARKUPS,
  PRICES,
} from '../../../../queries';
import Field from '../../../general/rhf/Field';
import GlassSelection from '../../FdForm/GlassSelection';
import { useUpdateManySystems } from './lib';

const GlobalChanges = ({ estimate, show, handleClose }) => {
  const form = useForm();
  const compositions = useQuery(GLASS_COMPOSITIONS_LIST);
  const frameColors = useQuery(FRAME_COLORS_LIST);
  const markups = useQuery(MARKUPS);
  const prices = useQuery(PRICES);
  const update = useUpdateManySystems(estimate, compositions, markups, prices);

  const [frameColor, glass, glassCompositionId] = useWatch({
    control: form.control,
    name: ['frameColor', 'glass', 'glassCompositionId'],
  });

  const onSubmit = async (values) => {
    await update(values);
    handleClose();
  };

  if (compositions.loading || frameColors.loading) return null;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Global Changes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...form}>
          <h5>What would you like to change?</h5>
          <Field type="checkbox" name="frameColor" label="Frame Color" />
          <Field type="checkbox" name="glass" label="Glass" />

          {frameColor && (
            <div className="mt-4">
              <Field type="select" name="frameColorId" label="Frame Color">
                {frameColors.data.frameColors.map(({ id, color }) => (
                  <option key={id} value={id}>
                    {color}
                  </option>
                ))}
              </Field>
            </div>
          )}

          {glass && (
            <div className="mt-4">
              <GlassSelection
                required={false}
                glassCompositions={compositions.data.glassCompositions}
              />
            </div>
          )}
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={form.handleSubmit(onSubmit)}
          disabled={(glass && !glassCompositionId) || !(glass || frameColor)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GlobalChanges;
