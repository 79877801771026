import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import Moment from 'react-moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery'
import bootbox from 'bootbox'
import { PROJECT_DELETE } from '../../mutations'
import { PROJECTS_LIST } from '../../queries'

export default class ProjectBox extends Component {
  editProject = () => {
    const { project: { id }, selectProject } = this.props
    selectProject(id)
    $("#edit-project-modal").modal()
  }

  updateCache = (cache, { data: { projectDelete } }) => {
    const data = cache.readQuery({ query: PROJECTS_LIST })
    data.projects = data.projects.filter(p => p.id !== projectDelete.id)
    data.tableInfo = { ...data.tableInfo, count: data.tableInfo.count - 1 }
    cache.writeQuery({
      query: PROJECTS_LIST,
      data
    })
  }

  render() {
    const { id, name, client, insertedAt } = this.props.project
    return (
      <Mutation
        mutation={PROJECT_DELETE}
        variables={{ id }}
        update={this.updateCache}
      >
        {mutation => (
          <div className="card project-box">
            <div className="card-body p-3">
              <h5 className="card-title">
                {name}
                <div className="dropdown float-right">
                  <button
                    className="btn btn-link text-secondary p-0"
                    type="button"
                    id="project-options-menu"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon icon={["fal", "ellipsis-h-alt"]} />
                  </button>
                  <div className="dropdown-menu" aria-labelledby="project-options-menu">
                    <button className="dropdown-item" onClick={this.editProject}>
                      <FontAwesomeIcon icon={["fal", "edit"]} className="mr-3" />
                      Edit
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => {
                        bootbox.confirm("Are you sure?", r => { r && mutation() })
                      }}
                    >
                      <FontAwesomeIcon icon={["fal", "trash-alt"]} className="mr-3" />
                      Delete
                    </button>
                  </div>
                </div>
              </h5>
              <small className="card-subtitle text-muted font-weight-light">
                <p className="mb-1">For client {client.name}</p>
                <p className="mb-1">Created on <Moment date={insertedAt} format="MMM DD, YYYY" /></p>
              </small>
            </div>
            <div className="card-footer text-center">
              <button className="btn btn-link ml-auto">
                New Estimate
                <FontAwesomeIcon icon={["fal", "plus"]} className="ml-2" />
              </button>
            </div>
          </div>
        )}
      </Mutation>
    )
  }
}