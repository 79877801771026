import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import Moment from 'react-moment';
import {
  CLIENT_WITH_ESTIMATES,
  CLIENT_WITH_META_ESTIMATES,
} from '../../../queries';
import Estimates from './Estimates';
import { AuthContext } from '../../../auth';

const Client = () => {
  const { currentUser } = useContext(AuthContext);
  const { id } = useParams();
  const { loading, data: { client } = {} } = useQuery(
    currentUser.internal ? CLIENT_WITH_ESTIMATES : CLIENT_WITH_META_ESTIMATES,
    {
      variables: { id },
    }
  );

  if (loading) return null;

  return (
    <div className="row flex-grow-1">
      <div className="col-sm-3">
        <h3 className="font-weight-bold">{client.name}</h3>
        <div className="p-2">
          <h6 className="mb-1 font-weight-bold">
            <span className="text-muted">Created on: </span>
            <Moment date={client.insertedAt} format="MMM DD, YYYY" />
          </h6>
        </div>
      </div>
      <div className="col-sm-9">
        <Estimates
          estimates={
            currentUser.internal ? client.estimates : client.metaEstimates
          }
        />
      </div>
    </div>
  );
};

export default Client;
