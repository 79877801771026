import React, { createContext, useContext, useState } from 'react';

const SelectionContext = createContext();

export const useSelectionContext = () => useContext(SelectionContext);

const SelectionProvider = ({ estimate, children }) => {
  const [selected, setSelected] = useState([]);

  const toggleSelect = (id) => {
    if (selected.includes(id))
      setSelected((prev) => prev.filter((e) => e !== id));
    else setSelected((prev) => prev.concat(id));
  };

  const toggleAll = () => {
    const ids = estimate.systems
      .filter(({ type }) => !['misc', 'client_misc'].includes(type))
      .map(({ id }) => id);
    if (selected.length < ids.length) {
      setSelected(ids);
    } else {
      setSelected([]);
    }
  };

  return (
    <SelectionContext.Provider value={{ selected, toggleAll, toggleSelect }}>
      {children}
    </SelectionContext.Provider>
  );
};

export default SelectionProvider;
