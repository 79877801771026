import gql from "graphql-tag";

export const VENDOR_CREATE = gql`
  mutation VendorCreate(
    $name: String!
    $street: String!
    $city: String!
    $state: String!
    $zip: String!
    $phone: String!
    $fax: String
    $email: String
  ) {
    vendorCreate(
      name: $name
      street: $street
      city: $city
      state: $state
      zip: $zip
      phone: $phone
      fax: $fax
      email: $email
    ) {
      id
      name
      street
      city
      state
      zip
      phone
      fax
      email
    }
  }
`;

export const VENDOR_UPDATE = gql`
  mutation VendorUpdate(
    $id: ID!
    $name: String!
    $street: String!
    $city: String!
    $state: String!
    $zip: String!
    $phone: String!
    $fax: String
    $email: String
  ) {
    vendorUpdate(
      id: $id
      name: $name
      street: $street
      city: $city
      state: $state
      zip: $zip
      phone: $phone
      fax: $fax
      email: $email
    ) {
      id
      name
      street
      city
      state
      zip
      phone
      fax
      email
    }
  }
`;
