import gql from 'graphql-tag';

export const USER_CREATE = gql`
  mutation UserCreate(
    $email: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    userCreate(
      email: $email
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      id
      email
      internal
      admin
    }
  }
`;

export const USER_UPDATE = gql`
  mutation UserUpdate(
    $id: ID!
    $email: String
    $admin: Boolean
    $internal: Boolean
    $password: String
    $passwordConfirmation: String
    $lockedAt: DateTime
  ) {
    userUpdate(
      id: $id
      email: $email
      admin: $admin
      internal: $internal
      password: $password
      passwordConfirmation: $passwordConfirmation
      lockedAt: $lockedAt
    ) {
      id
      email
      internal
      admin
      lockedAt
    }
  }
`;

export const USER_INVITE = gql`
  mutation InviteUser(
    $email: String!
    $admin: Boolean
    $internal: Boolean
    $clientId: ID
  ) {
    inviteUser(
      email: $email
      admin: $admin
      internal: $internal
      clientId: $clientId
    ) {
      id
      email
      internal
      admin
    }
  }
`;

export const SIGNUP = gql`
  mutation Signup(
    $token: String!
    $email: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    signup(
      token: $token
      email: $email
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      id
      email
      internal
      admin
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: string!) {
    forgotPassword(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $email: String!
    $resetToken: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    resetPassword(
      email: $email
      resetToken: $resetToken
      password: $password
      passwordConfirmation: $passwordConfirmation
    )
  }
`;
