import { quotation } from './quotation';
import Fraction from 'fraction.js';
import { toNearest } from '../util';
import {
  DOUBLE_DOOR,
  LEFT_LITE,
  RIGHT_LITE,
} from '../components/systems/FdForm/constants';

const toFraction = (x) => new Fraction(toNearest(0.0625)(x)).toFraction(true);

const leftHandle = ({ two, x, height, active = true }) => [
  two.makeRoundedRectangle(x, height - 36, 1.5, 8, 0.7),
  two.makeRoundedRectangle(x + 2, height - 38, 6, 1, 0.5),
  ...(active ? [two.makeCircle(x, height - 34, 0.5)] : []),
];

const rightHandle = ({ two, x, height, active = true }) => [
  two.makeRoundedRectangle(x, height - 36, 1.5, 8, 0.7),
  two.makeRoundedRectangle(x - 2, height - 38, 6, 1, 0.5),
  ...(active ? [two.makeCircle(x, height - 34, 0.5)] : []),
];

const drawHandle = ({ two, config, doorWidth, height, hinge, active }) => {
  if (DOUBLE_DOOR.includes(config)) {
    return [
      ...leftHandle({
        two,
        x: doorWidth / 2 + 0.5 + 3.75 / 2,
        height,
        active: active === 'Right',
      }),
      ...rightHandle({
        two,
        x: doorWidth / 2 - 0.5 - 3.75 / 2,
        height,
        active: active === 'Left',
      }),
    ];
  }
  if (hinge === 'Left') {
    return rightHandle({ two, x: doorWidth - 1.75 - 3.75 / 2, height });
  }
  // single right-hinge left handle
  return leftHandle({ two, x: 1.75 + 3.75 / 2, height });
};

export const frenchDoor = ({
  two,
  config,
  doorWidth,
  leftWidth = 0,
  rightWidth = 0,
  height,
  hinge,
  active,
}) => {
  two.clear();

  const totalWidth =
    doorWidth +
    (LEFT_LITE.includes(config) ? leftWidth : 0) +
    (RIGHT_LITE.includes(config) ? rightWidth : 0);

  const leafWidth = DOUBLE_DOOR.includes(config)
    ? (doorWidth - 4.5) / 2
    : doorWidth - 3.5;

  const door = [
    // frame
    two.makeRectangle(doorWidth / 2, height / 2, doorWidth, height),
    two.makePath(1.5, 0, 1.5, height),
    two.makePath(doorWidth - 1.5, 0, doorWidth - 1.5, height),
    // leafs
    ...(DOUBLE_DOOR.includes(config)
      ? [
          // left
          two.makeRectangle(
            1.75 + leafWidth / 2,
            height / 2,
            leafWidth,
            height - 3
          ),
          two.makePath(5.5, 1.5, 5.5, height - 1.5),
          two.makePath(
            1.75 + leafWidth - 3.75,
            1.5,
            1.75 + leafWidth - 3.75,
            height - 1.5
          ),
          two.makePath(5.5, 5.5, 1.75 + leafWidth - 3.75, 5.5),
          two.makePath(
            5.5,
            height - 5.5,
            1.75 + leafWidth - 3.75,
            height - 5.5
          ),

          // right
          two.makeRectangle(
            doorWidth - 1.75 - leafWidth / 2,
            height / 2,
            leafWidth,
            height - 3
          ),
          two.makePath(doorWidth - 5.5, 1.5, doorWidth - 5.5, height - 1.5),
          two.makePath(
            doorWidth - 1.75 - leafWidth + 3.75,
            1.5,
            doorWidth - 1.75 - leafWidth + 3.75,
            height - 1.5
          ),
          two.makePath(
            doorWidth - 5.5,
            5.5,
            doorWidth - 1.75 - leafWidth + 3.75,
            5.5
          ),
          two.makePath(
            doorWidth - 5.5,
            height - 5.5,
            doorWidth - 1.75 - leafWidth + 3.75,
            height - 5.5
          ),
        ]
      : [
          two.makeRectangle(doorWidth / 2, height / 2, leafWidth, height - 3),
          two.makePath(5.5, 1.5, 5.5, height - 1.5),
          two.makePath(doorWidth - 5.5, 1.5, doorWidth - 5.5, height - 1.5),
          two.makePath(5.5, 5.5, doorWidth - 5.5, 5.5),
          two.makePath(5.5, height - 5.5, doorWidth - 5.5, height - 5.5),
        ]),
    two.makePath(1.5, 0.5, doorWidth - 1.5, 0.5),
    two.makePath(1.5, 1, doorWidth - 1.5, 1),
    two.makePath(1.5, height - 0.5, doorWidth - 1.5, height - 0.5),
    two.makePath(1.5, height - 1, doorWidth - 1.5, height - 1),
  ];

  const handle = drawHandle({ two, config, doorWidth, height, hinge, active });

  const left = [
    ...(LEFT_LITE.includes(config) && leftWidth
      ? [
          two.makeRectangle(0 - leftWidth / 2, height / 2, leftWidth, height),
          two.makePath(0 - leftWidth + 1.75, 0, 0 - leftWidth + 1.75, height),
          two.makePath(0 - leftWidth + 1.75, 5.5, 0, 5.5),
          two.makePath(0 - leftWidth + 1.75, height - 5.5, 0, height - 5.5),
        ]
      : []),
  ];

  const right = [
    ...(RIGHT_LITE.includes(config) && rightWidth
      ? [
          two.makeRectangle(
            doorWidth + rightWidth / 2,
            height / 2,
            rightWidth,
            height
          ),
          two.makePath(
            doorWidth + rightWidth - 1.5,
            0,
            doorWidth + rightWidth - 1.5,
            height
          ),
          two.makePath(doorWidth, 5.5, doorWidth + rightWidth - 1.5, 5.5),
          two.makePath(
            doorWidth,
            height - 5.5,
            doorWidth + rightWidth - 1.5,
            height - 5.5
          ),
        ]
      : []),
  ];

  const group = two.makeGroup(...door, ...handle, ...left, ...right);
  group.linewidth = 0.3;

  quotation({
    two,
    length: doorWidth,
    pos: 'down',
    y: height,
    label: `${toFraction(doorWidth)}"`,
  });

  if (LEFT_LITE.includes(config) && leftWidth) {
    quotation({
      two,
      length: leftWidth,
      pos: 'down',
      x: -leftWidth,
      y: height,
      label: `${toFraction(leftWidth)}"`,
    });
  }

  if (RIGHT_LITE.includes(config) && rightWidth) {
    quotation({
      two,
      length: rightWidth,
      pos: 'down',
      x: doorWidth,
      y: height,
      label: `${toFraction(rightWidth)}"`,
    });
  }

  quotation({
    two,
    length: height,
    dir: 'vertical',
    x: LEFT_LITE.includes(config) && leftWidth ? -leftWidth : 0,
    label: `${toFraction(height)}"`,
  });

  two.update();

  return [
    -3 - 10 - (LEFT_LITE.includes(config) ? leftWidth : 0),
    -3,
    totalWidth + 6 + 10,
    height + 6 + 10,
  ];
};
