import currency from 'currency.js';
import { useQuery } from 'react-apollo';
import { SYSTEM } from '../../../../queries';

export const getFieldValues = (system) => {
  const config = system.config.toString();
  const glass = system.verticals[0].glass[0];
  const price = currency(system.priceSqft);

  const values = {
    id: system.id,
    mark: system.mark,
    qty: system.qty,
    config: config,
    frameColorId: system.frameColor.id,
    autoPrice: system.autoPrice,
    price,
    width: system.verticals[0].width,
    height: system.verticals[0].lHeight,
    ...(!!glass && {
      glassCompositionId: glass.glassComposition.id,
      glassHeatTreatment: glass.glassComposition.heatTreatment,
      glassType: glass.glassComposition.type,
      glassThickness: glass.glassComposition.thickness,
      glassInterlayer: glass.glassComposition.interlayer,
    }),
  };

  return values;
};

const onCompleted = (system, formContext) => {
  const { reset, getValues } = formContext;
  const values = getFieldValues(system);

  reset({ ...getValues(), ...values });
};

export const useInitialValues = ({ systemId, formContext }) => {
  useQuery(SYSTEM, {
    variables: { id: systemId },
    skip: !systemId,
    onCompleted: ({ system }) => onCompleted(system, formContext),
  });
};
