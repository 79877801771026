import gql from 'graphql-tag';

export const SEARCH = gql`
  query Search($term: String!) {
    search(term: $term) {
      ... on Estimate {
        id
        __typename
        title: name
        subTitle: id
      }
      ... on Client {
        id
        __typename
        title: name
        subTitle: email
      }
    }
  }
`;
