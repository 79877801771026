import React, { useContext } from 'react';
import { AuthContext } from '../../../auth';
import { useSelectionContext } from './SelectionProvider';
import SystemRow from './SystemRow';

const SystemsTable = ({ estimate, editMisc }) => {
  const { currentUser } = useContext(AuthContext);
  const { selected, toggleAll } = useSelectionContext();
  const rows = currentUser.internal
    ? estimate.systems.filter(({ type }) => type !== 'client_misc')
    : estimate.systems;

  return (
    <table className="table table-hover table-striped">
      <thead>
        <tr>
          <th itemScope="col" style={{ width: 40 }}>
            <input
              type="checkbox"
              checked={
                selected.length ===
                estimate.systems.filter(
                  ({ type }) => !['misc', 'client_misc'].includes(type)
                ).length
              }
              onChange={toggleAll}
            />
          </th>
          <th itemScope="col">Mark</th>
          <th itemScope="col">Type</th>
          <th itemScope="col">Qty</th>
          <th itemScope="col" />
        </tr>
      </thead>
      <tbody>
        {rows.sort((a, b) => a.mark.localeCompare(b.mark)).map((system) => (
          <SystemRow
            key={system.id}
            estimate={estimate}
            system={system}
            editMisc={editMisc}
          />
        ))}
      </tbody>
    </table>
  );
};

export default SystemsTable;
