import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AutoComplete from "react-autocomplete";

export default ({ term, items, onSelect, onChange, renderItem }) => (
  <AutoComplete
    value={term}
    items={items}
    onChange={onChange}
    onSelect={onSelect}
    getItemValue={({ __typename, id }) => `${__typename}:${id}`}
    renderItem={renderItem}
    renderInput={props => (
      <div className="search-box">
        <input {...props} className="form-control form-control-sm" />
        <FontAwesomeIcon icon={["fal", "search"]} />
      </div>
    )}
    wrapperStyle={{
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      zIndex: 10
    }}
  />
);
