import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApolloConsumer } from 'react-apollo';
import { signedIn } from '.';
import { SIGNIN } from '../queries';
import logo from '../assets/images/logo.png';

export default class Signin extends Component {
  state = {
    redirect: signedIn(),
    errors: '',
  };

  validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    }
    if (!values.password) {
      errors.password = 'Required';
    }
    return errors;
  };

  handleSubmit = async (client, values) => {
    try {
      const { data } = await client.query({
        query: SIGNIN,
        variables: values,
      });
      localStorage.setItem('auth_token', data.signin.token);
      this.setState({ redirect: true });
    } catch (err) {
      const errors = err.graphQLErrors.map((e) => e.message);
      this.setState({ errors });
    }
  };

  render() {
    const { redirect, errors } = this.state;
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    if (redirect) return <Redirect to={from} />;

    return (
      <ApolloConsumer>
        {(client) => (
          <div className="signin-container">
            <div className="signin-form p-4">
              <div className="content">
                <h2 className="font-weight-light text-center">Welcome back!</h2>
                <h4 className="font-weight-light text-center">
                  Please sign in
                </h4>
                {errors && (
                  <div className="alert alert-danger mb-0">
                    {errors.map((e, i) => (
                      <Fragment key={i}>
                        <span>{e}</span>
                        <br />
                      </Fragment>
                    ))}
                  </div>
                )}
                <Form
                  onSubmit={(values) => this.handleSubmit(client, values)}
                  validate={this.validate}
                >
                  {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="mt-5">
                      <Field
                        name="email"
                        render={({ input }) => (
                          <div className="input-container">
                            <input
                              {...input}
                              type="email"
                              placeholder="email"
                              className="form-control"
                            />
                            <FontAwesomeIcon icon={['fal', 'at']} size="lg" />
                          </div>
                        )}
                      />
                      <Field
                        name="password"
                        render={({ input }) => (
                          <div className="input-container">
                            <input
                              {...input}
                              type="password"
                              placeholder="password"
                              className="form-control"
                            />
                            <FontAwesomeIcon
                              icon={['fal', 'unlock-alt']}
                              size="lg"
                            />
                          </div>
                        )}
                      />
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-block mt-4"
                      >
                        Sign in
                      </button>
                    </form>
                  )}
                </Form>
                <div className="mt-4 text-center">
                  <Link to="/forgotPassword">
                    <small>Forgot password?</small>
                  </Link>
                </div>
              </div>
            </div>
            <div className="powered-by">
              <img src={logo} alt="Krystal logo" width="40" height="40" />
              <h6 className="font-wight-light">Powered by Krystal</h6>
            </div>
          </div>
        )}
      </ApolloConsumer>
    );
  }
}
