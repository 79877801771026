import React, { useRef } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { Form } from 'react-final-form';
import { Modal, Button } from 'react-bootstrap';
import ValidatedSelect from '../../general/ValidatedSelect';
import { VENDORS_NAMES, PO_LIST } from '../../../queries';
import { PO_CREATE } from '../../../mutations';
import { poItems } from './lib';
import { pageParams } from '../../../util';

const PAGE_SIZE = 10;

const validate = (values) => {
  const errors = {};
  if (!values.vendorId) errors.vendorId = 'Required';
  return errors;
};

export default ({ show, onClose, type, estimate }) => {
  const formRef = useRef();
  const { data, loading } = useQuery(VENDORS_NAMES);
  const [createPo] = useMutation(PO_CREATE, {
    onCompleted: onClose,
    refetchQueries: [
      {
        query: PO_LIST,
        variables: pageParams(1, PAGE_SIZE),
      },
      {
        query: PO_LIST,
        variables: { ...pageParams(1, PAGE_SIZE), estimateId: estimate.id },
      },
    ],
  });

  const vendors = loading
    ? []
    : data.vendors.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {type === 'glass' ? 'Add Glass PO' : 'Add Balance PO'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          validate={validate}
          onSubmit={({ vendorId }) =>
            createPo({
              variables: {
                vendorId,
                estimateId: estimate.id,
                poItems: poItems(type, estimate),
              },
            })
          }
        >
          {({ handleSubmit, form }) => {
            formRef.current = form;

            return (
              <form id={`${type}-po-form`} onSubmit={handleSubmit}>
                {loading ? (
                  ''
                ) : (
                  <ValidatedSelect name="vendorId" label="Vendor">
                    <option value="" disabled>
                      Select a vendor...
                    </option>
                    {vendors.map((v) => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </ValidatedSelect>
                )}
              </form>
            );
          }}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            document
              .getElementById(`${type}-po-form`)
              .dispatchEvent(new Event('submit', { cancelable: true }));
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
