export default glass => {
  const {
    heatTreatment,
    interlayer,
    interlayerThickness,
    thickness,
    type
  } = glass;

  const paramsPresent =
    heatTreatment && interlayer && interlayerThickness && thickness && type;

  if (!paramsPresent) return null;

  return interlayerThickness === "_090" ? "LMI" : "SMI";
};
