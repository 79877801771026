import gql from 'graphql-tag';

export const CLIENTS_LIST = gql`
  {
    clients {
      id
      email
      name
      phone
      fax
      address
      city
      state
      zip
      markup
      tax
      insertedAt
    }
    tableInfo(name: "clients") {
      count
    }
  }
`;

export const CLIENT = gql`
  query Client($id: ID!) {
    client(id: $id) {
      id
      email
      name
      phone
      fax
      address
      city
      state
      zip
      markup
      tax
      insertedAt
    }
  }
`;

export const CLIENT_WITH_ESTIMATES = gql`
  query ClientEstimates($id: ID!) {
    client(id: $id) {
      id
      name
      estimates {
        id
        name
        orderedAt
        clientOrderedAt
        insertedAt
      }
    }
  }
`;

export const CLIENT_WITH_META_ESTIMATES = gql`
  query ClientEstimates($id: ID!) {
    client(id: $id) {
      id
      name
      metaEstimates {
        id
        name
        orderedAt
        clientOrderedAt
        insertedAt
      }
    }
  }
`;
