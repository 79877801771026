import gql from 'graphql-tag';

export const MARKUP_UPSERT = gql`
  mutation MarkupUpsert($type: String!, $desc: String!, $value: Float!) {
    markupUpsert(type: $type, desc: $desc, value: $value) {
      id
      type
      desc
      value
    }
  }
`;
