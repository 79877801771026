import PropTypes from 'prop-types';
import React from 'react';

const Header = ({ isNew }) => {
  return (
    <div>
      <h3>{`${isNew ? 'New' : 'Edit'} Sliding Glass Dooor`}</h3>
      <h6 className="mb-5">
        Wind Loads: <span className="badge badge-primary">+0 / -0</span>
      </h6>
    </div>
  );
};

Header.defaultProps = {
  isNew: true,
};

Header.propTypes = {
  isNew: PropTypes.bool,
};

export default Header;
