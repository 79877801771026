import gql from 'graphql-tag';

export const PO = gql`
  query Po($id: ID!) {
    po(id: $id) {
      id
      received
      sent
      insertedAt
      vendor {
        id
        name
        street
        city
        state
        zip
        phone
        fax
        email
      }
      poItems {
        id
        qty
        weight
        itemNo
        description
        price
      }
      estimate {
        id
      }
    }
  }
`;

export const PO_LIST = gql`
  query Pos($estimateId: ID, $limit: Int, $offset: Int) {
    pos(estimateId: $estimateId, limit: $limit, offset: $offset) {
      id
      received
      sent
      insertedAt
      vendor {
        id
        name
        street
        city
        state
        zip
        phone
        fax
        email
      }
      poItems {
        id
        qty
        weight
        itemNo
        description
        price
      }
    }
    tableInfo(name: "pos") {
      count
    }
  }
`;
