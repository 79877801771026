import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bootbox from 'bootbox';
import React, { useContext } from 'react';
import { useMutation } from 'react-apollo';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../../auth';
import { SYSTEM_DELETE } from '../../../mutations';
import { ESTIMATE, ESTIMATE_SYSTEMS } from '../../../queries';
import { getFullSystemType } from '../../../util';
import { useSelectionContext } from './SelectionProvider';

const updateCache = (estimateId) => (cache, { data: { systemDelete } }) => {
  const data = cache.readQuery({
    query: ESTIMATE_SYSTEMS,
    variables: { id: estimateId },
  });
  data.estimate.systems = data.estimate.systems.filter(
    (s) => s.id !== systemDelete.id
  );
  cache.writeQuery({
    query: ESTIMATE_SYSTEMS,
    variables: { id: estimateId },
    data,
  });
};

const SystemRow = ({ estimate, system, editMisc }) => {
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();
  const { estimateId } = useParams();
  const { toggleSelect, selected } = useSelectionContext();

  const internalMisc = system.type === 'misc' && !currentUser.internal;
  const systemType = getFullSystemType(system);

  const [deleteSystem] = useMutation(SYSTEM_DELETE, {
    update: updateCache(estimateId),
    refetchQueries: [
      {
        query: ESTIMATE,
        variables: { id: estimateId },
      },
    ],
  });

  return (
    <tr
      className={internalMisc ? 'uneditable' : ''}
      onClick={() => {
        if (!internalMisc && !estimate.orderedAt && !estimate.clientOrderedAt) {
          if (['misc', 'client_misc'].includes(system.type)) {
            editMisc(system);
          } else {
            history.push(`/estimates/${estimateId}/systems/${system.id}/edit`);
          }
        }
      }}
    >
      <td itemScope="col">
        <input
          type="checkbox"
          checked={selected.includes(system.id)}
          onChange={() => toggleSelect(system.id)}
          onClick={(e) => e.stopPropagation()}
          disabled={['misc', 'client_misc'].includes(system.type)}
        />
      </td>
      <th itemScope="row">{system.mark}</th>
      <td>{internalMisc ? `${systemType} (Cannot edit)` : systemType}</td>
      <td>{system.qty}</td>
      <td>
        {!estimate.orderedAt &&
          !estimate.clientOrderedAt && (
            <div className="dropdown float-right mr-3">
              <button
                onClick={(e) => e.stopPropagation()}
                className="btn btn-link text-secondary p-0"
                type="button"
                id="estimate-options-menu"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <FontAwesomeIcon icon={['fal', 'ellipsis-h-alt']} />
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="estimate-options-menu"
              >
                <button
                  className="dropdown-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    bootbox.confirm('Are you sure?', (r) => {
                      r && deleteSystem({ variables: { id: system.id } });
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={['fal', 'trash-alt']}
                    className="mr-3"
                  />
                  Delete
                </button>
              </div>
            </div>
          )}
      </td>
    </tr>
  );
};

export default SystemRow;
