export const heatTreatments = [
  { text: 'Annealed', value: 'ANNEALED' },
  { text: 'Heat Strengthened', value: 'HEAT_STRENGTHENED' },
];
export const types = [
  { text: 'Laminated', value: 'LAMINATED' },
  { text: 'Insulated Laminated', value: 'INSULATED_LAMINATED' },
];
export const thickness = [
  { text: '5/16', value: '_5_16' },
  { text: '7/16', value: '_7_16' },
  { text: '9/16', value: '_9_16' },
  { text: '15/16', value: '_15_16' },
];
export const interlayers = [
  { text: 'SGP', value: 'SGP' },
  { text: 'PVB', value: 'PVB' },
];
export const interlayerThickness = [
  { text: '.090', value: '_090' },
  { text: '.060', value: '_060' },
];
