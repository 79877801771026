import React from 'react';
import classNames from 'classnames';
import Fraction from 'fraction.js';
import { toNearest } from '../../../util';

const adjustPrecision = toNearest(0.0625);

const reduceGlass = (verticals) =>
  Object.values(
    verticals.flatMap(({ glass }) => glass).reduce((acc, cur) => {
      const key = [
        cur.glassComposition.composition,
        cur.width,
        cur.lHeight,
        cur.rHeight,
      ].join('_');

      return {
        ...acc,
        [key]: acc[key] ? { ...acc[key], qty: acc[key].qty + 1 } : cur,
      };
    }, {})
  );

export default ({
  reportType,
  system: { verticals, qty, hardware, details },
  className,
}) => {
  const shape = details.find(({ type }) => type === 'shape');

  return (
    <table
      className={classNames(
        'table table-bordered table-sm glass-list',
        className
      )}
      style={{ fontSize: 12 }}
    >
      <tbody>
        {reportType === 'print' && (
          <tr className="heading">
            <th>Glass Composition</th>
            {(!shape || shape.desc === 'rectangle') && (
              <>
                <th>Glass Width</th>
                <th>Glass Height</th>
              </>
            )}
            <th>Qty</th>
          </tr>
        )}
        {reduceGlass(verticals).map((g) => (
          <tr key={`glass-${g.width}-${g.lHeight}`}>
            {reportType !== 'print' && (
              <th className="glass-composition-header">Glass Composition</th>
            )}
            <td>{g.glassComposition.composition}</td>
            {reportType === 'print' && (
              <>
                {(!shape || shape.desc === 'rectangle') && (
                  <>
                    <td>
                      {new Fraction(adjustPrecision(g.width)).toFraction(true)}"
                    </td>
                    <td>
                      {new Fraction(
                        adjustPrecision(Math.max(g.lHeight, g.rHeight))
                      ).toFraction(true)}
                      "
                    </td>
                  </>
                )}
                <td>{g.qty * qty}</td>
              </>
            )}
          </tr>
        ))}
        {reportType === 'print' &&
          hardware.length > 0 && (
            <>
              <tr className="heading">
                <th>Hardware Type</th>
                <th colSpan={2}>Hardware Description</th>
                <th>Qty</th>
              </tr>
              {hardware.map((item) => (
                <tr key={item.id}>
                  <td>{item.type}</td>
                  <td colSpan={2}>{item.desc}</td>
                  <td>{item.qty * qty}</td>
                </tr>
              ))}
            </>
          )}
      </tbody>
    </table>
  );
};
