import currency from 'currency.js';

export const getSystemMarkups = (sysType, markups) => {
  const markup = markups.find(
    ({ type, desc }) => type === 'SYSTEM_MARKUP' && desc === sysType
  );

  return markup ? markup.value : currency(0);
};

export const getGlassMarkup = (compositionId, compositions) =>
  compositionId
    ? compositions.find(({ id }) => id === compositionId).markup
    : currency(0);

export const getShapeMarkup = (shape, markups) => {
  const markup = markups.find(
    ({ type, desc }) => type === 'FW_SHAPE_MARKUP' && desc === shape
  );

  return markup ? markup.value : currency(0);
};
