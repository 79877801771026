import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import MonthlyOrders from './MonthlyOrders';
import MonthlyOrdersPrint from './MonthlyOrders/Print';
import { AuthContext } from '../../auth';

const Reports = () => {
  const { currentUser } = useContext(AuthContext);

  if (!currentUser.internal) return <Redirect to="/" />;

  return (
    <div className="reports">
      <h3 className="mb-5">Reports</h3>
      <div className="reports-container">
        <MonthlyOrders />
      </div>
    </div>
  );
};

export default () => (
  <Switch>
    <Route path="/reports/monthly-orders" component={MonthlyOrdersPrint} />
    <Route path="/reports" component={Reports} />
  </Switch>
);
