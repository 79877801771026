import { getFullSystemType } from '../../../../util';

export const estimateGlass = (estimate) =>
  estimate.systems.flatMap((system) => {
    const glass = system.verticals.flatMap(({ glass }) => glass);
    return glass.map((item) => ({
      ...item,
      height: Math.max(item.lHeight, item.rHeight),
      composition: item.glassComposition.composition,
      mark: system.mark,
      system: getFullSystemType(system),
      qty: item.qty * system.qty,
    }));
  });
