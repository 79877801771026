import currency from 'currency.js';
import { useMutation, useQuery } from 'react-apollo';
import { SYSTEM_UPDATE_MANY } from '../../../../mutations';
import { ESTIMATE_SYSTEMS, GLASS_COMPOSITIONS_LIST } from '../../../../queries';
import { getFieldValues } from '../../FdForm/lib';
import { getGlassMarkup } from '../../price/markups';
import { useSelectionContext } from '../SelectionProvider';

const adjustPrice = (curPrice, curMarkup, newMarkup) => {
  let price = currency(curPrice);

  if (curMarkup && curMarkup !== 0) price = price.divide(1 + curMarkup / 100);

  if (newMarkup && newMarkup !== 0)
    price = price.add(price.multiply(newMarkup / 100));

  return price.toString();
};

const calcPrices = (system, compositions, curCompId, newCompId) => {
  const curMarkup = getGlassMarkup(curCompId, compositions);
  const newMarkup = getGlassMarkup(newCompId, compositions);

  switch (system.type) {
    case 'fd':
      const {
        doorPrice: curDoorPrice,
        litePrice: curLitePrice,
      } = getFieldValues(system);
      const doorPrice = adjustPrice(curDoorPrice, curMarkup, newMarkup);
      const litePrice = adjustPrice(curLitePrice, curMarkup, newMarkup);
      return { doorPrice, litePrice };

    default:
      const price = adjustPrice(system.priceSqft, curMarkup, newMarkup);
      return { price };
  }
};

const getVariables = ({
  estimate,
  selected,
  values: { frameColorId, glassCompositionId },
  compositions,
}) => {
  const data = selected.map((id) => {
    const system = estimate.systems.find((item) => item.id === id);
    if (glassCompositionId && system.autoPrice) {
      const compositionId = system.verticals[0].glass[0].glassComposition.id;
      const prices = calcPrices(
        system,
        compositions,
        compositionId,
        glassCompositionId
      );
      return {
        id,
        frameColorId,
        glassCompositionId,
        type: system.type,
        ...prices,
      };
    }
    return { id, frameColorId, glassCompositionId, type: system.type };
  });

  return { data };
};

export const useUpdateManySystems = (estimate) => {
  const compositions = useQuery(GLASS_COMPOSITIONS_LIST);
  const { selected } = useSelectionContext();
  const [update] = useMutation(SYSTEM_UPDATE_MANY, {
    refetchQueries: [
      { query: ESTIMATE_SYSTEMS, variables: { id: estimate.id } },
    ],
  });

  if (compositions.loading) return null;

  return (values) => {
    const variables = getVariables({
      estimate,
      selected,
      values,
      compositions: compositions.data.glassCompositions,
    });

    return update({ variables });
  };
};
