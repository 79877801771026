import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { Router } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import * as serviceWorker from './serviceWorker';
import faSetup from './faSetup';
import apolloClient from './apolloClient';
import 'bootstrap';
import './assets/styles/master.scss';
import 'react-datepicker/dist/react-datepicker.css';
import App from './components/App';

faSetup();

const history = createHistory();
const client = apolloClient(history);

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router history={history}>
      <App />
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
