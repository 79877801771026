import React, { Component } from "react";
import { Query } from "react-apollo";
import $ from "jquery";
import Modal from "../../general/Modal";
import MiscForm from "./MiscForm";
import { ESTIMATE_SYSTEMS } from "../../../queries";

export default class MiscModals extends Component {
  submitMiscForm = id => {
    document
      .getElementById(id)
      .dispatchEvent(new Event("submit", { cancelable: true }));
  };

  onMiscFormCompleted = form => {
    const { onClose } = this.props;
    $("#new-misc-modal").modal("hide");
    $("#edit-misc-modal").modal("hide");
    setTimeout(form.reset);
    onClose();
  };

  render() {
    const { estimateId, misc, onClose } = this.props;
    return (
      <Query query={ESTIMATE_SYSTEMS} variables={{ id: estimateId }}>
        {({ loading, error, data: { estimate } = {} }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error</p>;

          const marks = estimate.systems.map(s => s.mark);

          return (
            <div className="misc-form-component">
              {misc ? (
                <Modal
                  onClose={onClose}
                  modalId="edit-misc-modal"
                  title="Edit Miscellaneous"
                  onContinue={() => this.submitMiscForm("edit-misc-form")}
                >
                  <MiscForm
                    marks={misc && marks.filter(m => m !== misc.mark)}
                    estimateId={estimateId}
                    onCompleted={this.onMiscFormCompleted}
                    misc={misc}
                  />
                </Modal>
              ) : (
                <Modal
                  onClose={onClose}
                  modalId="new-misc-modal"
                  title="New Miscellaneous"
                  onContinue={() => this.submitMiscForm("new-misc-form")}
                >
                  <MiscForm
                    marks={marks}
                    estimateId={estimateId}
                    onCompleted={this.onMiscFormCompleted}
                  />
                </Modal>
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}
