import { quotation } from './quotation';
import Fraction from 'fraction.js';
import { toNearest } from '../util';

const toFraction = (x) => new Fraction(toNearest(0.0625)(x)).toFraction(true);

export const glass = (two, width, lHeight, rHeight) => {
  const viewportWidth =
    lHeight !== rHeight ? width + 10 + 10 + 6 : width + 10 + 6;
  const height = Math.max(lHeight, rHeight);

  const glass = two.makePath(
    0,
    height - lHeight,
    0,
    height,
    width,
    height,
    width,
    height - rHeight,
    false
  );

  glass.linewidth = 0.3;

  quotation({
    two,
    length: width,
    pos: 'down',
    y: height,
    label: `${toFraction(width)}"`,
  });
  quotation({
    two,
    length: lHeight,
    dir: 'vertical',
    y: height - lHeight,
    label: `${toFraction(lHeight)}"`,
  });
  if (lHeight !== rHeight)
    quotation({
      two,
      length: rHeight,
      dir: 'vertical',
      pos: 'down',
      y: height - rHeight,
      x: width,
      label: `${toFraction(rHeight)}"`,
    });

  return [-3 - 10, -3, viewportWidth, height + 6 + 10];
};
