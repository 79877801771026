import React from 'react'

// TODO: This will not work properly on systems with multiple verticals
export default ({ system: { verticals, qty } }) => (
  verticals.map((v, i) => {
    if (v.extrusions.length === 0) return null
    return (
      <table className="table table-bordered table-sm cut-list" key={i} style={{ fontSize: 12 }}>
        <thead>
          <tr>
            <th>Extrusion</th>
            <th>Length</th>
            <th>Qty</th>
            <th>Desc</th>
          </tr>
        </thead>
        <tbody>
          {v.extrusions.map(e => {
            const qties = e.lengths.reduce((acc, cur) => {
              acc[cur] = acc[cur] || 0
              acc[cur] = acc[cur] + qty
              return acc
            }, {})

            let trs = []
            for (const key in qties) {
              trs.push(<tr key={`${e.type}-${key}-${qties[key]}`}>
                <td>{e.type}</td>
                <td>{key}"</td>
                <td>{qties[key]}</td>
                <td>{e.desc}</td>
              </tr>)
            }
            return trs
          })}
        </tbody>
      </table>
    )
  })
)