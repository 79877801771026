import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import bootbox from 'bootbox';
import { CLIENT_DELETE } from '../../mutations';
import { CLIENTS_LIST, PROJECTS_LIST } from '../../queries';

export default class ClientBox extends Component {
  editClient = () => {
    const {
      client: { id },
      selectClient,
    } = this.props;
    selectClient(id);
    $('#edit-client-modal').modal();
  };

  updateCache = (cache, { data: { clientDelete } }) => {
    const data = cache.readQuery({ query: CLIENTS_LIST });
    data.clients = data.clients.filter((c) => c.id !== clientDelete.id);
    data.tableInfo = { ...data.tableInfo, count: data.tableInfo.count - 1 };
    cache.writeQuery({
      query: CLIENTS_LIST,
      data,
    });
  };

  render() {
    const { id, name, insertedAt } = this.props.client;
    return (
      <Mutation
        mutation={CLIENT_DELETE}
        variables={{ id }}
        update={this.updateCache}
        refetchQueries={[{ query: PROJECTS_LIST }]}
      >
        {(mutation) => (
          <div className="card client-box">
            <div className="card-body p-3">
              <h5 className="card-title">
                {name}
                <div className="dropdown float-right">
                  <button
                    className="btn btn-link text-secondary p-0"
                    type="button"
                    id="client-options-menu"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon icon={['fal', 'ellipsis-h-alt']} />
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="client-options-menu"
                  >
                    <button className="dropdown-item" onClick={this.editClient}>
                      <FontAwesomeIcon
                        icon={['fal', 'edit']}
                        className="mr-3"
                      />
                      Edit
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => {
                        bootbox.confirm('Are you sure?', (r) => {
                          r && mutation();
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        icon={['fal', 'trash-alt']}
                        className="mr-3"
                      />
                      Delete
                    </button>
                  </div>
                </div>
              </h5>
              <small className="card-subtitle text-muted font-weight-light">
                Created on <Moment date={insertedAt} format="MMM DD, YYYY" />
              </small>
            </div>
            <div className="card-footer text-center">
              <Link to={`/clients/${id}`}>
                View
                <FontAwesomeIcon icon={['fal', 'list-ul']} className="ml-2" />
              </Link>
            </div>
          </div>
        )}
      </Mutation>
    );
  }
}
