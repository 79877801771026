import React from 'react';
import { useMutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FORGOT_PASSWORD } from '../mutations';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  }
  return errors;
};

const ForgotPassword = () => {
  const [forgotPassword, { loading, data }] = useMutation(FORGOT_PASSWORD);
  const complete = !loading && data;

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-form p-4">
        <div className="content">
          <h4 className="font-weight-light text-center">
            Forgot your password?
          </h4>
          <h6 className="font-weight-light text-center">
            Enter your email address and we will send you instructions to reset
            your password.
          </h6>

          {complete ? (
            <div className="mt-4">
              <h6 className="font-weight-light text-center text-success">
                Please check your email
              </h6>
            </div>
          ) : (
            <Form
              onSubmit={(variables) => forgotPassword({ variables })}
              validate={validate}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="mt-5">
                  <Field
                    name="email"
                    render={({ input }) => (
                      <div className="input-container">
                        <input
                          {...input}
                          type="email"
                          placeholder="email"
                          className="form-control"
                        />
                        <FontAwesomeIcon icon={['fal', 'at']} size="lg" />
                      </div>
                    )}
                  />
                  <button
                    type="submit"
                    className="btn btn-outline-primary btn-block mt-4"
                  >
                    Continue
                  </button>
                </form>
              )}
            </Form>
          )}
          <div className="mt-4 text-center">
            <Link to="/signin">
              <small>Sign in</small>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
