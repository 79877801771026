import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

const Select = ({ name, input, meta, children }) => (
  <>
    <select
      {...input}
      id={name}
      className={`form-control ${meta.error && meta.touched && 'is-invalid'}`}
    >
      {children}
    </select>
    {meta.error &&
      meta.touched && <div className="invalid-feedback">{meta.error}</div>}
  </>
);

const ValidatedSelect = ({ name, label, children, cols }) => {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <div
          className={classNames('form-group', {
            row: cols.length >= 2,
          })}
        >
          <label
            htmlFor={name}
            className={classNames({
              [`col-sm-${cols[0]} col-form-label`]: cols.length >= 2,
            })}
          >
            {label}
          </label>
          {cols.length >= 2 ? (
            <div className={`col-sm-${cols[1]}`}>
              <Select name={name} input={input} meta={meta}>
                {children}
              </Select>
            </div>
          ) : (
            <Select name={name} input={input} meta={meta}>
              {children}
            </Select>
          )}
        </div>
      )}
    </Field>
  );
};

ValidatedSelect.defaultProps = {
  cols: [],
};

ValidatedSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  cols: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ValidatedSelect;
