import { quotation } from '../quotation';
import Fraction from 'fraction.js';
import { toNearest } from '../../util';

const toFraction = (x) => new Fraction(toNearest(0.0625)(x)).toFraction(true);

export const eyebrow = (two, w, h, leg) => {
  if (!(two && w && h && leg)) return;

  const group = two.makeGroup(
    two.makePath(0, h - leg, 0, h, w, h, w, h - leg, true),
    two.makeCurve(0, h - leg, w / 2, 0, w, h - leg, true)
  );

  group.linewidth = 0.3;

  quotation({
    two,
    length: h,
    dir: 'vertical',
    pos: 'down',
    x: w / 2,
    separation: w / 2 + 10,
    label: `${toFraction(h)}"`,
  });

  quotation({
    two,
    length: leg,
    dir: 'vertical',
    y: h - leg,
    label: `${toFraction(leg)}"`,
  });

  quotation({
    two,
    length: w,
    pos: 'down',
    y: h,
    label: `${toFraction(w)}"`,
  });

  return [-3 - 12, -3, w + 24 + 6, h + 12 + 6];
};
