import currency from 'currency.js';
import createDecorator from 'final-form-calculate';
import PropTypes from 'prop-types';
import React, { Component, useContext } from 'react';
import { Mutation, Query } from 'react-apollo';
import { Field, Form, FormSpy } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import Two from 'two.js';
import { AuthContext } from '../../auth';
import { glass as draw } from '../../draw';
import { SYSTEM_CREATE, SYSTEM_UPDATE } from '../../mutations';
import {
  ESTIMATE,
  ESTIMATE_SYSTEMS,
  PRICES_BY_SYSTEM_TYPE,
  SYSTEM,
} from '../../queries';
import ValidatedField from '../general/ValidatedField';
import GlassSelection, { glassFilter } from './GlassSelection';
import getPrice from './price';

const calculator = createDecorator({
  field: ['width', 'lHeight', 'rHeight', 'autoPrice', 'glassCompositionId'],
  updates: {
    price: (
      _value,
      {
        width,
        lHeight,
        rHeight,
        price,
        prices,
        autoPrice,
        glassCompositions,
        glassCompositionId,
        markups,
      }
    ) => {
      return getPrice({
        type: 'glass',
        compositions: glassCompositions,
        markups,
        prices,
        autoPrice,
        price,
        glassCompositionId,
        width,
        lHeight,
        rHeight,
      });
    },
  },
});

class SystemMutation extends Component {
  static contextType = AuthContext;

  state = {
    two: null,
    twoElem: null,
  };

  draw = ({ values: { width, lHeight, rHeight } }) => {
    const { two, twoElem } = this.state;
    const _width = parseFloat(width);
    const _lHeight = parseFloat(lHeight);
    const _rHeight = parseFloat(rHeight);
    if (!(two && _width > 0 && _lHeight > 0 && _rHeight > 0)) return;
    two.clear();
    const viewBox = draw(two, _width, _lHeight, _rHeight);
    two.update();
    twoElem.children[0].setAttribute('viewBox', viewBox.join(' '));
  };

  getSvgFile = (fileName) => {
    const svg = this.state.twoElem.children[0];
    const data = new XMLSerializer().serializeToString(svg);
    return new File([data], fileName, { type: 'image/svg+xml;charset=utf-8' });
  };

  handleSubmit = (mutation, variables) => {
    const fileName = `mark_${variables.mark.toUpperCase()}.svg`;
    return mutation({
      variables: {
        ...variables,
        price: variables.price.toString(),
        qty: parseInt(variables.qty, 10),
        width: parseFloat(variables.width),
        height: Math.max(
          parseFloat(variables.lHeight),
          parseFloat(variables.rHeight)
        ),
        lHeight: parseFloat(variables.lHeight),
        rHeight: parseFloat(variables.rHeight),
        image: this.getSvgFile(fileName),
      },
    });
  };

  componentDidMount() {
    const opts = { width: '100%', height: '100%' };
    const twoElem = document.getElementById('drawing-container');
    const two = new Two(opts).appendTo(twoElem);
    this.setState({ two, twoElem }, () => {
      this.draw({ values: this.props.initialValues });
    });
  }

  render() {
    const { currentUser } = this.context;
    let formRef;
    const {
      formId,
      mutation,
      initialValues,
      updateCache,
      onCompleted,
      validate,
      goBack,
      glassCompositions,
      markups,
      prices,
      autoPrice,
    } = this.props;

    return (
      <Mutation
        mutation={mutation}
        update={updateCache}
        onCompleted={() => onCompleted(formRef)}
        refetchQueries={[
          { query: ESTIMATE, variables: { id: initialValues.estimateId } },
        ]}
      >
        {(mutation) => (
          <Form
            decorators={[calculator, glassFilter(glassCompositions)]}
            onSubmit={(variables) => this.handleSubmit(mutation, variables)}
            validate={validate}
            initialValues={{
              ...initialValues,
              prices,
              autoPrice,
              glassCompositions,
              markups,
            }}
          >
            {({ handleSubmit, form, initialValues, values }) => {
              formRef = form;
              return (
                <form id={formId} onSubmit={handleSubmit} className="h-100">
                  <FormSpy
                    onChange={this.draw}
                    subscription={{ values: true }}
                  />
                  <div className="system-form row flex-grow-1">
                    <div className="col-3 d-flex flex-column">
                      <div>
                        <h3 className="mb-5">
                          {initialValues.id ? 'Edit Glass' : 'New Glass'}
                        </h3>
                      </div>
                      <div className="form-container">
                        <div className="fixed">
                          <Field name="id">
                            {(input) => <input {...input} type="hidden" />}
                          </Field>
                          <ValidatedField
                            name="mark"
                            label="Mark"
                            type="text"
                          />
                          {currentUser.internal && (
                            <div className="d-flex align-items-end">
                              <ValidatedField
                                name="price"
                                label="Price per sqft"
                                type="number"
                                disabled={values.autoPrice}
                                className="flex-grow-1"
                              />
                              <div className="mb-4 ml-4" title="Auto Price">
                                <Field
                                  name="autoPrice"
                                  component="input"
                                  type="checkbox"
                                />
                              </div>
                            </div>
                          )}
                          <ValidatedField
                            name="qty"
                            label="Qty"
                            type="number"
                          />
                          <GlassSelection
                            glassCompositions={glassCompositions}
                          />
                          <ValidatedField
                            name="width"
                            label="Width"
                            type="number"
                          />
                          <ValidatedField
                            name="lHeight"
                            label="Left Height"
                            type="number"
                          />
                          <ValidatedField
                            name="rHeight"
                            label="Right Height"
                            type="number"
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block mt-4"
                      >
                        Save
                      </button>
                      <button
                        className="btn btn-outline-secondary btn-block"
                        onClick={goBack}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="col-9 d-flex">
                      <div id="drawing-container" className="flex-grow-1" />
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        )}
      </Mutation>
    );
  }
}

const SystemQuery = ({
  systemId,
  updateCache,
  onCompleted,
  validate,
  goBack,
  initialValues,
  glassCompositions,
  markups,
  prices,
}) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Query query={SYSTEM} variables={{ id: systemId }}>
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error</p>;

        const price = currency(data.system.priceSqft);

        const glassCompositionId =
          data.system.verticals[0].glass[0].glassComposition.id;

        const glassComposition = glassCompositions.find(
          ({ id }) => id === glassCompositionId
        );

        const filteredGlassCompositions = currentUser.internal
          ? glassCompositions
          : glassCompositions.filter(
              ({ id, glassOnly }) => !glassOnly || id === glassCompositionId
            );

        const initial = {
          id: data.system.id,
          type: 'glass',
          mark: data.system.mark,
          qty: data.system.qty,
          width: data.system.verticals[0].glass[0].width,
          lHeight: data.system.verticals[0].glass[0].lHeight,
          rHeight: data.system.verticals[0].glass[0].rHeight,
          glassCompositionId,
          glassHeatTreatment: glassComposition.heatTreatment,
          glassType: glassComposition.type,
          glassThickness: glassComposition.thickness,
          glassInterlayer: glassComposition.interlayer,
          price,
          ...initialValues,
        };

        return (
          <SystemMutation
            formId="update-system-form"
            mutation={SYSTEM_UPDATE}
            initialValues={initial}
            updateCache={updateCache}
            onCompleted={onCompleted}
            validate={validate}
            goBack={goBack}
            glassCompositions={filteredGlassCompositions}
            markups={markups}
            prices={prices}
            autoPrice={data.system.autoPrice}
          />
        );
      }}
    </Query>
  );
};

class GlassForm extends Component {
  static contextType = AuthContext;

  static defaultProps = {
    onCompleted: () => {},
  };

  validate = (values) => {
    const errors = {};
    const required = [
      'mark',
      'width',
      'lHeight',
      'rHeight',
      'qty',
      'glassCompositionId',
      'price',
    ];
    required.forEach((r) => !values[r] && (errors[r] = 'Required'));
    if (values.mark && this.props.marks.includes(values.mark.toUpperCase())) {
      errors.mark = 'Mark is already in use';
    }
    return errors;
  };

  updateCacheOnCreate = (cache, { data: { systemCreate } }) => {
    const { estimateId } = this.props;
    const data = cache.readQuery({
      query: ESTIMATE_SYSTEMS,
      variables: { id: estimateId },
    });
    data.estimate.systems = [systemCreate].concat(data.estimate.systems);
    cache.writeQuery({
      query: ESTIMATE_SYSTEMS,
      variables: { id: estimateId },
      data,
    });
  };

  // TODO: Pretty sure there's a mechanism to do this simple update automatically
  updateCacheOnUpdate = (cache, { data: { systemUpdate } }) => {
    const data = cache.readQuery({
      query: SYSTEM,
      variables: { id: systemUpdate.id },
    });
    data.system = systemUpdate;
    cache.writeQuery({
      query: SYSTEM,
      variables: { id: systemUpdate.id },
      data,
    });
  };

  goBack = () => {
    const { history, estimateId } = this.props;
    history.push(`/estimates/${estimateId}`);
  };

  render() {
    const { currentUser } = this.context;
    const { systemId, estimateId, glassCompositions, markups } = this.props;
    const filteredGlassCompositions = currentUser.internal
      ? glassCompositions
      : glassCompositions.filter(({ glassOnly }) => glassOnly);
    const glassCompositionId =
      filteredGlassCompositions.length && filteredGlassCompositions[0].id;

    return (
      <Query query={PRICES_BY_SYSTEM_TYPE} variables={{ systemType: 'glass' }}>
        {({ loading, data, error }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error</p>;

          return systemId ? (
            <SystemQuery
              systemId={systemId}
              updateCache={this.updateCacheOnUpdate}
              onCompleted={this.goBack}
              validate={this.validate}
              goBack={this.goBack}
              initialValues={{ estimateId }}
              glassCompositions={glassCompositions}
              markups={markups}
              prices={data.pricesBySystemType}
            />
          ) : (
            <SystemMutation
              formId="create-system-form"
              mutation={SYSTEM_CREATE}
              initialValues={{
                type: 'glass',
                qty: 1,
                estimateId,
                glassCompositionId,
                price: currency(0),
              }}
              updateCache={this.updateCacheOnCreate}
              onCompleted={this.goBack}
              validate={this.validate}
              goBack={this.goBack}
              glassCompositions={filteredGlassCompositions}
              markups={markups}
              prices={data.pricesBySystemType}
              autoPrice={true}
            />
          );
        }}
      </Query>
    );
  }
}

GlassForm.propTypes = {
  onCompleted: PropTypes.func.isRequired,
};

export default withRouter(GlassForm);
