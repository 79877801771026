import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "react-apollo";
import bootbox from "bootbox";
import { PRICES } from "../../../queries";
import { PRICE_DELETE } from "../../../mutations";

const update = (cache, { data: { priceDelete } }) => {
  const { prices } = cache.readQuery({ query: PRICES });
  cache.writeQuery({
    query: PRICES,
    data: { prices: prices.filter(price => price.id !== priceDelete.id) }
  });
};

export default ({ priceId }) => {
  const [deletePrice, { loading }] = useMutation(PRICE_DELETE, { update });

  return (
    <div className="dropdown float-right mr-3">
      <button
        onClick={e => e.stopPropagation()}
        className="btn btn-link text-secondary p-0"
        type="button"
        id={`estimate-options-menu-${priceId}`}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <FontAwesomeIcon icon={["fal", "ellipsis-h-alt"]} />
      </button>
      <div
        className="dropdown-menu"
        aria-labelledby={`estimate-options-menu-${priceId}`}
      >
        <button
          disabled={loading}
          className="dropdown-item"
          onClick={e => {
            e.stopPropagation();
            bootbox.confirm("Are you sure?", r => {
              r && deletePrice({ variables: { id: priceId } });
            });
          }}
        >
          <FontAwesomeIcon icon={["fal", "trash-alt"]} className="mr-3" />
          Delete
        </button>
      </div>
    </div>
  );
};
