import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { sgd as draw } from '../../../draw';

const Drawing = ({ formContext: { control }, two, twoElem }) => {
  const [config, width, height] = useWatch({
    control: control,
    name: ['config', 'width', 'height'],
  });

  useEffect(
    () => {
      if (two && width && height) {
        const viewBox = draw({
          two,
          config,
          width: parseFloat(width),
          height: parseFloat(height),
        });
        twoElem.children[0].setAttribute('viewBox', viewBox.join(' '));
      }
    },
    [two, config, width, height]
  );

  return <div id="drawing-container" className="flex-grow-1" />;
};

Drawing.propTypes = {
  formContext: PropTypes.shape({ control: PropTypes.object.isRequired })
    .isRequired,
};

export default Drawing;
