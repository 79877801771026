import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Modal, Button } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import { USER_INVITE, USER_UPDATE } from '../../../mutations';
import { CLIENTS_LIST, USERS_AND_INVITES } from '../../../queries';
import ValidatedField from '../../general/ValidatedField';
import ValidatedSelect from '../../general/ValidatedSelect';
import Checkbox from '../../general/Checkbox';

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

const validate = (values) => {
  const errors = {};
  const required = ['email'];
  required.forEach((r) => !values[r] && (errors[r] = 'Required'));
  return errors;
};

const internalCalculator = createDecorator({
  field: 'internal',
  updates: {
    admin: (internal, { admin }) => (internal ? admin : internal),
    clientId: (internal, { clientId }) => (internal ? null : clientId),
  },
});

export default ({ show, handleClose, user }) => {
  const { data } = useQuery(CLIENTS_LIST);
  const [invite, { loading, called, error }] = useMutation(
    user ? USER_UPDATE : USER_INVITE,
    { refetchQueries: [{ query: USERS_AND_INVITES }] }
  );

  useEffect(
    () => {
      if (called && !loading && !error) handleClose();
    },
    [loading, called, handleClose]
  );

  const { clients } = data || { clients: [] };
  const sortedClients = clients.sort((a, b) => a.name.localeCompare(b.name));
  const initialValues = user
    ? {
        ...user,
        ...(user.client && { clientId: user.client.id }),
        locked: !!user.lockedAt,
      }
    : { internal: true };

  const clientIdCalculator = createDecorator({
    field: 'clientId',
    updates: {
      email: (clientId, { email }) =>
        clientId
          ? sortedClients.find(({ id }) => id === clientId).email
          : email,
    },
  });

  return (
    <Modal show={show} onHide={handleClose}>
      <Form
        validate={validate}
        onSubmit={({ locked, ...variables }) =>
          invite({
            variables: { ...variables, lockedAt: locked ? new Date() : null },
          })
        }
        initialValues={initialValues}
        decorators={[internalCalculator, clientIdCalculator]}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{user ? 'Update' : 'Invite'} User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {user && (
                <Field name="id">
                  {(input) => <input {...input} type="hidden" />}
                </Field>
              )}
              <Checkbox name="internal" label="Internal" />
              <Condition when="internal" is={true}>
                <Checkbox name="admin" label="Admin" />
              </Condition>
              <Condition when="internal" is={false}>
                <ValidatedSelect name="clientId" label="Client">
                  <option value="" disabled>
                    Select a client...
                  </option>
                  {sortedClients.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </ValidatedSelect>
              </Condition>
              <ValidatedField name="email" label="Email" type="email" />
              {!!user && <Checkbox name="locked" label="Locked" />}
              {error && (
                <div className="alert alert-danger mb-0">
                  {error.graphQLErrors
                    .map(({ message }) => message)
                    .map((e, i) => (
                      <div key={i}>{e}</div>
                    ))}
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                {user ? 'Save' : 'Invite'}
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Form>
    </Modal>
  );
};
