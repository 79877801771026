import { inferMissileImpact } from "../util";

const defaultLoads = { pos: 0, neg: 0 };

export default (tables, width, height, glass, config) => {
  const impact = inferMissileImpact(glass);
  const { heatTreatment, thickness } = glass;

  if (!impact || !heatTreatment || !thickness || !width || !height)
    return defaultLoads;

  const table = config
    ? tables[impact][config][heatTreatment][thickness]
    : tables[impact][heatTreatment][thickness];

  return (
    table.find(line => height <= line.height && width <= line.width) ||
    defaultLoads
  );
};
