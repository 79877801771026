import React from 'react';
import { useQuery } from 'react-apollo';
import { useLocation } from 'react-router-dom';
import { ESTIMATES_LIST } from '../../queries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../general/Modal';
import $ from 'jquery';
import EstimatesList from './EstimatesList';
import EstimateForm from './EstimateForm';
import Pagination from '../general/Pagination';
import { pageParams, PAGE_SIZE } from '../../util';

const onEstimateFormCompleted = (form) => {
  $('#new-estimate-modal').modal('hide');
  setTimeout(form.reset);
};

const submitEstimateForm = (id) => {
  document
    .getElementById(id)
    .dispatchEvent(new Event('submit', { cancelable: true }));
};

const Estimates = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { data, loading, error } = useQuery(ESTIMATES_LIST, {
    fetchPolicy: 'network-only',
    variables: pageParams(params.get('page') || 1),
  });

  if (!data && loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  const { estimates, tableInfo } = data;

  return (
    <div className="row flex-grow-1">
      <div className="col-sm-3">
        <h3>Current Estimates</h3>
        <h6 className="text-muted">{tableInfo.count} estimates</h6>
        <button
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#new-estimate-modal"
        >
          Add Estimate{' '}
          <FontAwesomeIcon icon={['fal', 'plus']} className="ml-2" />
        </button>
      </div>
      <div className="col-sm-9 position-relative">
        <EstimatesList estimates={estimates} />
        <div className="estimates-pagination">
          <Pagination totalItems={tableInfo.count} pageSize={PAGE_SIZE} />
        </div>
      </div>
      <Modal
        modalId="new-estimate-modal"
        title="New Estimate"
        onContinue={() => submitEstimateForm('create-estimate-form')}
      >
        <EstimateForm onCompleted={onEstimateFormCompleted} />
      </Modal>
    </div>
  );
};

export default Estimates;
