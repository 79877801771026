import React from "react";
import { Field } from "react-final-form";
import classNames from "classnames";

export default ({ name, placeholder }) => (
  <Field name={name}>
    {({ input, meta }) => (
      <div>
        <input
          {...input}
          type="number"
          placeholder={placeholder}
          className={classNames("form-control", {
            "is-invalid": meta.error && meta.touched
          })}
        />
        {meta.error && meta.touched && (
          <div className="invalid-feedback">{meta.error}</div>
        )}
      </div>
    )}
  </Field>
);
