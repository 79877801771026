import currency from 'currency.js';
import calcFwMeasurements from '../calcFwMeasurements';
import { getGlassMarkup, getShapeMarkup, getSystemMarkups } from './markups';
import { getPrice } from './prices';

export default ({
  type,
  compositions,
  markups,
  prices,
  autoPrice,
  price,
  glassCompositionId,
  width,
  lHeight,
  rHeight,
  shape, // fw only
}) => {
  if (!autoPrice) return price;

  let dim = { width, lHeight, rHeight };
  let shapeMarkup = 0;

  // start fw_only
  if (type === 'fw') {
    if (
      !width ||
      (['rectangle', 'fan'].includes(shape) && !lHeight) ||
      (['trapezoid', 'eyebrow'].includes(shape) && !(lHeight && rHeight)) ||
      !glassCompositionId
    )
      return currency(0);

    dim = calcFwMeasurements({
      shape,
      width,
      lHeight,
      rHeight,
    });

    shapeMarkup = getShapeMarkup(shape, markups);
  }
  // end fw_only

  const glassMarkup = getGlassMarkup(glassCompositionId, compositions);
  const systemMarkup = getSystemMarkups(type, markups);
  let result = getPrice(prices, dim.width, Math.max(dim.lHeight, dim.rHeight));

  if (glassMarkup && glassMarkup !== 0)
    result = result.add(result.multiply(glassMarkup / 100));

  if (systemMarkup && systemMarkup !== 0)
    result = result.add(result.multiply(systemMarkup / 100));

  if (shapeMarkup && shapeMarkup !== 0)
    result = result.add(result.multiply(shapeMarkup / 100));

  return result;
};
