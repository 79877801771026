import gql from 'graphql-tag';

export const ESTIMATE_CREATE = gql`
  mutation EstimateCreate(
    $clientId: ID!
    $projectId: ID
    $name: String!
    $markup: Float!
    $tax: Float!
    $metaClientId: ID
    $metaMarkup: Float
    $metaTax: Float
    $etc: DateTime
  ) {
    estimateCreate(
      clientId: $clientId
      projectId: $projectId
      name: $name
      markup: $markup
      tax: $tax
      metaClientId: $metaClientId
      metaMarkup: $metaMarkup
      metaTax: $metaTax
      etc: $etc
    ) {
      id
      name
      markup
      tax
      orderedAt
      clientOrderedAt
      sellPrice
      taxes
      sellPriceTaxes
      insertedAt
      metaMarkup
      metaTax
      etc
      client {
        id
        name
        email
        phone
        address
        city
        state
        zip
        logoPath
      }
      metaClient {
        id
        name
        email
        phone
        address
        city
        state
        zip
        logoPath
      }
      project {
        id
        name
      }
    }
  }
`;

export const ESTIMATE_UPDATE = gql`
  mutation EstimateUpdate(
    $id: ID!
    $clientId: ID
    $projectId: ID
    $name: String
    $markup: Float
    $tax: Float
    $orderedAt: DateTime
    $clientOrderedAt: DateTime
    $metaClientId: ID
    $metaMarkup: Float
    $metaTax: Float
    $etc: DateTime
  ) {
    estimateUpdate(
      id: $id
      clientId: $clientId
      projectId: $projectId
      name: $name
      markup: $markup
      tax: $tax
      orderedAt: $orderedAt
      clientOrderedAt: $clientOrderedAt
      metaClientId: $metaClientId
      metaMarkup: $metaMarkup
      metaTax: $metaTax
      etc: $etc
    ) {
      id
      name
      markup
      tax
      orderedAt
      clientOrderedAt
      sellPrice
      taxes
      sellPriceTaxes
      insertedAt
      metaMarkup
      metaTax
      etc
      client {
        id
        name
        email
        phone
        address
        city
        state
        zip
        logoPath
      }
      metaClient {
        id
        name
        email
        phone
        address
        city
        state
        zip
        logoPath
      }
      project {
        id
        name
      }
    }
  }
`;

export const ESTIMATE_COPY = gql`
  mutation EstimateCopy($id: ID!) {
    estimateCopy(id: $id) {
      id
    }
  }
`;

export const ESTIMATE_DELETE = gql`
  mutation EstimateDelete($id: ID!) {
    estimateDelete(id: $id) {
      id
    }
  }
`;
