import { quotation } from '../quotation';
import Fraction from 'fraction.js';
import { toNearest } from '../../util';

const toFraction = (x) => new Fraction(toNearest(0.0625)(x)).toFraction(true);
const WIDTH = 1.93;

export const halfCircle = (two, radius) => {
  if (!(two && radius >= 5)) return;

  const bottom = Math.sqrt(Math.pow(radius - WIDTH, 2) - Math.pow(WIDTH, 2));
  const group = two.makeGroup([
    two.makeArcSegment(radius, radius, radius, 0, Math.PI, Math.PI * 2),
    two.makeArcSegment(radius, radius, radius - WIDTH, 0, Math.PI, Math.PI * 2),
    two.makeLine(
      radius - bottom,
      radius - WIDTH,
      radius + bottom,
      radius - WIDTH
    ),
  ]);

  group.linewidth = 0.3;

  quotation({
    two,
    length: radius,
    dir: 'vertical',
    pos: 'down',
    x: radius,
    separation: radius + 5,
    label: `${toFraction(radius)}"`,
  });

  quotation({
    two,
    length: radius * 2,
    pos: 'down',
    y: radius,
    label: `${toFraction(radius * 2)}"`,
  });

  return [-3, -3, radius * 2 + 12, radius + 6 + 10];
};
