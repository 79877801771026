import React from 'react'
import moment from 'moment'
import ProjectBox from './ProjectBox'

export default ({ selectProject, projects }) => (
    <div className="row">
      {projects.sort((a, b) => moment(a.insertedAt).diff(b.insertedAt)).map(project => (
        <div className="col-sm-4 mb-3" key={project.id}>
          <ProjectBox project={project} selectProject={selectProject} />
        </div>
      ))}
    </div>
)