import gql from "graphql-tag";

export const VENDOR = gql`
  query Vendor($id: ID!) {
    vendor(id: $id) {
      id
      name
      street
      city
      state
      zip
      phone
      fax
      email
    }
  }
`;

export const VENDOR_LIST = gql`
  query VendorList($limit: Int, $offset: Int) {
    vendors(limit: $limit, offset: $offset) {
      id
      name
      street
      city
      state
      zip
      phone
      fax
      email
    }
    tableInfo(name: "vendors") {
      count
    }
  }
`;

export const VENDORS_NAMES = gql`
  query VendorsNames {
    vendors {
      id
      name
    }
  }
`;
