import { useState, useEffect } from 'react';
import Two from 'two.js';

export const useTwoJs = (opts = { width: '100%', height: '100%' }) => {
  const [twoState, setTwoState] = useState({});

  useEffect(() => {
    const twoElem = document.getElementById('drawing-container');
    const two = new Two(opts).appendTo(twoElem);
    setTwoState({ two, twoElem });
  }, []);

  const getImage = (fileName) => {
    const svg = twoState.twoElem.children[0];
    const data = new XMLSerializer().serializeToString(svg);
    return new File([data], fileName, { type: 'image/svg+xml;charset=utf-8' });
  };

  return { ...twoState, getImage };
};
