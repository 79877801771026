import currency from 'currency.js';
import { useQuery } from 'react-apollo';
import { SYSTEM } from '../../../../queries';
import { LEFT_LITE, RIGHT_LITE } from '../constants';

export const getFieldValues = (system) => {
  const config = system.config.toString();
  const glass = system.verticals[0].glass[0];
  const values = {
    id: system.id,
    mark: system.mark,
    qty: system.qty,
    config: config,
    frameColorId: system.frameColor.id,
    autoPrice: system.autoPrice,
    ...(!!glass && {
      glassCompositionId: glass.glassComposition.id,
      glassHeatTreatment: glass.glassComposition.heatTreatment,
      glassType: glass.glassComposition.type,
      glassThickness: glass.glassComposition.thickness,
      glassInterlayer: glass.glassComposition.interlayer,
    }),
  };

  // left lite
  if (LEFT_LITE.includes(config)) {
    const left = system.verticals.find(({ index }) => index === 0);

    values.leftWidth = left.width;
    values.litePrice = currency(left.priceSqft);
  }

  // right lite
  if (RIGHT_LITE.includes(config)) {
    const right = system.verticals.find(
      ({ index }) => index === (LEFT_LITE.includes(config) ? 2 : 1)
    );
    values.rightWidth = right.width;
    values.litePrice = currency(right.priceSqft);
  }

  // door
  const door = system.verticals.find(
    ({ index }) => index === (LEFT_LITE.includes(config) ? 1 : 0)
  );
  values.doorWidth = door.width;
  values.height = door.lHeight;
  values.doorPrice = currency(door.priceSqft);

  // details
  ['hinges', 'active', 'swing'].forEach((possibleType) => {
    const detail = system.details.find(({ type }) => type === possibleType);
    if (detail) values[possibleType] = detail.desc;
  });

  return values;
};

const onCompleted = (system, formContext) => {
  const { reset, getValues } = formContext;
  const values = getFieldValues(system);

  reset({ ...getValues(), ...values });
};

export const useInitialValues = ({ systemId, formContext }) => {
  useQuery(SYSTEM, {
    variables: { id: systemId },
    skip: !systemId,
    onCompleted: ({ system }) => onCompleted(system, formContext),
  });
};
