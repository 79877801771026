const ANNEALED_1_4 = [
  { height: 23, width: 23, pos: 70, neg: 70 },
  { height: 23, width: 47, pos: 70, neg: 70 },
  { height: 23, width: 71, pos: 70, neg: 70 },
  { height: 23, width: 83, pos: 70, neg: 70 },
  { height: 23, width: 95, pos: 70, neg: 70 },
  { height: 23, width: 107, pos: 70, neg: 70 },

  { height: 25, width: 25.5, pos: 70, neg: 70 },
  { height: 25, width: 36, pos: 70, neg: 70 },
  { height: 25, width: 52.125, pos: 70, neg: 70 },
  { height: 25, width: 73, pos: 70, neg: 70 },
  { height: 25, width: 75.125, pos: 70, neg: 70 },
  { height: 25, width: 105.25, pos: 70, neg: 70 },
  { height: 25, width: 110, pos: 70, neg: 70 },

  { height: 35, width: 23, pos: 70, neg: 70 },
  { height: 35, width: 47, pos: 70, neg: 70 },
  { height: 35, width: 71, pos: 70, neg: 70 },
  { height: 35, width: 83, pos: 70, neg: 70 },
  { height: 35, width: 95, pos: 70, neg: 70 },
  { height: 35, width: 107, pos: 70, neg: 70 },

  { height: 37.375, width: 25.5, pos: 70, neg: 70 },
  { height: 37.375, width: 36, pos: 70, neg: 70 },
  { height: 37.375, width: 52.125, pos: 70, neg: 70 },
  { height: 37.375, width: 73, pos: 70, neg: 70 },
  { height: 37.375, width: 75.125, pos: 70, neg: 70 },
  { height: 37.375, width: 105.25, pos: 70, neg: 70 },
  { height: 37.375, width: 110, pos: 70, neg: 70 },

  { height: 47, width: 23, pos: 70, neg: 70 },
  { height: 47, width: 47, pos: 70, neg: 70 },
  { height: 47, width: 71, pos: 70, neg: 70 },
  { height: 47, width: 83, pos: 70, neg: 70 },
  { height: 47, width: 95, pos: 70, neg: 70 },

  { height: 49.625, width: 25.5, pos: 70, neg: 70 },
  { height: 49.625, width: 36, pos: 70, neg: 70 },
  { height: 49.625, width: 52.125, pos: 70, neg: 70 },
  { height: 49.625, width: 73, pos: 70, neg: 70 },
  { height: 49.625, width: 75.125, pos: 70, neg: 70 },

  { height: 55, width: 25.5, pos: 70, neg: 70 },
  { height: 55, width: 36, pos: 70, neg: 70 },
  { height: 55, width: 52.125, pos: 70, neg: 70 },
  { height: 55, width: 73, pos: 70, neg: 70 },
  { height: 55, width: 75.125, pos: 70, neg: 70 },

  { height: 59, width: 23, pos: 70, neg: 70 },
  { height: 59, width: 47, pos: 70, neg: 70 },
  { height: 59, width: 71, pos: 70, neg: 70 },

  { height: 62, width: 25.5, pos: 70, neg: 70 },
  { height: 62, width: 36, pos: 70, neg: 70 },
  { height: 62, width: 52.125, pos: 70, neg: 70 },
  { height: 62, width: 73, pos: 70, neg: 70 },
  { height: 62, width: 75.125, pos: 70, neg: 70 },
];

const _5_16_ANNEALED_1_4 = [
  { height: 23, width: 23, pos: 55, neg: 55 },
  { height: 23, width: 47, pos: 55, neg: 55 },
  { height: 23, width: 71, pos: 55, neg: 55 },
  { height: 23, width: 83, pos: 55, neg: 55 },
  { height: 23, width: 95, pos: 55, neg: 55 },
  { height: 23, width: 107, pos: 55, neg: 55 },

  { height: 25, width: 25.5, pos: 55, neg: 55 },
  { height: 25, width: 36, pos: 55, neg: 55 },
  { height: 25, width: 52.125, pos: 55, neg: 55 },
  { height: 25, width: 73, pos: 55, neg: 55 },
  { height: 25, width: 75.125, pos: 55, neg: 55 },
  { height: 25, width: 105.25, pos: 55, neg: 55 },
  { height: 25, width: 110, pos: 55, neg: 55 },

  { height: 35, width: 23, pos: 55, neg: 55 },
  { height: 35, width: 47, pos: 55, neg: 55 },
  { height: 35, width: 71, pos: 55, neg: 55 },
  { height: 35, width: 83, pos: 55, neg: 55 },
  { height: 35, width: 95, pos: 55, neg: 55 },
  { height: 35, width: 107, pos: 55, neg: 55 },

  { height: 37.375, width: 25.5, pos: 55, neg: 55 },
  { height: 37.375, width: 36, pos: 55, neg: 55 },
  { height: 37.375, width: 52.125, pos: 55, neg: 55 },
  { height: 37.375, width: 73, pos: 55, neg: 55 },
  { height: 37.375, width: 75.125, pos: 55, neg: 55 },
  { height: 37.375, width: 105.25, pos: 55, neg: 55 },
  { height: 37.375, width: 110, pos: 55, neg: 55 },

  { height: 47, width: 23, pos: 55, neg: 55 },
  { height: 47, width: 47, pos: 55, neg: 55 },
  { height: 47, width: 71, pos: 55, neg: 55 },
  { height: 47, width: 83, pos: 55, neg: 55 },
  { height: 47, width: 95, pos: 55, neg: 55 },

  { height: 49.625, width: 25.5, pos: 55, neg: 55 },
  { height: 49.625, width: 36, pos: 55, neg: 55 },
  { height: 49.625, width: 52.125, pos: 55, neg: 55 },
  { height: 49.625, width: 73, pos: 55, neg: 55 },
  { height: 49.625, width: 75.125, pos: 55, neg: 55 },

  { height: 55, width: 25.5, pos: 55, neg: 55 },
  { height: 55, width: 36, pos: 55, neg: 55 },
  { height: 55, width: 52.125, pos: 55, neg: 55 },
  { height: 55, width: 73, pos: 55, neg: 55 },
  { height: 55, width: 75.125, pos: 55, neg: 55 },

  { height: 59, width: 23, pos: 55, neg: 55 },
  { height: 59, width: 47, pos: 55, neg: 55 },
  { height: 59, width: 71, pos: 55, neg: 55 },

  { height: 62, width: 25.5, pos: 55, neg: 55 },
  { height: 62, width: 36, pos: 55, neg: 55 },
  { height: 62, width: 52.125, pos: 55, neg: 55 },
  { height: 62, width: 73, pos: 55, neg: 55 },
  { height: 62, width: 75.125, pos: 55, neg: 55 },
];

const HEAT_STRENGTHENED_1_4 = [
  { height: 23, width: 23, pos: 80, neg: 95 },
  { height: 23, width: 47, pos: 80, neg: 95 },
  { height: 23, width: 71, pos: 80, neg: 95 },
  { height: 23, width: 83, pos: 80, neg: 95 },
  { height: 23, width: 95, pos: 80, neg: 95 },
  { height: 23, width: 107, pos: 80, neg: 95 },

  { height: 25, width: 25.5, pos: 80, neg: 95 },
  { height: 25, width: 36, pos: 80, neg: 95 },
  { height: 25, width: 52.125, pos: 80, neg: 95 },
  { height: 25, width: 73, pos: 80, neg: 95 },
  { height: 25, width: 75.125, pos: 80, neg: 95 },
  { height: 25, width: 105.25, pos: 80, neg: 95 },
  { height: 25, width: 110, pos: 80, neg: 95 },

  { height: 35, width: 23, pos: 80, neg: 95 },
  { height: 35, width: 47, pos: 80, neg: 95 },
  { height: 35, width: 71, pos: 80, neg: 95 },
  { height: 35, width: 83, pos: 80, neg: 95 },
  { height: 35, width: 95, pos: 80, neg: 95 },
  { height: 35, width: 107, pos: 80, neg: 95 },

  { height: 37.375, width: 25.5, pos: 80, neg: 95 },
  { height: 37.375, width: 36, pos: 80, neg: 95 },
  { height: 37.375, width: 52.125, pos: 80, neg: 95 },
  { height: 37.375, width: 73, pos: 80, neg: 95 },
  { height: 37.375, width: 75.125, pos: 80, neg: 95 },
  { height: 37.375, width: 105.25, pos: 80, neg: 95 },
  { height: 37.375, width: 110, pos: 80, neg: 95 },

  { height: 47, width: 23, pos: 80, neg: 95 },
  { height: 47, width: 47, pos: 80, neg: 95 },
  { height: 47, width: 71, pos: 80, neg: 95 },
  { height: 47, width: 83, pos: 80, neg: 95 },
  { height: 47, width: 95, pos: 80, neg: 95 },
  { height: 47, width: 107, pos: 80, neg: 95 },

  { height: 49.625, width: 25.5, pos: 80, neg: 95 },
  { height: 49.625, width: 36, pos: 80, neg: 95 },
  { height: 49.625, width: 52.125, pos: 80, neg: 95 },
  { height: 49.625, width: 73, pos: 80, neg: 95 },
  { height: 49.625, width: 75.125, pos: 80, neg: 95 },
  { height: 49.625, width: 105.25, pos: 80, neg: 95 },
  { height: 49.625, width: 110, pos: 80, neg: 95 },

  { height: 55, width: 25.5, pos: 80, neg: 95 },
  { height: 55, width: 36, pos: 80, neg: 95 },
  { height: 55, width: 52.125, pos: 80, neg: 95 },
  { height: 55, width: 73, pos: 80, neg: 95 },
  { height: 55, width: 75.125, pos: 80, neg: 95 },
  { height: 55, width: 105.25, pos: 80, neg: 95 },
  { height: 55, width: 110, pos: 80, neg: 95 },

  { height: 59, width: 23, pos: 80, neg: 95 },
  { height: 59, width: 47, pos: 80, neg: 95 },
  { height: 59, width: 71, pos: 80, neg: 95 },
  { height: 59, width: 83, pos: 80, neg: 95 },
  { height: 59, width: 95, pos: 80, neg: 90.8 },
  { height: 59, width: 107, pos: 80, neg: 87.1 },

  { height: 62, width: 25.5, pos: 80, neg: 95 },
  { height: 62, width: 36, pos: 80, neg: 95 },
  { height: 62, width: 52.125, pos: 80, neg: 95 },
  { height: 62, width: 73, pos: 80, neg: 94.6 },
  { height: 62, width: 75.125, pos: 80, neg: 92.7 },
  { height: 62, width: 105.25, pos: 76.6, neg: 76.6 },
];

const ANNEALED_2 = [
  { height: 23, width: 71, pos: 70, neg: 70 },
  { height: 23, width: 83, pos: 70, neg: 70 },
  { height: 23, width: 95, pos: 70, neg: 70 },
  { height: 23, width: 107, pos: 70, neg: 70 },
  { height: 23, width: 119, pos: 70, neg: 70 },
  { height: 23, width: 131, pos: 70, neg: 70 },
  { height: 23, width: 143, pos: 70, neg: 70 },
  { height: 23, width: 155, pos: 70, neg: 70 },

  { height: 25, width: 73, pos: 70, neg: 70 },
  { height: 25, width: 105.25, pos: 70, neg: 70 },
  { height: 25, width: 110, pos: 70, neg: 70 },
  { height: 25, width: 111, pos: 70, neg: 70 },

  { height: 35, width: 71, pos: 70, neg: 70 },
  { height: 35, width: 83, pos: 70, neg: 70 },
  { height: 35, width: 95, pos: 70, neg: 70 },
  { height: 35, width: 107, pos: 70, neg: 70 },
  { height: 35, width: 119, pos: 70, neg: 70 },
  { height: 35, width: 131, pos: 70, neg: 70 },
  { height: 35, width: 143, pos: 70, neg: 70 },
  { height: 35, width: 155, pos: 70, neg: 70 },

  { height: 37.375, width: 73, pos: 70, neg: 70 },
  { height: 37.375, width: 105.25, pos: 70, neg: 70 },
  { height: 37.375, width: 110, pos: 70, neg: 70 },
  { height: 37.375, width: 111, pos: 70, neg: 70 },

  { height: 47, width: 71, pos: 70, neg: 70 },
  { height: 47, width: 83, pos: 70, neg: 70 },
  { height: 47, width: 95, pos: 70, neg: 70 },
  { height: 47, width: 107, pos: 70, neg: 70 },
  { height: 47, width: 119, pos: 70, neg: 70 },
  { height: 47, width: 131, pos: 70, neg: 70 },
  { height: 47, width: 143, pos: 70, neg: 70 },

  { height: 49.625, width: 73, pos: 70, neg: 70 },
  { height: 49.625, width: 105.25, pos: 70, neg: 70 },
  { height: 49.625, width: 110, pos: 70, neg: 70 },
  { height: 49.625, width: 111, pos: 70, neg: 70 },

  { height: 55, width: 73, pos: 70, neg: 70 },
  { height: 55, width: 105.25, pos: 70, neg: 70 },
  { height: 55, width: 110, pos: 70, neg: 70 },
  { height: 55, width: 111, pos: 70, neg: 70 },

  { height: 59, width: 71, pos: 70, neg: 70 },
  { height: 59, width: 83, pos: 70, neg: 70 },
  { height: 59, width: 95, pos: 70, neg: 70 },
  { height: 59, width: 107, pos: 70, neg: 70 },

  { height: 62, width: 73, pos: 70, neg: 70 },
  { height: 62, width: 105.25, pos: 70, neg: 70 },
  { height: 62, width: 110, pos: 70, neg: 70 },
  { height: 62, width: 111, pos: 70, neg: 70 },
];

const _5_16_ANNEALED_2 = [
  { height: 23, width: 71, pos: 55, neg: 55 },
  { height: 23, width: 83, pos: 55, neg: 55 },
  { height: 23, width: 95, pos: 55, neg: 55 },
  { height: 23, width: 107, pos: 55, neg: 55 },
  { height: 23, width: 119, pos: 55, neg: 55 },
  { height: 23, width: 131, pos: 55, neg: 55 },
  { height: 23, width: 143, pos: 55, neg: 55 },
  { height: 23, width: 155, pos: 55, neg: 55 },

  { height: 25, width: 73, pos: 55, neg: 55 },
  { height: 25, width: 105.25, pos: 55, neg: 55 },
  { height: 25, width: 110, pos: 55, neg: 55 },
  { height: 25, width: 111, pos: 55, neg: 55 },

  { height: 35, width: 71, pos: 55, neg: 55 },
  { height: 35, width: 83, pos: 55, neg: 55 },
  { height: 35, width: 95, pos: 55, neg: 55 },
  { height: 35, width: 107, pos: 55, neg: 55 },
  { height: 35, width: 119, pos: 55, neg: 55 },
  { height: 35, width: 131, pos: 55, neg: 55 },
  { height: 35, width: 143, pos: 55, neg: 55 },
  { height: 35, width: 155, pos: 55, neg: 55 },

  { height: 37.375, width: 73, pos: 55, neg: 55 },
  { height: 37.375, width: 105.25, pos: 55, neg: 55 },
  { height: 37.375, width: 110, pos: 55, neg: 55 },
  { height: 37.375, width: 111, pos: 55, neg: 55 },

  { height: 47, width: 71, pos: 55, neg: 55 },
  { height: 47, width: 83, pos: 55, neg: 55 },
  { height: 47, width: 95, pos: 55, neg: 55 },
  { height: 47, width: 107, pos: 55, neg: 55 },
  { height: 47, width: 119, pos: 55, neg: 55 },
  { height: 47, width: 131, pos: 55, neg: 55 },
  { height: 47, width: 143, pos: 55, neg: 55 },

  { height: 49.625, width: 73, pos: 55, neg: 55 },
  { height: 49.625, width: 105.25, pos: 55, neg: 55 },
  { height: 49.625, width: 110, pos: 55, neg: 55 },
  { height: 49.625, width: 111, pos: 55, neg: 55 },

  { height: 55, width: 73, pos: 55, neg: 55 },
  { height: 55, width: 105.25, pos: 55, neg: 55 },
  { height: 55, width: 110, pos: 55, neg: 55 },
  { height: 55, width: 111, pos: 55, neg: 55 },

  { height: 59, width: 71, pos: 55, neg: 55 },
  { height: 59, width: 83, pos: 55, neg: 55 },
  { height: 59, width: 95, pos: 55, neg: 55 },
  { height: 59, width: 107, pos: 55, neg: 55 },

  { height: 62, width: 73, pos: 55, neg: 55 },
  { height: 62, width: 105.25, pos: 55, neg: 55 },
  { height: 62, width: 110, pos: 55, neg: 55 },
  { height: 62, width: 111, pos: 55, neg: 55 },
];

const HEAT_STRENGTHENED_2 = [
  { height: 23, width: 71, pos: 80, neg: 95 },
  { height: 23, width: 83, pos: 80, neg: 95 },
  { height: 23, width: 95, pos: 80, neg: 95 },
  { height: 23, width: 107, pos: 80, neg: 95 },
  { height: 23, width: 119, pos: 80, neg: 95 },
  { height: 23, width: 131, pos: 80, neg: 95 },
  { height: 23, width: 143, pos: 80, neg: 95 },
  { height: 23, width: 155, pos: 80, neg: 95 },

  { height: 25, width: 73, pos: 80, neg: 95 },
  { height: 25, width: 105.25, pos: 80, neg: 95 },
  { height: 25, width: 110, pos: 80, neg: 95 },
  { height: 25, width: 111, pos: 80, neg: 95 },
  { height: 25, width: 159.5, pos: 80, neg: 95 },

  { height: 35, width: 71, pos: 80, neg: 95 },
  { height: 35, width: 83, pos: 80, neg: 95 },
  { height: 35, width: 95, pos: 80, neg: 95 },
  { height: 35, width: 107, pos: 80, neg: 95 },
  { height: 35, width: 119, pos: 80, neg: 95 },
  { height: 35, width: 131, pos: 80, neg: 95 },
  { height: 35, width: 143, pos: 80, neg: 95 },
  { height: 35, width: 155, pos: 80, neg: 95 },

  { height: 37.375, width: 73, pos: 80, neg: 95 },
  { height: 37.375, width: 105.25, pos: 80, neg: 95 },
  { height: 37.375, width: 110, pos: 80, neg: 95 },
  { height: 37.375, width: 111, pos: 80, neg: 95 },
  { height: 37.375, width: 159.5, pos: 80, neg: 95 },

  { height: 47, width: 71, pos: 80, neg: 95 },
  { height: 47, width: 83, pos: 80, neg: 95 },
  { height: 47, width: 95, pos: 80, neg: 95 },
  { height: 47, width: 107, pos: 80, neg: 95 },
  { height: 47, width: 119, pos: 80, neg: 95 },
  { height: 47, width: 131, pos: 80, neg: 95 },
  { height: 47, width: 143, pos: 80, neg: 95 },
  { height: 47, width: 155, pos: 80, neg: 95 },

  { height: 49.625, width: 73, pos: 80, neg: 95 },
  { height: 49.625, width: 105.25, pos: 80, neg: 95 },
  { height: 49.625, width: 110, pos: 80, neg: 95 },
  { height: 49.625, width: 111, pos: 80, neg: 95 },
  { height: 49.625, width: 159.5, pos: 80, neg: 95 },

  { height: 55, width: 73, pos: 80, neg: 95 },
  { height: 55, width: 105.25, pos: 80, neg: 95 },
  { height: 55, width: 110, pos: 80, neg: 95 },
  { height: 55, width: 111, pos: 80, neg: 95 },
  { height: 55, width: 159.5, pos: 80, neg: 95 },

  { height: 59, width: 71, pos: 80, neg: 95 },
  { height: 59, width: 83, pos: 80, neg: 95 },
  { height: 59, width: 95, pos: 80, neg: 95 },
  { height: 59, width: 107, pos: 80, neg: 95 },
  { height: 59, width: 119, pos: 80, neg: 95 },
  { height: 59, width: 131, pos: 80, neg: 94.6 },
  { height: 59, width: 143, pos: 80, neg: 90.6 },
  { height: 59, width: 155, pos: 80, neg: 87.6 },

  { height: 62, width: 73, pos: 80, neg: 95 },
  { height: 62, width: 105.25, pos: 80, neg: 95 },
  { height: 62, width: 110, pos: 80, neg: 94.3 },
  { height: 62, width: 111, pos: 80, neg: 93.7 },
  { height: 62, width: 159.5, pos: 76.3, neg: 76.3 },
];

const ANNEALED_3 = [
  { height: 23, width: 71, pos: 70, neg: 70 },
  { height: 23, width: 83, pos: 70, neg: 70 },
  { height: 23, width: 95, pos: 70, neg: 70 },
  { height: 23, width: 107, pos: 70, neg: 70 },
  { height: 23, width: 119, pos: 70, neg: 70 },
  { height: 23, width: 131, pos: 70, neg: 70 },
  { height: 23, width: 143, pos: 70, neg: 70 },
  { height: 23, width: 155, pos: 70, neg: 70 },

  { height: 25, width: 73, pos: 70, neg: 70 },
  { height: 25, width: 105.25, pos: 70, neg: 70 },
  { height: 25, width: 110, pos: 70, neg: 70 },
  { height: 25, width: 111, pos: 70, neg: 70 },
  { height: 25, width: 159.5, pos: 70, neg: 70 },

  { height: 35, width: 71, pos: 70, neg: 70 },
  { height: 35, width: 83, pos: 70, neg: 70 },
  { height: 35, width: 95, pos: 70, neg: 70 },
  { height: 35, width: 107, pos: 70, neg: 70 },
  { height: 35, width: 119, pos: 70, neg: 70 },
  { height: 35, width: 131, pos: 70, neg: 70 },

  { height: 37.375, width: 73, pos: 70, neg: 70 },
  { height: 37.375, width: 105.25, pos: 70, neg: 70 },
  { height: 37.375, width: 110, pos: 70, neg: 70 },
  { height: 37.375, width: 111, pos: 70, neg: 70 },

  { height: 47, width: 71, pos: 70, neg: 70 },
  { height: 47, width: 83, pos: 70, neg: 70 },
  { height: 47, width: 95, pos: 70, neg: 70 },

  { height: 49.625, width: 73, pos: 70, neg: 70 },

  { height: 55, width: 73, pos: 70, neg: 70 },

  { height: 59, width: 71, pos: 70, neg: 70 },

  { height: 62, width: 73, pos: 70, neg: 70 },
];

const _5_16_ANNEALED_3 = [
  { height: 23, width: 71, pos: 55, neg: 55 },
  { height: 23, width: 83, pos: 55, neg: 55 },
  { height: 23, width: 95, pos: 55, neg: 55 },
  { height: 23, width: 107, pos: 55, neg: 55 },
  { height: 23, width: 119, pos: 55, neg: 55 },
  { height: 23, width: 131, pos: 55, neg: 55 },
  { height: 23, width: 143, pos: 55, neg: 55 },
  { height: 23, width: 155, pos: 55, neg: 55 },

  { height: 25, width: 73, pos: 55, neg: 55 },
  { height: 25, width: 105.25, pos: 55, neg: 55 },
  { height: 25, width: 110, pos: 55, neg: 55 },
  { height: 25, width: 111, pos: 55, neg: 55 },
  { height: 25, width: 159.5, pos: 55, neg: 55 },

  { height: 35, width: 71, pos: 55, neg: 55 },
  { height: 35, width: 83, pos: 55, neg: 55 },
  { height: 35, width: 95, pos: 55, neg: 55 },
  { height: 35, width: 107, pos: 55, neg: 55 },
  { height: 35, width: 119, pos: 55, neg: 55 },
  { height: 35, width: 131, pos: 55, neg: 55 },

  { height: 37.375, width: 73, pos: 55, neg: 55 },
  { height: 37.375, width: 105.25, pos: 55, neg: 55 },
  { height: 37.375, width: 110, pos: 55, neg: 55 },
  { height: 37.375, width: 111, pos: 55, neg: 55 },

  { height: 47, width: 71, pos: 55, neg: 55 },
  { height: 47, width: 83, pos: 55, neg: 55 },
  { height: 47, width: 95, pos: 55, neg: 55 },

  { height: 49.625, width: 73, pos: 55, neg: 55 },

  { height: 55, width: 73, pos: 55, neg: 55 },

  { height: 59, width: 71, pos: 55, neg: 55 },

  { height: 62, width: 73, pos: 55, neg: 55 },
];

const HEAT_STRENGTHENED_3 = [
  { height: 23, width: 71, pos: 80, neg: 95 },
  { height: 23, width: 83, pos: 80, neg: 95 },
  { height: 23, width: 95, pos: 80, neg: 95 },
  { height: 23, width: 107, pos: 80, neg: 95 },
  { height: 23, width: 119, pos: 80, neg: 95 },
  { height: 23, width: 131, pos: 80, neg: 95 },
  { height: 23, width: 143, pos: 80, neg: 95 },
  { height: 23, width: 155, pos: 80, neg: 95 },

  { height: 25, width: 73, pos: 80, neg: 95 },
  { height: 25, width: 105.25, pos: 80, neg: 95 },
  { height: 25, width: 110, pos: 80, neg: 95 },
  { height: 25, width: 111, pos: 80, neg: 95 },
  { height: 25, width: 159.5, pos: 80, neg: 95 },

  { height: 35, width: 71, pos: 80, neg: 95 },
  { height: 35, width: 83, pos: 80, neg: 95 },
  { height: 35, width: 95, pos: 80, neg: 95 },
  { height: 35, width: 107, pos: 80, neg: 95 },
  { height: 35, width: 119, pos: 80, neg: 95 },
  { height: 35, width: 131, pos: 80, neg: 95 },
  { height: 35, width: 143, pos: 80, neg: 95 },
  { height: 35, width: 155, pos: 80, neg: 95 },

  { height: 37.375, width: 73, pos: 80, neg: 95 },
  { height: 37.375, width: 105.25, pos: 80, neg: 95 },
  { height: 37.375, width: 110, pos: 80, neg: 95 },
  { height: 37.375, width: 111, pos: 80, neg: 95 },
  { height: 37.375, width: 159.5, pos: 80, neg: 95 },

  { height: 47, width: 71, pos: 80, neg: 95 },
  { height: 47, width: 83, pos: 80, neg: 95 },
  { height: 47, width: 95, pos: 80, neg: 95 },
  { height: 47, width: 107, pos: 80, neg: 95 },
  { height: 47, width: 119, pos: 80, neg: 95 },
  { height: 47, width: 131, pos: 80, neg: 95 },

  { height: 49.625, width: 73, pos: 80, neg: 95 },
  { height: 49.625, width: 105.25, pos: 80, neg: 95 },
  { height: 49.625, width: 110, pos: 80, neg: 95 },
  { height: 49.625, width: 111, pos: 80, neg: 95 },

  { height: 55, width: 73, pos: 80, neg: 95 },
  { height: 55, width: 105.25, pos: 80, neg: 95 },
  { height: 55, width: 110, pos: 80, neg: 95 },
  { height: 55, width: 111, pos: 80, neg: 95 },

  { height: 59, width: 71, pos: 80, neg: 95 },
  { height: 59, width: 83, pos: 80, neg: 95 },
  { height: 59, width: 95, pos: 80, neg: 95 },
  { height: 59, width: 107, pos: 80, neg: 95 },

  { height: 62, width: 73, pos: 80, neg: 95 },
  { height: 62, width: 105.25, pos: 80, neg: 95 },
];

const SMI_1_4 = [
  { height: 23, width: 23, pos: 70, neg: 70 },
  { height: 23, width: 47, pos: 70, neg: 70 },
  { height: 23, width: 71, pos: 70, neg: 70 },
  { height: 23, width: 83, pos: 70, neg: 70 },
  { height: 23, width: 95, pos: 70, neg: 70 },
  { height: 23, width: 107, pos: 70, neg: 70 },

  { height: 25, width: 25.5, pos: 70, neg: 70 },
  { height: 25, width: 36, pos: 70, neg: 70 },
  { height: 25, width: 52.125, pos: 70, neg: 70 },
  { height: 25, width: 73, pos: 70, neg: 70 },
  { height: 25, width: 75.125, pos: 70, neg: 70 },
  { height: 25, width: 105.25, pos: 70, neg: 70 },
  { height: 25, width: 110, pos: 70, neg: 70 },

  { height: 35, width: 23, pos: 70, neg: 70 },
  { height: 35, width: 47, pos: 70, neg: 70 },
  { height: 35, width: 71, pos: 70, neg: 70 },
  { height: 35, width: 83, pos: 70, neg: 70 },
  { height: 35, width: 95, pos: 70, neg: 70 },
  { height: 35, width: 107, pos: 70, neg: 70 },

  { height: 37.375, width: 25.5, pos: 70, neg: 70 },
  { height: 37.375, width: 36, pos: 70, neg: 70 },
  { height: 37.375, width: 52.125, pos: 70, neg: 70 },
  { height: 37.375, width: 73, pos: 70, neg: 70 },
  { height: 37.375, width: 75.125, pos: 70, neg: 70 },
  { height: 37.375, width: 105.25, pos: 70, neg: 70 },
  { height: 37.375, width: 110, pos: 70, neg: 70 },

  { height: 47, width: 23, pos: 70, neg: 70 },
  { height: 47, width: 47, pos: 70, neg: 70 },
  { height: 47, width: 71, pos: 70, neg: 70 },
  { height: 47, width: 83, pos: 70, neg: 70 },
  { height: 47, width: 95, pos: 70, neg: 70 },

  { height: 49.625, width: 25.5, pos: 70, neg: 70 },
  { height: 49.625, width: 36, pos: 70, neg: 70 },
  { height: 49.625, width: 52.125, pos: 70, neg: 70 },
  { height: 49.625, width: 73, pos: 70, neg: 70 },
  { height: 49.625, width: 75.125, pos: 70, neg: 70 },

  { height: 55, width: 25.5, pos: 70, neg: 70 },
  { height: 55, width: 36, pos: 70, neg: 70 },
  { height: 55, width: 52.125, pos: 70, neg: 70 },
  { height: 55, width: 73, pos: 70, neg: 70 },
  { height: 55, width: 75.125, pos: 70, neg: 70 },

  { height: 59, width: 23, pos: 70, neg: 70 },
  { height: 59, width: 47, pos: 70, neg: 70 },
  { height: 59, width: 71, pos: 70, neg: 70 },

  { height: 62, width: 25.5, pos: 70, neg: 70 },
  { height: 62, width: 36, pos: 70, neg: 70 },
  { height: 62, width: 52.125, pos: 70, neg: 70 },
  { height: 62, width: 73, pos: 70, neg: 70 },
  { height: 62, width: 75.125, pos: 70, neg: 70 },
];

const SMI_2 = [
  { height: 23, width: 71, pos: 70, neg: 70 },
  { height: 23, width: 83, pos: 70, neg: 70 },
  { height: 23, width: 95, pos: 70, neg: 70 },
  { height: 23, width: 107, pos: 70, neg: 70 },
  { height: 23, width: 119, pos: 70, neg: 70 },
  { height: 23, width: 131, pos: 70, neg: 70 },
  { height: 23, width: 143, pos: 70, neg: 70 },
  { height: 23, width: 155, pos: 70, neg: 70 },

  { height: 25, width: 73, pos: 70, neg: 70 },
  { height: 25, width: 105.25, pos: 70, neg: 70 },
  { height: 25, width: 110, pos: 70, neg: 70 },
  { height: 25, width: 111, pos: 70, neg: 70 },

  { height: 35, width: 71, pos: 70, neg: 70 },
  { height: 35, width: 83, pos: 70, neg: 70 },
  { height: 35, width: 95, pos: 70, neg: 70 },
  { height: 35, width: 107, pos: 70, neg: 70 },
  { height: 35, width: 119, pos: 70, neg: 70 },
  { height: 35, width: 131, pos: 70, neg: 70 },
  { height: 35, width: 143, pos: 70, neg: 70 },
  { height: 35, width: 155, pos: 70, neg: 70 },

  { height: 37.375, width: 73, pos: 70, neg: 70 },
  { height: 37.375, width: 105.25, pos: 70, neg: 70 },
  { height: 37.375, width: 110, pos: 70, neg: 70 },
  { height: 37.375, width: 111, pos: 70, neg: 70 },

  { height: 47, width: 71, pos: 70, neg: 70 },
  { height: 47, width: 83, pos: 70, neg: 70 },
  { height: 47, width: 95, pos: 70, neg: 70 },
  { height: 47, width: 107, pos: 70, neg: 70 },
  { height: 47, width: 119, pos: 70, neg: 70 },
  { height: 47, width: 131, pos: 70, neg: 70 },
  { height: 47, width: 143, pos: 70, neg: 70 },

  { height: 49.625, width: 73, pos: 70, neg: 70 },
  { height: 49.625, width: 105.25, pos: 70, neg: 70 },
  { height: 49.625, width: 110, pos: 70, neg: 70 },
  { height: 49.625, width: 111, pos: 70, neg: 70 },

  { height: 55, width: 73, pos: 70, neg: 70 },
  { height: 55, width: 105.25, pos: 70, neg: 70 },
  { height: 55, width: 110, pos: 70, neg: 70 },
  { height: 55, width: 111, pos: 70, neg: 70 },

  { height: 59, width: 71, pos: 70, neg: 70 },
  { height: 59, width: 83, pos: 70, neg: 70 },
  { height: 59, width: 95, pos: 70, neg: 70 },
  { height: 59, width: 107, pos: 70, neg: 70 },

  { height: 62, width: 73, pos: 70, neg: 70 },
  { height: 62, width: 105.25, pos: 70, neg: 70 },
  { height: 62, width: 110, pos: 70, neg: 70 },
  { height: 62, width: 111, pos: 70, neg: 70 },
];

const SMI_3 = [
  { height: 23, width: 71, pos: 70, neg: 70 },
  { height: 23, width: 83, pos: 70, neg: 70 },
  { height: 23, width: 95, pos: 70, neg: 70 },
  { height: 23, width: 107, pos: 70, neg: 70 },
  { height: 23, width: 119, pos: 70, neg: 70 },
  { height: 23, width: 131, pos: 70, neg: 70 },
  { height: 23, width: 143, pos: 70, neg: 70 },
  { height: 23, width: 155, pos: 70, neg: 70 },

  { height: 25, width: 73, pos: 70, neg: 70 },
  { height: 25, width: 105.25, pos: 70, neg: 70 },
  { height: 25, width: 110, pos: 70, neg: 70 },
  { height: 25, width: 111, pos: 70, neg: 70 },

  { height: 35, width: 71, pos: 70, neg: 70 },
  { height: 35, width: 83, pos: 70, neg: 70 },
  { height: 35, width: 95, pos: 70, neg: 70 },
  { height: 35, width: 107, pos: 70, neg: 70 },
  { height: 35, width: 119, pos: 70, neg: 70 },
  { height: 35, width: 131, pos: 70, neg: 70 },

  { height: 37.375, width: 73, pos: 70, neg: 70 },
  { height: 37.375, width: 105.25, pos: 70, neg: 70 },
  { height: 37.375, width: 110, pos: 70, neg: 70 },
  { height: 37.375, width: 111, pos: 70, neg: 70 },

  { height: 47, width: 71, pos: 70, neg: 70 },
  { height: 47, width: 83, pos: 70, neg: 70 },
  { height: 47, width: 95, pos: 70, neg: 70 },

  { height: 49.625, width: 73, pos: 70, neg: 70 },

  { height: 55, width: 73, pos: 70, neg: 70 },

  { height: 59, width: 71, pos: 70, neg: 70 },

  { height: 62, width: 73, pos: 70, neg: 70 },
];

export default {
  LMI: {
    '1': {
      ANNEALED: {
        _5_16: _5_16_ANNEALED_1_4,
        _7_16: ANNEALED_1_4,
        _9_16: ANNEALED_1_4,
        _15_16: ANNEALED_1_4,
      },
      HEAT_STRENGTHENED: {
        _5_16: [],
        _7_16: HEAT_STRENGTHENED_1_4,
        _9_16: HEAT_STRENGTHENED_1_4,
        _15_16: HEAT_STRENGTHENED_1_4,
      },
    },
    '2': {
      ANNEALED: {
        _5_16: _5_16_ANNEALED_2,
        _7_16: ANNEALED_2,
        _9_16: ANNEALED_2,
        _15_16: ANNEALED_2,
      },
      HEAT_STRENGTHENED: {
        _5_16: [],
        _7_16: HEAT_STRENGTHENED_2,
        _9_16: HEAT_STRENGTHENED_2,
        _15_16: HEAT_STRENGTHENED_2,
      },
    },
    '3': {
      ANNEALED: {
        _5_16: _5_16_ANNEALED_3,
        _7_16: ANNEALED_3,
        _9_16: ANNEALED_3,
        _15_16: ANNEALED_3,
      },
      HEAT_STRENGTHENED: {
        _5_16: [],
        _7_16: HEAT_STRENGTHENED_3,
        _9_16: HEAT_STRENGTHENED_3,
        _15_16: HEAT_STRENGTHENED_3,
      },
    },
    '4': {
      ANNEALED: {
        _5_16: _5_16_ANNEALED_1_4,
        _7_16: ANNEALED_1_4,
        _9_16: ANNEALED_1_4,
        _15_16: ANNEALED_1_4,
      },
      HEAT_STRENGTHENED: {
        _5_16: [],
        _7_16: HEAT_STRENGTHENED_1_4,
        _9_16: HEAT_STRENGTHENED_1_4,
        _15_16: HEAT_STRENGTHENED_1_4,
      },
    },
  },
  SMI: {
    '1': {
      ANNEALED: { _5_16: [], _7_16: SMI_1_4, _9_16: SMI_1_4, _15_16: SMI_1_4 },
      HEAT_STRENGTHENED: {
        _5_16: [],
        _7_16: SMI_1_4,
        _9_16: SMI_1_4,
        _15_16: SMI_1_4,
      },
    },
    '2': {
      ANNEALED: { _5_16: [], _7_16: SMI_2, _9_16: SMI_2, _15_16: SMI_2 },
      HEAT_STRENGTHENED: {
        _5_16: [],
        _7_16: SMI_2,
        _9_16: SMI_2,
        _15_16: SMI_2,
      },
    },
    '3': {
      ANNEALED: { _5_16: [], _7_16: SMI_3, _9_16: SMI_3, _15_16: SMI_3 },
      HEAT_STRENGTHENED: {
        _5_16: [],
        _7_16: SMI_3,
        _9_16: SMI_3,
        _15_16: SMI_3,
      },
    },
    '4': {
      ANNEALED: { _5_16: [], _7_16: SMI_1_4, _9_16: SMI_1_4, _15_16: SMI_1_4 },
      HEAT_STRENGTHENED: {
        _5_16: [],
        _7_16: SMI_1_4,
        _9_16: SMI_1_4,
        _15_16: SMI_1_4,
      },
    },
  },
};
