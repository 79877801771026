import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Mutation, Query } from 'react-apollo';
import { Form, Field } from 'react-final-form';
import ValidatedField from '../general/ValidatedField';
import { CLIENT_CREATE, CLIENT_UPDATE } from '../../mutations';
import { CLIENTS_LIST, CLIENT } from '../../queries';
import { AuthContext } from '../../auth';

const ClientMutation = (props) => {
  const { currentUser } = useContext(AuthContext);
  let formRef;
  const {
    formId,
    mutation,
    initialValues,
    updateCache,
    onCompleted,
    validate,
  } = props;

  return (
    <Mutation
      mutation={mutation}
      update={updateCache}
      onCompleted={() => onCompleted(formRef)}
    >
      {(mutation) => (
        <Form
          onSubmit={(variables) =>
            mutation({
              variables: {
                ...variables,
                markup: parseFloat(variables.markup),
                tax: parseFloat(variables.tax),
              },
            })
          }
          validate={validate}
          initialValues={{
            ...initialValues,
            ...(!currentUser.internal && { ownerId: currentUser.client.id }),
          }}
        >
          {({ handleSubmit, form }) => {
            formRef = form;
            return (
              <form id={formId} onSubmit={handleSubmit}>
                <Field name="id">
                  {(input) => <input {...input} type="hidden" />}
                </Field>
                {!currentUser.internal && (
                  <Field name="ownerId">
                    {(input) => <input {...input} type="hidden" />}
                  </Field>
                )}
                <ValidatedField name="name" label="Name" />
                <ValidatedField name="email" label="Email" type="email" />
                <div className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <Field
                    id="phone"
                    name="phone"
                    component="input"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="fax">Fax</label>
                  <Field
                    id="fax"
                    name="fax"
                    component="input"
                    className="form-control"
                  />
                </div>
                <ValidatedField name="address" label="Address" />
                <ValidatedField name="city" label="City" />
                <ValidatedField name="state" label="State" />
                <ValidatedField name="zip" label="Zip" />
                <ValidatedField name="markup" label="Markup" type="number" />
                <ValidatedField name="tax" label="Tax" type="number" />
              </form>
            );
          }}
        </Form>
      )}
    </Mutation>
  );
};

const ClientQuery = ({ clientId, updateCache, onCompleted, validate }) => (
  <Query query={CLIENT} variables={{ id: clientId }}>
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error</p>;

      return (
        <ClientMutation
          formId="update-client-form"
          mutation={CLIENT_UPDATE}
          initialValues={data.client}
          updateCache={updateCache}
          onCompleted={onCompleted}
          validate={validate}
        />
      );
    }}
  </Query>
);

export default class ClientForm extends Component {
  static defaultProps = {
    onCompleted: () => {},
  };

  validate = (values) => {
    const errors = {};
    const required = [
      'name',
      'email',
      'markup',
      'tax',
      'address',
      'city',
      'state',
      'zip',
    ];
    required.forEach((r) => !values[r] && (errors[r] = 'Required'));
    return errors;
  };

  updateCacheOnCreate = (cache, { data: { clientCreate } }) => {
    const { clients, tableInfo } = cache.readQuery({ query: CLIENTS_LIST });
    cache.writeQuery({
      query: CLIENTS_LIST,
      data: {
        clients: [clientCreate].concat(clients),
        tableInfo: { ...tableInfo, count: tableInfo.count + 1 },
      },
    });
  };

  updateCacheOnUpdate = (cache, { data: { clientUpdate } }) => {
    const { clients, tableInfo } = cache.readQuery({ query: CLIENTS_LIST });
    cache.writeQuery({
      query: CLIENTS_LIST,
      data: {
        tableInfo,
        clients: clients
          .filter((c) => c.id !== clientUpdate.id)
          .concat([clientUpdate])
          .sort((a, b) => moment(a.insertedAt).diff(b.insertedAt)),
      },
    });
  };

  render() {
    const { clientId, onCompleted } = this.props;
    return clientId ? (
      <ClientQuery
        clientId={clientId}
        updateCache={this.updateCacheOnUpdate}
        onCompleted={onCompleted}
        validate={this.validate}
      />
    ) : (
      <ClientMutation
        formId="create-client-form"
        mutation={CLIENT_CREATE}
        updateCache={this.updateCacheOnCreate}
        onCompleted={onCompleted}
        validate={this.validate}
      />
    );
  }
}

ClientForm.propTypes = {
  onCompleted: PropTypes.func.isRequired,
};
