import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Query } from 'react-apollo';
import { ESTIMATE_SYSTEMS } from '../../../queries';
import Pages from './Pages';
import GlassList from './GlassList';
import Labels from './Labels';
import { AuthContext } from '../../../auth';

export default class EstimatePrint extends Component {
  static contextType = AuthContext;

  goBack = () => {
    const { history, match } = this.props;
    history.push(`/estimates/${match.params.id}`);
  };

  render() {
    const { currentUser } = this.context;
    const { match, location } = this.props;
    const { id } = match.params;
    const params = new URLSearchParams(location.search);
    const isOrder = !!params.get('order');

    return (
      <Query query={ESTIMATE_SYSTEMS} variables={{ id }}>
        {({ loading, error, data: { estimate } = {} }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error</p>;

          return (
            <div className="pages-container">
              <div className="actions">
                <div className="dropup">
                  <button
                    type="button"
                    className="type-button btn btn-outline-primary"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon icon={['fal', 'file-chart-line']} />
                  </button>
                  <div className="dropdown-menu">
                    {currentUser.internal && (
                      <>
                        <NavLink
                          className="dropdown-item"
                          exact
                          to={`${match.url}${location.search}`}
                        >
                          Cut List
                        </NavLink>
                        <NavLink
                          className="dropdown-item"
                          to={`${match.url}/glass${location.search}`}
                        >
                          Glass List
                        </NavLink>
                        <NavLink
                          className="dropdown-item"
                          to={`${match.url}/labels${location.search}`}
                        >
                          Labels
                        </NavLink>
                      </>
                    )}
                    <NavLink
                      className="dropdown-item"
                      to={`${match.url}/total${location.search}`}
                    >
                      Total
                    </NavLink>
                    <NavLink
                      className="dropdown-item"
                      to={`${match.url}/line${location.search}`}
                    >
                      Line/Total
                    </NavLink>
                    {currentUser.internal && (
                      <NavLink
                        className="dropdown-item"
                        to={`${match.url}/contract${location.search}`}
                      >
                        Contract
                      </NavLink>
                    )}
                  </div>
                </div>
                <button
                  onClick={window.print}
                  className="print-button btn btn-outline-success"
                >
                  <FontAwesomeIcon icon={['fal', 'print']} />
                </button>
                <button
                  onClick={this.goBack}
                  className="close-button btn btn-outline-danger"
                >
                  <FontAwesomeIcon icon={['fal', 'times']} />
                </button>
              </div>
              {currentUser.internal && (
                <>
                  <Route
                    exact={true}
                    path={`${match.path}`}
                    render={(props) => (
                      <Pages
                        {...props}
                        estimate={estimate}
                        type="print"
                        order={isOrder}
                      />
                    )}
                  />
                  <Route
                    path={`${match.path}/glass`}
                    render={(props) => (
                      <GlassList
                        {...props}
                        estimate={estimate}
                        order={isOrder}
                      />
                    )}
                  />
                  <Route
                    path={`${match.path}/labels`}
                    render={(props) => (
                      <Labels {...props} estimate={estimate} order={isOrder} />
                    )}
                  />
                </>
              )}
              <Route
                path={`${match.path}/total`}
                render={(props) => (
                  <Pages
                    {...props}
                    estimate={estimate}
                    type="total"
                    order={isOrder}
                  />
                )}
              />
              <Route
                path={`${match.path}/line`}
                render={(props) => (
                  <Pages
                    {...props}
                    estimate={estimate}
                    type="line"
                    order={isOrder}
                  />
                )}
              />
              {currentUser.internal && (
                <Route
                  path={`${match.path}/contract`}
                  render={(props) => (
                    <Pages
                      {...props}
                      estimate={estimate}
                      type="contract"
                      order={isOrder}
                    />
                  )}
                />
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}
