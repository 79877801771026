import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../general/Modal';
import $ from 'jquery';
import { Query } from 'react-apollo';
import { CLIENTS_LIST } from '../../queries';
import ClientsList from './ClientsList';
import ClientForm from './ClientForm';
import { AuthContext } from '../../auth';

export default class Clients extends Component {
  static contextType = AuthContext;

  state = {
    selectedClientId: null,
  };

  setSelectedClientId = (selectedClientId) =>
    this.setState({ selectedClientId });

  submitClientForm = (id) => {
    document
      .getElementById(id)
      .dispatchEvent(new Event('submit', { cancelable: true }));
  };

  onClientFormCompleted = (form) => {
    $('#new-client-modal').modal('hide');
    $('#edit-client-modal').modal('hide');
    setTimeout(form.reset);
  };

  render() {
    const { currentUser } = this.context;
    return (
      <Query query={CLIENTS_LIST}>
        {({ loading, error, data }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error</p>;

          const { clients, tableInfo } = data;

          return (
            <div className="row">
              <div className="col-sm-3">
                <h3>Current Clients</h3>
                <h6 className="text-muted">
                  {currentUser.internal ? tableInfo.count : tableInfo.count - 1}{' '}
                  clients
                </h6>
                <button
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#new-client-modal"
                >
                  Add Client{' '}
                  <FontAwesomeIcon icon={['fal', 'plus']} className="ml-2" />
                </button>
              </div>
              <div className="col-sm-9">
                <ClientsList
                  selectClient={this.setSelectedClientId}
                  clients={
                    currentUser.internal
                      ? clients
                      : clients.filter((c) => c.id !== currentUser.client.id)
                  }
                />
              </div>
              <Modal
                modalId="new-client-modal"
                title="New Client"
                onContinue={() => this.submitClientForm('create-client-form')}
              >
                <ClientForm onCompleted={this.onClientFormCompleted} />
              </Modal>
              <Modal
                modalId="edit-client-modal"
                title="Edit Client"
                onContinue={() => this.submitClientForm('update-client-form')}
              >
                <ClientForm
                  onCompleted={this.onClientFormCompleted}
                  clientId={this.state.selectedClientId}
                />
              </Modal>
            </div>
          );
        }}
      </Query>
    );
  }
}
