import gql from 'graphql-tag'

export const PROJECT_CREATE = gql`
  mutation ProjectCreate(
    $clientId: ID!,
    $name: String!,
  ) {
    projectCreate(clientId: $clientId, name: $name) {
      id
      name
      insertedAt
      client {
        id
        name
      }
    }
  }
`

export const PROJECT_UPDATE = gql`
  mutation ProjectUpdate(
    $id: ID!
    $clientId: ID,
    $name: String,
  ) {
    projectUpdate(id: $id, clientId: $clientId, name: $name) {
      id
      name
      insertedAt
      client {
        id
        name
      }
    }
  }
`

export const PROJECT_DELETE = gql`
  mutation ProjectDelete($id: ID!) {
    projectDelete(id: $id) {
      id
    }
  }
`