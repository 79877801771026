import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Mutation, Query } from 'react-apollo'
import { Form, Field } from 'react-final-form'
import ValidatedField from '../general/ValidatedField'
import { PROJECT_CREATE, PROJECT_UPDATE } from '../../mutations'
import { PROJECTS_LIST, PROJECT, CLIENTS_LIST } from '../../queries'

const ProjectMutation = props => {
  let formRef
  const { formId, mutation, initialValues, updateCache, onCompleted, validate } = props
  return (
    <Query query={CLIENTS_LIST}>
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>
        if (error) return <p>Error</p>
        if (data.clients.length === 0) return null

        const sortedClients = data.clients.sort((a, b) => a.name.localeCompare(b.name))
        const _initialValues = initialValues ? initialValues : { clientId: sortedClients[0].id }

        return (
          <Mutation
            mutation={mutation}
            update={updateCache}
            onCompleted={() => onCompleted(formRef)}
          >
            {mutation => (
              <Form
                onSubmit={variables => mutation({ variables })}
                validate={validate}
                initialValues={_initialValues}
              >
                {({ handleSubmit, form }) => {
                  formRef = form
                  return (
                    <form id={formId} onSubmit={handleSubmit}>
                      <Field name="id">{input => <input {...input} type="hidden"></input>}</Field>
                      <div className="form-group">
                        <label htmlFor="clientId">Client</label>
                        <Field id="clientId" name="clientId" component="select" className="form-control">
                          {sortedClients.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                        </Field>
                      </div>
                      <ValidatedField name="name" label="Name" />
                    </form>
                  )
                }}
              </Form>
            )}
          </Mutation>
        )
      }}
    </Query>
  )
}

const ProjectQuery = ({ projectId, updateCache, onCompleted, validate }) => (
  <Query query={PROJECT} variables={{ id: projectId }}>
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>
      if (error) return <p>Error</p>

      return (
        <ProjectMutation
          formId="update-project-form"
          mutation={PROJECT_UPDATE}
          initialValues={{...data.project, clientId: data.project.client.id}}
          updateCache={updateCache}
          onCompleted={onCompleted}
          validate={validate}
        />
      )
    }}
  </Query>
)

export default class ProjectForm extends Component {
  static defaultProps = {
    onCompleted: () => {}
  }

  validate = values => {
    const errors = {}
    if (!values.name) {
      errors.name = "Required"
    }
    return errors
  }

  updateCacheOnCreate = (cache, { data: { projectCreate } }) => {
    const { projects, tableInfo } = cache.readQuery({ query: PROJECTS_LIST })
    cache.writeQuery({
      query: PROJECTS_LIST,
      data: {
        projects: [projectCreate].concat(projects),
        tableInfo: { ...tableInfo, count: tableInfo.count + 1 }
      }
    })
  }

  updateCacheOnUpdate = (cache, { data: { projectUpdate } }) => {
    const { projects, tableInfo } = cache.readQuery({ query: PROJECTS_LIST })
    cache.writeQuery({
      query: PROJECTS_LIST,
      data: {
        tableInfo,
        projects: projects.filter(p => p.id !== projectUpdate.id).concat([projectUpdate])
          .sort((a, b) => moment(a.insertedAt).diff(b.insertedAt))
      }
    })
  }

  render() {
    const { projectId, onCompleted } = this.props
    return projectId
      ? <ProjectQuery
          projectId={projectId}
          updateCache={this.updateCacheOnUpdate}
          onCompleted={onCompleted}
          validate={this.validate}
        />
      : <ProjectMutation
          formId="create-project-form"
          mutation={PROJECT_CREATE}
          updateCache={this.updateCacheOnCreate}
          onCompleted={onCompleted}
          validate={this.validate}
        />
  }
}

ProjectForm.propTypes = {
  onCompleted: PropTypes.func.isRequired
}