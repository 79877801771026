import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { frenchDoor as draw } from '../../../draw';

const Drawing = ({ formContext: { control }, two, twoElem }) => {
  const [
    config,
    doorWidth,
    leftWidth,
    rightWidth,
    height,
    hinges,
    active,
  ] = useWatch({
    control: control,
    name: [
      'config',
      'doorWidth',
      'leftWidth',
      'rightWidth',
      'height',
      'hinges',
      'active',
    ],
  });

  useEffect(
    () => {
      if (two && doorWidth && height) {
        const viewBox = draw({
          two,
          config,
          doorWidth: parseFloat(doorWidth),
          leftWidth: leftWidth ? parseFloat(leftWidth) : 0,
          rightWidth: rightWidth ? parseFloat(rightWidth) : 0,
          height: parseFloat(height),
          hinge: hinges,
          active,
        });
        twoElem.children[0].setAttribute('viewBox', viewBox.join(' '));
      }
    },
    [two, config, doorWidth, height, leftWidth, rightWidth, hinges, active]
  );

  return <div id="drawing-container" className="flex-grow-1" />;
};

Drawing.propTypes = {
  formContext: PropTypes.shape({ control: PropTypes.object.isRequired })
    .isRequired,
};

export default Drawing;
