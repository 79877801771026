import React from "react";
import { Redirect } from "react-router-dom";
import { signedIn } from "../auth";
import Form from "./Form";
import logo from "../../assets/images/logo.png";

export default ({ location }) => {
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const email = params.get("email");

  if (signedIn()) return <Redirect to="/" />;
  if (!(token && email)) return <Redirect to="/signin" />;

  return (
    <div className="signup-container">
      <div className="signup-form p-4">
        <div className="content">
          <Form token={token} email={email} />
        </div>
      </div>
      <div className="powered-by">
        <img src={logo} alt="Krystal logo" width="40" height="40" />
        <h6 className="font-wight-light">Powered by Krystal</h6>
      </div>
    </div>
  );
};
